/* eslint-disable @typescript-eslint/no-explicit-any */
import Empty from "@/components/Empty";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { BarChart2 } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  AddDashboardItemModal,
  EditDashboardItemModal,
} from "./components/dashboard-items-input";
import { Sortable, SortableItem } from "@heffl/ui/components/sortable";
import { closestCorners } from "@dnd-kit/core";
import { RenderDashboardChartItem } from "./charts-item-helpers";
import { ChartDetailsModal } from "./components/dashboard-items-modals";
import { Icon } from "@iconify/react";
const CustomDashboard = () => {
  const { id } = useParams();

  const trpcUtils = trpc.useUtils();

  const [addChart, setAddChart] = useState(false);
  const [editChart, setEditChart] = useState<number | null>(null);
  const [showExpandedChart, setShowExpandedChart] = useState<number | null>(
    null
  );

  const { data } = trpc.dashboards.details.useQuery(Number(id), {
    enabled: !!id,
  });

  const bulkItemsUpdate = trpc.dashboards.items.bulkUpdate.useMutation();

  if (!data) {
    return <FullScreenSpinner />;
  }

  return (
    <Page
      title={data.title}
      breadcrumbs={[
        { label: "Dashboards", path: "/projects/dashboard" },
        { label: data.title },
      ]}
    >
      <div className="flex justify-start">
        <ResponsiveActionButton
          text="Add Chart"
          onClick={() => setAddChart(true)}
        />
      </div>
      {!data.dashboardItems.length && (
        <Empty
          title="No charts added yet"
          icon={BarChart2}
          description="Add a chart to your dashboard to get started"
          actionText="Add Chart"
          onAction={() => {
            setAddChart(true);
          }}
        />
      )}
      <ChartDetailsModal
        open={showExpandedChart !== null}
        onClose={() => setShowExpandedChart(null)}
        id={showExpandedChart || 0}
      />
      <AddDashboardItemModal
        open={addChart}
        onClose={() => setAddChart(false)}
        dashboardId={Number(id)}
      />
      {!!editChart && (
        <EditDashboardItemModal
          open={!!editChart}
          onClose={() => setEditChart(null)}
          id={editChart}
        />
      )}

      <Sortable
        orientation="mixed"
        collisionDetection={closestCorners}
        value={data.dashboardItems}
        onValueChange={(value) => {
          console.log(value);
          trpcUtils.dashboards.details.setData(Number(id), {
            ...data,
            dashboardItems: value,
          });
          bulkItemsUpdate.mutate(
            value.map((item, i) => ({
              id: item.id,
              position: i + 1,
            }))
          );
        }}
        overlay={<div className="rounded-md size-full bg-primary/10" />}
      >
        <div className="flex flex-wrap gap-3 pt-4">
          {data.dashboardItems.map((item) => (
            <SortableItem key={item.id} value={item.id} asTrigger asChild>
              <div
                key={item.id}
                className="relative p-4 w-full rounded-xl border border-gray-200 transition-all duration-300 hover:border-gray-400 group h-fit"
                style={{ width: `${item.width - 1}%` }}
              >
                <div className="flex absolute top-3 right-3 gap-1 items-center p-1 rounded-lg border opacity-0 transition-all duration-300 group-hover:opacity-100 !z-50 bg-white">
                  <Button
                    onClick={() => setShowExpandedChart(item.id)}
                    iconify="tabler:arrows-maximize"
                    variant="ghost"
                    size="sm"
                  />
                  <Button
                    variant="ghost"
                    iconify="tabler:pencil"
                    onClick={() => setEditChart(item.id)}
                    size="sm"
                  />
                </div>
                <div className="flex gap-2 items-center text-sm font-medium">
                  {item.title}
                  {!!Object.keys(item.filtersValues as Record<string, string>)
                    .length && (
                    <div className="flex gap-1 items-center text-xs font-normal text-gray-500">
                      <Icon icon="tabler:filter" />
                      {
                        Object.keys(
                          item.filtersValues as Record<string, string>
                        ).length
                      }{" "}
                      Filters
                    </div>
                  )}
                </div>
                <RenderDashboardChartItem
                  name={item.name}
                  filterValues={item.filtersValues}
                  width={item.width}
                />
              </div>
            </SortableItem>
          ))}
        </div>
      </Sortable>
    </Page>
  );
};

export default CustomDashboard;
