import Page from "@/components/page";
import useTeam from "@/lib/hooks/useTeam";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import SimpleTable from "@heffl/ui/components/simple-table";
import React from "react";
import { useNavigate } from "react-router-dom";

export const anilTeamIds = [24, 18];

const reports = [
  {
    title: "Invoice Summary Report",
    description: "Overview of invoice status and key financial metrics.",
    slug: "invoice-summary-report",
  },
  {
    title: "Profit Loss Report",
    description: "Overview of profit and loss.",
    slug: "profit-loss-report",
  },
  {
    title: "Payments Received Report",
    description: "Detailed report on payments received.",
    slug: "payments-received-report",
  },
  {
    title: "Quotes Summary Report",
    description: "Overview of quote status and key financial metrics.",
    slug: "quotes-summary-report",
  },
  {
    title: "Vendor Summary Report",
    description: "Overview of vendor status and key financial metrics.",
    slug: "vendor-summary-report",
  },
  {
    title: "Customer Balances Report",
    description: "Overview of customer balances.",
    slug: "customer-balances-report",
  },
  {
    title: "Vendor Balances Report",
    description: "Overview of vendor balances.",
    slug: "vendor-balances-report",
  },
  {
    title: "Typing Center Report",
    description: "Overview of typing center status and key financial metrics.",
    slug: "typing-center-report",
  },
];

const InvoiceReports: React.FC = () => {
  const navigate = useNavigate();
  const team = useTeam();

  if (!team) return <FullScreenSpinner />;
  if (!team.user.permissions.VIEW_SALES_REPORTS.allowed)
    return <NoPermissionScreen />;

  return (
    <Page title="Invoice Reports">
      <SimpleTable
        fixedHeader={false}
        columns={[
          {
            label: "Report Title",
            render: (report) => (
              <span className="font-medium text-blue-400 cursor-pointer">
                {report.title}
              </span>
            ),
          },
          {
            label: "Description",
            render: (report) => (
              <span className="text-sm text-gray-500">
                {report.description}
              </span>
            ),
          },
          {
            label: "Created By",
            render: () => (
              <span className="text-sm text-gray-500">System generated</span>
            ),
          },
        ]}
        rows={
          anilTeamIds.includes(team.id)
            ? reports.filter((r) => r.slug !== "typing-center-report")
            : reports
        }
        idKey="slug"
        onRowClick={(report) => navigate(`/sales/reports/${report.slug}`)}
      />
    </Page>
  );
};

export default InvoiceReports;
