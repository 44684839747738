import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button } from "@heffl/ui/components/primitives/button";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { useState } from "react";

export type PromptModalProps = {
  title?: string;
  message?: string;
  defaultValue?: string;
  placeholder?: string;
  onClose?: () => void;
  onSubmit: (value: string) => void;
  submitText?: string;
  cancelText?: string;
  variant?: "primary" | "destructive";
};

export const PromptModal = NiceModal.create(
  ({
    title = "Input",
    message = "",
    defaultValue = "",
    placeholder = "",
    onClose,
    onSubmit,
    submitText = "OK",
    cancelText = "Cancel",
    variant = "primary",
  }: PromptModalProps) => {
    const modal = useModal();
    const [value, setValue] = useState(defaultValue);

    const onModalClose = () => {
      setValue("");
      onClose?.();
      modal.hide();
      modal.remove();
    };

    const handleSubmit = () => {
      onSubmit(value);
      onModalClose();
    };

    return (
      <ModalDrawer
        open={modal.visible}
        onClose={onModalClose}
        title={title}
        footer={
          <div className="flex gap-2 w-full">
            <Button variant="outline" className="w-full" onClick={onModalClose}>
              {cancelText}
            </Button>
            <Button className="w-full" variant={variant} onClick={handleSubmit}>
              {submitText}
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-1">
          {message && <p className="text-sm text-gray-600">{message}</p>}
          <input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="px-3 py-2 w-full rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            autoFocus
            placeholder={placeholder}
          />
        </div>
      </ModalDrawer>
    );
  }
);
