import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { Card } from "@heffl/ui/components/primitives/card";
import StatsBar from "@heffl/ui/components/stats-cards";
import { Building2, DollarSign, Home, Users } from "lucide-react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const occupancyData = [
  { month: "Jan", occupancyRate: 92, vacancyRate: 8 },
  { month: "Feb", occupancyRate: 94, vacancyRate: 6 },
  { month: "Mar", occupancyRate: 91, vacancyRate: 9 },
  { month: "Apr", occupancyRate: 95, vacancyRate: 5 },
  { month: "May", occupancyRate: 97, vacancyRate: 3 },
  { month: "Jun", occupancyRate: 96, vacancyRate: 4 },
];

const revenueData = [
  { month: "Jan", revenue: 125000, expenses: 45000 },
  { month: "Feb", revenue: 135000, expenses: 48000 },
  { month: "Mar", revenue: 128000, expenses: 51000 },
  { month: "Apr", revenue: 142000, expenses: 49000 },
  { month: "May", revenue: 148000, expenses: 52000 },
  { month: "Jun", revenue: 152000, expenses: 50000 },
];

const propertyTypeData = [
  { name: "Residential", value: 65, color: "#8884d8" },
  { name: "Commercial", value: 25, color: "#82ca9d" },
  { name: "Industrial", value: 10, color: "#ffc658" },
];

const maintenanceData = [
  { month: "Jan", requests: 24 },
  { month: "Feb", requests: 18 },
  { month: "Mar", requests: 29 },
  { month: "Apr", requests: 22 },
  { month: "May", requests: 16 },
  { month: "Jun", requests: 20 },
];

const PropertyManagementDashboard = () => {
  return (
    <Page title="Dashboard">
      <div className="space-y-6">
        <StatsBar
          items={[
            {
              title: "Total Properties",
              value: "156",
              icon: Building2,
            },
            {
              title: "Total Units",
              value: "842",
              icon: Home,
            },
            {
              title: "Active Tenants",
              value: "789",
              icon: Users,
            },
            {
              title: "Monthly Revenue",
              value: heffl.format.currency(152000, "AED"),
              icon: DollarSign,
            },
          ]}
        />

        <div className="grid grid-cols-2 gap-6">
          <Card title="Occupancy Trends">
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={occupancyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="occupancyRate"
                    name="Occupancy Rate %"
                    fill="#8884d8"
                  />
                  <Bar
                    dataKey="vacancyRate"
                    name="Vacancy Rate %"
                    fill="#82ca9d"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>

          <Card title="Revenue vs Expenses">
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={revenueData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="revenue"
                    name="Revenue"
                    stroke="#8884d8"
                  />
                  <Line
                    type="monotone"
                    dataKey="expenses"
                    name="Expenses"
                    stroke="#82ca9d"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>

          <Card title="Property Type Distribution">
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={propertyTypeData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    label={({ name, value }) => `${name} ${value}%`}
                  >
                    {propertyTypeData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Card>

          <Card title="Maintenance Requests">
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={maintenanceData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Bar
                    dataKey="requests"
                    name="Maintenance Requests"
                    fill="#ffc658"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
      </div>
    </Page>
  );
};

export default PropertyManagementDashboard;
