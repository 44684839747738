import { z } from "zod";
import enums from "./enums";

export const pmLeaseItems = z.object({
  productId: z.number().nullish(),
  quantity: z.coerce.number(),
  price: z.coerce.number(),
  description: z.string().default(""),
  tax: z.coerce.number().default(0),
  viewType: enums.lineItemViewType,
  paymentType: enums.pmLeaseChargeTypes.default("RECURRING"),
  name: z.string().min(1, { message: "Please add a item or title" }),
  discount: z.number().default(0),
});

export const pmLease = z.object({
  clientId: z.number(),
  startDate: z.date(),
  endDate: z.date(),
  firstRentDate: z.date(),
  pmPropertyUnitId: z.number(),
  pmLeaseItems: pmLeaseItems.array().min(1, {
    message: "Please add at least one item",
  }),
});

export const pmProperty = z.object({
  name: z.string().min(1, { message: "Please add a name" }),
  city: z.string(),
  address: z.string(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  stateId: z.number(),
  countryId: z.number(),
  googleMapsPlaceId: z.string().nullish(),
  googleMapsLink: z.string().nullish(),
});

export const pmPropertyUnit = z.object({
  name: z.string(),
  pmPropertyId: z.number(),
  sizeSqft: z.coerce.number().nullish(),
  marketRent: z.coerce.number().default(0),
});
