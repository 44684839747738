import FilterBar from "@/components/filter-bar";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import enums, { enumsToOptions } from "@heffl/server/src/schemas/enums";
import { Badge } from "@heffl/ui/components/primitives/badge";
import SimpleTable from "@heffl/ui/components/simple-table";
import { cn } from "@heffl/ui/lib/utils";
import { Building2, Sparkles } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import { z } from "zod";

const TaskStatusByProject: React.FC = () => {
  const [filters, setFilters] = useParamsState<
    RouterInputs["projects"]["reports"]["taskStatusByProject"]
  >({
    startDate: undefined,
    createdAt: undefined,
    status: ["ACTIVE"],
  });

  const { data: taskData } =
    trpc.projects.reports.taskStatusByProject.useQuery(filters);

  return (
    <div className="w-full">
      <FilterBar
        filters={[
          {
            label: "Start Date",
            type: "date-range",
            key: "startDate",
            value: filters.startDate,
            onChange: (value) => setFilters({ startDate: value }),
          },
          {
            label: "Created At",
            type: "date-range",
            key: "createdAt",
            value: filters.createdAt,
            onChange: (value) => setFilters({ createdAt: value }),
          },
          {
            label: "Status",
            type: "checkbox",
            key: "status",
            value: filters.status || [],
            onChange: (value: (string | number)[]) =>
              setFilters({
                status: value as z.infer<typeof enums.projectStatsuses>[],
              }),
            // @ts-ignore
            options: enumsToOptions(enums.projectStatsuses),
          },
        ]}
      />

      <div className="overflow-hidden relative p-3 mt-4 mb-6 bg-gradient-to-br from-indigo-50 to-purple-50 rounded-xl">
        <div className="absolute top-0 right-0 w-32 h-32 bg-purple-200 rounded-full opacity-20 filter blur-3xl animate-pulse" />

        <div className="flex gap-3 items-center mb-4">
          <div className="p-2 bg-purple-100 rounded-lg">
            <Sparkles className="w-6 h-6 text-purple-600 animate-pulse" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900">
            Project Status Overview
          </h3>
          <Badge variant="warning">Beta</Badge>
        </div>

        <p className="leading-relaxed text-gray-700">
          {taskData?.length ? (
            <>
              Currently tracking {taskData.length}{" "}
              {taskData.length === 1 ? "project" : "projects"}.{" "}
              {(() => {
                const totalTasks = taskData.reduce(
                  (acc, project) =>
                    acc +
                    project.stats.todo +
                    project.stats.inProgress +
                    project.stats.onHold +
                    project.stats.completed,
                  0
                );
                const completedTasks = taskData.reduce(
                  (acc, project) => acc + project.stats.completed,
                  0
                );
                const inProgressTasks = taskData.reduce(
                  (acc, project) => acc + project.stats.inProgress,
                  0
                );

                return `There are ${totalTasks} total tasks, with ${completedTasks} completed and ${inProgressTasks} currently in progress. ${
                  completedTasks > 0
                    ? `The team has achieved a ${Math.round(
                        (completedTasks / totalTasks) * 100
                      )}% completion rate.`
                    : ""
                }`;
              })()}
            </>
          ) : (
            "No projects found matching the current filters."
          )}
        </p>

        <div className="absolute bottom-0 left-0 w-40 h-40 bg-indigo-200 rounded-full opacity-20 filter blur-3xl delay-700 animate-pulse" />
      </div>

      <SimpleTable
        rows={taskData || []}
        idKey="title"
        height={400}
        columns={[
          {
            label: "Project",
            fixed: "left",
            className: "w-16",
            render: (row) => {
              const total =
                row.stats.todo +
                row.stats.inProgress +
                row.stats.onHold +
                row.stats.completed;
              return (
                <div className="flex flex-col gap-2">
                  <div className="flex-1">
                    <h4 className="text-sm font-semibold text-gray-900 truncate">
                      <Link
                        to={`/projects/details/${row.id}`}
                        className="hover:text-primary"
                      >
                        {row.title}
                      </Link>
                    </h4>
                    <div className="flex gap-1 items-center text-xs text-gray-500">
                      <Building2 className="w-3.5 h-3.5" />
                      {row.clients ? (
                        <Link
                          to={`/sales/clients/details/${row.clients.id}`}
                          className="hover:text-primary"
                        >
                          {row.clients.name}
                        </Link>
                      ) : (
                        <span>No client assigned</span>
                      )}
                    </div>
                  </div>
                  <div className="w-24">
                    <div className="flex h-1.5 rounded-full overflow-hidden bg-gray-100">
                      {[
                        { value: row.stats.todo, color: "bg-yellow-500" },
                        { value: row.stats.inProgress, color: "bg-blue-500" },
                        { value: row.stats.onHold, color: "bg-orange-500" },
                        { value: row.stats.completed, color: "bg-emerald-500" },
                      ].map(
                        (stat, i) =>
                          stat.value > 0 && (
                            <div
                              key={i}
                              className={`h-full ${stat.color}`}
                              style={{
                                width: `${(stat.value / total) * 100}%`,
                              }}
                            />
                          )
                      )}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            label: "Open",
            render: (row) => (
              <span className="text-gray-500">{row.stats.todo}</span>
            ),
          },
          {
            label: "In Progress",
            render: (row) => (
              <span className="text-blue-500">{row.stats.inProgress}</span>
            ),
          },
          {
            label: "On Hold",
            render: (row) => (
              <span className="text-orange-500">{row.stats.onHold}</span>
            ),
          },
          {
            label: "Completed",
            render: (row) => (
              <span className="text-emerald-500">{row.stats.completed}</span>
            ),
          },
          {
            label: "Total",
            render: (row) => {
              const total =
                row.stats.todo +
                row.stats.inProgress +
                row.stats.onHold +
                row.stats.completed;
              return <span className="font-medium">{total}</span>;
            },
          },
          {
            label: "Completion Rate",
            render: (row) => {
              const total =
                row.stats.todo +
                row.stats.inProgress +
                row.stats.onHold +
                row.stats.completed;
              const completionRate =
                total > 0 ? Math.round((row.stats.completed / total) * 100) : 0;
              return (
                <span
                  className={cn(
                    "font-medium",
                    completionRate >= 75
                      ? "text-emerald-500"
                      : completionRate >= 50
                      ? "text-blue-500"
                      : completionRate >= 25
                      ? "text-orange-500"
                      : "text-gray-500"
                  )}
                >
                  {completionRate}%
                </span>
              );
            },
          },
        ]}
        className="mt-8"
      />
    </div>
  );
};

export default TaskStatusByProject;
