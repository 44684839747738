import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import dayjs from "dayjs";
import CashflowChart from "./components/cashflow-chart";
import PayablesChart from "./components/payables-chart";
import RecievablesChart from "./components/recievables-chart";
import ExpensePieChart from "./components/expense-piechart";
import { useState } from "react";
import IncomeExpenseChart from "./components/income-expense-chart";
import CashflowAccountsBalances from "./cashflow/cashflow-accounts-balances";

export const periodOptions = {
  this_month: {
    startDate: dayjs().startOf("month").toDate(),
    endDate: dayjs().endOf("month").toDate(),
  },
  last_month: {
    startDate: dayjs().subtract(1, "month").startOf("month").toDate(),
    endDate: dayjs().subtract(1, "month").endOf("month").toDate(),
  },
  last_6_months: {
    startDate: dayjs().subtract(6, "month").startOf("month").toDate(),
    endDate: dayjs().endOf("month").toDate(),
  },
  last_12_months: {
    startDate: dayjs().subtract(12, "month").startOf("month").toDate(),
    endDate: dayjs().endOf("month").toDate(),
  },
};

const BooksDashboard = () => {
  const [topExpensesPeriod, setTopExpensesPeriod] =
    useState<keyof typeof periodOptions>("last_12_months");

  const { data: user } = trpc.users.currentUser.useQuery();

  const { data: dashboardData } = trpc.books.dashboards.dashboard.useQuery({
    year: dayjs().year(),
  });

  const { data: topExpenses } = trpc.books.dashboards.topExpenses.useQuery({
    startDate: periodOptions[topExpensesPeriod].startDate,
    endDate: periodOptions[topExpensesPeriod].endDate,
  });

  return (
    <Page title="Books Dashboard" className="p-4 min-h-screen md:p-6">
      <div className="mx-auto max-w-7xl">
        <div className="flex items-center text-xl font-bold text-gray-800 md:text-2xl">
          <div className="pr-2 motion-preset-shake">👋</div>
          {dayjs().hour() < 12
            ? `Good morning, ${user?.firstName}!`
            : dayjs().hour() < 17
            ? `Good afternoon, ${user?.firstName}!`
            : `Good evening, ${user?.firstName}!`}
        </div>
        <p className="text-sm md:text-base text-[#6B7280]">
          {dayjs().format("dddd, MMMM D, YYYY")}
        </p>

        <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-3">
          <div className="space-y-4 lg:col-span-2">
            <Card parentClassName="w-full shadow-none">
              <div className="flex justify-between w-full">
                <h2 className="text-lg font-semibold text-gray-800 md:text-xl">
                  Cashflow
                </h2>
              </div>
              {dashboardData ? (
                <CashflowChart
                  data={dashboardData.cashflow.data}
                  finalBalance={dashboardData.cashflow.finalBalance}
                />
              ) : (
                <FullScreenSpinner
                  text="Loading cashflow chart..."
                  className="h-[250px] md:h-[316px]"
                />
              )}
            </Card>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <Card parentClassName="w-full shadow-none">
                {dashboardData ? (
                  <RecievablesChart
                    current={dashboardData.receivables.current}
                    overdue={dashboardData.receivables.overdue}
                  />
                ) : (
                  <FullScreenSpinner className="h-[140px]" />
                )}
              </Card>
              <Card parentClassName="w-full shadow-none">
                {dashboardData ? (
                  <PayablesChart
                    current={dashboardData.payables.current}
                    overdue={dashboardData.payables.overdue}
                  />
                ) : (
                  <FullScreenSpinner className="h-[140px]" />
                )}
              </Card>
            </div>

            <Card parentClassName="w-full shadow-none">
              {dashboardData ? <IncomeExpenseChart /> : <FullScreenSpinner />}
            </Card>
          </div>

          <div className="space-y-4">
            <Card parentClassName="w-full shadow-none">
              {topExpenses ? (
                <ExpensePieChart
                  data={topExpenses}
                  expenses={topExpenses}
                  onChange={(value) => setTopExpensesPeriod(value)}
                  value={topExpensesPeriod}
                />
              ) : (
                <FullScreenSpinner />
              )}
            </Card>

            <Card parentClassName="w-full shadow-none">
              <h2 className="text-lg font-semibold text-gray-800 md:text-xl">
                Bank & Cash Accounts
              </h2>
              <CashflowAccountsBalances />
            </Card>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default BooksDashboard;
