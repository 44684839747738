import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@heffl/ui/components/primitives/dropdown-menu";
import { cn } from "@heffl/ui/lib/utils";
import { LucideIcon } from "lucide-react";
import { Icon as IconifyIcon } from "@iconify/react";

export type DropMenuItem = {
  type?: "item";
  label: React.ReactNode | string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  icon?: LucideIcon;
  iconify?: string;
  iconClassName?: string;
  style?: object;
};

type Separator = {
  type: "separator";
};

type Heading = {
  type: "heading";
  label: string;
};

const DropMenu = ({
  children,
  items,
  className,
  align = "end",
  contentClassName,
  empty,
}: {
  children: React.ReactNode;
  items: (DropMenuItem | Separator | Heading)[];
  className?: string;
  align?: "start" | "end" | "center";
  contentClassName?: string;
  empty?: React.ReactNode;
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={cn(className)}>
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent align={align} className={cn(contentClassName)}>
        {items.length === 0 && empty
          ? empty
          : items.map((item, index) => {
              if (item.type === "separator") {
                return <DropdownMenuSeparator key={index} />;
              }
              if (item.type === "heading") {
                return (
                  <DropdownMenuLabel key={index}>
                    {item.label}
                  </DropdownMenuLabel>
                );
              }
              const Icon = item.icon;
              return (
                <DropdownMenuItem
                  key={index}
                  className={cn(
                    "cursor-pointer flex items-center gap-2 text-wrap",
                    item.className
                  )}
                  style={item.style}
                  onClick={(e) => {
                    e.stopPropagation();
                    item.onClick(e);
                  }}
                >
                  {Icon && (
                    <div className="flex-shrink-0">
                      <Icon className={cn("w-4 h-4", item.iconClassName)} />
                    </div>
                  )}
                  {item.iconify && (
                    <div className="flex-shrink-0">
                      <IconifyIcon icon={item.iconify} className="w-4 h-4" />
                    </div>
                  )}
                  <div className="flex-grow text-ellipsis">{item.label}</div>
                </DropdownMenuItem>
              );
            })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default DropMenu;
