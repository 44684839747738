import FilterBar from "@/components/filter-bar";
import heffl from "@/helpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { BarChart } from "@heffl/ui/components/charts/barchart";
import { Badge } from "@heffl/ui/components/primitives/badge";
import EmptyScreen from "@heffl/ui/components/primitives/empty-screen";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import SimpleTable from "@heffl/ui/components/simple-table";
import StatsBar from "@heffl/ui/components/stats-cards";
import { Icon } from "@iconify/react";
import { Popover, Tooltip } from "antd";
import { useState } from "react";
import { match } from "ts-pattern";
import { getProjectTaskStatus } from "../../details/components/kanban";
import { ProjectTaskDetailsModal } from "../../tasks/components/project-task-modals";

const TaskOverdue = () => {
  const [selectedTask, setSelectedTask] = useState<number | null>(null);
  const [projectSearch, setProjectSearch] = useState("");
  const [clientSearch, setClientSearch] = useState("");

  const [filters, setFilters] = useParamsState<
    RouterInputs["projects"]["reports"]["tasksOverdue"]
  >({
    projects: [],
    assignees: [],
    clients: [],
  });

  const { data: overdueTasksData, isLoading } =
    trpc.projects.reports.tasksOverdue.useQuery(filters);

  const { data: projects } = trpc.projects.list.useQuery({
    search: projectSearch,
    statuses: ["OPEN"],
    pageSize: 20,
  });
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageSize: 20,
  });
  const { data: users } = trpc.users.list.useQuery();

  return (
    <div>
      <ProjectTaskDetailsModal
        open={!!selectedTask}
        onClose={() => setSelectedTask(null)}
        taskId={selectedTask || 0}
        switchToTask={(id) => setSelectedTask(id)}
      />
      <FilterBar
        onChange={() => {}}
        className="mb-4"
        filters={[
          {
            label: "Assignee",
            type: "checkbox",
            key: "assignees",
            value: filters.assignees,
            onChange: (value) => setFilters({ assignees: value }),
            options: users?.map((user) => ({
              label: heffl.format.name(user),
              value: user.id,
            })),
          },
          {
            label: "Project",
            type: "checkbox",
            key: "projects",
            value: filters.projects,
            onSearch(search) {
              setProjectSearch(search);
            },
            onChange: (value) => setFilters({ projects: value }),
            options: projects?.projects.map((project) => ({
              label: project.title,
              value: project.id,
            })),
          },
          {
            label: "Client",
            type: "checkbox",
            key: "clients",
            value: filters.clients,
            onSearch(search) {
              setClientSearch(search);
            },
            onChange: (value) => setFilters({ clients: value }),
            options: clients?.clients.map((client) => ({
              label: client.name,
              value: client.id,
            })),
          },
        ]}
      />

      {match({
        isLoading,
        overdueTasksData,
      })
        .with({ isLoading: false, overdueTasksData: { tasks: [] } }, () => (
          <EmptyScreen title="No overdue tasks found" />
        ))
        .with({ isLoading: false }, () => (
          <div>
            <div className="mb-4">
              <BarChart
                data={
                  overdueTasksData?.tasks.map((group) => ({
                    id: group.range,
                    range: group.range,
                    "Tasks count": group.tasks.length,
                    title: `${group.tasks.length} tasks`,
                  })) || []
                }
                index="range"
                yAxisLabel="Days overdue"
                xAxisLabel="Tasks"
                layout="vertical"
                showLegend={false}
                categories={["Tasks count"]}
                colors={["red"]}
                onValueChange={(value) => {
                  if (value) {
                    const element = document.getElementById(
                      `table-${value.id}`
                    );
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }
                }}
              />
              <StatsBar
                className="w-fit"
                items={[
                  {
                    title: "Total tasks",
                    value: overdueTasksData?.meta.total.toString() || "0",
                  },
                  {
                    title: "Most overdue user",
                    value: overdueTasksData?.meta.stats.mostOverDueUser
                      ? `${overdueTasksData.meta.stats.mostOverDueUser.firstName} (${overdueTasksData.meta.stats.mostOverDueUser.count})`
                      : "None",
                  },
                ]}
              />
            </div>
            <SimpleTable
              size="small"
              borderless
              rows={
                overdueTasksData?.tasks.flatMap((group) => group.tasks) || []
              }
              onRowClick={(row) => setSelectedTask(row.id)}
              columns={[
                {
                  label: "Task",
                  key: "title",
                  width: 300,
                  render: (row) => row.title,
                },
                {
                  label: "Due Date",
                  width: 100,
                  render: (row) => (
                    <div className="flex gap-1 items-center">
                      <div>{row.date ? heffl.format.date(row.date) : ""}</div>
                      <div className="flex justify-center items-center px-1 h-3.5 text-[10px] text-white bg-red-400 rounded-sm">
                        {row.daysOverdue}
                      </div>
                      {row.dateUpdates.length > 0 && (
                        <Popover
                          content={
                            <div className="flex flex-col gap-1">
                              {!row.dateUpdates && <div>No date updates</div>}
                              {row.dateUpdates?.map((update) => (
                                <div
                                  // @ts-ignore
                                  key={update.value}
                                  className="flex gap-1 text-xs text-gray-500"
                                >
                                  Updated
                                  <span>
                                    {/* @ts-ignore */}
                                    {heffl.format.date(update.oldValue)}
                                  </span>
                                  to
                                  <span className="text-black">
                                    {/* @ts-ignore */}
                                    {heffl.format.date(update.value)}
                                  </span>
                                </div>
                              ))}
                            </div>
                          }
                          title="History"
                        >
                          <Icon
                            icon="tabler:history"
                            className="pl-1 w-5 h-5 text-gray-500"
                          />
                        </Popover>
                      )}
                    </div>
                  ),
                },
                {
                  label: "Assignees",
                  render: (row) => (
                    <Tooltip
                      title={row.projectTaskAssignees
                        .map((assignee) => heffl.format.name(assignee.users))
                        .join(", ")}
                    >
                      <div className="truncate">
                        {row.projectTaskAssignees
                          .map((assignee) => heffl.format.name(assignee.users))
                          .join(", ")}
                      </div>
                    </Tooltip>
                  ),
                  width: 100,
                },
                {
                  label: "Project",
                  render: (row) => (
                    <Tooltip title={row.projects.title}>
                      <div className="truncate">{row.projects.title}</div>
                    </Tooltip>
                  ),
                  width: 120,
                },
                {
                  label: "Status",
                  key: "status",
                  width: 100,
                  render: (row) => (
                    <Badge background={getProjectTaskStatus(row.status).hex}>
                      {getProjectTaskStatus(row.status).label}
                    </Badge>
                  ),
                },
              ]}
              idKey="id"
              groupBy={{
                defaultOpened: overdueTasksData?.tasks
                  .slice(0, 20)
                  .map((group) => group.range),
                rowKey: "range",
                groups: overdueTasksData?.tasks.map((group) => ({
                  label: (
                    <div className="flex gap-2 items-center pr-2 font-medium text-red-500">
                      <span>{group.range}</span>
                      <span>days overdue</span>
                      <span className="text-xs font-normal text-gray-500">
                        ({group.tasks.length})
                      </span>
                    </div>
                  ),
                  key: group.range,
                })),
              }}
            />
          </div>
        ))
        .otherwise(() => (
          <FullScreenSpinner />
        ))}
    </div>
  );
};

export default TaskOverdue;
