import DataGrid from "@/components/dataGrid/DataGrid";
import { SearchInput, VendorInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { getS3URLLegacy } from "@/helpers/s3Helpers";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { DollarSign, Package, Paperclip, Wrench } from "lucide-react";
import { capitalize, map } from "radash";
import { Dispatch, SetStateAction, useState } from "react";

import FilterBar from "@/components/filter-bar";
import { AddChartOfAccountsModal } from "@/pages/books/chart-of-accounts";
import { DocumentViewer } from "@/pages/files";
import Schemas from "@heffl/server/src/schemas";
import FilePicker from "@heffl/ui/components/file-picker";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import Select from "@heffl/ui/components/primitives/select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { Trash2 } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import heffl from "@/helpers/heffl";
import ExpenseDetailsModal from "./details-modal";

export const ExpenseForm = () => {
  const [showAddChartOfAccount, setShowAddChartOfAccount] = useState(false);

  const { data: expenseAccounts } = trpc.books.chartOfAccounts.list.useQuery({
    type: "EXPENSE",
  });
  const { data: paidThroughAccounts } =
    trpc.books.chartOfAccounts.list.useQuery({ type: "PAID_THROUGH" });

  return (
    <>
      <AddChartOfAccountsModal
        open={showAddChartOfAccount}
        onClose={() => setShowAddChartOfAccount(false)}
      />
      <FormField name="expenseAccountId" label="Expense Account">
        <Select
          placeholder="Select expense account"
          options={expenseAccounts?.chartOfAccounts.map((account) => ({
            label: account.name,
            value: account.id,
            type: account.type,
          }))}
          render={(option) => (
            <>
              <span className="text-gray-500">{capitalize(option.type)}</span>:{" "}
              {option.label}
            </>
          )}
          createButton={{
            onClick: () => setShowAddChartOfAccount(true),
            label: "Add Account",
          }}
        />
      </FormField>
      <div className="grid grid-cols-2 gap-3">
        <FormField name="date" label="Date">
          <DatePicker />
        </FormField>
        <FormField name="amount" label="Amount" className="mb-4">
          <Input type="number" placeholder="00.00" prefix="AED" prefixFilled />
        </FormField>
      </div>
      <FormField name="paidThroughAccountId" label="Paid Through">
        <Select
          placeholder="Select paid through account"
          options={paidThroughAccounts?.chartOfAccounts.map((account) => ({
            label: account.name,
            value: account.id,
            type: account.type,
          }))}
          render={(option) => (
            <>
              <span className="text-gray-500">{capitalize(option.type)}</span>:{" "}
              {option.label}
            </>
          )}
          createButton={{
            onClick: () => setShowAddChartOfAccount(true),
            label: "Add Account",
          }}
        />
      </FormField>
      <div className="grid grid-cols-2 gap-3">
        <FormField name="type" label="Expense Type">
          <TabsInput
            options={[
              { label: "Goods", value: "GOODS", icon: Package },
              { label: "Services", value: "SERVICES", icon: Wrench },
            ]}
          />
        </FormField>
        <FormField name="refNo" label="Reference No">
          <Input placeholder="Enter reference number" />
        </FormField>
      </div>
      <FormField name="files.expense_reciept" label="Receipt" className="mb-4">
        <FilePicker />
      </FormField>
      <VendorInput name="vendorId" label="Vendor" />
      <FormField name="notes" label="Notes" className="mb-4">
        <Textarea placeholder="Notes" />
      </FormField>
    </>
  );
};

export const defaultFilesInput = (
  files: z.infer<typeof Schemas.files.fileItem>[]
) => {
  return {
    new: [],
    deleted: [],
    existing: files ? files : [],
  };
};

export const AddExpenseModal = ({
  open,
  onClose,
  defaultValues,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<z.infer<typeof Schemas.purchases.expenses>> & {
    aggregatorCharge?: number;
  };
}) => {
  const [loading, setLoading] = useState(false);

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation({
      onSuccess: (data) => {
        console.log("data", data);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const form = useForm<z.infer<typeof Schemas.purchases.expenses>, unknown>({
    resolver: zodResolver(Schemas.purchases.expenses),
    defaultValues: {
      date: new Date(),
      type: "GOODS",
      files: {
        expense_reciept: defaultFilesInput([]),
      },
      fsJobId: defaultValues?.fsJobId,
    },
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset({
        ...form.getValues(),
        ...defaultValues,
      });
    }
  }, [defaultValues]);

  const onSubmit = async (
    values: z.infer<typeof Schemas.purchases.expenses>
  ) => {
    setLoading(true);
    const presignedUrls = values?.files?.expense_reciept.new.length
      ? await getPresignedUrls({
          files: values?.files?.expense_reciept.new,
          section: "expenses/receipts",
        })
      : [];
    addExpenseMutation.mutate({
      ...values,
      files: {
        expense_reciept: { new: presignedUrls, deleted: [] },
      },
    });
    await map(presignedUrls, async (file, index) => {
      const fileToUpload = values?.files?.expense_reciept.new[index].file;
      await axios.put(file.presignedUrl, fileToUpload, {
        headers: {
          "Content-Type": file.format,
        },
      });
    });
    setLoading(false);
  };

  const addExpenseMutation = trpc.purchases.expenses.add.useMutation({
    onSuccess: () => {
      toast.success("Expense added successfully");
      onClose();
      form.reset();
    },
    onError: () => {
      setLoading(false);
      toast.error("Something went wrongg");
    },
  });

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      className="w-full sm:w-1/4"
      title="Add Expense"
      footer={
        <Button
          variant="primary"
          size="lg"
          type="submit"
          className="w-full"
          loading={loading || addExpenseMutation.isLoading}
          onClick={() => {
            form.handleSubmit(onSubmit)();
          }}
        >
          Add Expense
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ExpenseForm />
      </Form>
    </ModalDrawer>
  );
};

export const EditExpenseModal = ({
  open,
  onClose,
  id: expenseId,
}: {
  open: boolean;
  onClose: () => void;
  id: number;
}) => {
  const confirm = useConfirm();

  const [loading, setLoading] = useState(false);

  const { data: expense } = trpc.purchases.expenses.details.useQuery({
    id: expenseId,
  });

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation({
      onSuccess: (data) => {
        console.log("data", data);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const form = useForm<z.infer<typeof Schemas.purchases.expenses>, unknown>({
    resolver: zodResolver(Schemas.purchases.expenses),
    defaultValues: {
      date: new Date(),
      paidBy: "COMPANY",
    },
  });

  useEffect(() => {
    if (expense) {
      form.reset({
        ...expense,
        files: {
          expense_reciept: defaultFilesInput(
            expense.files?.fields?.expense_reciept
              ? expense.files?.fields?.expense_reciept
              : []
          ),
        },
      });
    }
  }, [expense]);

  const updateExpenseMutation = trpc.purchases.expenses.update.useMutation({
    onSuccess: () => {
      toast.success("Expense updated successfully");
      onClose();
      form.reset();
    },
    onError: () => {
      setLoading(false);
      toast.error("Something went wrong");
    },
  });

  const expenseDeleteMutation = trpc.purchases.expenses.delete.useMutation({
    onSuccess: () => {
      toast.success("Expense deleted successfully");
      onClose();
      form.reset();
    },
  });

  const onSubmit = async (
    values: z.infer<typeof Schemas.purchases.expenses>
  ) => {
    setLoading(true);
    const presignedUrls = values?.files?.expense_reciept.new.length
      ? await getPresignedUrls({
          files: values?.files?.expense_reciept.new,
          section: "expenses/receipts",
        })
      : [];
    updateExpenseMutation.mutate({
      id: expenseId,
      expense: {
        ...values,
        files: {
          expense_reciept: {
            deleted: [],
            ...values?.files?.expense_reciept,
            new: presignedUrls,
          },
        },
      },
    });
    await map(presignedUrls, async (file, index) => {
      const fileToUpload = values?.files?.expense_reciept.new[index].file;
      await axios.put(file.presignedUrl, fileToUpload, {
        headers: {
          "Content-Type": file.format,
        },
      });
    });
    setLoading(false);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      className="w-full sm:w-1/4"
      title="Update Expense"
      footer={
        <div className="flex gap-2 justify-between pt-2 w-full">
          <Button
            icon={Trash2}
            variant="destructiveOutline"
            loading={expenseDeleteMutation.isLoading}
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this expense?",
              });
              if (confirmed) {
                expenseDeleteMutation.mutate(expenseId);
              }
            }}
          />
          <Button
            variant="primary"
            size="lg"
            type="submit"
            className="w-full"
            loading={loading || updateExpenseMutation.isLoading}
            onClick={() => {
              form.handleSubmit(onSubmit)();
            }}
          >
            Update Expense
          </Button>
        </div>
      }
    >
      {!expense ? (
        <FullScreenSpinner />
      ) : (
        <Form {...form} onSubmit={onSubmit}>
          <ExpenseForm />
        </Form>
      )}
    </ModalDrawer>
  );
};

type PageFilters = {
  search: string;
  pageNo: number;
  dates?: [Date, Date];
  expenseAccounts: number[];
  paidThroughAccounts: number[];
  pageSize: number;
  projectIds: number[];
};

export const ExpensesTable = ({
  filters,
  classes,
  setMeta,
}: {
  filters: Partial<PageFilters>;
  classes?: {
    parent?: string;
    datagrid?: string;
  };
  setMeta?: Dispatch<SetStateAction<{ total: number }>>;
}) => {
  const [showReceipt, setShowReceipt] = useState<{
    name: string;
    link: string;
  } | null>(null);
  const [editExpenseId, setEditExpenseId] = useState<number | null>(null);
  const [expenseDetailsId, setExpenseDetailsId] = useState<number | null>(null);
  const [showAdd, setShowAdd] = useState(false);

  const { data: expenses, isLoading } = trpc.purchases.expenses.list.useQuery({
    pageNo: filters.pageNo,
    pageSize: filters.pageSize,
    search: filters.search,
    dates: filters.dates,
    expenseAccounts: filters.expenseAccounts,
    paidThroughAccounts: filters.paidThroughAccounts,
    projectIds: filters.projectIds,
  });

  useEffect(() => {
    if (setMeta && expenses?.meta) {
      setMeta(expenses?.meta);
    }
  }, [expenses?.meta]);

  return (
    <>
      {showReceipt && (
        <DocumentViewer
          open={true}
          onClose={() => setShowReceipt(null)}
          doc={showReceipt}
        />
      )}

      <AddExpenseModal open={showAdd} onClose={() => setShowAdd(false)} />
      {!!editExpenseId && (
        <EditExpenseModal
          open={!!editExpenseId}
          onClose={() => setEditExpenseId(null)}
          id={editExpenseId}
        />
      )}
      {!!expenseDetailsId && (
        <ExpenseDetailsModal
          open={!!expenseDetailsId}
          onClose={() => setExpenseDetailsId(null)}
          id={expenseDetailsId}
        />
      )}
      <DataGrid
        rowKey="id"
        name="expensesListMain"
        className={classes?.datagrid}
        label="Expenses"
        loading={isLoading}
        onCellClick={({ row }) => {
          setExpenseDetailsId(row.id);
        }}
        empty={{
          icon: DollarSign,
          title: "No expenses added",
          description: "Please add an expense",
          actionText: "Add expense",
          buttonSize: "sm",
        }}
        rows={expenses?.expenses}
        columns={[
          {
            key: "date",
            name: "Date",
            width: 140,
            renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
          },
          {
            key: "number",
            name: "#",
            width: 80,
            renderCell: ({ row }) => (
              <p className="cursor-pointer hover:underline hover:text-primary-700">
                {row.number}
              </p>
            ),
          },
          {
            key: "expenseAccount",
            name: "Expense Account",
            width: 200,
            renderCell: ({ row }) => row.expenseAccount.name,
          },
          {
            key: "amount",
            name: "Amount",
            width: 200,
            renderCell: ({ row }) => (
              <div className="flex gap-2 items-center w-full text-right">
                {row.paidBy === "EMPLOYEE" && <Badge>To be Reimbursed</Badge>}
                {row.files?.fields?.expense_reciept && (
                  <Button
                    size="xs"
                    icon={Paperclip}
                    onClick={(e) => {
                      e.stopPropagation();
                      row.files?.fields?.expense_reciept &&
                        setShowReceipt({
                          name:
                            row.files?.fields?.expense_reciept[0].name || "",
                          link: getS3URLLegacy(
                            "expenses/receipts",
                            row.files?.fields?.expense_reciept[0].link
                          ),
                        });
                    }}
                  >
                    Receipt
                  </Button>
                )}
                <p className="w-full font-medium text-right">
                  {formatCurrency(row.amount, "AED")}
                </p>
              </div>
            ),
          },
          {
            key: "paidThroughAccount",
            name: "Paid Through",
            width: 200,
            renderCell: ({ row }) => row.paidThroughAccount.name,
          },
          {
            key: "notes",
            name: "Notes",
            width: 200,
          },
        ]}
      />
    </>
  );
};

const ExpensesList = () => {
  const [showAddExpense, setShowAddExpense] = useState(false);
  const [meta, setMeta] = useState<{ total: number }>({ total: 0 });

  const [filters, setFilters] = useParamsState<PageFilters>({
    search: "",
    pageNo: 1,
    dates: undefined,
    expenseAccounts: [],
    paidThroughAccounts: [],
    pageSize: 30,
    projectIds: [],
  });

  const { data: chartOfAccounts } = trpc.books.chartOfAccounts.list.useQuery();

  const expenseAccounts = chartOfAccounts?.chartOfAccounts.filter((account) =>
    [
      "EXPENSE",
      "OTHER_EXPENSE",
      "COST_OF_GOODS_SOLD",
      "OTHER_CURRENT_ASSET",
    ].includes(account.type)
  );

  const paidThroughAccounts = chartOfAccounts?.chartOfAccounts.filter(
    (account) =>
      ["BANK", "CASH", "FIXED_ASSET", "OTHER_CURRENT_ASSET"].includes(
        account.type
      )
  );

  return (
    <Page title="Expenses" className="sm:p-0" fullWidth>
      <div className="flex flex-col gap-3 justify-between w-full border-gray-200 sm:border-b sm:p-3 sm:flex-row sm:gap-0">
        <SearchInput
          value={filters.search}
          onChange={(e: string) => setFilters({ search: e })}
        />
        <AddExpenseModal
          open={showAddExpense}
          onClose={() => setShowAddExpense(false)}
        />
        <ResponsiveActionButton
          onClick={() => setShowAddExpense(true)}
          text="Expense"
        />
      </div>
      <div className="sm:px-3">
        <FilterBar
          suffix={
            <div className="p-1 px-2 text-sm rounded-lg border shadow-sm">
              Total: {heffl.format.currency(meta?.total || 0, "AED")}
            </div>
          }
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          className="py-3"
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value }),
            },
            {
              key: "expenseAccounts",
              type: "checkbox",
              label: "Expense Account",
              multiple: true,
              value: filters.expenseAccounts,
              onChange: (value) =>
                setFilters({ expenseAccounts: value as number[] }),
              options:
                expenseAccounts?.map((account) => ({
                  label: account.name,
                  value: account.id,
                })) || [],
            },
            {
              key: "paidThroughAccounts",
              type: "checkbox",
              label: "Paid Through",
              multiple: true,
              options:
                paidThroughAccounts?.map((account) => ({
                  label: account.name,
                  value: account.id,
                })) || [],
              value: filters.paidThroughAccounts,
              onChange: (value) =>
                setFilters({ paidThroughAccounts: value as number[] }),
            },
          ]}
        />
      </div>
      <ExpensesTable
        filters={filters}
        classes={{
          datagrid: "h-[calc(100vh-117px-var(--header-height))]",
        }}
        setMeta={setMeta}
      />
    </Page>
  );
};

export default ExpensesList;
