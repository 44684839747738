import FilterBar from "@/components/filter-bar";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { objectToParamsJSON } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const COLORS = [
  "#F59E0B", // yellow
  "#60A5FA", // blue
  "#F97316", // orange
  "#34D399", // green
  "#A78BFA", // purple
  "#EC4899", // pink
  "#6366F1", // indigo
  "#10B981", // emerald
];

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    name: string;
    value: number;
    payload: {
      name: string;
      value: number;
      color: string;
    };
  }>;
}

const TaskAssigneePie = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<{
    dates: [Date, Date];
  }>({
    dates: [dayjs().subtract(30, "days").toDate(), dayjs().toDate()],
  });

  const { data: taskData } =
    trpc.projects.reports.taskStatusByAssignee.useQuery({
      dates: filters.dates,
    });

  const pieData = taskData?.map((assignee, index) => ({
    id: assignee.id,
    name: assignee.name,
    value:
      assignee.stats.todo +
      assignee.stats.inProgress +
      assignee.stats.onHold +
      assignee.stats.completed,
    color: COLORS[index % COLORS.length],
    onClick: () => onChartClick(assignee.id),
  }));

  const CustomTooltip = ({ active, payload }: TooltipProps) => {
    if (active && payload && payload.length) {
      const data = taskData?.find((a) => a.name === payload[0].payload.name);
      if (!data) return null;

      return (
        <div className="p-4 bg-white rounded-xl border border-gray-100 shadow-lg">
          <p className="mb-3 text-lg font-bold text-gray-800">{data.name}</p>
          <div className="space-y-2">
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
              <span className="font-medium">{`${data.stats.todo} To Do`}</span>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-blue-500 rounded-full"></div>
              <span className="font-medium">{`${data.stats.inProgress} In Progress`}</span>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-orange-500 rounded-full"></div>
              <span className="font-medium">{`${data.stats.onHold} On Hold`}</span>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-green-500 rounded-full"></div>
              <span className="font-medium">{`${data.stats.completed} Completed`}</span>
            </div>
          </div>
          <div className="pt-3 mt-3 border-t border-gray-100">
            <p className="font-medium text-gray-600">
              Total Tasks: {payload[0].value}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  const onChartClick = (assignee: number) => {
    const params = objectToParamsJSON({
      assignees: [assignee],
      dates: filters.dates,
      statuses: ["OPEN", "IN_PROGRESS", "ON_HOLD", "COMPLETED"],
    });
    navigate(`/projects/tasks/table?${params}`);
  };

  return (
    <div className="w-full h-[500px]">
      <FilterBar
        onChange={() => {}}
        filters={[
          {
            label: "Date",
            type: "date-range",
            key: "dates",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
          },
        ]}
      />
      <div className="mt-4">
        <ResponsiveContainer width="100%" height={700}>
          <PieChart>
            <Pie
              data={pieData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={250}
              label={({ name, percent }) =>
                `${name} ${(percent * 100).toFixed(0)}%`
              }
              labelLine={true}
            >
              {pieData?.map((entry, index) => (
                <Cell
                  cursor="pointer"
                  key={`cell-${index}`}
                  fill={entry.color}
                  onClick={() => onChartClick(entry.id)}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default TaskAssigneePie;
