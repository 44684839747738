import RenderHtml from "@heffl/ui/components/render-html";
import {
  activityStatuses,
  ActivitySummaryModal,
  EditActivityModal,
} from "@/components/actionsModals/activities-modals";
import { EditLogModal } from "@/components/actionsModals/log-modals";
import { EditNotesModal } from "@/components/actionsModals/notes-modals";
import { EditTaskModal } from "@/components/actionsModals/tasks-modals";
import FileIcon from "@/components/file-icon";
import { getS3URLLegacy } from "@/helpers/s3Helpers";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import {
  downloadFile,
  dynamicDateFormatting,
  formatName,
} from "@heffl/ui/lib/utils";
import CircleTick from "@/pages/crm/deals/details/components/circle-tick";
import { historyIcons } from "@/pages/crm/deals/details/history";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";
import { convertFileSize } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import {
  Clock,
  Pencil,
  Timer,
  Trash,
  UserRoundPlus,
  Users,
} from "lucide-react";
import { P, match } from "ts-pattern";
import { useImmer } from "use-immer";
import mime from "mime-types";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import {
  renderUpdateDescription,
  renderUpdateTitle,
} from "@/helpers/userActivity/render-timeline";
import { capitalize } from "radash";

type THistoryType = RouterOutputs["deals"]["history"];

const ActivityTimeline = ({ activities }: { activities: THistoryType }) => {
  const confirm = useConfirm();

  const [edit, setEdit] = useImmer<{
    taskId: number | undefined;
    noteId: number | undefined;
    activityId: number | undefined;
    logId: number | undefined;
  }>({
    taskId: undefined,
    noteId: undefined,
    activityId: undefined,
    logId: undefined,
  });
  const [summaryModalOpen, setSummaryModalOpen] = useImmer<
    | {
        open: boolean;
        activity: {
          id: number;
          type: "call" | "meeting" | "todo";
          description: string;
          durationMinutes: number;
        };
      }
    | undefined
  >(undefined);

  const groupedActivitiesObject = activities.reduce((acc, activity) => {
    const startDate = format(activity.value.date, "yyyy-MM-dd");
    if (!acc[startDate]) {
      acc[startDate] = [];
    }
    acc[startDate].push(activity);
    return acc;
  }, {} as Record<string, THistoryType>);

  const groupedActivities = Object.keys(groupedActivitiesObject).map((key) => ({
    date: key,
    activities: groupedActivitiesObject[key],
  }));

  const deleteCommentMutation = trpc.comments.delete.useMutation();

  const activityUpdateMutation = trpc.activities.update.useMutation();
  const taskUpdateMutation = trpc.tasks.update.useMutation();

  return (
    <>
      {!!edit.noteId && (
        <EditNotesModal
          open={!!edit.noteId}
          onClose={() =>
            setEdit((draft) => {
              draft.noteId = undefined;
            })
          }
          noteId={edit.noteId}
        />
      )}
      {!!edit.activityId && (
        <EditActivityModal
          open={!!edit.activityId}
          onClose={() =>
            setEdit((draft) => {
              draft.activityId = undefined;
            })
          }
          activityId={edit.activityId}
        />
      )}
      {!!edit.taskId && (
        <EditTaskModal
          open={!!edit.taskId}
          onClose={() =>
            setEdit((draft) => {
              draft.taskId = undefined;
            })
          }
          taskId={edit.taskId}
        />
      )}
      {!!edit.logId && (
        <EditLogModal
          open={!!edit.logId}
          onClose={() =>
            setEdit((draft) => {
              draft.logId = undefined;
            })
          }
          activityId={edit.logId}
        />
      )}
      {!!summaryModalOpen && (
        <ActivitySummaryModal
          open={true}
          activity={summaryModalOpen.activity}
          onClose={() => setSummaryModalOpen(undefined)}
        />
      )}
      <div className="flex flex-col">
        {groupedActivities.map(({ activities, date }) => (
          <div key={date}>
            <div className="flex justify-center items-center">
              <div className="flex-grow border-t border-gray-300"></div>
              <p
                className={`text-xs font-medium text-center text-gray-500 px-2 ${
                  date < format(new Date(), "yyyy-MM-dd") &&
                  activities.find(
                    (activity) =>
                      [
                        "email",
                        "meeting",
                        "call",
                        "todo",
                        "whatsapp_message",
                        "sms",
                      ].includes(activity.type) &&
                      "completed" in activity.value &&
                      !activity.value.completed
                  ) &&
                  "text-red-600"
                }`}
              >
                {date === format(new Date(), "yyyy-MM-dd") ? (
                  <span>
                    <span className="text-green-600">Today</span> -{" "}
                    {format(new Date(), "PPP")}
                  </span>
                ) : (
                  format(new Date(date), "PPP")
                )}
              </p>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
            <div className="mt-2">
              {activities.map((history, index) => {
                const user = (
                  <div className="flex items-center capitalize">
                    <UserRoundPlus className="h-3.5" />
                    {history.value.createdBy.firstName +
                      " " +
                      history.value.createdBy.lastName}
                  </div>
                );
                return (
                  <div key={index} className="flex flex-row gap-2">
                    <div className="flex flex-col items-center">
                      <div className="flex items-center justify-center !w-8 !h-8 !bg-white shadow-lg border border-gray-200 rounded-full">
                        {historyIcons[history.type]}
                      </div>
                      {!(activities.length - 1 === index) && (
                        <div className="h-full border border-gray-300 border-dashed"></div>
                      )}
                    </div>
                    <div className="mb-3 w-full">
                      {match(history)
                        .with({ type: "task" }, ({ value: task }) => (
                          <div className="relative p-3 bg-gray-100 rounded-md shadow-sm sm:bg-white h-fit">
                            <div className="flex gap-1 items-center">
                              <CircleTick
                                checked={task.status === "COMPLETED"}
                                loading={
                                  taskUpdateMutation.isLoading &&
                                  taskUpdateMutation.variables?.id === task.id
                                }
                                onClick={() => {
                                  taskUpdateMutation.mutate({
                                    id: task.id,
                                    task: {
                                      status:
                                        task.status === "COMPLETED"
                                          ? "OPEN"
                                          : "COMPLETED",
                                    },
                                  });
                                }}
                              />

                              <p className="text-base font-medium">
                                {task.title}
                              </p>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: task.description || "",
                              }}
                            />
                            <div className="flex flex-col gap-1 mt-1 text-xs text-gray-600 sm:flex-row">
                              {format(task.date, "yyyy-MM-dd") <
                                format(new Date(), "yyyy-MM-dd") &&
                                task.status !== "COMPLETED" && (
                                  <Badge className="w-fit" variant="error">
                                    Due
                                  </Badge>
                                )}
                              {task.taskAssignees.length ? (
                                <div className="flex items-center">
                                  <Users className="h-3.5" />
                                  {task.taskAssignees
                                    .map((user) => formatName(user.users))
                                    .join(", ")}
                                </div>
                              ) : null}
                              {user}
                              {task.status === "COMPLETED" && task.completedOn
                                ? `| Completed on ${dynamicDateFormatting(
                                    task.completedOn
                                  )}`
                                : null}
                            </div>
                            <Button
                              onClick={() => {
                                setEdit((draft) => {
                                  draft.taskId = task.id;
                                });
                              }}
                              size="xs"
                              variant="outline"
                              className="absolute top-3 right-3"
                            >
                              Edit <Pencil className="h-3.5" />
                            </Button>
                          </div>
                        ))
                        .with({ type: "note" }, ({ value: note }) => (
                          <div className="relative p-3 bg-yellow-100 rounded-md shadow-sm h-fit">
                            {note.note}
                            <div className="flex gap-0.5 text-xs text-gray-600 mt-1">
                              <p className="flex items-center">
                                <Clock className="h-3.5" />
                                {format(note.createdAt, "hh:mm a")}
                              </p>
                              {user}
                            </div>
                            <Button
                              size="xs"
                              variant="outline"
                              className="absolute top-3 right-3"
                              onClick={() =>
                                setEdit((draft) => {
                                  draft.noteId = note.id;
                                })
                              }
                            >
                              Edit <Pencil className="h-3.5" />
                            </Button>
                          </div>
                        ))
                        .with({ type: "event" }, ({ value: event }) => (
                          <div className="flex flex-col">
                            <p className="text-sm">{event.title}</p>
                            <p className="text-sm text-gray-600">{}</p>
                            <div className="flex gap-1 text-xs text-gray-600">
                              <p className="flex gap-1 items-center">
                                <Clock className="h-3.5 w-3.5" />
                                {format(event.date, "hh:mm a")}
                              </p>
                              {user}
                            </div>
                          </div>
                        ))
                        .with(
                          {
                            type: P.union(
                              "email",
                              "meeting",
                              "call",
                              "todo",
                              "whatsapp_message",
                              "sms"
                            ),
                          },
                          ({ value: activity }) => (
                            <div className="relative p-3 bg-gray-100 rounded-md shadow-sm sm:bg-white h-fit">
                              <div className="flex gap-1 items-center">
                                {!activity.isLogged && (
                                  <CircleTick
                                    checked={activity.completed}
                                    loading={
                                      activityUpdateMutation.isLoading &&
                                      activityUpdateMutation.variables?.id ===
                                        activity.id
                                    }
                                    onClick={() => {
                                      activityUpdateMutation.mutate({
                                        id: activity.id,
                                        activity: {
                                          completed: !activity.completed,
                                          completedOn: null,
                                        },
                                      });

                                      if (
                                        ["call", "meeting"].includes(
                                          activity.type
                                        ) &&
                                        !activity.completed
                                      ) {
                                        setSummaryModalOpen({
                                          open: true,
                                          activity: {
                                            id: activity.id,
                                            type: activity.type as
                                              | "call"
                                              | "todo"
                                              | "meeting",
                                            description:
                                              activity.description || "",
                                            durationMinutes:
                                              activity.durationMinutes,
                                          },
                                        });
                                      }
                                    }}
                                  />
                                )}
                                <div className="flex gap-2 items-center">
                                  <p className="text-base font-medium">
                                    {activity.title}
                                  </p>
                                  {activity.status !== "DONE" &&
                                    activity.status !== "TODO" &&
                                    (activity.type === "call" ||
                                      activity.type === "meeting") && (
                                      <Badge
                                        small
                                        className="w-fit"
                                        variant={
                                          activityStatuses.find(
                                            (status) =>
                                              status.value === activity.status
                                          )?.variant
                                        }
                                        icon={
                                          activityStatuses.find(
                                            (status) =>
                                              status.value === activity.status
                                          )?.icon
                                        }
                                      >
                                        {capitalize(
                                          activity.status.split("_").join(" ")
                                        )}
                                      </Badge>
                                    )}
                                </div>
                              </div>
                              <RenderHtml className="text-sm text-gray-600">
                                {activity.description}
                              </RenderHtml>

                              <div className="flex flex-col gap-1 mt-1 text-xs text-gray-600 sm:flex-row">
                                {format(activity.date, "yyyy-MM-dd") <
                                  format(new Date(), "yyyy-MM-dd") &&
                                  !activity.completed && (
                                    <Badge className="w-fit" variant="error">
                                      Due
                                    </Badge>
                                  )}
                                <Tooltip content="Start time">
                                  <p className="flex gap-1 items-center">
                                    <Clock className="h-3.5 w-3.5" />
                                    {format(activity.startDate, "hh:mm a")}
                                  </p>
                                </Tooltip>
                                {activity.durationMinutes > 0 && (
                                  <Tooltip content="Duration minutes">
                                    <p className="flex gap-1 items-center">
                                      <Timer className="h-3.5 w-3.5" />
                                      {activity.durationMinutes} mins
                                    </p>
                                  </Tooltip>
                                )}
                                {activity.activityAttendees.length ? (
                                  <Tooltip content="Contacts associated">
                                    <div className="flex gap-1 items-center">
                                      <Users className="h-3.5 w-3.5 text-green-500" />
                                      {activity.activityAttendees
                                        .map((user) =>
                                          formatName(user.contacts)
                                        )
                                        .join(", ")}
                                    </div>
                                  </Tooltip>
                                ) : null}
                                {activity.activityAssignees.length ? (
                                  <Tooltip content="Assigned to">
                                    <div className="flex gap-1 items-center">
                                      <Users className="h-3.5 w-3.5" />
                                      {activity.activityAssignees
                                        .map((user) => formatName(user.users))
                                        .join(", ")}
                                    </div>
                                  </Tooltip>
                                ) : null}
                                <Tooltip content="Created by">{user}</Tooltip>
                                {activity.completed && activity.completedOn
                                  ? `| Completed on ${dynamicDateFormatting(
                                      activity.completedOn
                                    )}`
                                  : null}
                              </div>
                              <Button
                                onClick={() => {
                                  setEdit((draft) => {
                                    if (activity.isLogged) {
                                      draft.logId = activity.id;
                                    } else {
                                      draft.activityId = activity.id;
                                    }
                                  });
                                }}
                                size="xs"
                                variant="outline"
                                className="absolute top-3 right-3"
                              >
                                Edit <Pencil className="h-3.5" />
                              </Button>
                            </div>
                          )
                        )
                        .with({ type: "comment" }, ({ value: comment }) => (
                          <div className="flex relative flex-col gap-1 p-3 bg-gray-100 rounded-md shadow-sm sm:bg-white h-fit">
                            <Button
                              onClick={async () => {
                                const confirmed = await confirm(
                                  "Are you sure you want to delete this comment?"
                                );
                                if (confirmed) {
                                  deleteCommentMutation.mutate(comment.id);
                                }
                              }}
                              loading={
                                deleteCommentMutation.isLoading &&
                                deleteCommentMutation.variables === comment.id
                              }
                              icon={Trash}
                              size="xs"
                              className="absolute top-2 right-2"
                            >
                              Delete
                            </Button>
                            {!!comment.commentFiles.length &&
                              match(comment.type)
                                .with("IMAGE", () => (
                                  <img
                                    src={getS3URLLegacy(
                                      "leads/comments",
                                      comment.commentFiles[0].link
                                    )}
                                    alt="Comment Image"
                                    className="max-w-full h-auto sm:w-96"
                                  />
                                ))
                                .with("FILE", () => (
                                  <div className="flex gap-4 items-center p-2 rounded-md border w-fit">
                                    <FileIcon
                                      ext={
                                        mime.extension(
                                          comment.commentFiles[0].format || ""
                                        ) || ""
                                      }
                                      className="w-12 h-12 border shadow-sm"
                                    />
                                    <div className="flex flex-col justify-between h-11">
                                      <p>{comment.commentFiles[0].name}</p>
                                      <div className="flex gap-2 items-center">
                                        <p className="text-xs text-gray-500">
                                          {convertFileSize(
                                            comment.commentFiles[0].size
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))
                                .otherwise(() => null)}
                            {!!comment.commentFiles.length && (
                              <div className="flex gap-2 items-center">
                                <Button
                                  onClick={() => {
                                    downloadFile(
                                      comment.commentFiles[0].name,
                                      getS3URLLegacy(
                                        "leads/comments",
                                        comment.commentFiles[0].link
                                      )
                                    );
                                  }}
                                  variant="link"
                                  size="xs"
                                >
                                  Download
                                </Button>
                                <Button
                                  onClick={() => {
                                    window.open(
                                      getS3URLLegacy(
                                        "leads/comments",
                                        comment.commentFiles[0].link
                                      ),
                                      "_blank"
                                    );
                                  }}
                                  variant="link"
                                  size="xs"
                                >
                                  Open
                                </Button>
                              </div>
                            )}

                            <RenderHtml>{comment.comment}</RenderHtml>
                            <div className="flex justify-between items-center">
                              {user}
                              <p className="text-xs text-gray-600">
                                {format(comment.createdAt, "hh:mm a")}
                              </p>
                            </div>
                          </div>
                        ))
                        .with({ type: "update" }, ({ value: update }) => (
                          <div className="flex flex-col pl-2">
                            <p className="text-sm">
                              {renderUpdateTitle(update)}
                            </p>
                            <p className="pl-4 text-sm text-gray-600">
                              {renderUpdateDescription(update)}
                            </p>
                          </div>
                        ))
                        .exhaustive()}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ActivityTimeline;
