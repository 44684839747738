import { z } from "zod";
import enums from "./enums";

export const dashboardItem = z.object({
  dashboardId: z.number(),
  name: z.string(),
  title: z.string(),
  filtersShown: z.array(z.string()).default([]),
  typesShown: enums.dashboardItemTypes.array().default(["CHART"]),
  filtersValues: z.record(z.string(), z.any()).default({}),
  width: z.coerce.number(),
});

export const dashboard = z.object({
  title: z.string(),
  icon: z.string(),
  type: enums.entities,
  description: z.string().default(""),
  viewers: z.array(z.number()).default([]),
  dashboardItems: z.array(dashboardItem.omit({ dashboardId: true })).optional(),
});
