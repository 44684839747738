import DataTable from "@/components/DataTable";
import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import InfoItems from "@heffl/ui/components/info-items";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Separator } from "@heffl/ui/components/primitives/separator";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  formatCurrency,
  formatName,
  objectToParamsJSON,
} from "@heffl/ui/lib/utils";
import {
  Album,
  ArrowUpDown,
  Calendar,
  CheckCircle,
  DollarSign,
  Eye,
  FileLineChart,
  Pencil,
  Target,
  Trash2,
  User,
} from "lucide-react";

import { useForm } from "react-hook-form";
import GaugeComponent from "react-gauge-component";
import { useNavigate, useParams } from "react-router-dom";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { useEffect, useState } from "react";
import { z } from "zod";
import { toast } from "react-hot-toast";
import { Form } from "@heffl/ui/components/primitives/form";
import { SalesGoalForm } from "./list";
import useTeam from "@/lib/hooks/useTeam";

const EditSalesGoal = ({
  open,
  onClose,
  id,
}: {
  open: boolean;
  onClose: () => void;
  id: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.crm.salesGoal>>({
    resolver: zodResolver(Schemas.crm.salesGoal),
  });

  const { data: goal } = trpc.salesGoals.details.useQuery(id);

  const onModalClose = () => {
    form.reset();
    onClose();
  };

  const goalUpdateMutation = trpc.salesGoals.update.useMutation({
    onSuccess: () => {
      heffl.toast.success("Sales goal updated successfully");
      onClose();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.crm.salesGoal>) => {
    goalUpdateMutation.mutate({ id, salesGoal: values });
  };

  useEffect(() => {
    if (goal) {
      // @ts-ignore
      form.reset(goal);
    }
  }, [goal]);

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title="Edit Sales Goal"
      footer={
        <Button
          loading={goalUpdateMutation.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
          variant="primary"
          className="w-full"
          size="md"
        >
          Update Sales Goal
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <SalesGoalForm form={form} />
      </Form>
    </ModalDrawer>
  );
};

const SalesGoalDetails = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const team = useTeam();

  const [showEditModal, setShowEditModal] = useState(false);

  const { data } = trpc.salesGoals.detailsWithMetrics.useQuery(Number(id));

  const deleteSalesGoal = trpc.salesGoals.delete.useMutation({
    onSuccess: () => {
      toast.success("Sales goal deleted successfully");
      navigate("/crm/goals");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  if (!data || !team) return <FullScreenSpinner />;

  const openSalesGoalItems = (dates: [Date, Date]) => {
    if (data.type === "CRM_DEAL" && data.measure === "QUOTATION_ACCEPTED") {
      const params = objectToParamsJSON({
        closedAt: dates,
        status: ["WON"],
        owners: data.targetFor === "USER" && data.userId ? [data.userId] : [],
      });
      return navigate(`/crm/deals/table?${params}`);
    }
    if (data.type === "QUOTATION" && data.measure === "QUOTATION_ACCEPTED") {
      const params = objectToParamsJSON({
        markedAcceptedOn: dates,
        salesPersons:
          data.targetFor === "USER" && data.userId ? [data.userId] : [],
        statuses: ["ACCEPTED"],
      });
      return navigate(`/sales/quotations?${params}`);
    }
    if (data.type === "FIELD_SERVICE_JOB" && data.measure === "ADDED") {
      const params = objectToParamsJSON({
        createdAt: dates,
        salesPersons:
          data.targetFor === "USER" && data.userId ? [data.userId] : [],
      });
      return navigate(`/field-service/jobs?${params}`);
    }
  };

  return (
    <Page
      title="Sales Goal Details"
      breadcrumbs={[{ label: "Sales Goals", path: "/crm/goals" }]}
      showBack
      fullWidth
      suffix={
        <div className="flex gap-2">
          <Button
            variant="destructive"
            size="sm"
            icon={Trash2}
            onClick={async () => {
              heffl.modal.confirm({
                title: "Delete Sales Goal",
                description: "Are you sure you want to delete this sales goal?",
                onConfirm: () => {
                  deleteSalesGoal.mutate(Number(id));
                },
              });
            }}
            disabled={!team.user.permissions.DELETE_SALES_GOALS.allowed}
          >
            Delete
          </Button>
          <Button
            disabled={!team.user.permissions.UPDATE_SALES_GOALS.allowed}
            size="sm"
            icon={Pencil}
            onClick={() => setShowEditModal(true)}
          >
            Edit
          </Button>
        </div>
      }
    >
      {!!id && (
        <EditSalesGoal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          id={Number(id)}
        />
      )}
      <div className="flex flex-col gap-4">
        <Card title="Target Details">
          <InfoItems
            items={[
              {
                key: "assignee",
                label: (
                  <div className="flex gap-2 items-center">
                    <User className="h-4" /> Assignee
                  </div>
                ),
                children:
                  data.targetFor === "USER" && data.users
                    ? formatName(data.users)
                    : "Team",
              },
              {
                key: "type",
                label: (
                  <div className="flex gap-2 items-center">
                    <Album className="h-4" /> Type
                  </div>
                ),
                children: heffl.format.capitalize(
                  `${data.type} ${data.measure}`.split("_").join(" ")
                ),
              },
              {
                key: "frequency",
                label: (
                  <div className="flex gap-2 items-center">
                    <FileLineChart className="h-4" /> Frequency
                  </div>
                ),
                children: data.frequency,
              },
              {
                key: "dates",
                label: (
                  <div className="flex gap-2 items-center">
                    <Calendar className="h-4" /> Dates
                  </div>
                ),
                children: `${heffl.format.date(data.startDate)} - ${
                  data.endDate ? heffl.format.date(data.endDate) : "No end date"
                }`,
              },
              {
                key: "goal",
                label: (
                  <div className="flex gap-2 items-center">
                    <DollarSign className="h-4" /> Goal
                  </div>
                ),
                children: formatCurrency(data.goal, "AED"),
              },
            ]}
          />
        </Card>
        <div className="grid gap-4 sm:grid-cols-3">
          {data.goalMetrics.map((item) => (
            <Card key={item.period}>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <h2 className="flex justify-between w-full text-sm font-medium">
                    <span className="flex-1">{item.periodLabel}</span>
                    <span className="text-xs text-gray-500">
                      {heffl.format.date(item.periodStart)} -{" "}
                      {heffl.format.date(item.periodEnd)}
                    </span>
                  </h2>
                </div>
                <Separator />
                <GaugeComponent
                  className="w-full sm:w-auto"
                  value={item.achievedPercentage}
                  labels={{
                    valueLabel: {
                      style: {
                        fill: "black",
                        textShadow: "none",
                      },
                    },
                  }}
                  arc={{
                    subArcs: [
                      {
                        limit: 20,
                        color: "#EA4228",
                        showTick: true,
                      },
                      {
                        limit: 40,
                        color: "#F58B19",
                        showTick: true,
                      },
                      {
                        limit: 60,
                        color: "#F5CD19",
                        showTick: true,
                      },
                      {
                        limit: 100,
                        color: "#5BE12C",
                        showTick: true,
                      },
                    ],
                  }}
                />
                <div className="mt-4 space-y-3">
                  <div className="flex justify-between items-center p-2 bg-gray-50 rounded-lg">
                    <div className="flex gap-2 items-center">
                      <Target className="w-4 h-4 text-blue-500" />
                      <p className="text-sm text-gray-700">Target</p>
                    </div>
                    <p className="text-sm font-medium text-blue-600">
                      {heffl.format.currency(item.targetAmount, "AED")}
                    </p>
                  </div>

                  <div className="flex justify-between items-center p-2 bg-gray-50 rounded-lg">
                    <div className="flex gap-2 items-center">
                      <CheckCircle className="w-4 h-4 text-green-500" />
                      <p className="text-sm text-gray-700">Achieved</p>
                    </div>
                    <p className="text-sm font-medium text-green-600">
                      {heffl.format.currency(item.achievedAmount, "AED")}
                    </p>
                  </div>

                  <div className="flex justify-between items-center p-2 bg-gray-50 rounded-lg">
                    <div className="flex gap-2 items-center">
                      <ArrowUpDown className="w-4 h-4 text-purple-500" />
                      <p className="text-sm text-gray-700">Difference</p>
                    </div>
                    <p className="text-sm font-medium text-purple-600">
                      {item.achievedAmount >= item.targetAmount ? (
                        <>
                          {heffl.format.currency(0, "AED")} (+
                          {heffl.format.currency(
                            item.achievedAmount - item.targetAmount,
                            "AED"
                          )}
                          )
                        </>
                      ) : (
                        heffl.format.currency(
                          item.targetAmount - item.achievedAmount,
                          "AED"
                        )
                      )}
                    </p>
                  </div>

                  <Button
                    variant="outline"
                    className="mt-2 w-full"
                    size="sm"
                    onClick={() =>
                      openSalesGoalItems([item.periodStart, item.periodEnd])
                    }
                  >
                    <Eye className="mr-2 w-4 h-4" />
                    View{" "}
                    {data.type === "FIELD_SERVICE_JOB"
                      ? "Jobs"
                      : data.type === "QUOTATION"
                      ? "Quotations"
                      : "Deals"}
                  </Button>
                </div>
              </div>
            </Card>
          ))}
        </div>
        <Card title="Summary">
          <DataTable
            data={data.goalMetrics}
            rowKey="period"
            columns={[
              {
                title: "Period",
                render: (value) => value.periodLabel,
              },
              {
                title: "Target",
                render: (value) => formatCurrency(value.targetAmount, "AED"),
              },
              {
                title: "Result",
                render: (value) => formatCurrency(value.achievedAmount, "AED"),
              },
              {
                title: "Difference",
                render: (value) =>
                  value.achievedAmount >= value.targetAmount ? (
                    <>
                      {heffl.format.currency(0, "AED")} (+
                      {heffl.format.currency(
                        value.achievedAmount - value.targetAmount,
                        "AED"
                      )}
                      )
                    </>
                  ) : (
                    heffl.format.currency(
                      value.targetAmount - value.achievedAmount,
                      "AED"
                    )
                  ),
              },
              {
                title: "Target Progress",
                render: (value) => `${value.achievedPercentage}%`,
              },
            ]}
          />
        </Card>
      </div>
    </Page>
  );
};

export default SalesGoalDetails;
