import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { formatCurrency, objectToParamsJSON } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { Box, ShoppingBag } from "lucide-react";
import { type FC } from "react";
import { useNavigate } from "react-router-dom";
import { reportDatePresets } from "./salesByItem";

interface PageFilters {
  dates?: [Date, Date];
}

const PurchasesByItemReport: FC = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<PageFilters>({
    dates: [dayjs().startOf("month").toDate(), dayjs().endOf("month").toDate()],
  });

  const { data: report, isLoading } =
    trpc.books.reports.purchasesByItem.useQuery({
      dates: filters.dates!,
    });

  return (
    <Page title="Purchases By Item Report" className="!p-0" fullWidth showBack>
      <FilterBar
        className="px-3 pt-3"
        onChange={() => {}}
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
            presets: reportDatePresets,
          },
        ]}
      />
      <DataGrid
        rowKey="productId"
        name="purchasesByItemReport"
        label="Purchases By Item"
        className="h-[calc(100vh-140px)] mt-12"
        loading={isLoading}
        empty={{
          icon: ShoppingBag,
          title: "No Purchases",
          description: "No purchases found for the selected filters.",
          buttonSize: "sm",
        }}
        rows={report || []}
        columns={[
          {
            key: "name",
            name: "Item Name",
            renderCell: ({ row }) => (
              <div
                onClick={() => {
                  navigate(
                    `/purchases/bills?${objectToParamsJSON({
                      statuses: ["OPEN", "PARTIALLY_PAID", "PAID"],
                      ...(row.type === "ITEM"
                        ? { search: row.name }
                        : { productIds: [row.productId] }),
                      dates: filters.dates,
                    })}`
                  );
                }}
                className="flex gap-1 items-center cursor-pointer hover:underline text-primary-800"
              >
                {row.name}{" "}
                {row.type === "PRODUCT" && (
                  <Box size={16} className="text-gray-500" />
                )}
              </div>
            ),
            width: 350,
          },
          {
            key: "_count",
            name: "Quantity",
            width: 140,
            renderCell: ({ row }) => row._sum?.quantity || 0,
          },
          {
            key: "_sum",
            name: "Amount",
            width: 140,
            renderCell: ({ row }) =>
              formatCurrency(row._sum?.price || 0, "AED", true),
          },
          {
            key: "tax",
            name: "Tax",
            width: 140,
            renderCell: ({ row }) =>
              formatCurrency(row._sum?.tax || 0, "AED", true),
          },
          {
            key: "_avg",
            name: "Average Price",
            width: 140,
            renderCell: ({ row }) =>
              formatCurrency(row._avg?.price || 0, "AED", true),
          },
        ]}
      />
    </Page>
  );
};

export default PurchasesByItemReport;
