import {
  ClientInput,
  ContactInput,
  UserInput,
} from "@/components/FormComponents";
import LineItemSelectorNew from "@/components/line-item-selector-new";

import Page from "@/components/page";
import CustomFieldsInputForm from "@/helpers/customFields/custom-fields-input-form";
import validateCustomFields from "@/helpers/customFields/validateCustomFields";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import useQueryParams from "@/helpers/useQuery";
import usePermissions from "@/lib/hooks/usePermissions";
import useTeam from "@/lib/hooks/useTeam";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import Schemas from "@heffl/server/src/schemas";
import { Button } from "@heffl/ui/components/primitives/button";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import RichTextEditor from "@heffl/ui/components/primitives/RichTextEditor";
import Select from "@heffl/ui/components/primitives/select";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { Pencil, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

export const SalesOrderForm = ({
  form,
  edit = false,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.sales.salesOrder>, unknown>;
  edit?: boolean;
}) => {
  const templateId = form.watch("templateId");
  const clientId = form.watch("clientId");
  const lineItems = form.watch("salesOrderItems");
  const discount = form.watch("discount");

  const permissions = usePermissions();

  const [showEditContent, setShowEditContent] = useState(false);

  const { data: templates } = trpc.templates.list.useQuery({
    type: "SALES_ORDER",
  });

  const { data: templateDetails } = trpc.templates.details.useQuery(
    Number(templateId),
    {
      enabled: !!templateId && !edit,
    }
  );

  useEffect(() => {
    if (templates?.length && !edit && !templateId) {
      form.setValue("templateId", templates[0].id);
    }
  }, [templates]);

  useEffect(() => {
    if (templateDetails && !edit) {
      form.setValue("contentHtml", templateDetails.contentHtml || "");
    }
  }, [templateDetails, form, edit]);

  if (!templates) return <FullScreenSpinner />;

  const lineItemsValues = calculateLineItems({ lineItems, discount });

  if (!permissions) return <FullScreenSpinner />;

  return (
    <div className="space-y-4">
      {!edit && (
        <FormField name="templateId" label="Template" className="sm:w-64">
          <Select
            placeholder="Select sales order template"
            options={templates.map((template) => ({
              label: template.name,
              value: template.id,
            }))}
          />
        </FormField>
      )}
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-4">
        <FormField name="date" label="Date">
          <DatePicker />
        </FormField>
        <UserInput
          name="salesPersonId"
          label="Sales person"
          defaultCurrentUser={!edit}
          type={["STAFF", "ADMIN", "OWNER", "SUPER_ADMIN"]}
          allowClear
        />
      </div>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-4">
        <ClientInput
          className="w-full"
          name="clientId"
          label="Client"
          disabled={!permissions.CREATE_SALES_ORDERS.allowed}
          onAddModalClose={(id) =>
            id &&
            form.setValue("clientId", id, {
              shouldDirty: true,
            })
          }
        />
        <ContactInput
          name="contactId"
          label="Contact"
          clientId={clientId}
          fetchEnabled={!!clientId}
          onAddModalClose={(id) => id && form.setValue("contactId", id)}
          disabled={!clientId || !permissions.CREATE_SALES_ORDERS.allowed}
          setDefault={!edit && !!clientId}
          allowClear
        />
        {!edit && (
          <FormField name="createInstantInvoice" label="Create invoice">
            <Switch />
          </FormField>
        )}
        <CustomFieldsInputForm
          section="SALES_ORDER"
          documentTemplateId={templateId}
        />
      </div>
      <div className="mb-6">
        <FormField name="salesOrderItems" label="">
          <LineItemSelectorNew
            type="SALES_ORDER"
            required={true}
            isEdit={edit}
            disabled={!permissions.CREATE_SALES_ORDERS.allowed}
          />
        </FormField>
      </div>
      <div className="flex justify-end w-full">
        <table className="gap-8 text-base border-separate border-spacing-y-2">
          <tr>
            <td className="w-52">Subtotal</td>
            <td className="text-right">
              {heffl.format.currency(lineItemsValues.subTotal, "AED")}
            </td>
          </tr>
          <tr>
            <td className="w-52">Discount</td>
            <td className="text-right">
              <FormField name="discount">
                <Input
                  disabled={!permissions.CREATE_SALES_ORDERS.allowed}
                  suffix="AED"
                  className="w-[70px]"
                />
              </FormField>
            </td>
          </tr>
          <tr>
            <td className="flex gap-2 items-center w-52">VAT</td>
            <td className="text-right">
              {heffl.format.currency(lineItemsValues.totalTax, "AED")}
            </td>
          </tr>
          <tr>
            <td className="w-52">Total</td>
            <td className="font-semibold text-right">
              {heffl.format.currency(lineItemsValues.total, "AED")}
            </td>
          </tr>
        </table>
      </div>
      <FormField name="notes" label="Notes" className="w-1/2">
        <Textarea />
      </FormField>
      {!showEditContent && (
        <Button
          variant="primaryOutline"
          onClick={() => setShowEditContent(true)}
          icon={Pencil}
        >
          Edit content
        </Button>
      )}
      {showEditContent && (
        <FormField name="contentHtml" label="Content">
          <RichTextEditor height="500px" />
        </FormField>
      )}
    </div>
  );
};

const AddSalesOrder = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const clientId = queryParams.get("clientId");

  const permissions = usePermissions();

  const form = useForm<z.infer<typeof Schemas.sales.salesOrder>>({
    resolver: zodResolver(Schemas.sales.salesOrder),
    defaultValues: {
      date: new Date(),
      clientId: undefined,
      status: "DRAFT",
      notes: "",
      discount: 0,
      contentHtml: "",
    },
  });

  const templateId = form.watch("templateId");

  const team = useTeam();

  const { data: quotationCustomFields } =
    trpc.customizations.customFields.list.useQuery({
      section: "SALES_ORDER",
      documentTemplateId: templateId,
    });

  const salesOrderAddMutation = trpc.sales.salesOrders.add.useMutation({
    onSuccess: (newSalesOrder) => {
      navigate(`/sales/sales-orders/details/${newSalesOrder.id}`);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    if (clientId) {
      form.setValue("clientId", Number(clientId));
    }
  }, [clientId]);

  useEffect(() => {
    if (team && form.getValues("createInstantInvoice") === undefined) {
      form.setValue(
        "createInstantInvoice",
        team.settings.salesOrder.createInstantInvoiceDefault ?? false
      );
    }
  }, [team]);

  if (!team || !permissions) return <FullScreenSpinner />;

  if (!permissions.CREATE_SALES_ORDERS.allowed) {
    return (
      <div className="flex flex-col items-center h-screen">
        <div className="flex gap-3 items-center px-4 py-[10px] w-full border-b border-gray-200">
          <h1 className="text-xl font-semibold">No permission</h1>
        </div>
        <p className="mt-5 md:text-xl text-primaryLight max-w-[690px] text-center font-medium px-2">
          You don&apos;t have permission to create sales orders
        </p>
      </div>
    );
  }

  const onSubmit = (values: z.infer<typeof Schemas.sales.salesOrder>) => {
    // validate quotation custom fields
    const { isValid } = validateCustomFields({
      customFields: quotationCustomFields,
      customFieldsValues: values.customFields,
      form,
    });
    if (!isValid) return;
    salesOrderAddMutation.mutate(values);
  };
  return (
    <Page
      showBack
      className="max-w-screen-lg"
      title="Add Sales Order"
      breadcrumbs={[
        {
          label: "Sales Orders",
          path: "/sales/sales-orders",
        },
        {
          label: "Add Sales Order",
          path: "/sales/sales-orders/add",
        },
      ]}
      suffix={
        <div>
          <ResponsivePrimaryButton
            onClick={() => form.handleSubmit(onSubmit)()}
            type="submit"
            variant="primary"
            loading={salesOrderAddMutation.isLoading}
            icon={Plus}
          >
            Add Sales Order
          </ResponsivePrimaryButton>
        </div>
      }
    >
      <div className="flex flex-col w-full">
        <Form {...form} onSubmit={onSubmit}>
          <div className="flex justify-center px-3 py-5 w-full sm:px-0">
            <SalesOrderForm form={form} />
          </div>
        </Form>
      </div>
    </Page>
  );
};

export default AddSalesOrder;
