import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { reportDatePresets } from "@/pages/books/reports/salesByItem";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { FileText } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const CustomerBalancesReport: React.FC = () => {
  const [filters, setFilters] = useParamsState<
    RouterInputs["sales"]["reports"]["customerBalancesReport"]
  >({
    dateType: "asOf",
    dates: [dayjs().endOf("month").toDate(), dayjs().endOf("month").toDate()],
  });

  const { data: customerBalances } =
    trpc.sales.reports.customerBalancesReport.useQuery(filters);

  const navigate = useNavigate();

  if (!customerBalances) return <FullScreenSpinner />;

  return (
    <Page
      title="Customer Balances Report"
      fullWidth
      className="sm:!p-0 "
      showBack
    >
      <FilterBar
        className="p-3"
        filters={[
          {
            key: "dates",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
            presets: reportDatePresets,
            endDateOnly: true,
          },
        ]}
      />

      <div className="grid grid-cols-2 gap-4 p-3 sm:grid-cols-3 lg:grid-cols-6">
        <Card>
          <h3 className="text-xs font-medium text-gray-500">Invoice Balance</h3>
          <p className="mt-1 text-xl font-semibold text-gray-900">
            {formatCurrency(
              customerBalances?.summary.totalInvoiceBalance,
              "AED"
            )}
          </p>
        </Card>
        <Card>
          <h3 className="text-xs font-medium text-gray-500">Unused Credits</h3>
          <p className="mt-1 text-xl font-semibold text-gray-900">
            {formatCurrency(
              customerBalances?.summary.totalUnUsedCredits,
              "AED"
            )}
          </p>
        </Card>
        <Card>
          <h3 className="text-xs font-medium text-gray-500">Balance</h3>
          <p className="mt-1 text-xl font-semibold text-gray-900">
            {formatCurrency(customerBalances?.summary.totalBalance, "AED")}
          </p>
        </Card>
      </div>
      <DataGrid
        rowKey="id"
        name="customerBalancesReport"
        className="h-[calc(100vh-155px-var(--header-height))]"
        label="Customer Balances"
        loading={!customerBalances}
        empty={{
          icon: FileText,
          title: "No customer balances",
          description:
            "There are no customer balances to display in the summary report.",
        }}
        rows={customerBalances?.clients || []}
        columns={[
          {
            key: "name",
            name: "Customer Name",
            width: 200,
            renderCell: ({ row }) => (
              <div
                className="truncate cursor-pointer hover:text-primary-600 hover:underline text-primary-800"
                onClick={() => navigate(`/crm/clients/details/${row.id}`)}
              >
                {row.name}
              </div>
            ),
          },
          {
            key: "invoiceBalance",
            name: "Invoice Balance",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.invoiceBalance, "AED", true),
          },
          {
            key: "unUsedCredits",
            name: "Unused Credits",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.unUsedCredits, "AED", true),
          },
          {
            key: "balance",
            name: "Balance",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.totalBalance, "AED", true),
          },
        ]}
      />
    </Page>
  );
};

export default CustomerBalancesReport;
