import Empty from "@/components/Empty";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import RenderHtml from "@heffl/ui/components/render-html";
import NextTabs from "@heffl/ui/components/tabs";
import dayjs from "dayjs";
import { FC } from "react";

import { Badge } from "@heffl/ui/components/primitives/badge";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { formatValue } from "@heffl/ui/lib/utils";
import { CircleDollarSignIcon, Navigation, Wrench } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";
import { openGoogleMaps } from "../components/property-selector";
import { ScheduleStatusBadge } from "../jobs/details";
import { getJobId } from "../jobs/list";
import { getJobName } from "../schedules/calendar";

export const calculateDuration = (startDate: Date, endDate: Date): string => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const duration = end.diff(start, "minute"); // Calculate duration in minutes
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  const hourString = hours > 0 ? `${hours}h` : "";
  const minuteString = minutes > 0 ? `${minutes}m` : "";

  return `${hourString} ${minuteString}`.trim();
};

const ScheduleCardMobile = ({
  schedule,
}: {
  schedule: RouterOutputs["fieldService"]["schedules"]["list"]["schedules"][number];
}) => {
  const navigate = useNavigate();

  return (
    <Card
      className="flex gap-2 items-center"
      key={schedule.id}
      onClick={() =>
        navigate(`/field-service/staff-app/schedules/details/${schedule.id}`)
      }
    >
      <div className="flex flex-col w-3/12 text-sm">
        <p className="italic">{getJobId(schedule.fsJobs)}</p>
        <p className="font-medium">
          {dayjs(schedule.startDate).format("hh:mm A")}
        </p>
        <p className="text-gray-500">
          {calculateDuration(schedule.startDate, schedule.endDate)}
        </p>
      </div>
      <div className="w-8/12">
        <p className="font-medium">
          {schedule.fsJobs.fsProperties.clients.name}
        </p>
        <p className="text-sm">{getJobName(schedule.fsJobs.fsJobServices)}</p>
        <p className="text-xs text-gray-500">
          {formatValue.propertyName(schedule.fsJobs.fsProperties)}
        </p>
        <div className="flex gap-1 mt-1">
          <ScheduleStatusBadge schedule={schedule} />
          {schedule.invoiceRequired && (
            <Badge variant="warning" icon={CircleDollarSignIcon}>
              To Invoice
            </Badge>
          )}
          {schedule.fsScheduleTags.map((tag) => (
            <Badge key={tag.id} style={{ backgroundColor: tag.tags.color }}>
              {tag.tags.name}
            </Badge>
          ))}
        </div>
      </div>
      <div className="w-2/12">
        <Button
          onClick={(e) => {
            e.stopPropagation();
            openGoogleMaps(schedule.fsJobs.fsProperties);
          }}
          size="icon"
        >
          <Navigation className="h-5" />
        </Button>
      </div>
    </Card>
  );
};

interface ActivityCardMobileProps {
  activity: RouterOutputs["activities"]["list"]["activities"][number];
  onClick: (id: number) => void;
}

export const ActivityCardMobile: FC<ActivityCardMobileProps> = ({
  activity,
  onClick,
}) => {
  return (
    <Card
      className="flex gap-2 items-center"
      key={activity.id}
      onClick={() => onClick(activity.id)}
    >
      <div className="flex flex-col w-3/12 text-sm">
        <p className="font-medium">
          {dayjs(activity.startDate).format("hh:mm A")}
        </p>
        <p className="text-gray-500">
          {calculateDuration(activity.startDate, activity.endDate)}
        </p>
      </div>
      <div className="w-8/12">
        <p className="font-medium">{activity.title}</p>
        <RenderHtml className="text-xs text-gray-500 line-clamp-2">
          {activity.description}
        </RenderHtml>
        <Badge variant="unique" className="mt-1 capitalize">
          {activity.type}
        </Badge>
      </div>
      <div className="w-2/12">
        {!!activity.locationLink && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              activity.locationLink &&
                openGoogleMaps({
                  googleMapsLink: activity.locationLink,
                });
            }}
            size="icon"
          >
            <Navigation className="h-5" />
          </Button>
        )}
      </div>
    </Card>
  );
};
type FieldStaffDashboardState = {
  date: Date;
  search: string;
  showCompleted: boolean;
  setDate: (date: Date | undefined) => void;
  setSearch: (search: string) => void;
  setShowCompleted: (showCompleted: boolean) => void;
};

const useFieldStaffDashboardStore = create<FieldStaffDashboardState>((set) => ({
  date: dayjs().toDate(),
  search: "",
  showCompleted: false,
  setDate: (date) => set({ date }),
  setSearch: (search) => set({ search }),
  setShowCompleted: (showCompleted) => set({ showCompleted }),
}));

const FieldStaffDashboard = () => {
  const date = useFieldStaffDashboardStore((state) => state.date);
  const search = useFieldStaffDashboardStore((state) => state.search);
  const showCompleted = useFieldStaffDashboardStore(
    (state) => state.showCompleted
  );
  const setDate = useFieldStaffDashboardStore((state) => state.setDate);
  const setSearch = useFieldStaffDashboardStore((state) => state.setSearch);
  const setShowCompleted = useFieldStaffDashboardStore(
    (state) => state.setShowCompleted
  );

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const { data: schedules, isLoading: schedulesLoading } =
    trpc.fieldService.schedules.list.useQuery({
      status: showCompleted
        ? ["COMPLETED"]
        : ["CONFIRMED", "IN_PROGRESS", "ON_MY_WAY", "ARRIVED"],
      dates: [
        dayjs(date).startOf("day").toDate(),
        dayjs(date).endOf("day").toDate(),
      ],
      currentUser: true,
      orderBy: {
        startDate: showCompleted ? "desc" : "asc",
      },
    });

  const { data: dashboardMetrics } =
    trpc.fieldService.jobs.fieldStaffDashboard.useQuery({
      date: date,
    });

  const joinedScehdules = [
    ...(schedules?.schedules || []).map((data) => ({
      type: "schedule" as const,
      data,
    })),
  ].sort((a, b) => {
    return dayjs(a.data.startDate).isAfter(dayjs(b.data.startDate)) ? 1 : -1;
  });

  if (!currentUser) return <FullScreenSpinner />;

  return (
    <Page>
      <p className="text-lg">
        Hello, <span className="font-medium">{currentUser.firstName}</span> 👋
      </p>
      <p className="text-gray-500">{dayjs().format("dddd, MMMM DD YYYY")}</p>
      <div className="grid grid-cols-2 gap-2 mt-4">
        <Card>
          <p className="text-sm font-medium text-gray-500">Total visits</p>
          <p className="text-xl font-semibold sm:text-xl">
            {dashboardMetrics?.totalJobs}
          </p>
        </Card>
        <Card>
          <p className="text-sm font-medium text-gray-500">To start</p>
          <p className="text-xl font-semibold sm:text-xl">
            {dashboardMetrics?.toStartJobs}
          </p>
        </Card>
        <Card>
          <p className="text-sm font-medium text-gray-500">In progress</p>
          <p className="text-xl font-semibold sm:text-xl">
            {dashboardMetrics?.inProgressJobs}
          </p>
        </Card>
        <Card>
          <p className="text-sm font-medium text-gray-500">Completed</p>
          <p className="text-xl font-semibold sm:text-xl">
            {dashboardMetrics?.completedJobs}
          </p>
        </Card>
      </div>
      <DatePicker value={date} onChange={setDate} className="mt-4" />
      <NextTabs
        className="mt-2 w-full"
        contentClassName="w-full"
        value={showCompleted ? "completed" : "todo"}
        onChange={(tab) => {
          setShowCompleted(tab === "completed");
        }}
        tabs={[
          {
            id: "todo",
            label: "Todo",
            content: (
              <div className="w-full">
                <div className="py-2 space-y-2 w-full">
                  <SearchInput
                    className="w-full"
                    value={search}
                    onChange={setSearch}
                    placeholder="Search jobs..."
                  />
                </div>
                <div className="flex flex-col gap-2">
                  {!joinedScehdules?.length && (
                    <Empty
                      loading={schedulesLoading}
                      icon={Wrench}
                      title="No jobs pending"
                      description="There are no pending jobs"
                    />
                  )}
                  {joinedScehdules?.map((schedule) => (
                    <ScheduleCardMobile
                      key={schedule.data.id}
                      schedule={schedule.data}
                    />
                  ))}
                </div>
              </div>
            ),
          },
          {
            id: "completed",
            label: "Completed",
            content: (
              <div className="w-full">
                {!joinedScehdules?.length && (
                  <Empty
                    className="pt-12"
                    icon={Wrench}
                    title="No jobs completed"
                    description="You have no completed jobs"
                  />
                )}
                <div className="flex flex-col gap-2">
                  {joinedScehdules?.map((schedule) => (
                    <ScheduleCardMobile
                      key={schedule.data.id}
                      schedule={schedule.data}
                    />
                  ))}
                </div>
              </div>
            ),
          },
        ]}
      />
    </Page>
  );
};

export default FieldStaffDashboard;
