import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import Select from "@heffl/ui/components/primitives/select";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";

type CashflowFormProps = {
  form: UseFormReturn<z.infer<typeof Schemas.books.cashflowTransaction>>;
};

const CashflowForm = ({ form }: CashflowFormProps) => {
  const type = form.watch("type");
  const accountId = form.watch("accountId");

  const accounts = trpc.books.cashflowAccounts.list.useQuery();

  if (!accounts.data) return <FullScreenSpinner />;

  const cashAndBankAccounts = accounts.data.filter(
    (account) =>
      (account.type === "CASH" || account.type === "BANK") &&
      account.id !== accountId
  );

  return (
    <>
      <div className="grid grid-cols-2 gap-y-1 gap-x-2">
        <FormField name="date" label="Date">
          <DatePicker />
        </FormField>
        <FormField name="refNo" label="Refno">
          <Input placeholder="Enter reference" />
        </FormField>
        <FormField
          name="creditAccountId"
          className="col-span-1"
          label={
            type === "TRANSFER_FROM_ACCOUNT" ? "From Account" : "To Account"
          }
        >
          <Select
            options={cashAndBankAccounts.map((account) => ({
              label: account.name,
              value: account.id,
            }))}
            placeholder="Select account"
            className="w-full"
          />
        </FormField>
        <FormField name="amount" className="col-span-1" label="Amount">
          <Input
            type="number"
            prefix="AED"
            prefixFilled
            placeholder="Enter amount"
            className="w-full"
          />
        </FormField>
        <FormField
          name="description"
          label="Description"
          className="col-span-2"
        >
          <Textarea placeholder="Enter description" />
        </FormField>
      </div>
    </>
  );
};

export default CashflowForm;
