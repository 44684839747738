import { ConfirmModal, ConfirmModalProps } from "@/lib/confirm-modal";
import { ReasonModal, ReasonModalProps } from "@/lib/reason-modal";
import NiceModal from "@ebay/nice-modal-react";
import appIcons from "@heffl/ui/components/appIcons";
import {
  dynamicDateFormatting,
  formatCurrency,
  formatName,
  makeEllipsis,
  objectToParamsJSON,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { capitalize, sum } from "radash";
import toast from "react-hot-toast";
import { dinero, greaterThan, add, subtract, toSnapshot } from "dinero.js";
import { AED } from "@dinero.js/currencies";
import { PromptModal, PromptModalProps } from "@/lib/prompt-modal";
import { convert } from "html-to-text";
import calculateInvoice from "@heffl/server/src/helpers/lineItems/calculateInvoice";

// rules - only nest 3 levels deep
dayjs.extend(duration);

const modal = {
  reason: (props: ReasonModalProps) =>
    NiceModal.show(ReasonModal, { ...props }),
  confirm: (props: ConfirmModalProps) =>
    NiceModal.show(ConfirmModal, { ...props }),
  prompt: (props: PromptModalProps) =>
    NiceModal.show(PromptModal, { ...props }),
};

const math = {
  sum,
};

const icons = appIcons;

const dateFormat = {
  now: () => dayjs().toDate(),
  dynamicDate: (date: Date, showTime: boolean = false) =>
    dynamicDateFormatting(date, !showTime),
  date: (date: Date) => dayjs(date).format("DD/MM/YYYY"),
  dateTime: (date: Date) => dayjs(date).format("DD/MM/YYYY hh:mm A"),
  duration: (minutes: number, format: "xs" | "sm" = "sm") => {
    const duration = dayjs.duration(minutes, "minutes");
    const hours = Math.floor(duration.asHours());
    const mins = duration.minutes();

    if (hours === 0) return `${mins}${format === "xs" ? "m" : " mins"}`;
    if (mins === 0) return `${hours}${format === "xs" ? "h" : " hrs"}`;
    return `${hours}${format === "xs" ? "h" : " hrs"} ${mins}${
      format === "xs" ? "m" : "mins"
    }`;
  },
};

const url = {
  objToParams: (obj: Record<string, unknown>) => {
    return objectToParamsJSON(obj);
  },
};

const calculate = {
  invoice: calculateInvoice,
};

const date = {
  start: (date: Date) => {
    return dayjs(date).startOf("day").toDate();
  },
  end: (date: Date) => {
    return dayjs(date).endOf("day").toDate();
  },
  now: () => {
    return dayjs().toDate();
  },
  nowEnd: () => {
    return dayjs().endOf("day").toDate();
  },
  nowStart: () => {
    return dayjs().startOf("day").toDate();
  },
  monthStart: () => {
    return dayjs().startOf("month").toDate();
  },
  monthEnd: () => {
    return dayjs().endOf("month").toDate();
  },
  isDue: (date: Date) => {
    return dayjs().startOf("day").isAfter(dayjs(date).startOf("day"));
  },
  isToday: (date: Date) => {
    return dayjs().isSame(date, "day");
  },
  isThisWeek: (date: Date) => {
    return dayjs().isSame(date, "week");
  },
};

const array = {
  includes: <T>(array: T[], value: T): boolean => {
    return array.includes(value);
  },
};

const money = {
  gt: (a: number, b: number) => {
    // this only works for AED or currency with 100 subunits, oman rial has 1000 subunits
    return greaterThan(
      dinero({ amount: Math.round(a * 100), currency: AED }),
      dinero({ amount: Math.round(b * 100), currency: AED })
    );
  },
  sum: <T>(arr: T[], fn: (item: T) => number) => {
    // Convert each amount to dinero, sum them, then convert back to number
    return arr.reduce((acc, item) => {
      const amount = fn(item);
      const dineroAmount = dinero({
        amount: Math.round(amount * 100),
        currency: AED,
      });
      const accDinero = dinero({
        amount: Math.round(acc * 100),
        currency: AED,
      });
      const total = add(accDinero, dineroAmount);
      return total.toJSON().amount / 100;
    }, 0);
  },
  add: (a: number, b: number) => {
    const aDinero = dinero({ amount: Math.round(a * 100), currency: AED });
    const bDinero = dinero({ amount: Math.round(b * 100), currency: AED });
    return toSnapshot(add(aDinero, bDinero)).amount / 100;
  },
  subtract: (a: number, b: number) => {
    const aDinero = dinero({ amount: Math.round(a * 100), currency: AED });
    const bDinero = dinero({ amount: Math.round(b * 100), currency: AED });
    return toSnapshot(subtract(aDinero, bDinero)).amount / 100;
  },
};

const is = {
  empty: (value?: unknown | null) => {
    if (typeof value === "object") {
      return value === null || Object.keys(value).length === 0;
    }
    return !value;
  },
};

const format = {
  name: formatName,
  currency: formatCurrency,
  capitalize: capitalize,
  ellipsis: makeEllipsis,
  html: (html: string) => {
    return convert(html);
  },
  ...dateFormat,
};

const heffl = {
  format,
  modal,
  toast,
  icons,
  math,
  array,
  date,
  money,
  url,
  is,
  calculate,
};

export default heffl;
