import Page from "@/components/page";
import useTeam from "@/lib/hooks/useTeam";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import SimpleTable from "@heffl/ui/components/simple-table";
import React from "react";
import { useNavigate } from "react-router-dom";

const reports = [
  {
    title: "Project Summary Reports",
    description: "Overview of project status and key metrics.	",
    slug: "project-summary-report",
  },
  {
    title: "Recurring Project Report",
    description: "Overview of recurring project status and key metrics.",
    slug: "recurring-project-report",
  },
  {
    title: "Project Financial Report",
    description: "Detailed report on project financial status and performance.",
    slug: "project-financial-report",
  },
  {
    title: "Tasks Reports",
    description: "Detailed report on task status and performance.",
    slug: "tasks-report",
  },
  {
    title: "Timesheet Reports",
    description: "Detailed report on timesheet status and performance.",
    slug: "timesheet-reports",
  },
];

const ProjectReports: React.FC = () => {
  const navigate = useNavigate();
  const team = useTeam();

  if (!team) return <FullScreenSpinner />;

  if (!team.user.permissions.VIEW_PROJECTS_REPORTS.allowed)
    return <NoPermissionScreen />;

  return (
    <Page title="Project Reports" showBack>
      <SimpleTable
        rows={reports}
        fixedHeader={false}
        idKey="slug"
        onRowClick={(report) => navigate(`/projects/reports/${report.slug}`)}
        columns={[
          {
            label: "Report Title",
            render: (report) => (
              <span className="font-medium text-blue-400">{report.title}</span>
            ),
          },
          {
            label: "Description",
            render: (report) => (
              <span className="text-sm text-gray-500">
                {report.description}
              </span>
            ),
          },
          {
            label: "Created By",
            render: () => (
              <span className="text-sm text-gray-500">System generated</span>
            ),
          },
        ]}
      />
    </Page>
  );
};

export default ProjectReports;
