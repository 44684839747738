import FilterBar from "@/components/filter-bar";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { Badge } from "@heffl/ui/components/primitives/badge";
import SimpleTable from "@heffl/ui/components/simple-table";
import { cn, objectToParamsJSON } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { Sparkles } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface TaskStats {
  todo: number;
  inProgress: number;
  onHold: number;
  completed: number;
}

interface TaskData {
  name: string;
  stats: TaskStats;
}

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    payload: TaskData;
  }>;
}

const TaskStatusByAssignee: React.FC = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<{
    dates: [Date, Date];
  }>({
    dates: [dayjs().subtract(30, "days").toDate(), dayjs().toDate()],
  });

  const { data: taskData } =
    trpc.projects.reports.taskStatusByAssignee.useQuery({
      dates: filters.dates,
    });

  const onChartClick = (assignee: number, status?: string) => {
    const params = objectToParamsJSON({
      assignees: [assignee],
      dates: filters.dates,
      statuses: status
        ? [status]
        : ["OPEN", "IN_PROGRESS", "ON_HOLD", "COMPLETED"],
    });
    navigate(`/projects/tasks/table?${params}`);
  };

  const CustomTooltip: React.FC<TooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="p-4 bg-white rounded-xl border border-gray-100 shadow-lg">
          <p className="mb-3 text-lg font-bold text-gray-800">{data.name}</p>
          <div className="space-y-2">
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
              <span className="font-medium">{`${data.stats.todo} To Do`}</span>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-blue-500 rounded-full"></div>
              <span className="font-medium">{`${data.stats.inProgress} In Progress`}</span>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-orange-500 rounded-full"></div>
              <span className="font-medium">{`${data.stats.onHold} On Hold`}</span>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-green-500 rounded-full"></div>
              <span className="font-medium">{`${data.stats.completed} Completed`}</span>
            </div>
          </div>
          <div className="pt-3 mt-3 border-t border-gray-100">
            <p className="font-medium text-gray-600">
              Total Tasks:{" "}
              {data.stats.todo +
                data.stats.inProgress +
                data.stats.onHold +
                data.stats.completed}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full h-[500px]">
      <FilterBar
        filters={[
          {
            label: "Date",
            type: "date-range",
            key: "dates",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
          },
        ]}
      />
      <div className="overflow-hidden relative p-3 mt-4 mb-6 bg-gradient-to-br from-indigo-50 to-purple-50 rounded-xl">
        <div className="absolute top-0 right-0 w-32 h-32 bg-purple-200 rounded-full opacity-20 filter blur-3xl animate-pulse" />

        <div className="flex gap-3 items-center mb-4">
          <div className="p-2 bg-purple-100 rounded-lg">
            <Sparkles className="w-6 h-6 text-purple-600 animate-pulse" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900">Tasks Summary</h3>
          <Badge variant="warning">Beta</Badge>
        </div>

        <div className="space-y-4 text-gray-700">
          {taskData?.length ? (
            <>
              <p className="leading-relaxed">
                {(() => {
                  const totalTasks = taskData.reduce(
                    (acc, project) =>
                      acc +
                      project.stats.todo +
                      project.stats.inProgress +
                      project.stats.onHold +
                      project.stats.completed,
                    0
                  );
                  const completedTasks = taskData.reduce(
                    (acc, project) => acc + project.stats.completed,
                    0
                  );
                  const inProgressTasks = taskData.reduce(
                    (acc, project) => acc + project.stats.inProgress,
                    0
                  );

                  return `Our team is currently managing ${totalTasks} tasks across ${
                    taskData.length
                  } ${
                    taskData.length === 1 ? "assignee" : "assignees"
                  }. ${completedTasks} tasks have been successfully completed, while ${inProgressTasks} are actively in progress. The overall completion rate stands at ${Math.round(
                    (completedTasks / totalTasks) * 100
                  )}%.`;
                })()}
              </p>

              <div className="pt-4">
                <h4 className="mb-2 font-medium text-gray-900">
                  Top Performers
                </h4>
                {(() => {
                  const performers = taskData
                    .map((person) => ({
                      name: person.name,
                      completionRate:
                        (person.stats.completed /
                          (person.stats.todo +
                            person.stats.inProgress +
                            person.stats.onHold +
                            person.stats.completed)) *
                        100,
                      totalCompleted: person.stats.completed,
                      totalTasks:
                        person.stats.todo +
                        person.stats.inProgress +
                        person.stats.onHold +
                        person.stats.completed,
                    }))
                    .filter((p) => p.totalTasks > 0)
                    .sort((a, b) => b.completionRate - a.completionRate)
                    .slice(0, 3);

                  return (
                    <ul className="space-y-2">
                      {performers.map((performer, idx) => (
                        <li
                          key={performer.name}
                          className="flex gap-2 items-center"
                        >
                          <span className="flex justify-center items-center w-6 h-6 text-xl font-medium">
                            {idx === 0 ? "🥇" : idx === 1 ? "🥈" : "🥉"}
                          </span>
                          <span className="font-medium">{performer.name}</span>
                          <span className="text-sm text-gray-500">
                            ({Math.round(performer.completionRate)}% completion
                            rate,
                            {performer.totalCompleted} tasks completed)
                          </span>
                        </li>
                      ))}
                    </ul>
                  );
                })()}
              </div>

              <div className="pt-0">
                <h4 className="mb-2 font-medium text-gray-900">
                  Growth Opportunities
                </h4>
                {(() => {
                  const needingSupport = taskData
                    .map((person) => ({
                      name: person.name,
                      completionRate:
                        (person.stats.completed /
                          (person.stats.todo +
                            person.stats.inProgress +
                            person.stats.onHold +
                            person.stats.completed)) *
                        100,
                      totalCompleted: person.stats.completed,
                      totalTasks:
                        person.stats.todo +
                        person.stats.inProgress +
                        person.stats.onHold +
                        person.stats.completed,
                    }))
                    .filter((p) => p.totalTasks > 0)
                    .sort((a, b) => a.completionRate - b.completionRate)
                    .slice(0, 3);

                  return (
                    <ul className="space-y-2">
                      {needingSupport.map((performer, idx) => (
                        <li
                          key={performer.name}
                          className="flex gap-2 items-center"
                        >
                          <span className="flex justify-center items-center w-6 h-6 text-xl font-medium">
                            {idx === 0 ? "🎯" : idx === 1 ? "🌱" : "💪"}
                          </span>
                          <span className="font-medium">{performer.name}</span>
                          <span className="text-sm text-gray-500">
                            ({Math.round(performer.completionRate)}% completion
                            rate,
                            {performer.totalCompleted} tasks completed)
                          </span>
                        </li>
                      ))}
                    </ul>
                  );
                })()}
              </div>
            </>
          ) : (
            <p>No task data available for the selected time period.</p>
          )}
        </div>

        <div className="absolute bottom-0 left-0 w-40 h-40 bg-indigo-200 rounded-full opacity-20 filter blur-3xl delay-700 animate-pulse" />
      </div>

      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={taskData}
          layout="vertical"
          margin={{ top: 20, right: 30, left: 100, bottom: 5 }}
          barSize={40}
          className="[&_.recharts-cartesian-grid-horizontal_line]:stroke-gray-200 [&_.recharts-cartesian-grid-vertical_line]:stroke-gray-200"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            domain={[0, "dataMax"]}
            tickLine={false}
            axisLine={true}
            stroke="#94a3b8"
          />
          <YAxis
            dataKey="name"
            type="category"
            tickLine={false}
            axisLine={true}
            stroke="#94a3b8"
            fontSize={14}
            width={100}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="top"
            height={50}
            iconType="circle"
            formatter={(value: string) => (
              <span className="text-sm font-medium">{value}</span>
            )}
          />
          <Bar
            dataKey="stats.todo"
            stackId="a"
            fill="#F59E0B"
            name="To Do"
            radius={[0, 0, 0, 0]}
            onClick={(c) => onChartClick(c.id, "OPEN")}
            cursor="pointer"
          >
            <LabelList
              dataKey="stats.todo"
              position="middle"
              fill="#fff"
              textAnchor="middle"
              fontSize={10}
              fontWeight="bold"
              formatter={(value: number) => (value > 0 ? value : "")}
            />
          </Bar>
          <Bar
            dataKey="stats.inProgress"
            stackId="a"
            fill="#60A5FA"
            name="In Progress"
            radius={[0, 0, 0, 0]}
            onClick={(c) => onChartClick(c.id, "IN_PROGRESS")}
            cursor="pointer"
          >
            <LabelList
              dataKey="stats.inProgress"
              position="middle"
              fill="#fff"
              textAnchor="middle"
              fontSize={10}
              fontWeight="bold"
              formatter={(value: number) => (value > 0 ? value : "")}
            />
          </Bar>
          <Bar
            dataKey="stats.onHold"
            stackId="a"
            fill="#F97316"
            name="On Hold"
            radius={[0, 0, 0, 0]}
            onClick={(c) => onChartClick(c.id, "ON_HOLD")}
            cursor="pointer"
          >
            <LabelList
              dataKey="stats.onHold"
              position="middle"
              fill="#fff"
              textAnchor="middle"
              fontSize={10}
              fontWeight="bold"
              formatter={(value: number) => (value > 0 ? value : "")}
            />
          </Bar>
          <Bar
            dataKey="stats.completed"
            stackId="a"
            fill="#34D399"
            name="Completed"
            radius={[0, 0, 0, 0]}
            onClick={(c) => onChartClick(c.id, "COMPLETED")}
            cursor="pointer"
          >
            <LabelList
              dataKey="stats.completed"
              position="middle"
              fill="#fff"
              textAnchor="middle"
              fontSize={10}
              fontWeight="bold"
              formatter={(value: number) => (value > 0 ? value : "")}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <SimpleTable
        rows={taskData || []}
        idKey="name"
        fixedHeader={false}
        columns={[
          {
            label: "Assignee",
            render: (row) => (
              <span
                onClick={() => onChartClick(row.id)}
                className="font-medium text-gray-900 cursor-pointer hover:underline"
              >
                {row.name}
              </span>
            ),
            fixed: "left",
          },
          {
            label: "Open",
            render: (row) => (
              <span className="text-gray-500">{row.stats.todo}</span>
            ),
          },
          {
            label: "In Progress",
            render: (row) => (
              <span className="text-blue-500">{row.stats.inProgress}</span>
            ),
          },
          {
            label: "On Hold",
            render: (row) => (
              <span className="text-orange-500">{row.stats.onHold}</span>
            ),
          },
          {
            label: "Completed",
            render: (row) => (
              <span className="text-emerald-500">{row.stats.completed}</span>
            ),
          },
          {
            label: "Total Tasks",
            render: (row) => {
              const total =
                row.stats.todo +
                row.stats.inProgress +
                row.stats.onHold +
                row.stats.completed;
              return <span className="font-medium">{total}</span>;
            },
          },
          {
            label: "Completion Rate",
            render: (row) => {
              const total =
                row.stats.todo +
                row.stats.inProgress +
                row.stats.onHold +
                row.stats.completed;
              const completionRate =
                total > 0 ? Math.round((row.stats.completed / total) * 100) : 0;
              return (
                <span
                  className={cn(
                    "font-medium",
                    completionRate >= 75
                      ? "text-emerald-500"
                      : completionRate >= 50
                      ? "text-blue-500"
                      : completionRate >= 25
                      ? "text-orange-500"
                      : "text-gray-500"
                  )}
                >
                  {completionRate}%
                </span>
              );
            },
          },
        ]}
        className="mt-8"
      />
    </div>
  );
};

export default TaskStatusByAssignee;
