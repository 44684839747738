import Page from "@/components/page";
import SimpleTable from "@heffl/ui/components/simple-table";
// import { trpc } from "@/helpers/trpc";
import useTeam from "@/lib/hooks/useTeam";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import React from "react";
import { useNavigate } from "react-router-dom";

const reports = [
  {
    title: "Service/product Utilization",
    description: "Overview of service/product utilization.",
    slug: "service-product-utilization",
  },
  {
    title: "Vendor Summary Report",
    description: "Overview of jobs with vendors.",
    slug: "vendor-summary-report",
  },
  {
    title: "Contracts Expiring Jobs",
    description: "Overview of contracts expiring jobs.",
    slug: "contracts-expiring-jobs",
  },
];

const FieldServiceReports: React.FC = () => {
  const navigate = useNavigate();

  const team = useTeam();

  if (!team) return <FullScreenSpinner />;

  if (!team.user.permissions.VIEW_JOBS_REPORTS.allowed)
    return <NoPermissionScreen />;

  return (
    <Page title="Field Service Reports">
      <SimpleTable
        fixedHeader={false}
        columns={[
          {
            label: "Report Title",
            render: (row) => (
              <span
                className="font-medium text-blue-400 cursor-pointer"
                onClick={() => {
                  navigate(`/field-service/reports/${row.slug}`);
                }}
              >
                {row.title}
              </span>
            ),
          },
          {
            label: "Description",
            render: (row) => (
              <span className="text-sm text-gray-500">{row.description}</span>
            ),
          },
          {
            label: "Created By",
            render: () => (
              <span className="text-sm text-gray-500">System generated</span>
            ),
          },
        ]}
        rows={reports}
        idKey="slug"
      />
    </Page>
  );
};

export default FieldServiceReports;
