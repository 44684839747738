import { RouterInputs, RouterOutputs, trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { objectToParamsJSON } from "@heffl/ui/lib/utils";
import { Link } from "react-router-dom";
import { DashboardChartItem } from "../charts-item-helpers";

const RecurringProjectCount: React.FC<{
  filterValues: RouterInputs["projects"]["reports"]["recurringProjectReport"];
}> = ({ filterValues }) => {
  const { data: recurringProjects } =
    trpc.projects.reports.recurringProjectCount.useQuery({
      ...filterValues,
    });

  if (recurringProjects === undefined) return <FullScreenSpinner />;

  return (
    <div className="text-8xl text-center">
      <Link
        to={`/projects/recurring-projects/list?${objectToParamsJSON({
          ...filterValues,
        })}`}
      >
        {recurringProjects?.count}
      </Link>
    </div>
  );
};

export const recurringProjectCountChart: DashboardChartItem = {
  name: "recurring-project-count",
  title: "Recurring Project Count",
  description: "Count of recurring projects",
  entity: "PROJECT",
  defaultWidth: 25,
  mainChartType: "number",
  components: {
    stat: RecurringProjectCount,
    chart: null,
    table: null,
  },
  filters: [
    {
      key: "startDate",
      type: "date-range",
      label: "Start Date",
    },
    {
      key: "endDate",
      type: "date-range",
      label: "End Date",
    },
    {
      key: "pipelines",
      type: "checkbox",
      label: "Pipeline",
      optionsMeta: {
        hasAsyncSearch: false,
        query: "projects.pipelines.list",
        queryInput: {},
        parseResult: (data: RouterOutputs["projects"]["pipelines"]["list"]) =>
          data.map((pipeline) => ({
            label: pipeline.name,
            value: pipeline.id,
          })),
      },
    },
    {
      key: "clients",
      type: "checkbox",
      label: "Clients",
      optionsMeta: {
        hasAsyncSearch: true,
        query: "clients.list",
        queryInput: {
          pageSize: 20,
        } as RouterInputs["clients"]["list"],
        parseResult: (data: RouterOutputs["clients"]["list"]) =>
          data.clients.map((client) => ({
            label: client.name,
            value: client.id,
          })),
      },
    },
    {
      key: "createdAt",
      type: "date-range",
      label: "Created At",
    },
    {
      key: "isActive",
      multiple: false,
      type: "checkbox",
      label: "Status",
      options: [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
      ],
    },
  ],
};
