"use client";

import { XCircle } from "lucide-react";

import { Button } from "@heffl/ui/components/primitives/button";
import { Calendar } from "@heffl/ui/components/primitives/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { cn, dynamicDateFormatting } from "@heffl/ui/lib/utils";
import * as chrono from "chrono-node";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Input } from "./input";
import TimePicker from "./time-picker";

type TProps = {
  displayFormat?: string;
  value?: Date;
  onChange?: (v: Date | undefined) => void;
  presets?: { label: string; value: Date }[];
  allowClear?: boolean;
  renderClassName?: string;
  placeholder?: string;
};

const getColorBasedOnDate = (date: Date): string => {
  const today = dayjs();
  const tomorrow = today.add(1, "day");
  const isToday = dayjs(date).isSame(today, "day");
  const isTomorrow = dayjs(date).isSame(tomorrow, "day");
  const isThisWeek = dayjs(date).isSame(today, "week");
  if (isToday) {
    return "text-green-500";
  } else if (isTomorrow) {
    return "text-orange-500";
  } else if (isThisWeek) {
    return "text-blue-500";
  } else {
    return "text-gray-800";
  }
};

export function DateTimePicker({
  value,
  onChange = () => {},
  displayFormat,
  presets = [],
  allowClear = false,
  renderClassName,
  placeholder,
}: TProps) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showCalendar) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
    setInputValue("");
  }, [showCalendar]);

  return (
    <div className="flex gap-1 items-center">
      <Popover open={showCalendar} onOpenChange={setShowCalendar}>
        <PopoverTrigger asChild>
          <Button
            onClick={() => setShowCalendar(true)}
            iconify="tabler:calendar"
            variant="ghost"
            className={cn(
              "justify-start text-left relative !text-xs border bg-white",
              !value && "text-muted-foreground",
              `${value && getColorBasedOnDate(value)} hover:${
                value && getColorBasedOnDate(value)
              }`,
              renderClassName
            )}
            size="sm"
          >
            {value ? (
              displayFormat ? (
                dayjs(value).format(displayFormat)
              ) : (
                dynamicDateFormatting(value, true)
              )
            ) : (
              <span>{placeholder || "Pick date & time"}</span>
            )}
            {allowClear && value && (
              <XCircle
                onClick={(e) => {
                  onChange(undefined);
                  setShowCalendar(false);
                  e.stopPropagation();
                }}
                className="absolute right-2 h-4 text-gray-400 cursor-pointer"
              />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0 w-auto" sideOffset={4}>
          <div className="p-2">
            <Input
              ref={inputRef}
              className="w-full !h-8"
              placeholder="Next week, next month"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const parsed = chrono.parseDate(inputValue);
                  if (parsed) {
                    onChange(parsed);
                    setInputValue("");
                    setShowCalendar(false);
                  }
                }
              }}
            />
          </div>
          <Calendar
            mode="single"
            selected={value}
            onSelect={(newValue) => {
              const time = value ? dayjs(value) : dayjs();
              onChange(
                dayjs(newValue)
                  .set("hour", time.hour())
                  .set("minute", time.minute())
                  .toDate()
              );
              setShowCalendar(false);
            }}
            initialFocus
          />
          {!!presets.length && (
            <div className="flex flex-wrap gap-2 p-2 bg-green-50">
              {presets.map((preset) => (
                <div
                  key={preset.label}
                  className="px-2 py-1 text-xs bg-white rounded-sm border shadow-sm cursor-pointer hover:bg-gray-50"
                  onClick={() => {
                    onChange(preset.value);
                    setShowCalendar(false);
                  }}
                >
                  {preset.label}
                </div>
              ))}
            </div>
          )}
        </PopoverContent>
      </Popover>
      <TimePicker
        value={value}
        onChange={(newTimeValue) => {
          if (!onChange || !dayjs(newTimeValue).isValid()) return;
          const time = dayjs(newTimeValue);
          if (onChange && newTimeValue) {
            onChange(
              dayjs(value)
                .set("hour", time.hour())
                .set("minute", time.minute())
                .toDate()
            );
          }
        }}
      />
    </div>
  );
}
