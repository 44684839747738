/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterDataType } from "@/components/filter-bar";
import enums from "@heffl/server/src/schemas/enums";
import { ReactNode } from "react";
import { z } from "zod";
import { assigneeDistributionChart } from "./all-charts/assignee-distribution";
import { taskStatusDistributionChart } from "./all-charts/project-task-status-distribution";
import parseRelativeDate from "./utils/parseRelativeDate";
import { taskCountChart } from "./all-charts/task-count";
import { projectCountChart } from "./all-charts/project-count";
import { recurringProjectCountChart } from "./all-charts/recurring-project-count";

type Filter = Omit<FilterDataType, "onChange" | "value"> & {
  optionsMeta?: {
    query: string;
    queryInput?: any;
    parseResult: (data: any) => Array<{ label: string; value: any }>;
    // means async search to db
    hasAsyncSearch: boolean;
  };
  options?: { label: string; value: any }[];
  multiple?: boolean;
};

export type DashboardChartItem = {
  name: string;
  title: string;
  description: string;
  entity: z.infer<typeof enums.entities>;
  filters: Filter[];
  defaultWidth: number;
  mainChartType: "pie" | "bar" | "funnel" | "number";
  components: {
    stat: ((props: any) => ReactNode) | null;
    chart: ((props: any) => ReactNode) | null;
    table: ReactNode | null;
  };
};

export const dashboardChartItems: DashboardChartItem[] = [
  taskStatusDistributionChart,
  assigneeDistributionChart,
  taskCountChart,
  projectCountChart,
  recurringProjectCountChart,
];

type RenderDashboardChartItemProps = {
  name: string;
  filterValues: any;
  width: number;
};

export const RenderDashboardChartItem = ({
  name,
  filterValues: savedFilters,
  width,
}: RenderDashboardChartItemProps) => {
  const item = dashboardChartItems.find((item) => item.name === name);

  if (!item) return null;

  const parsedFilterValues: Record<string, any> = {};

  Object.keys(savedFilters).forEach((savedFilterKey) => {
    const filterMeta = item.filters.find((f) => f.key === savedFilterKey);
    if (!filterMeta) parsedFilterValues[savedFilterKey] = undefined;
    else if (filterMeta.type === "date-range") {
      parsedFilterValues[savedFilterKey] = parseRelativeDate(
        savedFilters[savedFilterKey]
      );
    } else {
      parsedFilterValues[savedFilterKey] = savedFilters[savedFilterKey];
    }
  });

  return (
    <div>
      {item.components.stat &&
        item.components.stat({ filterValues: parsedFilterValues, width })}
      {item.components.chart &&
        item.components.chart({ filterValues: parsedFilterValues, width })}
    </div>
  );
};
