import { trpc } from "@/helpers/trpc";
import enums from "@heffl/server/src/schemas/enums";
import Select from "@heffl/ui/components/primitives/select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import { formatName, generateUniqueColor } from "@heffl/ui/lib/utils";
import { List, UserCircle2, Mail } from "lucide-react";
import { match } from "ts-pattern";
import { z } from "zod";
import { PhoneInput } from "@heffl/ui/components/primitives/phone-input";
import { Textarea } from "@heffl/ui/components/primitives/textarea";

export const preventInputMinus = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.code === "Minus") {
    e.preventDefault();
  }
};

type CustomField = {
  dataType: z.infer<typeof enums.customFieldTypes>;
  placeholder?: string;
  values?: string[];
  required: boolean;
  label: string;
};

export const CustomFieldInput = ({
  field,
  onChange,
  value,
  disabled = false,
}: {
  field: CustomField;
  onChange?: (value: unknown) => void;
  value?: unknown;
  disabled?: boolean;
}) => {
  const { data: users } = trpc.users.list.useQuery(undefined, {
    enabled: field.dataType === "MULTIPLE_USER_SELECT",
  });

  return match(field)
    .with({ dataType: "TEXT" }, () => (
      <Input
        placeholder={field.placeholder || `Enter ${field.label}`}
        onChange={(e) => onChange?.(e.target.value)}
        value={value as string}
        disabled={disabled}
      />
    ))
    .with({ dataType: "NUMBER" }, () => (
      <Input
        type="number"
        onKeyDown={preventInputMinus}
        min="0"
        placeholder={field.placeholder || `Enter ${field.label}`}
        // for preventing number increment or decrement on scroll
        onWheel={(e) => e.currentTarget.blur()}
        onChange={(e) =>
          onChange?.(e.target.value ? Number(e.target.value) : undefined)
        }
        value={value as number}
        disabled={disabled}
      />
    ))
    .with({ dataType: "DATE" }, () => (
      <DatePicker
        placeholder={field.placeholder}
        onChange={onChange}
        value={value as Date}
        disabled={disabled}
      />
    ))
    .with({ dataType: "SINGLE_OPTION" }, () => (
      <Select
        placeholder={field.placeholder || "Select an option"}
        options={field.values?.map((field) => ({
          label: field,
          value: field,
        }))}
        allowClear={!field.required}
        onChange={onChange}
        value={value as string}
        disabled={disabled}
      />
    ))
    .with({ dataType: "MULTIPLE_USER_SELECT" }, () => (
      <MultiSelect
        icon={UserCircle2}
        placeholder={field.placeholder || "Select users"}
        options={users?.map((user) => ({
          label: formatName(user),
          value: user.id,
          color: generateUniqueColor(user.id.toString()),
        }))}
        disabled={disabled}
      />
    ))
    .with({ dataType: "LINK_ENTITY" }, () => <p>Link entity</p>)
    .with({ dataType: "MULTIPLE_OPTION" }, () => (
      <MultiSelect
        options={field.values?.map((field) => ({
          label: field,
          value: field,
        }))}
        placeholder={field.placeholder || "Select options"}
        icon={List}
        value={value as string[]}
        onChange={onChange}
        disabled={disabled}
      />
    ))
    .with({ dataType: "FILE_PICKER" }, () => <p>File picker</p>)
    .with({ dataType: "LONG_TEXT" }, () => (
      <Textarea
        placeholder={field.placeholder || `Enter ${field.label}`}
        onChange={onChange}
        value={value as string}
        disabled={disabled}
      />
    ))
    .with({ dataType: "MOBILE" }, () => (
      <PhoneInput
        placeholder={field.placeholder}
        onChange={onChange}
        value={value as string}
        defaultCountry="AE"
        disabled={disabled}
      />
    ))
    .with({ dataType: "EMAIL" }, () => (
      <Input
        prefix={<Mail className="w-4 h-4" />}
        type="email"
        placeholder={field.placeholder || "Enter email"}
        onChange={onChange}
        value={value as string}
        disabled={disabled}
      />
    ))
    .exhaustive();
};

type RenderCustomFieldsProps = {
  section: z.infer<typeof enums.customFieldSections>;
  dealPipelineId?: number | null;
  projectPipelineId?: number | null;
  documentTemplateId?: number | null;
  showFields?: string[];
  name?: string;
};
const CustomFieldsInputForm = ({
  showFields,
  section,
  dealPipelineId,
  projectPipelineId,
  documentTemplateId,
  name = "customFields",
}: RenderCustomFieldsProps) => {
  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section,
      dealPipelineId,
      projectPipelineId: projectPipelineId || null,
      documentTemplateId: documentTemplateId || null,
    }
  );

  if (!customFields) {
    return <FullScreenSpinner />;
  }

  return (
    <>
      {customFields
        .filter((field) =>
          showFields?.length ? showFields?.includes(field.name) : true
        )
        .map((field) => (
          <FormField
            name={`${name}.${field.name}`}
            key={field.id}
            label={field.label}
          >
            <CustomFieldInput key={field.id} field={field} />
          </FormField>
        ))}
    </>
  );
};

export default CustomFieldsInputForm;
