import Empty from "@/components/Empty";
import FileIcon from "@/components/file-icon";
import Page from "@/components/page";
import { getS3URLLegacy } from "@/helpers/s3Helpers";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import { Checkbox } from "@heffl/ui/components/primitives/checkbox";
import { Input } from "@heffl/ui/components/primitives/input";
import { Download, FileSearch, Search, Trash2, XCircle } from "lucide-react";
import mime from "mime-types";
import { useState } from "react";
import toast from "react-hot-toast";
import { useImmer } from "use-immer";

type FilesFile = RouterOutputs["files"]["list"]["files"][number];

const FileItem = ({
  file,
  onClick,
  checked,
}: {
  file: FilesFile;
  onClick: () => void;
  checked: boolean;
}) => {
  const extension = file.format ? mime.extension(file.format) || "" : "";
  return (
    <div
      className="flex flex-col gap-1 cursor-pointer"
      onClick={() => file.type === "FILE" && onClick()}
    >
      <div
        className={`w-full h-[200px] rounded-sm flex items-center justify-center ${
          checked ? "bg-gray-400" : "bg-gray-100"
        } hover:bg-gray-200`}
      >
        {file.format?.startsWith("image/") ? (
          <img
            src={getS3URLLegacy("files", file.link)}
            alt={file.name}
            className="object-contain w-full h-full"
          />
        ) : (
          <FileIcon ext={extension} />
        )}
      </div>
      <p className="text-sm">{file.name}</p>
    </div>
  );
};

const Trash = () => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useImmer<{
    files: number[];
    folders: number[];
    documents: number[];
  }>({
    files: [],
    folders: [],
    documents: [],
  });

  const { data: folderContents, isLoading } = trpc.files.list.useQuery({
    parentFolderId: null,
    search,
    type: "trash",
  });

  const bulkTrashMutation = trpc.files.bulkTrash.useMutation({
    onSuccess() {
      toast.success("Files restored successfully");
      setSelected((draft) => {
        draft.files = [];
        draft.folders = [];
        draft.documents = [];
      });
    },
  });

  return (
    <Page title="Trash" fullWidth>
      {selected.files.length +
        selected.folders.length +
        selected.documents.length >
        0 && (
        <div className="flex fixed bottom-6 z-50 justify-center w-full bg-white">
          <div className="flex gap-2 justify-center items-center p-2 px-8 rounded-sm border shadow-md w-fit">
            <div className="flex justify-center items-center w-6 h-6 text-white bg-green-600 rounded">
              {selected.files.length +
                selected.folders.length +
                selected.documents.length}
            </div>
            <p className="text-sm">selected</p>
            <Button
              size="sm"
              onClick={() =>
                bulkTrashMutation.mutate({
                  selected,
                  trashed: false,
                })
              }
              loading={bulkTrashMutation.isLoading}
            >
              <Trash2 className="h-4 rotate-180" /> Restore selected
            </Button>
            <Button size="sm">
              <Download className="h-4" /> Download all
            </Button>
          </div>
        </div>
      )}

      <div className="flex flex-col gap-2 w-full">
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search files"
          className="w-full"
          prefix={<Search className="h-4 text-gray-500" />}
          suffix={
            search && (
              <XCircle
                onClick={() => setSearch("")}
                className="h-4 text-gray-500 cursor-pointer"
              />
            )
          }
        />

        <div className="flex flex-row gap-1 items-center border-b border-gray-200">
          <p className="text-sm">Name</p>
        </div>

        <div className="grid w-full gap-3 py-2 sm:grid-cols-6 grid-cols-2 mb-[100px]">
          {folderContents?.files?.map((file) => (
            <div key={file.id} className="relative">
              <FileItem
                onClick={() => {}}
                file={file}
                checked={selected.files.includes(file.id)}
              />
              <div className="absolute top-2 left-2 p-1">
                <Checkbox
                  id="terms"
                  value={selected.files.includes(file.id)}
                  onChange={() => {
                    setSelected((draft) => {
                      if (draft.files.includes(file.id)) {
                        draft.files = draft.files.filter(
                          (id) => id !== file.id
                        );
                      } else {
                        draft.files.push(file.id);
                      }
                    });
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {!folderContents?.files.length && !isLoading && (
        <Empty
          title="Trash is empty"
          icon={FileSearch}
          description="No files in trash"
        />
      )}
    </Page>
  );
};

export default Trash;
