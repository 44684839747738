import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";
import { MentionList } from "./mention-list";

export default {
  render: () => {
    let reactRenderer;
    let popup;

    return {
      onStart: (props) => {
        reactRenderer = new ReactRenderer(MentionList, {
          props: {
            ...props,
            command: ({ id }) => {
              if (popup?.[0]) {
                popup[0].hide();
              }
              props.command({ id });
            },
          },
          editor: props.editor
        });

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => {
            // this is a hack written by me to make the suggestion list appear in modal
            const modalElement = document.querySelector("#modal");
            return modalElement || document.body;
          },
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
          zIndex: 9999,
        });
      },

      onUpdate(props) {
        if (!popup?.[0] || !reactRenderer) return;

        reactRenderer.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect
        });
      },

      onKeyDown(props) {
        if (!popup?.[0] || !reactRenderer) return true;

        if (props.event.key === "Escape") {
          popup[0].hide();
          return true;
        }

        return reactRenderer.ref?.onKeyDown(props);
      },

      onExit() {
        if (popup?.[0]) {
          popup[0].destroy();
          popup = null;
        }
        if (reactRenderer) {
          reactRenderer.destroy();
        }
      }
    };
  }
};
