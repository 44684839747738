/* eslint-disable @typescript-eslint/no-explicit-any */
import { trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { objectToParamsJSON } from "@heffl/ui/lib/utils";
import { useNavigate } from "react-router-dom";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { DashboardChartItem } from "../charts-item-helpers";

const COLORS = [
  "#F59E0B", // yellow
  "#60A5FA", // blue
  "#F97316", // orange
  "#34D399", // green
  "#A78BFA", // purple
  "#EC4899", // pink
  "#6366F1", // indigo
  "#10B981", // emerald
];

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    name: string;
    value: number;
    payload: {
      name: string;
      value: number;
      color: string;
    };
  }>;
}

const AssigneeDistributionChart = ({
  data,
  width,
}: {
  data: any[];
  width: 50 | 100;
}) => {
  const dimensions = {
    50: {
      height: 400,
      outerRadius: 150,
    },
    100: {
      height: 700,
      outerRadius: 250,
    },
  };

  const CustomTooltip = ({ active, payload }: TooltipProps) => {
    if (active && payload && payload.length) {
      const tooltipData = data?.find((a) => a.name === payload[0].payload.name);
      if (!tooltipData) return null;

      return (
        <div className="p-4 bg-white rounded-xl border border-gray-100 shadow-lg">
          <p className="mb-3 text-lg font-bold text-gray-800">
            {tooltipData.name}
          </p>
          <div className="space-y-2">
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
              <span className="font-medium">{`${tooltipData.stats.todo} To Do`}</span>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-blue-500 rounded-full"></div>
              <span className="font-medium">{`${tooltipData.stats.inProgress} In Progress`}</span>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-orange-500 rounded-full"></div>
              <span className="font-medium">{`${tooltipData.stats.onHold} On Hold`}</span>
            </div>
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-green-500 rounded-full"></div>
              <span className="font-medium">{`${tooltipData.stats.completed} Completed`}</span>
            </div>
          </div>
          <div className="pt-3 mt-3 border-t border-gray-100">
            <p className="font-medium text-gray-600">
              Total Tasks: {payload[0].value}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={dimensions[width].height}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={dimensions[width].outerRadius}
          label={({ name, percent }) =>
            `${name} ${(percent * 100).toFixed(0)}%`
          }
          labelLine={true}
        >
          {data?.map((entry, index) => (
            <Cell
              cursor="pointer"
              key={`cell-${index}`}
              fill={entry.color}
              onClick={entry.onClick}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

const AssigneeDistribution = ({
  filterValues,
  width,
}: {
  filterValues: Record<string, any>;
  width: number;
}) => {
  const navigate = useNavigate();

  const { data: taskData } =
    trpc.projects.reports.taskStatusByAssignee.useQuery({
      ...filterValues,
    });

  const onChartClick = (assignee: number) => {
    const params = objectToParamsJSON({
      assignees: [assignee],
      dates: filterValues.dates,
      statuses: ["OPEN", "IN_PROGRESS", "ON_HOLD", "COMPLETED"],
    });
    navigate(`/projects/tasks/table?${params}`);
  };

  if (!taskData) return <FullScreenSpinner />;

  const pieData = taskData.map((assignee, index) => ({
    name: assignee.name,
    value:
      assignee.stats.todo +
      assignee.stats.inProgress +
      assignee.stats.onHold +
      assignee.stats.completed,
    color: COLORS[index % COLORS.length],
    onClick: () => onChartClick(assignee.id),
    stats: assignee.stats,
  }));

  return <AssigneeDistributionChart data={pieData} width={width as 50 | 100} />;
};

export const assigneeDistributionChart: DashboardChartItem = {
  name: "assignee-distribution",
  title: "Task Distribution by Assignee",
  description: "Distribution of tasks across team members",
  entity: "PROJECT",
  filters: [
    {
      label: "Date",
      type: "date-range",
      key: "dates",
    },
  ],
  defaultWidth: 50,
  mainChartType: "pie",
  components: {
    stat: null,
    chart: AssigneeDistribution,
    table: null,
  },
};
