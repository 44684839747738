/* eslint-disable @typescript-eslint/no-explicit-any */
import { trpc } from "@/helpers/trpc";
import { COUNTRIES_UAE_ID } from "@/lib/dbIds";
import {
  GOOGLE_MAPS_API_KEY,
  isValidUrl,
  mapPlaceFormatter,
} from "@/pages/field-service/components/property-selector";
import Schemas from "@heffl/server/src/schemas";
import { Icons } from "@heffl/ui/components/icons";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import Select from "@heffl/ui/components/primitives/select";
import { zodResolver } from "@hookform/resolvers/zod";
import Fuse from "fuse.js";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const PropertyForm = ({
  form,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.propertyManagement.pmProperty>>;
}) => {
  const countryId = form.watch("countryId");
  const googleMapsLink = form.watch("googleMapsLink");

  const { data: countries } = trpc.countries.list.useQuery();

  const googleMapsToPlaceIdMutation =
    trpc.fieldService.properties.googleMapsToPlaceId.useMutation();

  const selectedCountry = countries?.find(
    (country) => country.id === countryId
  );

  const onPlaceSelect = async (placeId: string) => {
    const result = await geocodeByPlaceId(placeId);
    const formattedAddress = mapPlaceFormatter(result[0]);
    if (selectedCountry) {
      const fuse = new Fuse(selectedCountry.countryStates, { keys: ["name"] });
      const result = fuse.search(formattedAddress.state);
      const stateId = result.length > 0 ? result[0].item.id : undefined;
      if (stateId) {
        form.setValue("stateId", stateId);
      }
    }
    form.setValue("address", formattedAddress.address);
    form.setValue("city", formattedAddress.city);
    form.setValue("latitude", formattedAddress.latitude);
    form.setValue("longitude", formattedAddress.longitude);
    form.setValue("googleMapsPlaceId", formattedAddress.placeId);
  };

  return (
    <>
      <div className="flex gap-2">
        <div className="relative w-full">
          <Icons.google className="absolute top-1.5 left-1.5 z-50 w-6 h-6" />
          <GooglePlacesAutocomplete
            apiKey={GOOGLE_MAPS_API_KEY}
            selectProps={{
              styles: {
                input: (provided) => ({
                  ...provided,
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  paddingLeft: 35,
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "6px",
                  border: "1px solid #E2E8F0",
                }),
              },
              placeholder: "Search google maps...",
              onChange: (e) => e && onPlaceSelect(e.value?.place_id),
            }}
            apiOptions={{
              language: "en",
              region: "ae",
            }}
          />
        </div>
      </div>
      <div className="flex gap-2 mt-1">
        <FormField
          name="googleMapsLink"
          label="Google maps link"
          className="w-4/5"
        >
          <Input placeholder="Add google maps link " />
        </FormField>
        <Button
          onClick={async () => {
            try {
              if (!googleMapsLink) return;
              if (!isValidUrl(googleMapsLink)) {
                return toast.error("Invalid google maps link");
              }
              const mapResponse = await googleMapsToPlaceIdMutation.mutateAsync(
                googleMapsLink
              );
              if (mapResponse) onPlaceSelect(mapResponse.placeId);
              else toast.error("Failed, check your maps link");
            } catch (err) {
              console.error("Failed to read clipboard contents: ", err);
            }
          }}
          size="md"
          className="mt-[22px] w-1/5"
          variant="primaryOutline"
          loading={googleMapsToPlaceIdMutation.isLoading}
        >
          Fetch
        </Button>
      </div>
      <div className="flex gap-2">
        <FormField name="name" label="Property name">
          <Input placeholder="Property name" />
        </FormField>
        <FormField name="city" label="City">
          <Input placeholder="City" />
        </FormField>
      </div>
      <FormField name="address" label="Address">
        <Input placeholder="Address" />
      </FormField>
      <div className="grid grid-cols-2 gap-2">
        <FormField name="countryId" label="Country">
          <Select
            allowClear={false}
            options={
              countries?.map((country) => ({
                label: country.name,
                value: country.id,
                states: country.countryStates,
              })) || []
            }
            // @ts-ignore
            onSelect={() => form.setValue("stateId", null)}
          />
        </FormField>
        <FormField name="stateId" label="State">
          <Select
            allowClear={false}
            options={
              selectedCountry?.countryStates?.map((state) => ({
                label: state.name,
                value: state.id,
              })) || []
            }
          />
        </FormField>
      </div>
    </>
  );
};

export const AddPropertyModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (id?: number) => void;
}) => {
  const form = useForm<z.infer<typeof Schemas.propertyManagement.pmProperty>>({
    resolver: zodResolver(Schemas.propertyManagement.pmProperty),
    defaultValues: {
      name: "",
      city: "",
      address: "",
      countryId: COUNTRIES_UAE_ID,
    },
  });

  const onModalClose = (id?: number) => {
    onClose(id);
    form.reset();
  };

  const addPmPropertyMutation =
    trpc.propertyManagement.properties.add.useMutation({
      onSuccess(newProperty) {
        toast.success("Successfully added property.");
        onModalClose(newProperty.id);
      },
    });

  const onSubmit = (
    values: z.infer<typeof Schemas.propertyManagement.pmProperty>
  ) => {
    addPmPropertyMutation.mutate(values);
  };

  return (
    <ModalDrawer
      title="Add property"
      open={open}
      onClose={() => onModalClose()}
      footer={
        <Button
          type="submit"
          variant="primary"
          loading={addPmPropertyMutation.isLoading}
          className="!w-full"
          onClick={() => form.handleSubmit(onSubmit)()}
        >
          Add property
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <PropertyForm form={form} />
      </Form>
    </ModalDrawer>
  );
};
