import ReportsHome from "@/components/reports-home";
import DealsByPipeline from "./deals-by-pipeline";
import DealsByStage from "./deals-by-stage";
import DealsLostReasonsReport from "./deals-lost-reasons";
import DealsValueByStage from "./deals-value-by-stage";

const DealsReports = () => {
  return (
    <ReportsHome
      title="Deals Reports"
      reports={[
        {
          links: [
            // {
            //   id: "deals-overview",
            //   label: "Deals Overview",
            //   description: "Overview of deals",
            //   children: <DealsOverview />,
            // },
            {
              id: "deals-value-by-stage",
              label: "Deals Value by Stage",
              description: "Deals by stage with total quotation value",
              children: <DealsValueByStage />,
            },
            {
              id: "deals-by-pipeline",
              label: "Deals by Pipeline",
              children: <DealsByPipeline />,
            },
            {
              id: "deals-by-stage",
              label: "Deals by Stage",
              description: "Deals count by stage",
              children: <DealsByStage />,
            },
            {
              id: "deals-lost-reasons",
              label: "Lost Reasons",
              description: "Analysis of deal lost reasons",
              children: <DealsLostReasonsReport />,
            },
          ],
        },
      ]}
    />
  );
};

export default DealsReports;
