import validateCustomFields from "@/helpers/customFields/validateCustomFields";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form } from "@heffl/ui/components/primitives/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { ProjectProfileForm } from "./AddProjectProfileDrawer";

export const EditProjectProfileDrawer = ({
  open,
  onClose,
  id,
}: {
  open: boolean;
  id: number;
  onClose: () => void;
}) => {
  const form = useForm<z.infer<typeof Schemas.projectProfile.projectProfile>>({
    resolver: zodResolver(Schemas.projectProfile.projectProfile),
  });

  const { data: projectProfileDetails } =
    trpc.projects.profiles.details.useQuery(id);

  const { data: pipelineCustomFields } =
    trpc.customizations.customFields.list.useQuery(
      {
        section: "PROJECT",
        projectPipelineId: projectProfileDetails?.projectPipelineId,
      },
      {
        enabled: !!projectProfileDetails?.projectPipelineId,
      }
    );

  const { data: projectCustomFields } =
    trpc.customizations.customFields.list.useQuery({
      section: "PROJECT",
      projectPipelineId: null,
    });

  const projectProfileUpdateMutation =
    trpc.projects.profiles.update.useMutation({
      onSuccess() {
        form.reset({});
        toast.success("Updated project profile");
        onClose();
      },
    });

  useEffect(() => {
    if (projectProfileDetails)
      form.reset({
        ...projectProfileDetails,
        projectProfileAssignees:
          projectProfileDetails.projectProfileAssignees?.map(
            (assignee) =>
              assignee.users_projectProfileAssignees_userIdTousers.id
          ),
        projectProfileItems: projectProfileDetails.projectProfileItems.map(
          (item) => ({
            ...item,
            quantity: item.quantity,
            price: item.price,
            tax: item.tax,
            buyPrice: item.buyPrice,
            description: item.description || undefined,
            productId: item.productId || undefined,
            name: item.name,
            viewType: item.viewType,
          })
        ),
        projectProfileQuotations:
          projectProfileDetails.projectProfileQuotations?.map(
            (quotation) => quotation.quotations.id
          ),
      });
  }, [projectProfileDetails]);

  const onSubmit = (
    values: z.infer<typeof Schemas.projectProfile.projectProfile>
  ) => {
    const { isValid: isValidPipeline } = validateCustomFields({
      customFields: pipelineCustomFields,
      customFieldsValues: values.projectCustomFields,
      name: "projectCustomFields",
      form,
    });

    const { isValid: isValidProject } = validateCustomFields({
      customFields: projectCustomFields,
      customFieldsValues: values.projectCustomFields,
      name: "projectCustomFields",
      form,
    });
    if (!isValidProject || !isValidPipeline) return;

    projectProfileUpdateMutation.mutate({
      id: id,
      projectProfile: values,
    });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose();
        form.reset({});
      }}
      title="Update Project Profile"
      type="sheet"
      footer={
        <Button
          loading={projectProfileUpdateMutation.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
          variant="primary"
          className="w-full"
        >
          Update Project Profile
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ProjectProfileForm form={form} />
      </Form>
    </ModalDrawer>
  );
};
