import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@heffl/ui/components/primitives/avatar";
import { getS3URLLegacy } from "@/helpers/s3Helpers";
import { ConditionalWrapper } from "@/pages/crm/deals/details";
import { cva, type VariantProps } from "class-variance-authority";
import {
  avatarName,
  cn,
  formatName,
  generateUniqueColor,
} from "@heffl/ui/lib/utils";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";

type AvatarUser = {
  firstName: string;
  lastName?: string | null;
  id: number;
  profileImage?: string | null;
};
type AvatarList = Array<AvatarUser>;

const avatarSizes = cva("", {
  variants: {
    size: {
      xs: "w-7 h-7",
      xxs: "w-5 h-5",
      sm: "w-9 h-9",
      md: "w-14 h-14",
      lg: "w-16 h-16",
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

export const UserAvatar = ({
  user,
  size,
  className,
  hideToast,
}: {
  user: AvatarUser;
  hideToast?: boolean;
  className?: string;
} & VariantProps<typeof avatarSizes>) => {
  const color = generateUniqueColor(String(user.id), 500);
  return (
    <ConditionalWrapper
      condition={!hideToast}
      wrapper={(children) => (
        <Tooltip key={user.id} content={formatName(user)}>
          {children}
        </Tooltip>
      )}
    >
      <Avatar className={cn(avatarSizes({ size }), "rounded-full", className)}>
        {!!user.profileImage && (
          <AvatarImage
            src={getS3URLLegacy("users/profileImages", user.profileImage)}
            alt="avatar"
          />
        )}
        <AvatarFallback
          className={cn(
            "text-sm text-white border border-gray-200",
            size === "xxs" && "!text-[8px]"
          )}
          style={{
            backgroundColor: color,
          }}
        >
          {avatarName(user)}
        </AvatarFallback>
      </Avatar>
    </ConditionalWrapper>
  );
};

export const AvatarsGroup = ({
  users,
  size,
}: { users: AvatarList } & VariantProps<typeof avatarSizes>) => {
  return (
    <div className="flex -space-x-2 cursor-pointer rtl:space-x-reverse">
      {users.length
        ? users.map((user) => (
            <UserAvatar key={user.id} user={user} size={size} />
          ))
        : null}
    </div>
  );
};
