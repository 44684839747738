import FilterBar from "@/components/filter-bar";
import heffl from "@/helpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { BarChart } from "@heffl/ui/components/charts/barchart";
import SimpleTable from "@heffl/ui/components/simple-table";
import { useNavigate } from "react-router-dom";
import { match } from "ts-pattern";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import EmptyScreen from "@heffl/ui/components/primitives/empty-screen";

const LostReasonsReport = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<
    RouterInputs["crm"]["reports"]["leads"]["lostReasons"]
  >({
    owners: [],
    sources: [],
    dates: [heffl.date.monthStart(), heffl.date.monthEnd()],
  });

  const { data: users } = trpc.users.list.useQuery({
    type: ["STAFF", "ADMIN", "OWNER", "SUPER_ADMIN"],
  });
  const { data: leadSources } = trpc.sources.list.useQuery();
  const { data: leadStages } = trpc.leads.stages.list.useQuery();

  const { data: lostReasons, isLoading } =
    trpc.crm.reports.leads.lostReasons.useQuery(filters);

  return (
    <div>
      <FilterBar
        defaultFilters={["owners", "sources"]}
        suffix={
          <div className="p-1 px-2 rounded-lg border">
            Count: {lostReasons?.meta.count}
          </div>
        }
        onChange={() => {}}
        className="pb-3"
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Created at",
            value: filters.dates,
            onChange: (value) =>
              setFilters({
                dates: value,
              }),
          },
          {
            key: "owners",
            type: "checkbox",
            label: "Owners",
            value: filters.owners,
            onChange: (value) =>
              setFilters({
                owners: value as number[],
              }),
            options:
              users?.map((user) => ({
                label: heffl.format.name(user),
                value: user.id,
              })) || [],
          },
          {
            key: "sources",
            type: "checkbox",
            label: "Sources",
            value: filters.sources,
            onChange: (value) =>
              setFilters({
                sources: value as number[],
              }),
            options:
              leadSources?.map((source) => ({
                label: source.name,
                value: source.id,
              })) || [],
          },
        ]}
      />

      {match({
        isLoading,
        lostReasons,
      })
        .with({ isLoading: false, lostReasons: { data: [] } }, () => (
          <EmptyScreen title="No lost reasons found" />
        ))
        .with({ isLoading: false }, () => (
          <div>
            <BarChart
              data={lostReasons?.data || []}
              index="reason"
              categories={["count"]}
              showLegend={false}
              yAxisWidth={48}
              className="h-64"
              yAxisLabel="Number of Leads"
              xAxisLabel="Lost Reason"
              onValueChange={(value) => {
                const allUnqualifiedStages = leadStages?.filter(
                  (stage) => stage.type === "UNQUALIFIED"
                );

                if (value) {
                  const params = heffl.url.objToParams({
                    ...filters,
                    archived: [],
                    stages:
                      allUnqualifiedStages?.map((stage) => stage.id) || [],
                    lostReasons: !value.id ? [0] : [value.id],
                  });
                  navigate(`/crm/leads/table?${params}`);
                }
              }}
            />

            <SimpleTable
              borderless
              size="small"
              onRowClick={(row) => {
                const allUnqualifiedStages = leadStages?.filter(
                  (stage) => stage.type === "UNQUALIFIED"
                );

                if (row.id) {
                  const params = heffl.url.objToParams({
                    ...filters,
                    archived: [],
                    stages:
                      allUnqualifiedStages?.map((stage) => stage.id) || [],
                    lostReasons: [row.id],
                  });
                  navigate(`/crm/leads/table?${params}`);
                }
              }}
              className="mt-4"
              columns={[
                {
                  label: "Lost Reason",
                  key: "reason",
                  width: 200,
                },
                {
                  label: "Count",
                  key: "count",
                  width: 100,
                },
              ]}
              rows={lostReasons?.data || []}
              idKey="reason"
            />
          </div>
        ))
        .otherwise(() => (
          <FullScreenSpinner />
        ))}
    </div>
  );
};

export default LostReasonsReport;
