import FilterBar from "@/components/filter-bar";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { objectToParamsJSON } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const COLORS = {
  OPEN: "#F59E0B", // yellow
  IN_PROGRESS: "#60A5FA", // blue
  ON_HOLD: "#F97316", // orange
  COMPLETED: "#34D399", // green
};

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    name: string;
    value: number;
    payload: {
      name: string;
      value: number;
      color: string;
    };
  }>;
}

const CustomTooltip = ({ active, payload }: TooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="p-3 bg-white rounded-lg border border-gray-100 shadow-lg">
        <p className="font-medium">{`${payload[0].payload.name}: ${payload[0].payload.value}`}</p>
      </div>
    );
  }
  return null;
};

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  width: 50 | 100;
};

const dimensions = {
  50: {
    height: 400,
    outerRadius: 150,
  },
  100: {
    height: 700,
    outerRadius: 250,
  },
};

export const TaskStatusDistributionChart = ({ data, width }: Props) => {
  return (
    <ResponsiveContainer width="100%" height={dimensions[width].height}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={dimensions[width].outerRadius}
          label={({ name, percent }) =>
            `${name} ${(percent * 100).toFixed(0)}%`
          }
          labelLine={true}
        >
          {data?.map((entry, index) => (
            <Cell
              cursor="pointer"
              key={`cell-${index}`}
              fill={entry.color}
              onClick={entry.onClick}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

const TaskStatusPie = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<{
    dates: [Date, Date];
  }>({
    dates: [dayjs().subtract(30, "days").toDate(), dayjs().toDate()],
  });

  const { data: taskData } = trpc.projects.reports.taskStats.useQuery({
    dates: filters.dates,
  });

  const onChartClick = (status: string) => {
    const params = objectToParamsJSON({
      statuses: [status],
      dates: filters.dates,
    });
    navigate(`/projects/tasks/table?${params}`);
  };

  const pieData = [
    {
      name: "Open",
      value: taskData?.byStatus.OPEN || 0,
      color: COLORS.OPEN,
      onClick: () => onChartClick("OPEN"),
    },
    {
      name: "In Progress",
      value: taskData?.byStatus.IN_PROGRESS || 0,
      color: COLORS.IN_PROGRESS,
      onClick: () => onChartClick("IN_PROGRESS"),
    },
    {
      name: "On Hold",
      value: taskData?.byStatus.ON_HOLD || 0,
      color: COLORS.ON_HOLD,
      onClick: () => onChartClick("ON_HOLD"),
    },
    {
      name: "Completed",
      value: taskData?.byStatus.COMPLETED || 0,
      color: COLORS.COMPLETED,
      onClick: () => onChartClick("COMPLETED"),
    },
  ];

  return (
    <div className="w-full h-[500px]">
      <FilterBar
        filters={[
          {
            label: "Date",
            type: "date-range",
            key: "dates",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
          },
        ]}
      />
      <div className="mt-4">
        <TaskStatusDistributionChart data={pieData} width={100} />
      </div>
    </div>
  );
};

export default TaskStatusPie;
