import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { teamSettingsSchema } from "@heffl/server/src/schemas/teamSettings";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save, Plus, Edit2, Trash2, GripVertical } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { Card } from "@heffl/ui/components/primitives/card";
import { Input } from "@heffl/ui/components/primitives/input";
import Select from "@heffl/ui/components/primitives/select";
import {
  Sortable,
  SortableItem,
  SortableDragHandle,
} from "@heffl/ui/components/sortable";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import heffl from "@/helpers/heffl";
import Schemas from "@heffl/server/src/schemas";

type AddLeadStageForm = z.infer<typeof Schemas.crm.leadStages>;

const LeadStageForm = () => {
  return (
    <>
      {" "}
      <FormField name="label" label="Label">
        <Input placeholder="Label for stage" />
      </FormField>
      <FormField name="type" label="Type">
        <Select
          placeholder="Select stage type"
          options={[
            { label: "New", value: "NEW" },
            { label: "Contacted", value: "CONTACTED" },
            { label: "Working", value: "WORKING" },
            { label: "Unqualified", value: "UNQUALIFIED" },
            { label: "Converted", value: "CONVERTED" },
          ]}
        />
      </FormField>
    </>
  );
};

const LeadSettings = () => {
  const form = useForm<z.infer<typeof teamSettingsSchema>>({
    resolver: zodResolver(teamSettingsSchema),
  });

  const addStageForm = useForm<AddLeadStageForm>({
    resolver: zodResolver(Schemas.crm.leadStages),
  });

  const editStageForm = useForm<AddLeadStageForm>({
    resolver: zodResolver(Schemas.crm.leadStages),
  });

  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();
  const { data: leadStages } = trpc.leads.stages.list.useQuery();

  const [showAddStage, setShowAddStage] = useState(false);
  const [selectedStage, setSelectedStage] = useState<{
    id: number;
    label: string;
    type: string;
  } | null>(null);

  const [showDeleteStage, setShowDeleteStage] = useState<{
    id: number;
    replaceWithStageId: number | null;
  } | null>(null);

  const updateTeamSettingsMutation = trpc.teams.updateTeamSettings.useMutation({
    onSuccess: () => {
      toast.success("Settings updated");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const addLeadStageMutation = trpc.leads.stages.add.useMutation({
    onSuccess: () => {
      toast.success("Lead stage added");
      addStageForm.reset();
      setShowAddStage(false);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const updateLeadStageMutation = trpc.leads.stages.update.useMutation({
    onSuccess: () => {
      toast.success("Lead stage updated");
      setSelectedStage(null);
      editStageForm.reset();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const deleteLeadStageMutation = trpc.leads.stages.delete.useMutation({
    onSuccess: () => {
      setShowDeleteStage(null);
      toast.success("Lead stage deleted");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const bulkUpdateLeadStagesMutation = trpc.leads.stages.bulkUpdate.useMutation(
    {
      onSuccess: () => {
        heffl.toast.success("Lead stages updated");
      },
      onError: (error) => {
        heffl.toast.error(error.message);
      },
    }
  );

  const onSubmit = (data: z.infer<typeof teamSettingsSchema>) => {
    updateTeamSettingsMutation.mutate({
      leads: data.leads,
    });
  };

  const handleStageReorder = (items: typeof leadStages) => {
    if (!items) return;
    bulkUpdateLeadStagesMutation.mutate(
      items.map((item, index) => ({
        id: item.id,
        position: index + 1,
      }))
    );
  };

  useEffect(() => {
    if (teamDetails?.teamSettings) {
      form.reset({
        ...teamDetails.teamSettings,
      });
    }
  }, [teamDetails]);

  useEffect(() => {
    if (selectedStage) {
      editStageForm.reset({
        label: selectedStage.label,
        type: selectedStage.type as AddLeadStageForm["type"],
      });
    }
  }, [selectedStage]);

  return (
    <Page title="Leads">
      <div className="space-y-6">
        <Card className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium">Lead Stages</h3>
            <Button
              icon={Plus}
              variant="primary"
              onClick={() => setShowAddStage(true)}
            >
              Add Stage
            </Button>
          </div>

          {leadStages && (
            <Sortable
              value={leadStages}
              onValueChange={handleStageReorder}
              orientation="vertical"
            >
              <div className="space-y-2">
                {leadStages.map((stage) => (
                  <SortableItem
                    key={stage.id}
                    value={stage.id}
                    className="flex gap-2 justify-between items-center p-2 w-80 bg-white rounded-md border"
                  >
                    <div className="flex gap-2 items-center">
                      <SortableDragHandle
                        variant="ghost"
                        size="sm"
                        iconClassName="text-gray-400"
                        className="px-0"
                      >
                        <GripVertical className="w-5 h-5 text-gray-400 cursor-grab" />
                      </SortableDragHandle>
                      <div className="flex flex-col">
                        <div className="text-sm">{stage.label}</div>
                        <div className="text-[10px] text-gray-500">
                          {stage.type}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <Button
                        variant="ghost"
                        size="sm"
                        icon={Edit2}
                        onClick={() => setSelectedStage(stage)}
                      />
                      <Button
                        variant="ghost"
                        size="sm"
                        icon={Trash2}
                        onClick={() => {
                          setShowDeleteStage({
                            id: stage.id,
                            replaceWithStageId: null,
                          });
                        }}
                      />
                    </div>
                  </SortableItem>
                ))}
              </div>
            </Sortable>
          )}
        </Card>

        {showDeleteStage && (
          <ModalDrawer
            title="Delete Lead Stage"
            open={!!showDeleteStage}
            onClose={() => setShowDeleteStage(null)}
            footer={
              <Button
                variant="destructive"
                className="w-full"
                loading={deleteLeadStageMutation.isLoading}
                onClick={() =>
                  showDeleteStage.replaceWithStageId &&
                  deleteLeadStageMutation.mutate({
                    id: showDeleteStage.id,
                    replaceWithStageId: showDeleteStage.replaceWithStageId,
                  })
                }
                disabled={!showDeleteStage.replaceWithStageId}
              >
                Delete
              </Button>
            }
          >
            <p>
              Are you sure you want to delete this lead stage? All leads in this
              stage will be moved to the stage you select.
            </p>
            <Select
              className="mt-3"
              placeholder="Select stage to move leads to"
              value={showDeleteStage.replaceWithStageId}
              onChange={(value) => {
                setShowDeleteStage({
                  ...showDeleteStage,
                  replaceWithStageId: value,
                });
              }}
              options={
                leadStages
                  ?.filter((stage) => stage.id !== showDeleteStage.id)
                  .map((stage) => ({
                    label: stage.label,
                    value: stage.id,
                  })) ?? []
              }
            />
          </ModalDrawer>
        )}

        <ModalDrawer
          title="Add Lead Stage"
          open={showAddStage}
          onClose={() => setShowAddStage(false)}
          footer={
            <Button
              className="w-full"
              loading={addLeadStageMutation.isLoading}
              onClick={addStageForm.handleSubmit((data) => {
                addLeadStageMutation.mutate(data);
              })}
              variant="primary"
            >
              Add Stage
            </Button>
          }
        >
          <Form
            {...addStageForm}
            onSubmit={(data: AddLeadStageForm) => {
              addLeadStageMutation.mutate(data);
            }}
          >
            <LeadStageForm />
          </Form>
        </ModalDrawer>

        <ModalDrawer
          title="Edit Lead Stage"
          open={!!selectedStage}
          onClose={() => setSelectedStage(null)}
          footer={
            <Button
              className="w-full"
              loading={updateLeadStageMutation.isLoading}
              onClick={editStageForm.handleSubmit((data) => {
                if (selectedStage) {
                  updateLeadStageMutation.mutate({
                    id: selectedStage.id,
                    data,
                  });
                }
              })}
              variant="primary"
            >
              Update Stage
            </Button>
          }
        >
          <Form
            {...editStageForm}
            onSubmit={(data: AddLeadStageForm) => {
              if (selectedStage) {
                updateLeadStageMutation.mutate({
                  id: selectedStage.id,
                  data,
                });
              }
            }}
          >
            <LeadStageForm />
          </Form>
        </ModalDrawer>

        <Form {...form} onSubmit={onSubmit}>
          <Card className="space-y-4">
            <h3 className="text-lg font-medium">Required Fields</h3>
            <FormField
              name="leads.requiredFields.mobile"
              label="Mobile required"
            >
              <Switch />
            </FormField>
            <FormField
              name="leads.requiredFields.crmSourceId"
              label="Source required"
            >
              <Switch />
            </FormField>
            <div className="flex justify-end">
              <Button
                loading={updateTeamSettingsMutation.isLoading}
                type="submit"
                icon={Save}
                variant="primary"
              >
                Update settings
              </Button>
            </div>
          </Card>
        </Form>
      </div>
    </Page>
  );
};

export default LeadSettings;
