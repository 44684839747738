import { ClientInput, ContactInput } from "@/components/FormComponents";
import RichTextEditor from "@heffl/ui/components/primitives/RichTextEditor";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { trpc } from "@/helpers/trpc";
import { INVOICE_STATUS } from "@/lib/constants";
import { cn, formatCurrency } from "@heffl/ui/lib/utils";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import { dynamicTags } from "@heffl/server/src/helpers/templates/dynamicTags";
import Schemas from "@heffl/server/src/schemas";
import { Pencil, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import LineItemSelectorNew from "@/components/line-item-selector-new";

const InvoiceForm = ({
  form,
  edit = false,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.sales.proformaInvoice>, unknown>;
  edit?: boolean;
}) => {
  const templateId = form.watch("templateId");
  const clientId = form.watch("clientId");
  const lineItems = form.watch("proformaInvoiceProducts");
  const discount = form.watch("discount");

  const [showEditContent, setShowEditContent] = useState(false);

  const { data: templates } = trpc.templates.list.useQuery({
    type: "PROFORMA_INVOICE",
  });
  const { data: projects } = trpc.projects.list.useQuery(
    {
      clients: clientId ? [clientId] : [],
    },
    {
      enabled: !!clientId,
    }
  );

  const { data: templateDetails } = trpc.templates.details.useQuery(
    Number(templateId),
    {
      enabled: !!templateId && !edit,
    }
  );

  useEffect(() => {
    if (templates?.length && !edit && !templateId) {
      form.setValue("templateId", templates[0].id);
    }
  }, [templates]);

  useEffect(() => {
    if (templateDetails && !edit) {
      form.setValue("contentHtml", templateDetails.contentHtml || "");
    }
  }, [templateDetails, form, edit]);

  if (!templates) return <FullScreenSpinner />;

  const lineItemsValues = calculateLineItems({ lineItems, discount });

  const includeItemsVat = () => {
    // decrease vat of each items
    const updatedLineItems = lineItems.map((item) => {
      const vatDeductedAmount: number = (item.price * 100) / (100 + item.tax);
      return {
        ...item,
        price: Number(vatDeductedAmount.toFixed(2)),
      };
    });
    form.setValue("proformaInvoiceProducts", updatedLineItems, {
      shouldDirty: true,
    });
  };

  return (
    <div className="space-y-4">
      {!edit && (
        <FormField name="templateId" label="Template" className="w-64">
          <Select
            placeholder="Select invoice template"
            options={templates.map((template) => ({
              label: template.name,
              value: template.id,
            }))}
          />
        </FormField>
      )}
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-4">
        <FormField name="date" label="Date">
          <DatePicker />
        </FormField>
        <FormField name="dueDate" label="Due date">
          <DatePicker />
        </FormField>
        {edit && (
          <FormField name="status" label="Status">
            <Select
              allowClear={false}
              options={INVOICE_STATUS.map((status) => ({
                label: status.label,
                value: status.value,
                color: status.color,
              }))}
              render={(option) => (
                <Badge className={cn("text-xs text-white", option.color)}>
                  {option.label}
                </Badge>
              )}
            />
          </FormField>
        )}
      </div>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-4">
        <ClientInput
          className="w-full"
          name="clientId"
          label="Client"
          onAddModalClose={(id) =>
            id &&
            form.setValue("clientId", id, {
              shouldDirty: true,
            })
          }
        />
        <ContactInput
          name="contactId"
          label="Contact"
          clientId={clientId}
          fetchEnabled={!!clientId}
          onAddModalClose={(id) => id && form.setValue("contactId", id)}
          disabled={!clientId}
          setDefault={!edit && !!clientId}
          allowClear
        />
        {!!(projects && projects.projects.length) && (
          <FormField name="projectInvoices.projectId" label="Project">
            <Select
              placeholder="Select project"
              options={projects?.projects.map((project) => ({
                label: project.title,
                value: project.id,
              }))}
            />
          </FormField>
        )}
      </div>

      <FormField name="proformaInvoiceProducts" label="">
        <LineItemSelectorNew
          required={true}
          isEdit={edit}
          type="PROFORMA_INVOICE"
        />
      </FormField>

      <div className="flex justify-end w-full">
        <table className="gap-8 text-base border-separate border-spacing-y-2">
          <tr>
            <td className="w-52">Subtotal</td>
            <td className="text-right">
              {formatCurrency(lineItemsValues.subTotal, "AED")}
            </td>
          </tr>
          <tr>
            <td className="w-52">Discount</td>
            <td className="text-right">
              <FormField name="discount">
                <Input suffix="AED" className="w-[70px]" />
              </FormField>
            </td>
          </tr>
          <tr>
            <td className="flex gap-2 items-center w-52">
              VAT
              <Button
                onClick={includeItemsVat}
                size="sm"
                variant="primaryOutline"
              >
                <Plus className="h-4" />
                Include
              </Button>
            </td>
            <td className="text-right">
              {formatCurrency(lineItemsValues.totalTax, "AED")}
            </td>
          </tr>
          <tr>
            <td className="w-52">Total</td>
            <td className="font-semibold text-right">
              {formatCurrency(lineItemsValues.total, "AED")}
            </td>
          </tr>
        </table>
      </div>
      <FormField name="notes" label="Notes" className="w-1/2">
        <Textarea />
      </FormField>
      {!showEditContent && (
        <Button
          variant="primaryOutline"
          onClick={() => setShowEditContent(true)}
        >
          <Pencil className="h-4" /> Edit content
        </Button>
      )}
      {showEditContent && (
        <FormField name="contentHtml" label="Content">
          <RichTextEditor
            height="500px"
            dynamicTags={[
              dynamicTags.Client,
              dynamicTags.Contact,
              dynamicTags.Quote,
            ]}
          />
        </FormField>
      )}
    </div>
  );
};

export default InvoiceForm;
