/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TagInput } from "emblor";
import { FC, useState } from "react";

interface TagOld {
  id: string;
  text: string;
}

interface TagsSelectInputProps {
  value?: (string | number)[];
  onChange?: (tags: string[] | number[]) => void;
  placeholder?: string;
  className?: string;
  type?: "number" | "text";
}

const TagsSelectInput: FC<TagsSelectInputProps> = ({
  value,
  onChange,
  placeholder = "Add a value",
  className,
  type = "text",
}) => {
  const [activeTagIndex, setActiveTagIndex] = useState<number | null>(null);

  const handleTagsChange = (newTags: TagOld[]) => {
    if (onChange) {
      const values = newTags.map((tag) =>
        type === "number" ? Number(tag.text) : tag.text
      );
      // @ts-ignore
      onChange(values);
    }
  };

  return (
    <TagInput
      inputProps={{
        type: type,
      }}
      className={className}
      placeholder={placeholder}
      styleClasses={{
        tag: {
          body: "bg-white",
        },
      }}
      tags={
        value?.map((val) => ({
          id: val.toString(),
          text: val.toString(),
        })) ?? []
      }
      // @ts-ignore
      setTags={handleTagsChange}
      activeTagIndex={activeTagIndex}
      setActiveTagIndex={setActiveTagIndex}
    />
  );
};

export default TagsSelectInput;
