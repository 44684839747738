import DataGrid from "@/components/dataGrid/DataGrid";
import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { match } from "ts-pattern";
import ExpenseDetailsModal from "../../purchases/expenses/details-modal";
import PaymentMadeDetailsModal from "../../purchases/payments-made/details-modal";
import PaymentReceivedDetailsModal from "../../sales/paymetsReceived/details-modal";
import { Button } from "@heffl/ui/components/primitives/button";
import { ArrowDownLeft, ArrowUpRight, PlusIcon } from "lucide-react";
import DropMenu from "@heffl/ui/components/DropMenu";
import { z } from "zod";
import enums from "@heffl/server/src/schemas/enums";
import AddCashflowModal from "./components/add-cashflow-modal";
import { BankingTransactionType } from "@heffl/server/src/routes/books/cashflow-accounts/transactions.router";
import CashflowTransactionDetailsModal from "./components/cashflow-transaction-details-modal";
import FilterBar from "@/components/filter-bar";
import { useParamsState } from "@/lib/hooks/useParamsState";

const CashflowTransactions = () => {
  const { id } = useParams();

  const [filters, setFilters] = useParamsState<
    Partial<RouterInputs["books"]["cashflowAccounts"]["transactions"]["list"]>
  >({
    dates: undefined,
  });

  const [showDetails, setShowDetails] = useState<
    | {
        type: BankingTransactionType;
        id: number;
      }
    | undefined
  >(undefined);

  const [showAddCashflowModal, setShowAddCashflowModal] = useState<
    z.infer<typeof enums.accounting.cashflowTransactionTypes> | undefined
  >(undefined);

  const { data: transactions } =
    trpc.books.cashflowAccounts.transactions.list.useQuery({
      id: Number(id),
      ...filters,
    });

  if (!transactions) return <FullScreenSpinner />;

  return (
    <Page
      title={transactions.accountDetails.name}
      className="!p-0"
      showBack
      fullWidth
      breadcrumbs={[
        {
          label: "Banking",
          path: "/books/banking",
        },
        {
          label: transactions.accountDetails.name,
          path: `/books/cashflow-accounts/transactions/${id}`,
        },
        {
          label: "Transactions",
          path: `/books/cashflow-accounts/transactions/${id}`,
        },
      ]}
      suffix={
        <DropMenu
          items={[
            {
              type: "heading",
              label: "Money In",
            },
            {
              label: "Transfer from Account",
              onClick: () => setShowAddCashflowModal("TRANSFER_FROM_ACCOUNT"),
              icon: ArrowDownLeft,
            },
            {
              type: "separator",
            },
            {
              type: "heading",
              label: "Money Out",
            },
            {
              label: "Transfer to Account",
              onClick: () => setShowAddCashflowModal("TRANSFER_TO_ACCOUNT"),
              icon: ArrowUpRight,
            },
          ]}
        >
          <Button icon={PlusIcon} size="sm" variant="primary">
            Transaction
          </Button>
        </DropMenu>
      }
    >
      {showAddCashflowModal && (
        <AddCashflowModal
          open={!!showAddCashflowModal}
          onClose={() => setShowAddCashflowModal(undefined)}
          defaultValues={{
            type: showAddCashflowModal,
            accountId: Number(id),
          }}
        />
      )}
      {match(showDetails)
        .with({ type: "EXPENSE" }, ({ id }) => {
          return (
            <ExpenseDetailsModal
              id={id}
              open={true}
              onClose={() => setShowDetails(undefined)}
            />
          );
        })
        .with({ type: "PAYMENT_MADE" }, ({ id }) => {
          return (
            <PaymentMadeDetailsModal
              id={id}
              open={true}
              onClose={() => setShowDetails(undefined)}
            />
          );
        })
        .with({ type: "PAYMENT_RECEIVED" }, ({ id }) => {
          return (
            <PaymentReceivedDetailsModal
              id={id}
              open={true}
              onClose={() => setShowDetails(undefined)}
            />
          );
        })
        .with({ type: "CASHFLOW_TRANSACTION" }, ({ id }) => {
          return (
            <CashflowTransactionDetailsModal
              id={id}
              open={true}
              onClose={() => setShowDetails(undefined)}
            />
          );
        })
        .otherwise(() => null)}

      <div className="px-3">
        <FilterBar
          defaultFilters={["date"]}
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) =>
                setFilters({
                  dates: value,
                }),
            },
          ]}
          className="py-3"
        />
      </div>

      <DataGrid
        name="cashflowTransactionsListMain"
        className="h-[calc(100vh-133px)]"
        label="Cashflow Transactions"
        show={{
          export: true,
        }}
        rowKey="transactionNo"
        rows={transactions?.transactions || []}
        onCellClick={(row) => {
          setShowDetails({
            type: row.row.type,
            id: row.row.id,
          });
        }}
        columns={[
          {
            key: "date",
            name: "Date",
            width: 120,
            renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
          },
          {
            key: "type",
            name: "Type",
            width: 120,
            renderCell: ({ row }) => row.typeLabel,
          },
          {
            key: "transactionNo",
            name: "Transaction #",
            width: 140,
            renderCell: ({ row }) => row.number,
          },
          {
            key: "refNo",
            name: "Ref.#",
            width: 120,
            renderCell: ({ row }) => row.refNo,
          },
          {
            key: "deposit",
            name: "Deposit",
            width: 140,
            renderCell: ({ row }) =>
              row.action === "DEPOSIT"
                ? heffl.format.currency(row.deposit, "AED", true)
                : "",
          },
          {
            key: "withdrawal",
            name: "Withdrawal",
            width: 140,
            renderCell: ({ row }) =>
              row.action === "WITHDRAWAL"
                ? heffl.format.currency(row.withdrawal, "AED", true)
                : "",
          },
          {
            key: "runningBalance",
            name: "Running Balance",
            width: 160,
            renderCell: ({ row }) =>
              heffl.format.currency(row.runningBalance, "AED", true),
          },
        ]}
      />
    </Page>
  );
};

export default CashflowTransactions;
