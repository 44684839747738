import Page from "@/components/page";
import useTeam from "@/lib/hooks/useTeam";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import SimpleTable from "@heffl/ui/components/simple-table";
import { BarChart2, FileText, User } from "lucide-react";
import { useNavigate } from "react-router-dom";

const reports = [
  {
    title: "Leads Reports",
    description: "Overview of leads status and key metrics.",
    slug: "leads-reports",
  },
  {
    title: "Deals Reports",
    description: "Overview of deals pipeline and conversion metrics.",
    slug: "deals-reports",
  },
  // {
  //   title: "Activities Reports",
  //   description: "Detailed report on activities and engagement.",
  //   slug: "activities-reports",
  // },
  // {
  //   title: "Performance Reports",
  //   description: "Overview of team performance and KPIs.",
  //   slug: "performance-reports",
  // },
];

const CRMReports: React.FC = () => {
  const navigate = useNavigate();
  const team = useTeam();

  if (!team) return <FullScreenSpinner />;
  if (!team.user.permissions.VIEW_CRM_REPORTS) return <NoPermissionScreen />;

  return (
    <Page title="CRM Reports">
      <SimpleTable
        size="small"
        fixedHeader={false}
        columns={[
          {
            label: "Report Title",
            icon: BarChart2,
            render: (report) => (
              <span className="font-medium text-blue-400 cursor-pointer">
                {report.title}
              </span>
            ),
          },
          {
            label: "Description",
            icon: FileText,
            render: (report) => (
              <span className="text-sm text-gray-500">
                {report.description}
              </span>
            ),
          },
          {
            label: "Created By",
            icon: User,
            render: () => (
              <span className="text-sm text-gray-500">System generated</span>
            ),
          },
        ]}
        rows={reports}
        idKey="slug"
        onRowClick={(report) => navigate(`/crm/reports/${report.slug}`)}
      />
    </Page>
  );
};

export default CRMReports;
