import { sum } from "radash";
import { dinero, toDecimal, subtract } from "dinero.js";
import { AED } from "@dinero.js/currencies";

type CalculatePaymentReceived = {
  amount: number;
  paymentReceivedAllocations: {
    amount: number;
  }[];
};

export const calculatePaymentReceived = (
  paymentReceived: CalculatePaymentReceived
) => {
  const amount = dinero({
    amount: Math.round(paymentReceived.amount * 100),
    currency: AED,
  });

  const allocated = dinero({
    amount: Math.round(
      sum(
        paymentReceived.paymentReceivedAllocations,
        (allocation) => allocation.amount
      ) * 100
    ),
    currency: AED,
  });

  const excess = subtract(amount, allocated);

  return {
    amount: Number(toDecimal(amount)),
    allocated: Number(toDecimal(allocated)),
    excess: Number(toDecimal(excess)),
  };
};
