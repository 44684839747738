import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form } from "@heffl/ui/components/primitives/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save, Trash2Icon } from "lucide-react";
import { capitalize } from "radash";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { z } from "zod";
import CashflowForm from "./cashflow-form";

type EditCashflowModalProps = {
  open: boolean;
  onClose: ({ deleted }: { deleted: boolean }) => void;
  id: number;
};

const EditCashflowModal = ({ open, onClose, id }: EditCashflowModalProps) => {
  const { data: transaction } =
    trpc.books.cashflowAccounts.transactions.details.useQuery(id);

  const form = useForm<z.infer<typeof Schemas.books.cashflowTransaction>>({
    resolver: zodResolver(Schemas.books.cashflowTransaction),
    values: transaction,
  });

  const type = form.watch("type");

  const updateCashflowTransaction =
    trpc.books.cashflowAccounts.transactions.update.useMutation({
      onSuccess: () => {
        toast.success("Transaction updated");
        onClose({ deleted: false });
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const deleteCashflowTransaction =
    trpc.books.cashflowAccounts.transactions.delete.useMutation({
      onSuccess: () => {
        toast.success("Transaction deleted");
        onClose({ deleted: true });
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const onSubmit = (
    data: z.infer<typeof Schemas.books.cashflowTransaction>
  ) => {
    updateCashflowTransaction.mutate({
      id,
      data,
    });
  };

  return (
    <ModalDrawer
      title={`Edit ${type?.split("_").map(capitalize).join(" ")}`}
      open={open}
      onClose={() => onClose({ deleted: false })}
      footer={
        <div className="flex gap-2 w-full">
          <Button
            onClick={() => {
              heffl.modal.confirm({
                title: "Are you sure you want to delete this transaction?",
                description: "This action cannot be undone.",
                onConfirm: () => {
                  deleteCashflowTransaction.mutate(id);
                },
              });
            }}
            icon={Trash2Icon}
            variant="destructiveOutline"
          />
          <Button
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
            className="w-full"
            variant="primary"
            icon={Save}
          >
            Update
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <CashflowForm form={form} />
      </Form>
    </ModalDrawer>
  );
};

export default EditCashflowModal;
