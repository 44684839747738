import { z } from "zod";
import { integrationProvidersSchema } from "../helpers/integrations";

const integrationProviders = integrationProvidersSchema;

const scheduleStatus = z.enum([
  "SCHEDULED",
  "CONFIRMED",
  "ARRIVED",
  "ON_MY_WAY",
  "IN_PROGRESS",
  "COMPLETED",
  "CANCELLED",
]);

const projectTaskStatusTypes = z.enum([
  "OPEN",
  "IN_PROGRESS",
  "ON_HOLD",
  "COMPLETED",
  "CANCELLED",
]);
const notificationTypes = z.enum([
  "REMINDER",
  "TASK_ASSIGNED",
  "TASK_COMPLETED",
  "ACTIVITY_ASSIGNED",
  "ACTIVITY_COMPLETED",
  "PROJECT_TASK_ASSIGNED",
  "PROJECT_ACTIVITY_ASSIGNED",
  "LEAD_ASSIGNED",
  "DEAL_ASSIGNED",
  "WORKFLOW_NOTIFICATION",
  "JOB_ASSIGNED",
  "PAYMENT_RECEIVED",
  "MENTIONED_USER",
]);

const dashboardItemTypes = z.enum(["CHART", "TABLE", "STAT"]);

const taskPriority = z.enum(["URGENT", "HIGH", "MEDIUM", "LOW"]);

const commissionPaidTo = z.enum(["USER", "VENDOR"]);

const projectStatsuses = z.enum(["ACTIVE", "COMPLETED", "CANCELLED"]);

const timeSheetDurationTypes = z.enum(["MINUTES", "DATE"]);

const salesGoalTargetFor = z.enum(["USER", "TEAM"]);
const timeSheetStatusTypes = z.enum(["OPEN", "APPROVED", "REJECTED"]);
const projectApprovalStatus = z.enum(["OPEN", "APPROVED", "REJECTED"]);
const lostReasonTypes = z.enum(["LEAD", "DEAL", "QUOTATION"]);

const dealStageType = z.enum(["ACTIVE", "WON", "LOST"]);

const formItemTypes = z.enum(["HEADING", "CUSTOM_FIELD", "IMAGE"]);

const customFieldTypes = z.enum([
  "TEXT",
  "NUMBER",
  "DATE",
  "SINGLE_OPTION",
  "MULTIPLE_OPTION",
  "LINK_ENTITY",
  "MULTIPLE_USER_SELECT",
  "FILE_PICKER",
  "LONG_TEXT",
  "MOBILE",
  "EMAIL",
]);
const customFieldSections = z.enum([
  "CLIENT",
  "LEAD",
  "DEAL",
  "CONTACT",
  "PROJECT",
  "QUOTATION",
  "QUOTATION_LINE_ITEM",
  "CUSTOM_OBJECT",
  "SALES_ORDER",
]);

const userActivitiesActionTypes = z.enum(["ADD", "UPDATE", "DELETE"]);

const invoiceStatus = z.enum([
  "DRAFT",
  "SENT",
  "PARTIALLY_PAID",
  "PAID",
  "CANCELLED",
]);

const paymentAllocationType = z.enum(["INVOICE", "OPENING_BALANCE"]);
const paymentMadeAllocationType = z.enum(["BILL", "OPENING_BALANCE"]);

const quotationStatus = z.enum(["DRAFT", "SENT", "ACCEPTED", "REJECTED"]);

const jobStatus = z
  .enum(["PENDING", "ACTIVE", "CANCELLED", "FREEZED", "COMPLETED"])
  .default("ACTIVE");
const jobType = z.enum(["ONEOFF", "CONTRACT"]);
const frequencyType = z
  .enum(["days", "weeks", "months", "years", "AS_NEEDED"])
  .default("days");

export const templateSettingsLineItemColumnsHidden = z.array(
  z.enum(["No", "Item", "Price", "Quantity", "VAT", "Total"])
);

const templateColumns = z.array(
  z.enum(["no", "item", "price", "quantity", "vat", "total", "custom"])
);

const paymentGateway = z.enum(["telr"]);
const paymentStatus = z.enum(["PENDING", "PAID", "FAILED"]);

const commentType = z.enum(["TEXT", "VOICE", "FILE", "IMAGE"]);

const activityTypes = z.enum([
  "email",
  "call",
  "todo",
  "meeting",
  "sms",
  "whatsapp_message",
]);

const importOperationType = z.enum([
  "CLIENTS",
  "CLIENT_ADDRESSES",
  "CLIENT_CONTACTS",
  "ADRESSES",
  "INVOICES",
  "INVOICE_LINE_ITEMS",
  "PRODUCTS",
]);

const userTypes = z.enum([
  "ADMIN",
  "SUPER_ADMIN",
  "STAFF",
  "OWNER",
  "FIELD_STAFF",
  "DRIVER",
  "SUPERVISOR",
]);

const customFieldLinkTypes = z.enum(["ONE_TO_ONE", "ONE_TO_MANY"]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumsToOptions = <T extends z.ZodEnum<any>>(
  zEnum: T,
  formatLabel?: (label: string) => string
): Array<{ label: string; value: T["Values"] }> => {
  return (zEnum.options as Array<T["Values"]>).map((value) => ({
    label: formatLabel ? formatLabel(String(value)) : String(value),
    value,
  }));
};

const purchaseOrderStatus = z.enum(["DRAFT", "SENT", "CLOSED", "CANCELLED"]);

const fsJobServiceType = z.enum(["PRODUCT", "SERVICE"]);

const tagTypes = z.enum([
  "QUOTATION",
  "INVOICE",
  "SCHEDULE",
  "JOB",
  "CLIENT",
  "PROJECT",
  "PROJECT_TASK",
  "PURCHASE_ORDER",
  "VENDOR",
  "BILL",
]);

const leadStageTypes = z.enum([
  "NEW",
  "CONTACTED",
  "WORKING",
  "UNQUALIFIED",
  "CONVERTED",
]);

const billStatuses = z.enum([
  "DRAFT",
  "OPEN",
  "PARTIALLY_PAID",
  "PAID",
  "CANCELLED",
]);

const commissionStatus = z.enum(["DRAFT", "CONFIRMED", "CANCELLED"]);
const activityStatuses = z.enum([
  "TODO",
  "NOT_ANSWERED",
  "DECLINED",
  "WRONG_CONTACT",
  "RESCHEDULED",
  "CANCELLED",
  "DONE",
]);

const templateTypes = z.enum([
  "QUOTATION",
  "INVOICE",
  "PROFORMA_INVOICE",
  "PAYMENT",
  "JOB",
  "SCHEDULE",
  "PURCHASE_ORDER",
  "BILL",
  "PRODUCT",
  "SALES_ORDER",
  "CLIENT_SOA",
]);

const appKeys = z.enum([
  "CRM",
  "SALES",
  "PURCHASES",
  "BOOKS",
  "CALENDAR",
  "PROJECTS",
  "PROPERTY_MANAGEMENT",
  "FILES",
  "CONTACTS",
  "FIELD_SERVICE",
  "EMPLOYEES",
  "TEMPLATES",
  "AI_ASSISTANT",
  "SETTINGS",
  "FORMS",
]);

const documentValueTypes = z.enum(["STATIC", "DYNAMIC"]);

const systemFileFields = z.enum(["fs_job_lpo", "expense_reciept", "team_logo"]);

const accounting = {
  accountingExpensePaidBy: z.enum(["COMPANY", "EMPLOYEE"]),
  chartOfAccountsType: z.enum([
    "EXPENSE",
    "COST_OF_GOODS_SOLD",
    "OTHER_EXPENSE",
    "INCOME",
    "OTHER_INCOME",
    "OTHER_ASSET",
    "OTHER_CURRENT_ASSET",
    "CASH",
    "BANK",
    "FIXED_ASSET",
    "STOCK",
    "PAYMENT_CLEARING",
  ]),
  cashflowTransactionTypes: z.enum([
    "OWNER_DRAWING",
    "OWNER_CONTRIBUTION",
    "OTHER_INCOME",
    "TRANSFER_FROM_ACCOUNT",
    "TRANSFER_TO_ACCOUNT",
    "OTHER_EXPENSE",
  ]),
};

const expenseTypes = z.enum(["GOODS", "SERVICES"]);

const jobSchedulePricingType = z.enum(["SCHEDULE", "FIELD_STAFF"]);

const expenses = {
  paidBy: {
    schema: z.enum(["COMPANY", "EMPLOYEE"]),
    values: [
      { label: "Company", value: "COMPANY" },
      { label: "Employee", value: "EMPLOYEE" },
    ],
  },
};

const salesOrderStatus = z.enum(["DRAFT", "CONFIRMED", "CANCELLED"]);

const entities = z.enum([
  "LEAD",
  "SALES_ORDER",
  "DEAL",
  "CONTACT",
  "CLIENT",
  "FS_JOB",
  "FS_SCHEDULE",
  "FILE",
  "TASK",
  "COMMENT",
  "PROJECT",
  "EXPENSE",
  "TEAM",
  "PROJECT_TASK",
  "CUSTOM_OBJECT",
  "CUSTOM_OBJECT_RECORD",
  "DOCUMENT",
  "ACTIVITY",
  "QUOTATION",
  "INVOICE",
  "PURCHASE_ORDER",
  "PROFORMA_INVOICE",
  "BILL",
]);

const S3Folders = z.enum([
  "files",
  "documents",
  "deals",
  "leads",
  "leads/comments",
  "projects",
  "projects/tasks",
  "expenses/receipts",
  "contacts",
  "users/profileImages",
  "templates",
  "documents/images",
  "field-service/jobs",
  "teams/logos",
  "custom-objects",
  "custom-objects/records",
]);

const lineItemViewType = z.enum(["LINE_ITEM", "HEADING"]);

const pmLeaseChargeTypes = z.enum(["ONETIME", "RECURRING"]);

export default {
  lineItemViewType,
  quotationStatus,
  scheduleStatus,
  jobStatus,
  jobType,
  frequencyType,
  formItemTypes,
  invoiceStatus,
  importOperationType,
  templateSettingsLineItemColumnsHidden,
  accounting,
  expenses,
  templateTypes,
  S3Folders,
  activityTypes,
  userTypes,
  appKeys,
  commentType,
  fsJobServiceType,
  customFieldSections,
  customFieldTypes,
  userActivitiesActionTypes,
  projectTaskStatusTypes,
  timeSheetStatusTypes,
  tagTypes,
  taskPriority,
  lostReasonTypes,
  salesGoalTargetFor,
  purchaseOrderStatus,
  templateColumns,
  paymentGateway,
  paymentStatus,
  timeSheetDurationTypes,
  dealStageType,
  leadStageTypes,
  billStatuses,
  entities,
  jobSchedulePricingType,
  systemFileFields,
  projectStatsuses,
  paymentAllocationType,
  notificationTypes,
  integrationProviders,
  customFieldLinkTypes,
  paymentMadeAllocationType,
  expenseTypes,
  commissionPaidTo,
  salesOrderStatus,
  documentValueTypes,
  commissionStatus,
  activityStatuses,
  projectApprovalStatus,
  dashboardItemTypes,
  pmLeaseChargeTypes,
};
