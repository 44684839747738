import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trash2 } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { DealForm } from "./add-deal-modal";
import validateCustomFields from "@/helpers/customFields/validateCustomFields";

const EditDealDrawer = ({
  open,
  onClose,
  dealId,
}: {
  dealId?: number;
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const { data: dealDetails } = trpc.deals.details.useQuery(dealId!, {
    enabled: !!dealId,
  });

  const onModalClose = () => {
    setTimeout(() => {
      form.reset();
    }, 200);
    onClose();
  };

  const dealUpdateMutation = trpc.deals.update.useMutation({
    onSuccess() {
      toast.success("Updated deal.");
      onModalClose();
    },
    onError(err) {
      toast.error(err.message);
    },
  });

  const dealDeleteMutation = trpc.deals.delete.useMutation({
    onSuccess() {
      toast.success("Deal deleted successfully.");
      navigate("/crm/deals/board");
      onModalClose();
    },
    onError() {
      toast.error("Failed to delete deal.");
    },
  });

  const form = useForm<z.infer<typeof Schemas.crm.deal>>({
    resolver: zodResolver(
      Schemas.crm.deal.superRefine(({ clientId, dealContacts }, ctx) => {
        if (!clientId && !dealContacts?.length) {
          ctx.addIssue({
            code: "custom",
            message: "Contact or company is needed",
            path: ["clientId"],
          });
          ctx.addIssue({
            code: "custom",
            message: "Contact or company is needed",
            path: ["dealContacts"],
          });
        }
      })
    ),
  });

  useEffect(() => {
    if (dealDetails) {
      form.reset({
        ...dealDetails,
        dealContacts: dealDetails.dealContacts.map(
          ({ contactId }) => contactId
        ),
        dealAssignees: dealDetails.dealAssignees.map(({ userId }) => userId),
      });
    }
  }, [dealDetails, form]);

  if (!dealDetails || !dealId) return <FullScreenSpinner />;

  const onSubmit = (values: z.infer<typeof Schemas.crm.deal>) => {
    const { isValid } = validateCustomFields({
      customFields: dealDetails.customFieldFields,
      customFieldsValues: values.customFields,
      form: form,
    });
    if (!isValid) return;
    dealUpdateMutation.mutate({ id: dealId, deal: values });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      className="w-full sm:w-[450px] h-4/5 sm:h-full"
      title="Update deal"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            loading={dealDeleteMutation.isLoading}
            variant="destructiveOutline"
            icon={Trash2}
            onClick={async () => {
              const confirmed = await confirm(
                "Are you sure you want to delete this deal?"
              );
              if (confirmed) {
                dealDeleteMutation.mutate(dealId);
              }
            }}
          />
          <Button
            loading={dealUpdateMutation.isLoading}
            type="submit"
            variant="primary"
            className="w-full"
            onClick={() => form.handleSubmit(onSubmit)()}
          >
            Update deal
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <DealForm form={form} edit />
      </Form>
    </ModalDrawer>
  );
};

export default EditDealDrawer;
