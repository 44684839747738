/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@heffl/ui/components/primitives/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandShortcut,
} from "@heffl/ui/components/primitives/command";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@heffl/ui/components/primitives/drawer";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import { CommandLoading } from "cmdk";
import {
  Check,
  ChevronDown,
  Loader2,
  LucideIcon,
  PlusCircle,
  XCircle,
} from "lucide-react";
import * as React from "react";
import { ScrollArea, ScrollBar } from "./scroll-area";
import { Icon } from "@iconify/react";
import { Tooltip } from "antd";

interface Option {
  label: React.ReactNode | string;
  value: any;
  icon?: LucideIcon;
  iconify?: string;
  iconClassName?: string;
  description?: string;
  hotkey?: string;
}

type SelectProps<T extends Option> = {
  hotkey?: string;
  popoverClassName?: string;
  popoverSide?: "bottom" | "top" | "left" | "right";
  iconOnly?: boolean;
  previewType?: "linear";
  previewParentClassName?: string;
  previewRender?: (record: T | undefined) => React.ReactNode;
  shouldFilter?: boolean;
  onSearch?: (v: string) => void;
  hideSearch?: boolean;
  className?: string;
  render?: (record: T) => React.ReactNode;
  options: T[] | undefined | null;
  previewLabel?: string;
  emptyMessage?: string;
  customIcon?: React.ReactNode;
  value?: any;
  onChange?: (v: any) => void;
  onSelect?: (v: T) => void;
  isLoading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  icon?: LucideIcon;
  iconify?: string;
  number?: boolean;
  allowClear?: boolean;
  createButton?: {
    label: string;
    onClick: () => void;
  };
};

// select is breakin if it contains \n or \r
const formatString = (str: string) => {
  return str.trim();
};

const Select = <T extends Option>(props: SelectProps<T>) => {
  const placeholder = props.placeholder || "Select option";

  const isDesktop = !isMobile();

  const [open, setOpen] = React.useState(false);

  const options = props.options || [];
  const selectedOption = options.find((o) => o.value === props.value);

  const selectPreview = (
    <Tooltip
      title={isMobile() ? "" : selectedOption?.label}
      placement="top"
      mouseEnterDelay={0.5}
      className={cn("w-full", props.className)}
    >
      <Button
        variant="outline"
        className={cn(
          "flex justify-between w-full px-2 sm:h-9 h-11  !font-normal sm:py-2 py-3"
        )}
        disabled={props.disabled || props.isLoading}
        size="md"
      >
        <div className="flex overflow-hidden gap-1 items-center">
          {props.customIcon ||
            (props.icon && <props.icon className="w-5 h-5" />)}
          {props.iconify && (
            <Icon icon={props.iconify} className="w-5 h-5 shrink-0" />
          )}
          <p className="flex gap-1 items-center truncate">
            {selectedOption?.icon && (
              <selectedOption.icon className="h-[17px] w-[17px]" />
            )}
            {selectedOption?.iconify && (
              <Icon
                icon={selectedOption.iconify}
                className={cn("h-4 shrink-0", selectedOption.iconClassName)}
              />
            )}
            {props.value ? (
              <p>{selectedOption?.label}</p>
            ) : (
              <p className="font-normal text-gray-500">{placeholder || ""}</p>
            )}
          </p>
        </div>
        <div className="flex items-center">
          {!!props.value && props.allowClear && (
            <XCircle
              className="h-4 text-gray-700"
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
                props.onChange && props.onChange(null);
              }}
            />
          )}
          {props.isLoading ? (
            <Loader2 className="h-4 animate-twSpin text-primary" />
          ) : (
            <ChevronDown className="h-4 text-gray-400" />
          )}
        </div>
      </Button>
    </Tooltip>
  );

  const selectLinear = (
    <Button
      variant="ghost"
      className={cn("-ml-2 w-full bg-white", props.className)}
      icon={selectedOption?.icon || props.icon}
      iconify={selectedOption?.iconify || props.iconify}
      iconClassName={cn("mr-2", selectedOption?.iconClassName)}
      size="sm"
    >
      <div className="flex justify-between items-center w-full">
        <div>
          {!props.iconOnly &&
            (selectedOption ? (
              <span className="!font-medium !text-xs">
                {props.previewLabel || selectedOption?.label}
              </span>
            ) : (
              <span className="!font-medium !text-xs text-gray-500">
                {placeholder}
              </span>
            ))}
        </div>
      </div>
      {props.allowClear && props.value && (
        <Icon
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
            props.onChange && props.onChange(null);
          }}
          icon="tabler:x"
          className="ml-2 text-gray-400 shrink-0 hover:text-black"
        />
      )}
    </Button>
  );

  const selectOptions = (
    <Command shouldFilter={props.shouldFilter}>
      {!props.hideSearch && (
        <CommandInput
          placeholder="Search options..."
          onValueChange={(v) => {
            if (v.length === 1 && options.some((o) => o.hotkey === v)) {
              const option = options.find((o) => o.hotkey === v);
              if (option) {
                props.onChange?.(option.value);
                setOpen(false);
                return;
              }
            }
            props.onSearch?.(v);
          }}
        />
      )}

      <CommandList
        onClick={(e) => e.stopPropagation()}
        className="mt-2 sm:mt-0"
      >
        {!props.isLoading && (
          <CommandEmpty>
            {props.emptyMessage || "No results found."}
          </CommandEmpty>
        )}
        {/* TODO BUG: There is an issue of select breaking if \n or r in label */}
        {props.isLoading ? (
          <CommandLoading>
            <div className="flex gap-1 items-center p-2">
              <Loader2 className="h-5 animate-twSpin text-primary" />
              <p>Loading...</p>
            </div>
          </CommandLoading>
        ) : (
          <CommandGroup>
            <ScrollArea
              className={cn("max-h-[300px] sm:max-h-[190px] !overflow-auto")}
            >
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={formatString(`${option.label} ${option.value}`)}
                  onSelect={() => {
                    props.onSelect && props.onSelect(option);
                    props.onChange && props.onChange(option.value);
                    setOpen(false);
                  }}
                >
                  {props.render ? (
                    props.render(option)
                  ) : (
                    <div className="flex flex-col w-full">
                      <div className="flex justify-between items-center w-full">
                        <div className="flex gap-2 items-center">
                          {option.icon && (
                            <option.icon
                              className={cn(
                                "w-4 h-4 text-gray-600",
                                option.iconClassName
                              )}
                            />
                          )}
                          {option.iconify && (
                            <Icon
                              icon={option.iconify}
                              className={cn(
                                "w-4 h-4 text-gray-600",
                                option.iconClassName
                              )}
                            />
                          )}

                          {option.label}
                        </div>
                        <div className="flex gap-1 items-center">
                          <Check
                            className={cn(
                              "h-4 font-medium",
                              props.value === option.value
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                          {option.hotkey && (
                            <CommandShortcut>{option.hotkey}</CommandShortcut>
                          )}
                        </div>
                      </div>
                      {option.description && (
                        <p className="pl-6 text-xs text-gray-500">
                          {option.description}
                        </p>
                      )}
                    </div>
                  )}
                </CommandItem>
              ))}
              <ScrollBar orientation="vertical" />
            </ScrollArea>
          </CommandGroup>
        )}
      </CommandList>
      {props.createButton?.onClick && (
        <div
          className="p-2.5 border-t border-gray-200 hover:text-white hover:bg-primary-300 flex items-center gap-1 cursor-pointer"
          key="create"
          onClick={() => {
            props.createButton?.onClick();
            setOpen(false);
          }}
        >
          <PlusCircle className="h-5 text-primary" />
          {props.createButton.label}
        </div>
      )}
    </Command>
  );

  if (isDesktop) {
    return (
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger
          className={cn(
            props.previewParentClassName,
            props.previewRender || props.previewType === "linear"
              ? "!w-fit"
              : "w-full"
          )}
          disabled={props.disabled}
          onClick={(e) => e.stopPropagation()}
          // asChild
        >
          {props.previewRender
            ? props.previewRender(selectedOption)
            : props.previewType === "linear"
            ? selectLinear
            : selectPreview}
        </PopoverTrigger>
        <PopoverContent
          className={cn(
            "w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] p-0 shadow-none rounded-lg",
            props.popoverClassName
          )}
          align="start"
          side={props.popoverSide || "bottom"}
          sideOffset={4}
          asChild
        >
          {selectOptions}
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger
        className={cn(
          props.previewParentClassName,
          props.previewRender || props.previewType === "linear"
            ? "!w-fit"
            : "w-full"
        )}
        disabled={props.disabled}
        onClick={(e) => e.stopPropagation()}
      >
        {props.previewRender
          ? props.previewRender(selectedOption)
          : props.previewType === "linear"
          ? selectLinear
          : selectPreview}
      </DrawerTrigger>
      <DrawerContent>
        {placeholder && (
          <DrawerHeader>
            <DrawerTitle>{placeholder}</DrawerTitle>
          </DrawerHeader>
        )}
        <div className="mt-4 border-t">{selectOptions}</div>
      </DrawerContent>
    </Drawer>
  );
};

export default Select;
