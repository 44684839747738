"use client";

import { Button } from "@heffl/ui/components/primitives/button";
import { Calendar } from "@heffl/ui/components/primitives/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { cn, dynamicDateFormatting } from "@heffl/ui/lib/utils";
import * as chrono from "chrono-node";
import { format } from "date-fns";
import { Calendar as CalendarIcon, LucideIcon, XCircle } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Input } from "./input";

type TProps = {
  disabled?: boolean;
  displayFormat?: string;
  value?: Date;
  onChange?: (v: Date | undefined) => void;
  presets?: { label: string; value: Date }[];
  presetsPosition?: "top" | "bottom";
  popoverSide?: "left" | "top" | "bottom" | "right";
  allowClear?: boolean;
  className?: string;
  placeholder?: string;
  fromDate?: Date;
  toDate?: Date;
  previewType?: "linear";
  iconClassName?: string | null;
  icon?: LucideIcon;
  iconify?: string;
};

const Presets = ({
  presets,
  onClick,
}: {
  presets: { label: string; value: Date }[];
  onClick: (preset: { label: string; value: Date }) => void;
}) => {
  return (
    <div className="flex flex-wrap gap-2 p-2 bg-green-50">
      {presets.map((preset) => (
        <div
          key={preset.label}
          className="px-2 py-1 text-xs bg-white rounded-sm border shadow-sm cursor-pointer hover:bg-gray-50"
          onClick={() => onClick(preset)}
        >
          {preset.label}
        </div>
      ))}
    </div>
  );
};

export const DatePicker = ({
  value,
  onChange = () => {},
  displayFormat = "dd/MM/yyyy",
  presets = [],
  presetsPosition = "bottom",
  allowClear = false,
  disabled = false,
  className = "",
  placeholder = "Pick a date",
  popoverSide = "bottom",
  iconClassName = "",
  fromDate,
  toDate,
  previewType,
  iconify = "tabler:calendar",
  icon = !iconify ? CalendarIcon : undefined,
}: TProps) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showCalendar) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
    setInputValue("");
  }, [showCalendar]);

  const dateLinear = (
    <Button
      onClick={(e) => {
        e.stopPropagation();
      }}
      variant="ghost"
      className="-ml-2 w-full bg-white"
      icon={icon}
      iconify={iconify}
      iconClassName={cn("mr-2", iconClassName)}
      size="sm"
      disabled={disabled}
    >
      <span className="text-xs font-medium">
        {value ? dynamicDateFormatting(value, true) : placeholder}
      </span>
    </Button>
  );

  const datePreview = (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        setShowCalendar(true);
      }}
      variant={"outline"}
      className={cn(
        "relative justify-start px-2 w-full font-normal text-left",
        className
      )}
      disabled={disabled}
      size="md"
    >
      <CalendarIcon className={cn("mr-2 w-4 h-4", iconClassName)} />
      {value ? (
        format(value, displayFormat)
      ) : (
        <span className="font-normal text-gray-500">{placeholder}</span>
      )}
      {allowClear && value && (
        <XCircle
          onClick={(e) => {
            onChange(undefined);
            setShowCalendar(false);
            e.stopPropagation();
          }}
          className="absolute right-2 h-4 text-gray-400 cursor-pointer"
        />
      )}
    </Button>
  );

  return (
    <Popover open={showCalendar} onOpenChange={setShowCalendar}>
      <PopoverTrigger
        className={cn(previewType === "linear" ? "!w-fit" : "w-full")}
        asChild
      >
        {previewType === "linear" ? dateLinear : datePreview}
      </PopoverTrigger>
      <PopoverContent
        className="p-0 w-auto"
        side={popoverSide}
        sideOffset={4}
        align="start"
      >
        <div className="p-2">
          <Input
            ref={inputRef}
            className="w-full !h-8"
            placeholder="Next week, next month"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                const parsed = chrono.parseDate(inputValue);
                if (parsed) {
                  onChange(parsed);
                  setInputValue("");
                  setShowCalendar(false);
                }
              }
            }}
          />
        </div>
        {!!presets.length && presetsPosition === "top" && (
          <Presets
            presets={presets}
            onClick={(preset) => {
              onChange(preset.value);
              setShowCalendar(false);
            }}
          />
        )}
        <Calendar
          defaultMonth={value}
          mode="single"
          selected={value}
          fromDate={fromDate}
          toDate={toDate}
          onSelect={(value) => {
            onChange(value);
            setShowCalendar(false);
          }}
          initialFocus
        />
        {!!presets.length && presetsPosition === "bottom" && (
          <Presets
            presets={presets}
            onClick={(preset) => {
              onChange(preset.value);
              setShowCalendar(false);
            }}
          />
        )}
      </PopoverContent>
    </Popover>
  );
};
