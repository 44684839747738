import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { Card } from "@heffl/ui/components/primitives/card";
import { formatCurrency, formatName } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { Banknote } from "lucide-react";
import React, { useState } from "react";

interface PageFilters {
  dates?: [Date, Date];
  vendors: number[];
  users: number[];
}

const ExpensesReport: React.FC = () => {
  const [filters, setFilters] = useParamsState<PageFilters>({
    dates: [dayjs().startOf("month").toDate(), dayjs().endOf("month").toDate()],
    vendors: [],
    users: [],
  });

  const [vendorSearch, setVendorSearch] = useState("");

  const { data: report, isLoading } =
    trpc.sales.reports.expensesReport.useQuery({
      vendors: filters.vendors,
      dates: filters.dates,
      users: filters.users,
    });
  const { data: vendors } = trpc.purchases.vendors.list.useQuery({
    search: vendorSearch,
  });
  const { data: users } = trpc.users.list.useQuery();

  return (
    <Page title="Expenses Report" className="!p-0" fullWidth showBack>
      <FilterBar
        className="px-3 pt-3"
        onChange={() => {}}
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
          },
          {
            key: "vendors",
            type: "checkbox",
            label: "Vendors",
            value: filters.vendors,
            showSearch: true,
            onChange: (value) => setFilters({ vendors: value as number[] }),
            onSearch: (value) => setVendorSearch(value),
            options: vendors?.vendors.map((vendor) => ({
              label: vendor.name,
              value: vendor.id,
            })),
          },
          {
            key: "users",
            type: "checkbox",
            label: "Users",
            value: filters.users,
            onChange: (value) => setFilters({ users: value as number[] }),
            options: users?.map((user) => ({
              label: formatName(user),
              value: user.id,
            })),
          },
        ]}
      />
      {report && (
        <div className="grid grid-cols-2 gap-4 p-3 sm:grid-cols-3 lg:grid-cols-6">
          <Card>
            <h3 className="text-xs font-medium text-gray-500">Total Expense</h3>
            <p className="mt-1 text-xl font-semibold text-gray-900">
              {formatCurrency(report.summary.totalExpense, "AED")}
            </p>
          </Card>
        </div>
      )}
      <DataGrid
        rowKey="id"
        name="expensesReport"
        label="Expenses"
        className="h-[calc(100vh-260px)] mt-12"
        loading={isLoading}
        empty={{
          icon: Banknote,
          title: "No Expenses",
          description: "No expenses found for the selected filters.",
          buttonSize: "sm",
        }}
        rows={report?.expenses || []}
        columns={[
          {
            key: "date",
            name: "Date",
            width: 150,
            renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
          },
          {
            key: "expenseAccount",
            name: "Expense Account",
            renderCell: ({ row }) => row.expenseAccount.name,
          },
          {
            key: "paidThroughAccount",
            name: "Paid Through",
            renderCell: ({ row }) => row.paidThroughAccount.name,
          },
          {
            key: "amount",
            name: "Amount",
            width: 140,
            renderCell: ({ row }) => formatCurrency(row.amount, "AED", true),
          },
          {
            key: "notes",
            name: "Notes",
            width: 200,
            renderCell: ({ row }) => (
              <div className="truncate">{row.notes || "-"}</div>
            ),
          },
          {
            key: "paidBy",
            name: "Paid By",
            width: 100,
            renderCell: ({ row }) => row.paidBy,
          },
        ]}
      />
    </Page>
  );
};

export default ExpensesReport;
