import { cn } from "@heffl/ui/lib/utils";
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import { LucideIcon } from "lucide-react";

type TProps<T extends string | number> = {
  value?: T;
  className?: string;
  tabsListClassName?: string;
  onChange?: (v: T) => void;
  options?: {
    value: T;
    label: React.ReactNode;
    count?: number;
    icon?: LucideIcon;
  }[];
};

const TabsInput = <T extends string | number>({
  onChange = () => {},
  options = [],
  value = "" as T,
  className,
  tabsListClassName,
}: TProps<T>) => {
  return (
    <div className={cn("flex flex-row gap-2", className)}>
      <Tabs
        className="w-full sm:block"
        value={value.toString()}
        onValueChange={(value) => onChange(value as T)}
      >
        <TabsList className={tabsListClassName}>
          {options.map((option) => (
            <TabsTrigger
              key={option.value}
              value={option.value.toString()}
              className="flex gap-1 items-center"
            >
              {option.icon && <option.icon className="h-4 text-gray-400" />}
              {option.label}
              {!!(option.count && option.count > 0) && (
                <div className="px-1.5 py-0.5 rounded bg-primary-600 flex items-center justify-center text-white text-xs">
                  {option.count}
                </div>
              )}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
    </div>
  );
};

export default TabsInput;
