import { cn } from "@heffl/ui/lib/utils";
import { Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { LucideIcon, MoreVertical, Search } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./primitives/accordion";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./primitives/dropdown-menu";

type Column<T> = {
  label: string;
  key?: keyof T;
  render?: (row: T, index: number) => React.ReactNode;
  icon?: LucideIcon;
  className?: string;
  // sm is desktop
  responsive?: "sm"[];
  fixed?: "left" | "right";
  width?: number;
  align?: "left" | "center" | "right";
};

type Group = {
  label: string | React.ReactNode;
  key: string;
};

const SimpleTable = <T extends Record<string, unknown>>({
  columns,
  rows,
  className,
  onRowClick,
  borderless = false,
  idKey,
  actions,
  expandable,
  size = "small",
  groupBy,
  rowSelection,
  fixedHeader = true,
  height,
}: {
  size?: "small" | "middle";
  groupBy?: {
    rowKey: ((row: T) => string) | keyof T;
    // label?: (row: T, count: number) => string | React.ReactNode;
    groups?: Group[];
    opened?: string[];
    defaultOpened?: string[];
    onOpenChange?: (opened: string[]) => void;
    showEmptyGroup?: boolean;
  };
  rowSelection?: TableProps<T>["rowSelection"];
  expandable?: TableProps<T>["expandable"];
  fixedHeader?: boolean;
  columns: Column<T>[];
  rows: (T & { children?: T[] })[];
  idKey: keyof T;
  className?: string;
  onRowClick?: (row: T) => void;
  borderless?: boolean;
  height?: number;
  actions?: (row: T) => (
    | {
        type: "item";
        icon?: LucideIcon;
        label: string;
        onClick: (row: T) => void;
        hidden?: boolean;
        classes?: {
          icon?: string;
          label?: string;
        };
      }
    | {
        type: "separator";
      }
  )[];
}) => {
  // Convert columns to antd format
  const antColumns: ColumnsType<T> = columns.map((col) => ({
    title: (
      <div className="flex gap-1 items-center text-xs text-[#75777c] text-start font-[500]">
        {col.icon && <col.icon className="w-3.5 h-3.5" />}
        {col.label}
      </div>
    ),
    align: col.align,
    dataIndex: col.key as string,
    key: col.key as string,
    className: cn("", col.className),
    render: col.render
      ? (_, record, index) => (
          <div
            style={{
              width: col.width,
            }}
          >
            {col.render!(record, index)}
          </div>
        )
      : undefined,
    responsive: col.responsive?.map((r) => (r === "sm" ? "md" : "lg")),
    fixed: col.fixed,
    width: col.width,
  }));

  // Add actions column if needed
  if (actions) {
    antColumns.push({
      key: "actions",
      fixed: "right",
      width: 50,
      render: (_, record) => (
        <DropdownMenu>
          <DropdownMenuTrigger>
            <MoreVertical className="w-4 h-4" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {actions(record)
              .filter((action) => ("hidden" in action ? !action.hidden : true))
              .map((action, i) =>
                action.type === "item" ? (
                  <DropdownMenuItem
                    key={action.label}
                    onClick={() => action.onClick(record)}
                    className={action.classes?.label}
                  >
                    {action.icon && (
                      <action.icon
                        className={cn("mr-2 w-4 h-4", action.classes?.icon)}
                      />
                    )}
                    {action.label}
                  </DropdownMenuItem>
                ) : (
                  <DropdownMenuSeparator key={`sep-${i}`} />
                )
              )}
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    });
  }

  type GroupGrouped = {
    label: string | React.ReactNode;
    key: string;
    count: number;
    rows: T[];
  };

  const getGroupedRows = (): GroupGrouped[] => {
    if (!groupBy) return [];

    let groups: GroupGrouped[] = [];
    if (!groupBy.groups) {
      const grouped = rows.reduce<{
        [key: string]: {
          rows: T[];
          count: number;
        };
      }>((acc, row) => {
        const key =
          typeof groupBy.rowKey === "function"
            ? groupBy.rowKey(row)
            : String(row[groupBy.rowKey as keyof T]);

        if (!key) return acc;
        if (!acc[key as string]) {
          acc[key as string] = {
            rows: [],
            count: 0,
          };
        }
        acc[key as string].rows.push(row);
        acc[key as string].count++;
        return acc;
      }, {});
      groups = Object.keys(grouped).map((key) => ({
        label: `${key} (${grouped[key].count})`,
        key,
        rows: grouped[key].rows,
        count: grouped[key].count,
      }));
    } else {
      groups = groupBy.groups.map((group) => {
        const groupRows = rows.filter((row) =>
          typeof groupBy.rowKey === "function"
            ? groupBy.rowKey(row) === group.key
            : String(row[groupBy.rowKey as keyof T]) === group.key
        );
        return {
          label: group.label,
          key: group.key,
          rows: groupRows,
          count: groupRows.length,
        };
      });
    }
    if (!groupBy.showEmptyGroup) {
      groups = groups.filter((group) => group.count > 0);
    }
    return groups;
  };

  const groupedRows = getGroupedRows();

  const scroll: { x?: number | string; y?: number | string } = {};
  if (fixedHeader) {
    scroll.y = height;
  }
  scroll.x = "max-content";
  const tableProps: TableProps<T> = {
    rowSelection: rowSelection,
    size: size,
    scroll: scroll,
    columns: antColumns,
    dataSource: rows,
    rowKey: (record) => String(record[idKey]),
    expandable: expandable,
    onRow: (record) => ({
      onClick: () => onRowClick?.(record),
      className: "hover:bg-gray-50",
    }),
    bordered: !borderless,
    pagination: false,
    sticky: fixedHeader ? { offsetHeader: 0 } : undefined,
    tableLayout: "fixed",
    locale: {
      emptyText: (
        <div className="flex flex-col justify-center items-center p-2 w-full">
          <div className="p-2 mb-4 bg-gray-50 rounded-full">
            <Search className="w-8 h-8 text-gray-400" />
          </div>
          <h3 className="mb-1 font-medium text-gray-900">No data found</h3>
          <p className="text-sm text-center text-gray-500">
            There are no items to display.
          </p>
        </div>
      ),
    },
  };

  return (
    <div className={className}>
      {groupedRows && groupBy ? (
        <div>
          <Accordion type="multiple" defaultValue={groupBy.defaultOpened}>
            {groupedRows.map((group) => {
              return (
                <AccordionItem
                  key={group.key}
                  value={group.key}
                  id={`table-${group.key}`}
                >
                  <AccordionTrigger>{group.label}</AccordionTrigger>
                  <AccordionContent>
                    <Table {...tableProps} dataSource={group.rows} />
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      ) : (
        <Table {...tableProps} />
      )}
    </div>
  );
};

export default SimpleTable;
