import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { Label } from "@heffl/ui/components/primitives/label";
import Modal from "@heffl/ui/components/primitives/modal";
import { RadioGroup } from "@heffl/ui/components/primitives/radio-group";
import { addNumberSuffix, capitalize, cn } from "@heffl/ui/lib/utils";
import { RadioItem } from "@/pages/documentTemplates/components/templateForm";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Frequency, RRule, Weekday, rrulestr } from "rrule";
import { useImmer } from "use-immer";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";

const weekDays = (weekNumber: 1 | 2 | 3 | 4) => [
  { label: "Sunday", value: RRule.SU.nth(weekNumber) },
  { label: "Monday", value: RRule.MO.nth(weekNumber) },
  { label: "Tuesday", value: RRule.TU.nth(weekNumber) },
  { label: "Wednesday", value: RRule.WE.nth(weekNumber) },
  { label: "Thursday", value: RRule.TH.nth(weekNumber) },
  { label: "Friday", value: RRule.FR.nth(weekNumber) },
  { label: "Saturday", value: RRule.SA.nth(weekNumber) },
];

const WeekDaysInput = ({
  onChange,
  selected,
  weekNumber,
}: {
  onChange: (v: Weekday[]) => void;
  selected: Weekday[];
  weekNumber: 1 | 2 | 3 | 4;
}) => {
  return (
    <div className="flex">
      {weekDays(weekNumber).map((day) => {
        return (
          <Tooltip content={day.label} key={day.label}>
            <div
              className={cn(
                "p-1 px-3 border cursor-pointer",
                selected.some((s) => s.equals(day.value)) &&
                  "bg-primary text-white"
              )}
              onClick={() => {
                const isAlreadySelected = selected.some((s) =>
                  s.equals(day.value)
                );
                const removed = selected.filter((s) => !s.equals(day.value));
                const added = [...selected, day.value];
                onChange(isAlreadySelected ? removed : added);
              }}
            >
              {day.label[0]}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

const FrequencySelector = ({
  value = "",
  onChange = () => {},
  placeholder = "Select frequency",
  iconify = "",
  allowClear = false,
  previewType,
  className = "",
}: {
  value?: string;
  onChange?: (v: string) => void;
  placeholder?: string;
  iconify?: string;
  allowClear?: boolean;
  previewType?: "linear";
  className?: string;
}) => {
  const [showCustom, setShowCustom] = useState(false);
  const [monthSelected, setMonthSelected] = useState<"month" | "week">("month");

  const form = useForm<{
    frequencyType: Frequency;
    frequencyCount: number;
    daysOfWeek: Weekday[];
    daysOfMonth: number[];
  }>({
    defaultValues: {
      frequencyCount: 1,
      frequencyType: 1,
      daysOfWeek: [],
      daysOfMonth: [],
    },
  });

  const frequencyType = form.watch("frequencyType");
  const frequencyCount = form.watch("frequencyCount");
  const daysOfWeek = form.watch("daysOfWeek");
  const daysOfMonth = form.watch("daysOfMonth");

  const [selectOptions, setSelectOptions] = useImmer<
    {
      label: string;
      value: string | "CUSTOM";
    }[]
  >([
    { label: "Daily", value: "RRULE:FREQ=DAILY" },
    { label: "Weekly", value: "RRULE:FREQ=WEEKLY" },
    { label: "Monthly", value: "RRULE:FREQ=MONTHLY" },
    { label: "Yearly", value: "RRULE:FREQ=YEARLY" },
    { label: "Custom", value: "CUSTOM" },
  ]);

  const updatedRule = new RRule({
    freq: frequencyType,
    interval: frequencyCount,
    byweekday: daysOfWeek,
    bymonthday: daysOfMonth,
  });

  useEffect(() => {
    if (value) {
      const inputRrule = rrulestr(value);
      const isValueOptionExist = selectOptions.find(
        (option) => option.value === value
      );
      if (!isValueOptionExist) {
        setSelectOptions((draft) => {
          draft.unshift({ label: capitalize(inputRrule.toText()), value });
        });
      }
      if (inputRrule.origOptions.freq)
        form.setValue("frequencyType", inputRrule.origOptions.freq);
      if (inputRrule.origOptions.interval)
        form.setValue("frequencyCount", inputRrule.origOptions.interval);
      if (inputRrule.origOptions.bymonthday)
        form.setValue(
          "daysOfMonth",
          Array.isArray(inputRrule.origOptions.bymonthday)
            ? inputRrule.origOptions.bymonthday
            : [inputRrule.origOptions.bymonthday]
        );
    }
  }, [value]);

  return (
    <div>
      <Select
        previewType={previewType}
        className={className}
        iconify={iconify}
        placeholder={placeholder}
        value={value}
        allowClear={allowClear}
        popoverClassName="w-fit"
        options={selectOptions}
        onChange={(v) => {
          if (v === "CUSTOM") {
            return setShowCustom(true);
          }
          if (onChange) onChange(v as string);
        }}
      />
      <Form {...form} onSubmit={(values) => console.log(values)}>
        <Modal
          title="Custom schedule"
          open={showCustom}
          className="w-96"
          onClose={() => setShowCustom(!showCustom)}
        >
          <div className="flex flex-col gap-2">
            <Label>Frequency</Label>
            <div className="grid grid-cols-2 gap-2 mt-1">
              <FormField name="frequencyCount">
                <Input prefix="Every" prefixFilled placeholder="Count" />
              </FormField>
              <FormField name="frequencyType">
                <Select
                  allowClear={false}
                  onSelect={() => {
                    form.setValue("daysOfWeek", []);
                    form.setValue("daysOfMonth", []);
                  }}
                  options={[
                    {
                      label: "Day(s)",
                      value: Frequency.DAILY,
                    },
                    {
                      label: "Week(s)",
                      value: Frequency.WEEKLY,
                    },
                    {
                      label: "Month(s)",
                      value: Frequency.MONTHLY,
                    },
                    {
                      label: "Year(s)",
                      value: Frequency.YEARLY,
                    },
                  ]}
                />
              </FormField>
            </div>
            {frequencyType === Frequency.WEEKLY && (
              <div className="space-y-1">
                <Label>Day of week</Label>
                <WeekDaysInput
                  onChange={(v) => form.setValue("daysOfWeek", v)}
                  selected={daysOfWeek}
                  weekNumber={1}
                />
              </div>
            )}
            {frequencyType === Frequency.MONTHLY && (
              <RadioGroup
                className="flex gap-2 w-full"
                value={monthSelected}
                onChange={(v) => {
                  setMonthSelected(v as unknown as "month" | "week");
                  form.setValue("daysOfWeek", []);
                  form.setValue("daysOfMonth", []);
                }}
              >
                <RadioItem value="month" label="Day of month" />
                <RadioItem value="week" label="Day of week" />
              </RadioGroup>
            )}
            {monthSelected === "week" &&
              frequencyType === Frequency.MONTHLY && (
                <div className="mt-2">
                  {[1, 2, 3, 4].map((weekNumber) => (
                    <div className="flex gap-2 items-center" key={weekNumber}>
                      <p className="w-8">{addNumberSuffix(weekNumber)}</p>
                      <WeekDaysInput
                        onChange={(v) => form.setValue("daysOfWeek", v)}
                        selected={daysOfWeek}
                        weekNumber={weekNumber as 1 | 2 | 3 | 4}
                      />
                    </div>
                  ))}
                </div>
              )}
            {monthSelected === "month" &&
              frequencyType === Frequency.MONTHLY && (
                <div className="grid grid-cols-7 mt-2">
                  {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                    <div
                      key={day}
                      className={cn(
                        "p-1 text-center border cursor-pointer",
                        daysOfMonth.find((d) => d === day) &&
                          "bg-primary text-white"
                      )}
                      onClick={() => {
                        const isAlreadySelected = daysOfMonth.some(
                          (s) => s === day
                        );
                        const removed = daysOfMonth.filter((s) => !(s === day));
                        const added = [...daysOfMonth, day];
                        form.setValue(
                          "daysOfMonth",
                          isAlreadySelected ? removed : added
                        );
                      }}
                    >
                      {day}
                    </div>
                  ))}
                  <div
                    className={cn(
                      "col-span-4 p-1 text-center border cursor-pointer",
                      daysOfMonth.find((s) => s === -1) &&
                        "bg-primary text-white"
                    )}
                    onClick={() => {
                      const isAlreadySelected = daysOfMonth.some(
                        (s) => s === -1
                      );
                      const removed = daysOfMonth.filter((s) => !(s === -1));
                      const added = [...daysOfMonth, -1];
                      form.setValue(
                        "daysOfMonth",
                        isAlreadySelected ? removed : added
                      );
                    }}
                  >
                    {" "}
                    Last day
                  </div>
                </div>
              )}
            <p className="mt-2 font-medium">
              {capitalize(updatedRule.toText())}
            </p>
            <div className="flex justify-end items-center mt-1">
              <Button
                className="mr-2"
                variant="outline"
                size="md"
                onClick={() => setShowCustom(false)}
              >
                Cancel
              </Button>
              <Button
                variant={"primary"}
                size="md"
                onClick={() => {
                  setShowCustom(false);
                  setSelectOptions((draft) => {
                    const doesExist = draft.some(
                      (item) => item.value === updatedRule.toString()
                    );
                    if (!doesExist) {
                      draft.unshift({
                        label: capitalize(updatedRule.toText()),
                        value: updatedRule.toString(),
                      });
                    }
                  });
                  onChange && onChange(updatedRule.toString());
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
      </Form>
    </div>
  );
};

export default FrequencySelector;
