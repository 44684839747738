import {
  ActivityDetailsModal,
  activityStatuses,
  ActivitySummaryModal,
  AddActivityModal,
} from "@/components/actionsModals/activities-modals";
import DataGrid from "@/components/dataGrid/DataGrid";
import Empty from "@/components/Empty";
import FilterBar from "@/components/filter-bar";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { AvatarsGroup } from "@/components/UserAvatar";
import { RouterInputs, RouterOutputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { ActivityLinkBadge } from "@/pages/personal-dashboard";
import enums, { enumsToOptions } from "@heffl/server/src/schemas/enums";
import BigCalendar from "@heffl/ui/components/bigCalendar";
import { Badge } from "@heffl/ui/components/primitives/badge";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import {
  cn,
  formatName,
  generateUniqueColor,
  isMobile,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  AlarmClock,
  Calendar,
  List,
  LucideIcon,
  Mail,
  MessageCircle,
  MessageSquare,
  Phone,
  Users,
} from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { match } from "ts-pattern";
import { useImmer } from "use-immer";
import { z } from "zod";
import CircleTick from "../deals/details/components/circle-tick";
import { capitalize } from "radash";

export const activityTypes: Record<
  z.infer<typeof enums.activityTypes>,
  {
    label: string;
    icon: LucideIcon;
  }
> = {
  call: {
    label: "Call",
    icon: Phone,
  },
  email: {
    label: "Email",
    icon: Mail,
  },
  todo: {
    label: "Todo",
    icon: AlarmClock,
  },
  meeting: {
    label: "Meeting",
    icon: Users,
  },
  sms: {
    label: "SMS",
    icon: MessageSquare,
  },
  whatsapp_message: {
    label: "Whatsapp Message",
    icon: MessageCircle,
  },
};

const AcitivitiesCalendar = ({
  activities,
  onActivityClick,
  className,
}: {
  activities?: RouterOutputs["activities"]["list"];
  onActivityClick: (id: number) => void;
  className?: string;
}) => {
  return (
    <BigCalendar
      className={cn(className, "w-full")}
      events={
        activities?.activities
          ?.filter(
            (activity): activity is typeof activity & { startDate: Date } =>
              activity.startDate !== null
          )
          .map((activity) => ({
            id: activity.id.toString(),
            title: activity.title,
            start: activity.startDate,
            end: activity.endDate,
            backgroundColor: activity.completed
              ? "#d6d6d6"
              : generateUniqueColor(activity.title, 400),
            extendedProps: {
              type: activity.type,
              completed: activity.completed,
            },
          })) ?? []
      }
      renderEvent={(event) => {
        const ActivityIcon =
          activityTypes[
            event.event.extendedProps.type as keyof typeof activityTypes
          ]?.icon;
        return (
          <div
            className={cn(
              "flex items-center gap-1 px-1.5 py-0.5 rounded text-xs w-full",
              "bg-opacity-20 hover:bg-opacity-30 transition-colors",
              event.event.extendedProps.completed
                ? "striped-background"
                : undefined
            )}
            style={{
              backgroundColor: event.event.backgroundColor,
            }}
          >
            {ActivityIcon && <ActivityIcon className="w-4 h-4" />}
            <span className="font-medium truncate">{event.event.title}</span>
          </div>
        );
      }}
      onEventClick={(event) => {
        onActivityClick(Number(event.id));
      }}
    />
  );
};

const ActivityList = ({
  filters,
  activities,
  onActivityClick,
  className,
  setFilters,
}: {
  filters: RouterInputs["activities"]["list"];
  activities?: RouterOutputs["activities"]["list"];
  onActivityClick: (id: number) => void;
  className?: string;
  setFilters: (filters: RouterInputs["activities"]["list"]) => void;
}) => {
  const [summaryModalOpen, setSummaryModalOpen] = useImmer<
    | {
        open: boolean;
        activity: {
          id: number;
          type: "call" | "meeting" | "todo";
          description: string;
          durationMinutes: number;
        };
      }
    | undefined
  >(undefined);

  const updateActivityMutation = trpc.activities.update.useMutation();

  return (
    <>
      {summaryModalOpen && (
        <ActivitySummaryModal
          open={!!summaryModalOpen}
          onClose={() => setSummaryModalOpen(undefined)}
          activity={summaryModalOpen?.activity}
        />
      )}
      <DataGrid
        rowKey="id"
        rowClass={(row) => {
          if (row.completed) {
            return "line-through text-decoration-line: line-through text-gray-500";
          }
          if (dayjs(row.startDate).isBefore(dayjs(), "day")) {
            return "text-red-500";
          }
          if (dayjs(row.startDate).isSame(dayjs(), "day")) {
            return "text-green-500";
          }
          return "";
        }}
        className={cn(className)}
        name="crmActivitiesListMain"
        label="Activities"
        rows={activities?.activities || []}
        pagination={{
          pageNo: filters.pageNo || 1,
          pageSize: filters.pageSize || 50,
          count: activities?.meta.count || 0,
          setPageNo: (pageNo) =>
            setFilters({
              pageNo,
            }),
          setPageSize: (pageSize) =>
            setFilters({
              pageSize,
            }),
        }}
        columns={[
          {
            key: "done",
            name: "Done",
            width: 100,
            renderCell: ({ row: activity }) => (
              <div>
                <CircleTick
                  checked={activity.completed}
                  loading={
                    updateActivityMutation.isLoading &&
                    updateActivityMutation.variables?.id === activity.id
                  }
                  onClick={() => {
                    updateActivityMutation.mutate({
                      id: activity.id,
                      activity: {
                        completed: !activity.completed,
                      },
                    });

                    if (
                      ["call", "meeting"].includes(activity.type) &&
                      !activity.completed
                    ) {
                      setSummaryModalOpen({
                        open: true,
                        activity: {
                          id: activity.id,
                          type: activity.type as "call" | "todo" | "meeting",
                          description: activity.description || "",
                          durationMinutes: activity.durationMinutes,
                        },
                      });
                    }
                  }}
                />
              </div>
            ),
          },
          {
            key: "title",
            name: "Subject",
            width: 300,
            renderCell: ({ row }) => (
              <div
                onClick={() => onActivityClick(row.id)}
                className="truncate rounded-md cursor-pointer hover:text-primary-600"
              >
                {row.title}
              </div>
            ),
          },
          {
            key: "type",
            name: "Type",
            width: 120,
            renderCell: ({ row }) => {
              const Icon = activityTypes[row.type].icon;
              const label = activityTypes[row.type].label;
              return (
                <div className="flex gap-1 items-center">
                  <Icon className="w-3.5 h-3.5" />
                  {label}
                  {row.durationMinutes > 0
                    ? ` (${row.durationMinutes} mins)`
                    : ""}
                </div>
              );
            },
          },
          {
            key: "date",
            name: "Due Date",
            width: 120,
            renderCell: ({ row }) => (
              <div>{dayjs(row.startDate).format("DD MMM YYYY hh:mm a")}</div>
            ),
          },
          {
            key: "status",
            name: "Status",
            width: 120,
            renderCell: ({ row }) => {
              if (
                !["meeting", "call"].includes(row.type) ||
                !row.completed ||
                row.status === "TODO"
              ) {
                return "";
              }

              const statusDetails = activityStatuses.find(
                (status) => status.value === row.status
              );
              const Icon = statusDetails?.icon;

              return (
                <Badge
                  small
                  variant={statusDetails?.variant}
                  icon={Icon}
                  className="flex gap-1 items-center"
                >
                  {row.status}
                </Badge>
              );
            },
          },
          {
            key: "assignee",
            name: "Assigned To",
            width: 120,
            renderCell: ({ row }) => (
              <AvatarsGroup
                size="xs"
                users={row.activityAssignees.map((assignee) => assignee.users)}
              />
            ),
          },
          {
            key: "dueDays",
            name: "Due days",
            width: 120,
            renderCell: ({ row }) => {
              const diffDays = dayjs().diff(
                dayjs(row.startDate).subtract(1, "day"),
                "day"
              );
              return (
                <span>
                  {diffDays > 0 && !row.completed ? `${diffDays} days` : "-"}
                </span>
              );
            },
          },
          {
            key: "relatedTo",
            name: "Related To",
            width: 150,
            renderCell: ({ row }) => <ActivityLinkBadge activity={row} />,
          },

          {
            key: "createdBy",
            name: "Created By",
            width: 120,
            renderCell: ({ row }) => <div>{formatName(row.createdBy)}</div>,
          },
        ]}
      />
    </>
  );
};

const Activities = () => {
  const { view } = useParams<{ view: "calendar" | "table" }>();
  const navigate = useNavigate();

  const [activityDetailsId, setActivityDetailsId] = useState<
    number | undefined
  >();
  const [showAddActivityDrawer, setShowAddActivityDrawer] = useState(false);
  const [filters, setFilters] = useParamsState<
    RouterInputs["activities"]["list"]
  >({
    type: [],
    assignedTo: [],
    createdBy: [],
    duration: undefined,
    search: "",
    dates: undefined,
    completed: [false],
    pageNo: view === "table" ? 1 : undefined,
    pageSize: view === "table" ? 50 : undefined,
  });

  const { data: activities, isLoading: activitiesLoading } =
    trpc.activities.list.useQuery(filters);

  const { data: users } = trpc.users.list.useQuery();

  return (
    <Page title="Activities" className="!p-0" fullWidth>
      <div className="flex justify-between items-center p-2 border-b border-gray-200">
        <SearchInput
          value={filters.search || ""}
          onChange={(value) => setFilters({ search: value })}
          placeholder="Search activities"
          className="w-full"
        />
        <ResponsiveActionButton
          onClick={() => setShowAddActivityDrawer(true)}
          text="Activity"
        />
      </div>
      {!!activityDetailsId && (
        <ActivityDetailsModal
          id={activityDetailsId}
          open={!!activityDetailsId}
          onClose={() => setActivityDetailsId(undefined)}
        />
      )}
      <AddActivityModal
        open={showAddActivityDrawer}
        onClose={() => setShowAddActivityDrawer(false)}
      />

      {!isMobile() && (
        <StripeTabs
          contentClassName="pt-0"
          className="pt-2 w-full"
          tabParentClassName="pl-4"
          value={view}
          onChange={(tab) => {
            if (tab === "calendar") {
              setFilters({
                dates: [
                  dayjs().startOf("month").toDate(),
                  dayjs().endOf("month").toDate(),
                ],
              });
            }
            navigate(`/crm/activities/${tab}`);
          }}
          items={[
            { label: "Table", key: "table", icon: List },
            { label: "Calendar", key: "calendar", icon: Calendar },
          ]}
        />
      )}

      <FilterBar
        onChange={() => {
          setFilters({
            pageNo: 1,
          });
        }}
        defaultFilters={["dates", "type", "status"]}
        className="px-3 pb-1 mt-3 sm:mb-3"
        filters={[
          {
            key: "dates",
            type: "date-range",
            label: "Dates",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value as [Date, Date] }),
          },
          {
            key: "type",
            type: "checkbox",
            label: "Type",
            value: filters.type || [],
            onChange: (value) =>
              setFilters({
                type: value as z.infer<typeof enums.activityTypes>[],
              }),
            options: enumsToOptions(enums.activityTypes, (v) =>
              capitalize(v.split("_").join(" "))
            ).map((option) => ({
              ...option,
              value: option.value.toString(),
            })),
          },
          {
            key: "assignedTo",
            type: "checkbox",
            label: "Assign To",
            value: filters.assignedTo || [],
            onChange: (value) => setFilters({ assignedTo: value as number[] }),
            options: [
              { label: "Unassigned", value: 0 },
              ...(users?.map((user) => ({
                label: formatName(user),
                value: user.id,
              })) || []),
            ],
          },
          {
            key: "createdBy",
            type: "checkbox",
            label: "Created By",
            value: filters.createdBy || [],
            onChange: (value) => setFilters({ createdBy: value as number[] }),
            options: users?.map((user) => ({
              label: formatName(user),
              value: user.id,
            })),
          },
          {
            key: "duration",
            type: "number-range",
            label: "Duration",
            value: filters.duration
              ? {
                  min: filters.duration.min,
                  max: filters.duration.max,
                }
              : undefined,
            onChange: (value) =>
              setFilters({
                duration: value as {
                  min: number | undefined;
                  max: number | undefined;
                },
              }),
          },
          {
            key: "completed",
            type: "checkbox",
            label: "Completed",
            multiple: false,
            // @ts-ignore
            value: filters.completed,
            // @ts-ignore
            onChange: (value) => setFilters({ completed: value }),
            options: [
              {
                label: "Completed",
                // @ts-ignore
                value: true,
              },
              {
                label: "Not Completed",
                // @ts-ignore
                value: false,
              },
            ],
          },
        ]}
      />

      {match({ view, isMobile: isMobile(), activities, activitiesLoading })
        .with({ activities: { activities: [] } }, () => (
          <Empty
            title="No activities found"
            description="Create a new activity to get started"
            icon={Calendar}
          />
        ))
        .with({ view: "calendar", isMobile: false }, () => (
          <AcitivitiesCalendar
            activities={activities}
            onActivityClick={(id) => setActivityDetailsId(id)}
          />
        ))
        .with({ view: "table", isMobile: false }, () => (
          <ActivityList
            filters={filters}
            setFilters={setFilters}
            activities={activities}
            onActivityClick={(id) => setActivityDetailsId(id)}
            className="h-[calc(100vh-120px)] sm:h-[calc(100vh-208px)]"
          />
        ))
        .otherwise(() => (
          <div>No view</div>
        ))}
    </Page>
  );
};

export default Activities;
