import useInfoStore from "@/stores/useInfoStore";
import { Button } from "@heffl/ui/components/primitives/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@heffl/ui/components/primitives/command";
import { Input } from "@heffl/ui/components/primitives/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import { cn } from "@heffl/ui/lib/utils";
import Fuse from "fuse.js";
import { type CountryCode } from "libphonenumber-js";
import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";
import { countries } from "./countries";
import { AsYouType } from "libphonenumber-js";

export type Country = (typeof countries)[number];

interface PhoneInputProps {
  value?: string;
  defaultCountry?: CountryCode;
  onChange?: (value: string) => void;
  className?: string;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
}

const fuse = new Fuse(countries, {
  keys: ["name", "phone_code", "iso2"],
  threshold: 0.3,
  includeScore: true,
});

export function PhoneInput({
  value,
  defaultCountry = "AE",
  className,
  id,
  placeholder,
  disabled,
  onChange = () => {},
}: PhoneInputProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const topPhoneCountries = useInfoStore((state) => state.topPhoneCountries);
  const setInfoItem = useInfoStore((state) => state.setInfoItem);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [openCommand, setOpenCommand] = React.useState(false);
  const [countryCode, setCountryCode] =
    React.useState<CountryCode>(defaultCountry);

  const itemsPerPage = 30;

  const filteredCountries = React.useMemo(() => {
    if (!searchQuery) return countries;
    return fuse.search(searchQuery).map((result) => result.item);
  }, [searchQuery]);

  const paginatedCountries = React.useMemo(() => {
    const start = 0;
    const end = page * itemsPerPage;
    return filteredCountries.slice(start, end);
  }, [filteredCountries, page]);

  const selectedCountry = countries.find(
    (country) => country.iso2 === countryCode
  );

  const handlePhoneInput = (inputValue: string) => {
    const asYouType = new AsYouType(countryCode);
    // Handle case where number starts with country code but no +
    // e.g. 919496742190 for India (+91)
    if (selectedCountry && !inputValue.startsWith("+")) {
      const countryCode = selectedCountry.phone_code;
      if (inputValue.startsWith(countryCode)) {
        inputValue = `+${inputValue}`;
      }
    }
    const formattedNumber = asYouType.input(inputValue);

    console.log("setting", selectedCountry);

    if (asYouType.isValid() && !formattedNumber.startsWith("+")) {
      onChange(`+${selectedCountry?.phone_code}${formattedNumber}`);
      selectedCountry &&
        setInfoItem("topPhoneCountries", [
          {
            iso2: selectedCountry.iso2,
            count: 1,
          },
        ]);
      // for frequently used countries
    } else {
      onChange(formattedNumber);
    }
  };

  const frequentlyUsedCountries = topPhoneCountries
    .map((country) => countries.find((c) => c.iso2 === country.iso2))
    .filter(Boolean) as Country[];

  React.useEffect(() => {
    if (selectedCountry && value) {
      handlePhoneInput(value);
    }
  }, [selectedCountry]);

  return (
    <div className={cn("flex", className)}>
      <Popover
        open={openCommand}
        onOpenChange={(open) => {
          setOpenCommand(open);
          if (!open) {
            setSearchQuery("");
            setPage(1);
          }
        }}
        modal={true}
      >
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            role="combobox"
            aria-expanded={openCommand}
            className="justify-between items-center w-max h-12 whitespace-nowrap rounded-r-none border border-r-0 sm:h-9"
          >
            {selectedCountry?.name ? (
              <span className="!font-normal text-gray-600">
                +{selectedCountry.phone_code}
              </span>
            ) : (
              "Select country"
            )}
            <ChevronsUpDown className="ml-2 opacity-50 size-4 shrink-0" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0 w-[280px]" align="start" sideOffset={4}>
          <Command>
            <CommandInput
              disabled={disabled}
              placeholder="Search country..."
              value={searchQuery}
              onValueChange={(value) => {
                setSearchQuery(value);
                setPage(1);
              }}
            />
            <CommandList>
              <CommandEmpty>No country found.</CommandEmpty>
              <ScrollArea className="h-[300px]">
                {!searchQuery && frequentlyUsedCountries.length > 0 && (
                  <CommandGroup heading="Frequently Used">
                    {frequentlyUsedCountries.map((country) => (
                      <CommandItem
                        key={country.iso3}
                        value={`${country.name} (+${country.phone_code})`}
                        onSelect={() => {
                          setCountryCode(country.iso2 as CountryCode);
                          setOpenCommand(false);
                          inputRef.current?.focus();
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 size-4",
                            countryCode === country.iso2
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        <span className="relative top-0.5 mr-2 text-lg">
                          {country.emoji}
                        </span>
                        {country.name}
                        <span className="ml-1 text-gray-11">
                          (+{country.phone_code})
                        </span>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                )}
                <CommandGroup heading="All Countries">
                  {paginatedCountries.map((country) => (
                    <CommandItem
                      key={country.iso3}
                      value={`${country.name} (+${country.phone_code})`}
                      onSelect={() => {
                        setCountryCode(country.iso2 as CountryCode);
                        setOpenCommand(false);
                        inputRef.current?.focus();
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 size-4",
                          countryCode === country.iso2
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      <span className="relative top-0.5 mr-2 text-lg">
                        {country.emoji}
                      </span>
                      {country.name}
                      <span className="ml-1 text-gray-11">
                        (+{country.phone_code})
                      </span>
                    </CommandItem>
                  ))}
                </CommandGroup>
                {!searchQuery && filteredCountries.length > itemsPerPage && (
                  <div className="flex justify-center p-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setPage((p) => p + 1)}
                      disabled={page * itemsPerPage >= filteredCountries.length}
                    >
                      Load More
                    </Button>
                  </div>
                )}
              </ScrollArea>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <Input
        ref={inputRef}
        type="text"
        pattern="^[0-9]*$"
        name="phone"
        disabled={disabled}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handlePhoneInput(e.target.value)}
        className="rounded-l-none"
      />
    </div>
  );
}
