import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import useQueryParams from "@/helpers/useQuery";
import useTeam from "@/lib/hooks/useTeam";
import { calculateMultiplierTotal } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import Schemas from "@heffl/server/src/schemas";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import { Alert } from "@heffl/ui/components/primitives/alert";
import { Form } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Switch } from "@heffl/ui/components/primitives/switch";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { Plus } from "lucide-react";
import { omit } from "radash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import InvoiceForm from "./components/invoice-form";
import heffl from "@/helpers/heffl";
import { Button } from "@heffl/ui/components/primitives/button";
import { PrintPreviewModal } from "../paymetsReceived/list";

const AddInvoice = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams().all();
  const team = useTeam();

  const form = useForm<z.infer<typeof Schemas.sales.invoice>, unknown>({
    resolver: zodResolver(Schemas.sales.invoice),
    defaultValues: {
      date: new Date(),
      dueDate: dayjs()
        .add(team?.settings?.invoice?.dueDateDays ?? 30, "days")
        .toDate(),
    },
  });

  const clientId = form.watch("clientId");
  const fsScheduleId = form.watch("fsJobInvoices.fsScheduleId");
  const fsJobId = form.watch("fsJobInvoices.fsJobId");
  const [convertToBill, setConvertToBill] = useState<boolean | undefined>(
    undefined
  );
  const [showInvoiceDetails, setShowInvoiceDetails] = useState<{
    uuid: string;
    number: string;
  } | null>(null);

  const { data: convertQuotation } = trpc.quotations.details.useQuery(
    Number(queryParams.quotationId),
    {
      enabled: !!queryParams.quotationId,
    }
  );
  const { data: convertJob } = trpc.fieldService.jobs.details.useQuery(
    Number(queryParams.fsJobId),
    {
      enabled: !!queryParams.fsJobId,
    }
  );
  const { data: convertProject } = trpc.projects.details.useQuery(
    Number(queryParams.projectId),
    {
      enabled: !!queryParams.projectId,
    }
  );

  const { data: convertLease } =
    trpc.propertyManagement.leases.details.useQuery(
      Number(queryParams.pmLeaseId),
      {
        enabled: !!queryParams.pmLeaseId,
      }
    );

  const { data: convertSalesOrder } = trpc.sales.salesOrders.details.useQuery(
    Number(queryParams.salesOrderId),
    {
      enabled: !!queryParams.salesOrderId,
    }
  );

  const { data: duplicateInvoice } = trpc.invoices.details.useQuery(
    Number(queryParams.invoiceId),
    {
      enabled: !!queryParams.invoiceId,
    }
  );

  const { data: unLinkedInvoices } = trpc.invoices.unlinkedInvoices.useQuery(
    {
      clientId: Number(clientId),
    },
    {
      enabled: !!clientId && !!fsScheduleId,
    }
  );

  const invoiceUpdateMutation = trpc.invoices.update.useMutation({
    onSuccess: (updatedInvoice) => {
      toast.success("Invoice updated successfully");
      navigate(`/sales/invoices/details/${updatedInvoice.id}`);
    },
  });

  const { data: invoiceTags } = trpc.tags.list.useQuery({
    type: "INVOICE",
  });

  const invoiceDate = form.watch("date");

  useEffect(() => {
    if (team && convertToBill === undefined) {
      setConvertToBill(team.settings.invoice.defaultFields.convertToBill);
    }
  }, [team]);

  useEffect(() => {
    if (!team) return;
    if (invoiceDate) {
      let dueDateDays = 30;
      if (team && team.settings.invoice.dueDateDays !== undefined) {
        dueDateDays = team.settings.invoice.dueDateDays;
      }
      if (
        convertJob &&
        convertJob.paymentDueDays !== undefined &&
        convertJob.paymentDueDays !== null
      ) {
        dueDateDays = convertJob.paymentDueDays;
      }
      form.setValue(
        "dueDate",
        dayjs(invoiceDate).add(dueDateDays, "days").toDate()
      );
    }
  }, [invoiceDate, team]);

  const invoiceAddMutation = trpc.invoices.add.useMutation({
    onSuccess: (newInvoice) => {
      if (convertToBill) {
        navigate(`/purchases/bills/add?invoiceId=${newInvoice.id}`);
      } else {
        navigate(`/sales/invoices/details/${newInvoice.id}`);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    if (queryParams.clientId) {
      form.setValue("clientId", Number(queryParams.clientId));
    }
  }, [queryParams.clientId]);

  useEffect(() => {
    if (queryParams.fsScheduleId) {
      form.setValue(
        "fsJobInvoices.fsScheduleId",
        Number(queryParams.fsScheduleId)
      );
    }
  }, [queryParams.fsScheduleId]);

  useEffect(() => {
    if (convertProject) {
      convertProject.clients &&
        form.setValue("clientId", convertProject.clients.id);
      form.setValue("projectInvoices.projectId", convertProject.id);
      form.setValue("discount", convertProject.discount);
      form.setValue("salesPersonId", convertProject.salesPersonId);
      form.setValue(
        "invoiceProducts",
        convertProject.projectItems.map((item) => ({
          ...omit(item, ["id"]),
          buyPrice: item.buyPrice,
        }))
      );
    }
  }, [convertProject]);

  useEffect(() => {
    if (convertLease) {
      form.setValue("clientId", convertLease.clients.id);
      form.setValue("pmLeaseInvoices.pmLeaseId", convertLease.id);
      form.setValue(
        "invoiceProducts",
        convertLease.pmLeaseItems.map((item) => ({
          ...omit(item, ["id"]),
          buyPrice: 0,
          price: Number(item.price),
          tax: Number(item.tax),
        }))
      );
    }
  }, [convertLease]);

  useEffect(() => {
    if (convertSalesOrder) {
      form.setValue("clientId", convertSalesOrder.clients.id);
      form.setValue("salesOrderInvoices.salesOrderId", convertSalesOrder.id);
      form.setValue("discount", convertSalesOrder.discount);
      form.setValue("salesPersonId", convertSalesOrder.salesPersonId);
      form.setValue(
        "invoiceProducts",
        convertSalesOrder.salesOrderItems.map((item) => ({
          ...omit(item, ["id"]),
          buyPrice: item.buyPrice,
        }))
      );
    }
  }, [convertSalesOrder]);

  useEffect(() => {
    if (convertQuotation) {
      form.setValue("clientId", convertQuotation.clients.id);
      form.setValue(
        "invoiceProducts",
        convertQuotation.quotationProducts.map((product) => ({
          ...product,
          quantity:
            calculateMultiplierTotal({
              customFieldFields:
                convertQuotation.documentTemplates.customFields,
              customFieldValues: product.customFields,
            }) * product.quantity,
          buyPrice: product.products?.buyPrice || 0,
        }))
      );
      form.setValue("contactId", convertQuotation.contactId);
      form.setValue("discount", convertQuotation.discount);
    }
  }, [convertQuotation]);

  useEffect(() => {
    if (convertJob) {
      const dateString = dayjs(convertJob.startDate).isSame(
        convertJob.endDate,
        "day"
      )
        ? `on ${dayjs(convertJob.startDate).format("DD/MM/YYYY")}`
        : `on ${convertJob.fsSchedules
            .filter((schedule) => schedule.status === "COMPLETED")
            .map((schedule) => dayjs(schedule.startDate).format("DD/MM/YYYY"))
            .join(", ")}`;
      const jobDescription = `Serviced in ${
        convertJob.fsProperties.name
          ? `${convertJob.fsProperties.name}, ${convertJob.fsProperties.city}`
          : convertJob.fsProperties.city
      } ${dateString}.`;

      form.setValue("clientId", convertJob.fsProperties.clientId);
      form.setValue("fsJobInvoices.fsJobId", convertJob.id);

      if (convertJob.type === "ONEOFF" || convertJob.paymentsCount <= 1) {
        form.setValue(
          "invoiceProducts",
          convertJob.fsJobServices.map((service) => {
            return {
              ...service,
              description: `${service.description}${
                team?.settings?.invoice?.addJobDescription ? jobDescription : ""
              }`,
              buyPrice: service.products?.buyPrice || 0,
            };
          })
        );
        form.setValue("discount", convertJob.discount);
      } else if (convertJob.type === "CONTRACT") {
        const products = convertJob.fsJobServices.map((service) => {
          const divisible = service.quantity % convertJob.paymentsCount === 0;

          const quantity = divisible
            ? service.quantity / convertJob.paymentsCount
            : 1;

          const price = divisible
            ? service.price
            : service.price / convertJob.paymentsCount;

          return {
            ...service,
            quantity,
            price,
            description: `${service.description}${
              team?.settings?.invoice?.addJobDescription ? jobDescription : ""
            }`,
          };
        });
        form.setValue(
          "invoiceProducts",
          products.map((p) => ({ ...p, buyPrice: p.products?.buyPrice || 0 }))
        );
        form.setValue(
          "discount",
          convertJob.discount / convertJob.paymentsCount > 0
            ? convertJob.discount / convertJob.paymentsCount
            : convertJob.discount
        );
      }

      form.setValue("salesPersonId", convertJob.salesPersonId);
    }
  }, [convertJob]);

  useEffect(() => {
    if (duplicateInvoice) {
      form.setValue("clientId", duplicateInvoice.clients.id);
      form.setValue("invoiceProducts", duplicateInvoice.invoiceProducts);
      form.setValue("contactId", duplicateInvoice.contactId);
      form.setValue("discount", duplicateInvoice.discount);
      form.setValue("salesPersonId", duplicateInvoice.salesPersonId);
      form.setValue("templateId", duplicateInvoice.templateId);
      form.setValue("contentHtml", duplicateInvoice.contentHtml);
    }
  }, [duplicateInvoice]);

  if (!invoiceTags || !team) return <FullScreenSpinner />;

  if (
    !team.user.permissions.CREATE_INVOICES.allowed &&
    !team.user.permissions.CONVERT_INVOICES.allowed
  ) {
    return <NoPermissionScreen />;
  }

  const onSubmit = (values: z.infer<typeof Schemas.sales.invoice>) => {
    if (
      team.settings.invoice.requiredFields.salesPersonId &&
      !values.salesPersonId
    ) {
      form.setError("salesPersonId", {
        message: "Sales person is required",
      });
      return;
    }
    invoiceAddMutation.mutate(values);
  };

  return (
    <Page
      className="max-w-screen-lg"
      showBack
      title="Add invoice"
      breadcrumbs={[
        {
          label: "Invoices",
          path: "/sales/invoices",
        },
        {
          label: "Add invoice",
          path: "/sales/invoices/add",
        },
      ]}
      suffix={
        <div className="flex gap-2 items-center">
          <p>Convert to bill</p>
          <Switch
            value={convertToBill}
            onChange={(value) => setConvertToBill(value)}
            className="mr-8"
          />
          <ResponsivePrimaryButton
            onClick={() => form.handleSubmit(onSubmit)()}
            type="submit"
            variant="primary"
            loading={invoiceAddMutation.isLoading}
            icon={Plus}
          >
            Add Invoice
          </ResponsivePrimaryButton>
        </div>
      }
    >
      {unLinkedInvoices &&
        fsScheduleId &&
        unLinkedInvoices.length > 0 &&
        team.settings.invoice.showUnLinkedInvoices && (
          <Alert
            closable
            type="warning"
            className="mb-4"
            message={`This client has ${unLinkedInvoices.length} unlinked invoices:`}
            description={
              <div className="flex flex-col gap-2">
                {unLinkedInvoices.map((invoice) => (
                  <div key={invoice.id} className="flex gap-2 items-center">
                    <span
                      className="font-medium cursor-pointer hover:underline hover:text-primary-800"
                      onClick={() => {
                        navigate(`/sales/invoices/details/${invoice.id}`);
                      }}
                    >
                      #{invoice.number} -{" "}
                      {heffl.format.currency(
                        heffl.calculate.invoice(invoice).invoiceTotal,
                        "AED"
                      )}
                    </span>
                    <Button
                      variant="primary"
                      loading={invoiceUpdateMutation.isLoading}
                      size="xs"
                      iconify="tabler:link"
                      onClick={() => {
                        invoiceUpdateMutation.mutate({
                          id: invoice.id,
                          invoice: {
                            fsJobInvoices: {
                              fsJobId,
                              fsScheduleId,
                            },
                          },
                        });
                      }}
                    >
                      Link schedule
                    </Button>
                    <Button
                      variant="default"
                      size="xs"
                      iconify="tabler:eye"
                      onClick={() => {
                        setShowInvoiceDetails({
                          uuid: invoice.uuid,
                          number: invoice.number,
                        });
                      }}
                    >
                      Preview
                    </Button>
                  </div>
                ))}
              </div>
            }
          />
        )}
      {showInvoiceDetails && (
        <PrintPreviewModal
          invoiceUuid={showInvoiceDetails.uuid}
          number={showInvoiceDetails.number}
          open={!!showInvoiceDetails}
          onClose={() => setShowInvoiceDetails(null)}
        />
      )}

      <Form {...form} onSubmit={onSubmit}>
        <div className="flex justify-center px-3 py-5 w-full sm:px-0">
          <InvoiceForm form={form} tags={invoiceTags} />
        </div>
      </Form>
    </Page>
  );
};

export default AddInvoice;
