import { z } from "zod";
import * as files from "./files";

const moduleNumberSchema = z.object({
  prefix: z.string().trim(),
  startingNumber: z.coerce
    .string()
    .trim()
    .refine((val) => !isNaN(Number(val)) && Number.isInteger(Number(val)), {
      message: "Starting number must be a valid integer",
    }),
});

const defaultValues = {
  clientVendor: {
    allowDuplicateNames: false,
    allowDuplicateMobile: false,
  },
  projects: {
    taskDurationMinutes: [0, 10, 30, 60, 90, 120],
    defaultTaskApproved: true,
  },
  quotation: {
    initialTags: [],
    requiredFields: {
      dealId: false,
    },
  },
  invoice: {
    dueDateDays: 30,
    addJobDescription: false,
    showBuyPrice: false,
    requiredFields: {
      salesPersonId: false,
    },
    defaultFields: {
      convertToBill: false,
    },
  },
  salesOrder: {
    createInstantInvoiceDefault: false,
  },
  commission: {
    commissionLineItemId: null,
    createInstantBillDefault: false,
  },
  leads: {
    requiredFields: {
      mobile: false,
      crmSourceId: false,
    },
  },
  fsJobs: {
    requiredFields: {
      paymentMethodId: false,
      lpoNumber: false,
    },
    allowScheduleCompletionWithoutAssignees: false,
    allowConversionAboveQuotationValue: false,
    oneoffDefaultConfirmed: true,
    showScheduleRevenue: false,
    allowJobEditingAfterClosed: false,
    showJobTagsInSchedules: false,
    showJobTypeModalOnAdd: true,
  },
  moduleNumbers: {
    client: {
      prefix: "",
      startingNumber: "1",
    },
    quotation: {
      prefix: "",
      startingNumber: "1",
    },
    invoice: {
      prefix: "",
      startingNumber: "1",
    },
    salesOrder: {
      prefix: "",
      startingNumber: "1",
    },
    proformaInvoice: {
      prefix: "",
      startingNumber: "1",
    },
    payment: {
      prefix: "",
      startingNumber: "1",
    },
    paymentMade: {
      prefix: "",
      startingNumber: "1",
    },
    expense: {
      prefix: "",
      startingNumber: "1",
    },
    purchaseOrder: {
      prefix: "",
      startingNumber: "1",
    },
    vendor: {
      prefix: "",
      startingNumber: "1",
    },
    lead: {
      prefix: "",
      startingNumber: "1",
    },
    bill: {
      prefix: "",
      startingNumber: "1",
    },
    timesheet: {
      prefix: "",
      startingNumber: "1",
    },
    cashflowTransaction: {
      prefix: "CF-",
      startingNumber: "1",
    },
    pmLease: {
      prefix: "L-",
      startingNumber: "1",
    },
    project: {
      prefix: "P-",
      startingNumber: "1",
    },
    recurringProject: {
      prefix: "RP-",
      startingNumber: "1",
    },
  },
};

export const teamSettingsSchema = z.object({
  clientVendor: z
    .object({
      allowDuplicateNames: z.boolean().default(false),
      allowDuplicateMobile: z.boolean().default(false),
    })
    .default(defaultValues.clientVendor),
  commission: z
    .object({
      commissionLineItemId: z
        .number()
        .nullable()
        .default(defaultValues.commission.commissionLineItemId),
      createInstantBillDefault: z
        .boolean()
        .default(defaultValues.commission.createInstantBillDefault),
    })
    .default(defaultValues.commission),
  quotation: z
    .object({
      initialTags: z.array(z.coerce.number()).default([]),
      requiredFields: z
        .object({
          dealId: z.boolean(),
        })
        .default(defaultValues.quotation.requiredFields),
    })
    .default(defaultValues.quotation),
  projects: z
    .object({
      taskDurationMinutes: z
        .array(z.coerce.number())
        .default(defaultValues.projects.taskDurationMinutes),
      defaultTaskApproved: z
        .boolean()
        .default(defaultValues.projects.defaultTaskApproved),
    })
    .default(defaultValues.projects),
  invoice: z
    .object({
      dueDateDays: z.coerce.number().default(defaultValues.invoice.dueDateDays),
      addJobDescription: z
        .boolean()
        .default(defaultValues.invoice.addJobDescription),
      showBuyPrice: z.boolean().default(defaultValues.invoice.showBuyPrice),
      requiredFields: z
        .object({
          salesPersonId: z.boolean(),
        })
        .default(defaultValues.invoice.requiredFields),
      defaultFields: z
        .object({
          convertToBill: z.boolean(),
        })
        .default(defaultValues.invoice.defaultFields),
      showUnLinkedInvoices: z.boolean().default(false),
    })
    .default(defaultValues.invoice),
  salesOrder: z
    .object({
      createInstantInvoiceDefault: z
        .boolean()
        .default(defaultValues.salesOrder.createInstantInvoiceDefault),
    })
    .default(defaultValues.salesOrder),
  leads: z
    .object({
      requiredFields: z
        .object({
          mobile: z.boolean(),
          crmSourceId: z.boolean(),
        })
        .default(defaultValues.leads.requiredFields),
    })
    .default(defaultValues.leads),
  fsJobs: z
    .object({
      requiredFields: z
        .object({
          paymentMethodId: z.boolean(),
          lpoNumber: z.boolean(),
        })
        .default(defaultValues.fsJobs.requiredFields),
      showJobTagsInSchedules: z
        .boolean()
        .default(defaultValues.fsJobs.showJobTagsInSchedules),
      allowScheduleCompletionWithoutAssignees: z
        .boolean()
        .default(defaultValues.fsJobs.allowScheduleCompletionWithoutAssignees),
      allowConversionAboveQuotationValue: z
        .boolean()
        .default(defaultValues.fsJobs.allowConversionAboveQuotationValue),
      oneoffDefaultConfirmed: z
        .boolean()
        .default(defaultValues.fsJobs.oneoffDefaultConfirmed),
      showScheduleRevenue: z
        .boolean()
        .default(defaultValues.fsJobs.showScheduleRevenue),
      allowJobEditingAfterClosed: z
        .boolean()
        .default(defaultValues.fsJobs.allowJobEditingAfterClosed),
      showJobTypeModalOnAdd: z
        .boolean()
        .default(defaultValues.fsJobs.showJobTypeModalOnAdd),
    })
    .default(defaultValues.fsJobs),
  moduleNumbers: z
    .object({
      quotation: moduleNumberSchema.default(
        defaultValues.moduleNumbers.quotation
      ),
      invoice: moduleNumberSchema.default(defaultValues.moduleNumbers.invoice),
      proformaInvoice: moduleNumberSchema.default(
        defaultValues.moduleNumbers.proformaInvoice
      ),
      payment: moduleNumberSchema.default(defaultValues.moduleNumbers.payment),
      paymentMade: moduleNumberSchema.default(
        defaultValues.moduleNumbers.paymentMade
      ),
      expense: moduleNumberSchema.default(defaultValues.moduleNumbers.expense),
      purchaseOrder: moduleNumberSchema.default(
        defaultValues.moduleNumbers.purchaseOrder
      ),
      vendor: moduleNumberSchema.default(defaultValues.moduleNumbers.vendor),
      lead: moduleNumberSchema.default(defaultValues.moduleNumbers.lead),
      bill: moduleNumberSchema.default(defaultValues.moduleNumbers.bill),
      client: moduleNumberSchema.default(defaultValues.moduleNumbers.client),
      salesOrder: moduleNumberSchema.default(
        defaultValues.moduleNumbers.salesOrder
      ),
      timesheet: moduleNumberSchema.default(
        defaultValues.moduleNumbers.timesheet
      ),
      cashflowTransaction: moduleNumberSchema.default(
        defaultValues.moduleNumbers.cashflowTransaction
      ),
      pmLease: moduleNumberSchema.default(defaultValues.moduleNumbers.pmLease),
      project: moduleNumberSchema.default(defaultValues.moduleNumbers.project),
      recurringProject: moduleNumberSchema.default(
        defaultValues.moduleNumbers.recurringProject
      ),
    })
    .default(defaultValues.moduleNumbers),
});

export const team = z.object({
  name: z.string(),
  address: z.string().nullish(),
  files: z
    .object({
      team_logo: files.filesInput,
    })
    .optional(),
  isTaxRegistered: z.boolean().default(true),
  booksMigrationDate: z.date().nullish(),
});

export type TeamSettings = z.infer<typeof teamSettingsSchema>;
