import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  return {
    all: () => Object.fromEntries(searchParams.entries()),
    get: (key: string) => searchParams.get(key),
  };
};

export default useQueryParams;
