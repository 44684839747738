import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import SimpleTable from "@heffl/ui/components/simple-table";
import { generateUniqueColor } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Database, Grid2X2, Plus } from "lucide-react";
import { useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import { z } from "zod";
import { convertToPlural } from "../projects/pipelines/add";

const CustomObjectForm = ({
  form,
  edit = false,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.customizations.customObject>>;
  edit?: boolean;
}) => {
  const name = form.watch("name");
  const singularLabel = form.watch("singularLabel");

  useEffect(() => {
    if (singularLabel && !edit) {
      form.setValue("pluralLabel", convertToPlural(singularLabel));
      form.setValue(
        "name",
        slugify(singularLabel || "", {
          lower: true,
          replacement: "_",
          trim: false,
          strict: true,
        })
      );
    }
  }, [singularLabel, form, edit]);

  useEffect(() => {
    if (name) {
      form.setValue(
        "name",
        slugify(name, {
          lower: true,
          replacement: "_",
          trim: false,
          strict: true,
        })
      );
    }
  }, [name, form]);

  return (
    <>
      <div className="grid grid-cols-2 gap-2">
        <FormField name="singularLabel" label="Singular Label">
          <Input placeholder="e.g. Project" />
        </FormField>
        <FormField name="pluralLabel" label="Plural Label">
          <Input placeholder="e.g. Projects" />
        </FormField>
      </div>
      <FormField name="name" label="Name">
        <Input placeholder="e.g. project" />
      </FormField>
    </>
  );
};

const AddCustomObjectModal = NiceModal.create(() => {
  const modal = useModal();

  const form = useForm<z.infer<typeof Schemas.customizations.customObject>>({
    resolver: zodResolver(Schemas.customizations.customObject),
  });

  const addCustomObject = trpc.customizations.objects.custom.add.useMutation({
    onSuccess: () => {
      toast.success("Custom object added successfully");
      form.reset();
      modal.hide();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (
    data: z.infer<typeof Schemas.customizations.customObject>
  ) => {
    addCustomObject.mutate(data);
  };

  return (
    <ModalDrawer
      title="Add Custom Object"
      open={modal.visible}
      onClose={() => modal.hide()}
      footer={
        <Button
          className="w-full"
          type="submit"
          variant="primary"
          loading={addCustomObject.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
        >
          Add Custom Object
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <CustomObjectForm form={form} edit={false} />
      </Form>
    </ModalDrawer>
  );
});

const ObjectsList: React.FC = () => {
  const navigate = useNavigate();

  const { data: objects } = trpc.customizations.objects.list.useQuery();

  return (
    <Page title="Custom Objects" loading={!objects} className="max-w-5xl">
      <div className="flex justify-end">
        <Button
          onClick={() => {
            NiceModal.show(AddCustomObjectModal, {});
          }}
          variant="primary"
          icon={Plus}
        >
          Custom Object
        </Button>
      </div>
      <SimpleTable
        borderless
        fixedHeader={false}
        idKey="name"
        className="mt-4"
        columns={[
          {
            label: "Object",
            key: "singularLabel",
            icon: Box,
            render: (row) => (
              <div className="flex gap-2 items-center">
                <Box
                  className="p-0.5 w-4 h-4 text-white rounded-md"
                  style={{
                    background: generateUniqueColor(row.singularLabel, 500),
                  }}
                />
                {row.singularLabel}
              </div>
            ),
          },
          {
            label: "Type",
            render: (row) => (row.type === "custom" ? "Custom" : "System"),
            icon: Grid2X2,
            className: "text-gray-600",
          },
          {
            label: "Records",
            render: (row) => row.recordsCount,
            icon: Database,
          },
        ]}
        rows={objects ?? []}
        onRowClick={(row) => {
          navigate(`details/${row.name}`);
        }}
      />
    </Page>
  );
};

export default ObjectsList;
