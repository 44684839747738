import InfoItemsHZ from "@/components/InfoItemHZ";
import { reminderDurations } from "@/components/actionsModals/activities-modals";
import SelectableDateicker from "@/components/selectable-date-picker";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import ActivityTimelineNew from "@/pages/crm/components/acitivity-timeline-new";
import CircleTick from "@/pages/crm/deals/details/components/circle-tick";
import FrequencySelector from "@/pages/field-service/jobs/components/job-frequency-selector";
import { defaultFilesInput } from "@/pages/purchases/expenses/list";
//import useInfoStore from "@/stores/useInfoStore";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Schemas from "@heffl/server/src/schemas";
import DropMenu from "@heffl/ui/components/DropMenu";
import FilePicker from "@heffl/ui/components/file-picker";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@heffl/ui/components/primitives/accordion";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { DateTimePicker } from "@heffl/ui/components/primitives/datetime-picker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FormList from "@heffl/ui/components/primitives/form-list";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { Label } from "@heffl/ui/components/primitives/label";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import { ProgressLabel } from "@heffl/ui/components/primitives/progress-label";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import Select from "@heffl/ui/components/primitives/select";
import RenderHtml from "@heffl/ui/components/render-html";
import RightClickMenu from "@heffl/ui/components/right-click-menu";
import SimpleTable from "@heffl/ui/components/simple-table";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import {
  calculateCompleted,
  cn,
  isMobile,
  makeEllipsis,
} from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import axios from "axios";
import dayjs from "dayjs";
import { debounce } from "lodash";
import {
  AlarmClock,
  Clock,
  Pencil,
  Plus,
  Tag,
  ThumbsUp,
  Trash,
  Trash2,
  UserCircle,
} from "lucide-react";
import { map } from "radash";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { rrulestr } from "rrule";
import { z } from "zod";
import {
  FileCardNew,
  InstantFileUploadCard,
} from "../../details/components/Files";
import { taskPriorities, taskStatuses } from "../../details/components/kanban";
import { EditProjectTaskProfileModal } from "../../recurringProjects/components/project-task-profile-modals";
import {
  AddTimesheetDrawer,
  TimesheetDetailsModal,
} from "../../timesheets/list";
import useUpdateTask from "@/lib/hooks/useUpdateTask";

const ProjectTaskForm = ({
  form,
  edit,
  showProject = true,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.project.projectTask>, unknown>;
  edit: boolean;
  showProject: boolean;
}) => {
  const [animateParent] = useAutoAnimate();

  const [subtaskEditIndex, setSubtaskEditIndex] = useState<number | null>(null);

  const dueDate = form.watch("date");
  const subTasks = form.watch("subTasks") || [];
  const projectId = form.watch("projectId");

  const [projectSearch, setProjectSearch] = useState("");

  const { data: users } = trpc.users.list.useQuery();
  const { data: projectAssignees } = trpc.projects.details.useQuery(projectId);
  const { data: projects } = trpc.projects.list.useQuery(
    {
      search: projectSearch,
      pageSize: 50,
      include: projectId ? [projectId] : undefined,
      statuses: ["OPEN"],
    },
    { keepPreviousData: true }
  );
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT_TASK",
  });

  return (
    <div className="flex flex-col gap-1">
      <FormField name="title">
        <Input placeholder="Task title" autoFocus />
      </FormField>
      <FormField name="description">
        <MiniRichTextEditor
          placeholder="Describe the task, note down points etc...."
          height={90}
        />
      </FormField>
      {showProject && (
        <FormField name="projectId" label="Project">
          <Select
            placeholder="Select project"
            options={projects?.projects.map((project) => ({
              label: project.title,
              value: project.id,
              client: project.clients?.name,
              number: project.number,
            }))}
            onSearch={setProjectSearch}
            onSelect={(option) => {
              form.setValue(
                "subTasks",
                subTasks.map((subTask) => ({
                  ...subTask,
                  projectId: option.value,
                }))
              );
            }}
            render={(option) => (
              <div className="flex flex-col">
                <span className="text-sm font-medium">
                  {option.number} - {option.label}
                </span>
                {option.client && (
                  <div className="flex gap-1 items-center text-xs text-gray-500">
                    <Icon icon="tabler:building" className="w-3.5 h-3.5" />
                    <span>{option.client}</span>
                  </div>
                )}
              </div>
            )}
          />
        </FormField>
      )}
      <FormField name="files">
        <FilePicker label="Upload file" allowMultiple className="w-full" />
      </FormField>

      <div className="flex flex-wrap gap-x-2 pl-2">
        <FormField name="projectTaskAssignees" className="w-fit">
          <MultiSelect
            iconify="tabler:user-square-rounded"
            previewType="linear"
            showSelected
            popoverClassName="w-fit"
            className="border"
            placeholder="Assignees"
            options={projectAssignees?.projectAssignees?.map((user) => ({
              value: user.users.id,
              label: user.users.firstName,
              icon: UserCircle,
            }))}
          />
        </FormField>
        <FormField name="date" className="pr-1 w-fit">
          <DateTimePicker />
        </FormField>
        <FormField name="priority" className="w-fit">
          <Select
            iconify="tabler:flag"
            placeholder="Priority"
            previewType="linear"
            className="!border w-fit"
            popoverClassName="w-fit"
            options={taskPriorities.map((priority) => ({
              label: priority.label,
              value: priority.value,
              color: priority.hex,
              iconify: priority.icon,
              hotkey: priority.hotkey,
              iconClassName: priority.iconClassName,
            }))}
          />
        </FormField>
        <FormField name="reminders" className="w-fit">
          <SelectableDateicker
            placeholder="Reminder"
            previewType="linear"
            className="!border"
            icon={AlarmClock}
            ogDate={dueDate || null}
            options={reminderDurations}
          />
        </FormField>
        <FormField name="projectTaskTags" className="w-fit">
          <MultiSelect
            iconify="tabler:tag"
            previewType="linear"
            className="border"
            popoverClassName="w-fit"
            showSelected
            placeholder="Tags"
            options={tags?.map((tag) => ({
              label: tag.name,
              value: tag.id,
              color: tag.color,
            }))}
          />
        </FormField>
        {!edit && (
          <FormField name="taskProfile.recurringRule" className="w-fit">
            <FrequencySelector
              iconify="tabler:repeat"
              previewType="linear"
              className="border"
              placeholder="Recurring"
              allowClear={true}
            />
          </FormField>
        )}
      </div>

      {!edit && (
        <div className="mt-1 space-y-2 w-full" ref={animateParent}>
          {!!subTasks.length && <Label className="">Sub tasks</Label>}
          <FormList name="subTasks">
            {({ fields, append, remove, _name }) => {
              return (
                <div className="flex flex-col gap-2 w-full">
                  {subtaskEditIndex === null && (
                    <>
                      {fields.map((field, index) => {
                        const subtask = subTasks[index] as z.infer<
                          typeof Schemas.project.projectTask
                        >;

                        if (!subtask) return null;

                        return (
                          <div
                            onClick={() => {
                              setSubtaskEditIndex(index);
                            }}
                            key={field.id}
                            className="flex justify-between p-2 w-full rounded-md border cursor-pointer"
                          >
                            <div className="flex gap-2 items-center">
                              <Icon icon="tabler:subtask" />
                              {subtask.title}
                            </div>
                            <div className="flex gap-3">
                              {subtask.date && (
                                <div className="flex gap-2 items-center">
                                  <Icon icon="tabler:calendar" />
                                  {heffl.format.date(subtask.date)}
                                </div>
                              )}
                              {subtask.projectTaskAssignees &&
                                subtask.projectTaskAssignees.length > 0 && (
                                  <div className="flex gap-1 items-center">
                                    <Icon icon="tabler:user-circle" />
                                    {subtask.projectTaskAssignees
                                      .map(
                                        (assignee) =>
                                          users?.find(
                                            (user) => user.id === assignee
                                          )?.firstName
                                      )
                                      .join(", ")}
                                  </div>
                                )}
                              {subtask.priority && (
                                <div className="flex gap-2 items-center">
                                  <Icon
                                    icon={
                                      taskPriorities.find(
                                        (p) => p.value === subtask.priority
                                      )?.icon || ""
                                    }
                                    className={cn(
                                      taskPriorities.find(
                                        (p) => p.value === subtask.priority
                                      )?.iconClassName
                                    )}
                                  />
                                  {
                                    taskPriorities.find(
                                      (p) => p.value === subtask.priority
                                    )?.label
                                  }
                                </div>
                              )}
                              <Button
                                size="sm"
                                iconClassName="text-red-500"
                                variant="ghost"
                                iconify="tabler:trash"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  remove(index);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <Button
                        size="sm"
                        className="w-fit"
                        variant="ghost"
                        iconify="tabler:plus"
                        onClick={() => {
                          append({
                            projectId,
                            priority: "LOW",
                          });
                          setSubtaskEditIndex(fields.length);
                        }}
                      >
                        Add subtask
                      </Button>
                    </>
                  )}
                  <div
                    className={cn(
                      subtaskEditIndex
                        ? "motion-preset-expand"
                        : "motion-preset-expand"
                    )}
                  >
                    {subtaskEditIndex !== null && (
                      <div className="flex flex-col p-2 bg-gray-100 rounded-md">
                        <FormField name={_name(subtaskEditIndex, "title")}>
                          <Input placeholder="Task title" autoFocus />
                        </FormField>
                        <FormField
                          name={_name(subtaskEditIndex, "description")}
                        >
                          <MiniRichTextEditor
                            placeholder="Describe the task, note down points etc...."
                            height={90}
                          />
                        </FormField>

                        <div className="flex flex-wrap gap-x-2">
                          <FormField
                            name={_name(
                              subtaskEditIndex,
                              "projectTaskAssignees"
                            )}
                            className="w-fit"
                          >
                            <MultiSelect
                              iconify="tabler:user-square-rounded"
                              previewType="linear"
                              showSelected
                              popoverClassName="w-fit"
                              className="border"
                              placeholder="Assignees"
                              options={users?.map((user) => ({
                                value: user.id,
                                label: user.firstName,
                                icon: UserCircle,
                              }))}
                            />
                          </FormField>
                          <FormField
                            name={_name(subtaskEditIndex, "date")}
                            className="pr-1 w-fit"
                          >
                            <DateTimePicker />
                          </FormField>
                          <FormField
                            name={_name(subtaskEditIndex, "priority")}
                            className="w-fit"
                          >
                            <Select
                              iconify="tabler:flag"
                              placeholder="Priority"
                              previewType="linear"
                              className="!border w-fit"
                              popoverClassName="w-fit"
                              options={taskPriorities.map((priority) => ({
                                label: priority.label,
                                value: priority.value,
                                color: priority.hex,
                                iconify: priority.icon,
                                hotkey: priority.hotkey,
                                iconClassName: priority.iconClassName,
                              }))}
                            />
                          </FormField>
                          <FormField
                            name={_name(subtaskEditIndex, "reminders")}
                            className="w-fit"
                          >
                            <SelectableDateicker
                              placeholder="Reminder"
                              previewType="linear"
                              className="!border"
                              icon={AlarmClock}
                              ogDate={dueDate || null}
                              options={reminderDurations}
                            />
                          </FormField>
                          <FormField
                            name={_name(subtaskEditIndex, "projectTaskTags")}
                            className="w-fit"
                          >
                            <MultiSelect
                              iconify="tabler:tag"
                              previewType="linear"
                              className="border"
                              popoverClassName="w-fit"
                              showSelected
                              placeholder="Tags"
                              options={tags?.map((tag) => ({
                                label: tag.name,
                                value: tag.id,
                                color: tag.color,
                              }))}
                            />
                          </FormField>
                          {!edit && (
                            <FormField
                              name={_name(
                                subtaskEditIndex,
                                "taskProfile.recurringRule"
                              )}
                              className="w-fit"
                            >
                              <FrequencySelector
                                iconify="tabler:repeat"
                                previewType="linear"
                                className="border"
                                placeholder="Recurring"
                                allowClear={true}
                              />
                            </FormField>
                          )}
                        </div>
                        <div className="flex gap-2 justify-end">
                          <Button
                            size="sm"
                            iconify="tabler:trash"
                            variant="destructiveOutline"
                            onClick={() => {
                              setSubtaskEditIndex(null);
                              remove(subtaskEditIndex);
                            }}
                          >
                            Delete
                          </Button>
                          <Button
                            size="sm"
                            variant="primary"
                            iconify="tabler:plus"
                            onClick={() => {
                              const t = subTasks[subtaskEditIndex];
                              if (!t || !t?.title)
                                return heffl.toast.error(
                                  "Please add title to subtask"
                                );
                              setSubtaskEditIndex(null);
                            }}
                          >
                            Save subtask
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          </FormList>
        </div>
      )}
    </div>
  );
};

export const AddProjectTaskModal = ({
  open,
  onClose,
  defaultValues,
  projectId,
  showProject = false,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<z.infer<typeof Schemas.project.projectTask>>;
  projectId?: number;
  showProject?: boolean;
}) => {
  const form = useForm<z.infer<typeof Schemas.project.projectTask>>({
    resolver: zodResolver(Schemas.project.projectTask),
    defaultValues: {
      title: "",
      priority: "MEDIUM",
      date: dayjs().add(2, "days").set("h", 8).set("m", 0).toDate(),
      ...defaultValues,
      projectId,
      subTasks: [],
    },
  });

  const [loading, setLoading] = useState(false);

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation({
      onSuccess: (data) => {
        console.log("data", data);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const onCloseModal = () => {
    onClose();
    form.reset();
  };

  const addTaskMutation = trpc.projects.tasks.add.useMutation({
    onSuccess() {
      toast.success("Succesfully added task.");
      onCloseModal();
      form.reset();
    },
  });

  const onSubmit = async (
    values: z.infer<typeof Schemas.project.projectTask>
  ) => {
    setLoading(true);
    const presignedUrls = values?.files?.new.length
      ? await getPresignedUrls({
          files: values?.files?.new,
          section: "projects/tasks",
        })
      : [];
    addTaskMutation.mutate({
      ...values,
      files: {
        new: presignedUrls,
        deleted: [],
      },
    });
    await map(presignedUrls, async (file, index) => {
      const fileToUpload = values?.files?.new[index].file;
      await axios.put(file.presignedUrl, fileToUpload, {
        headers: {
          "Content-Type": file.format,
        },
      });
    });
    setLoading(false);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onCloseModal}
      title="Add task"
      modalClassName="max-w-2xl"
      footer={
        <Button
          loading={loading || addTaskMutation.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
          variant="primary"
          className="px-2"
          size="sm"
          iconify="tabler:plus"
        >
          Create task
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ProjectTaskForm edit={false} form={form} showProject={showProject} />
      </Form>
    </ModalDrawer>
  );
};

export const EditProjectTaskModal = ({
  open,
  onClose,
  id,
}: {
  open: boolean;
  onClose: ({ deleted }: { deleted: boolean }) => void;
  id: number;
}) => {
  const confirm = useConfirm();
  const form = useForm<z.infer<typeof Schemas.project.projectTask>>({
    resolver: zodResolver(Schemas.project.projectTask),
  });

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation({
      onSuccess: (data) => {
        console.log("data", data);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const { data: taskDetails } = trpc.projects.tasks.details.useQuery(id);
  const taskDeleteMutation = trpc.projects.tasks.delete.useMutation({
    onSuccess() {
      toast.success("Succesfully deleted task.");
      onClose({ deleted: true });
      form.reset();
    },
  });

  useEffect(() => {
    if (taskDetails) {
      form.reset({
        ...taskDetails,
        projectTaskTags: taskDetails?.projectTaskTags.map((tag) => tag.tags.id),
        reminders: taskDetails?.reminders.length
          ? {
              date: taskDetails.reminders[0].date,
              dateType: taskDetails.reminders[0].dateType,
            }
          : undefined,
        projectTaskAssignees:
          taskDetails?.projectTaskAssignees.map((user) => user.userId) || [],
        files: defaultFilesInput(
          taskDetails?.files?.files?.length ? taskDetails?.files?.files : []
        ),
        subTasks: undefined,
      });
    }
  }, [taskDetails, form]);

  const onCloseModal = () => {
    onClose({ deleted: false });
    form.reset();
  };

  const taskUpdateMutation = useUpdateTask({
    onSuccess() {
      toast.success("Succesfully updated task.");
      onCloseModal();
      form.reset();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const onSubmit = async (
    values: z.infer<typeof Schemas.project.projectTask>
  ) => {
    const presignedUrls = values?.files?.new.length
      ? await getPresignedUrls({
          files: values?.files?.new,
          section: "projects/tasks",
        })
      : [];

    taskUpdateMutation.mutate({
      id: id,
      task: {
        ...values,
        files: {
          deleted: [],
          ...values?.files,
          new: presignedUrls,
        },
      },
    });
    await map(presignedUrls, async (file, index) => {
      const fileToUpload = values?.files?.new[index].file;
      await axios.put(file.presignedUrl, fileToUpload, {
        headers: {
          "Content-Type": file.format,
        },
      });
    });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onCloseModal}
      title="Edit task"
      modalClassName="max-w-2xl"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            size="md"
            loading={taskDeleteMutation.isLoading}
            icon={Trash2}
            variant="destructiveOutline"
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this task?",
              });
              if (confirmed) {
                taskDeleteMutation.mutate(id);
              }
            }}
          />
          <Button
            className="w-full"
            size="md"
            loading={taskUpdateMutation.isLoading}
            variant="primary"
            onClick={() => form.handleSubmit(onSubmit)()}
          >
            Update task
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        {taskDetails ? (
          <ProjectTaskForm edit={true} form={form} showProject={false} />
        ) : (
          <FullScreenSpinner />
        )}
      </Form>
    </ModalDrawer>
  );
};

const TaskInputForm = () => {
  const { data: users } = trpc.users.list.useQuery();

  return (
    <>
      <FormField name="title">
        <Input placeholder="Task title" autoFocus />
      </FormField>
      <FormField name="description">
        <MiniRichTextEditor placeholder="Description" />
      </FormField>
      <div className="flex gap-2">
        <FormField name="date" className="w-fit">
          <DatePicker previewType="linear" />
        </FormField>

        <FormField name="projectTaskAssignees" className="w-fit">
          <MultiSelect
            iconify="tabler:user-square-rounded"
            previewType="linear"
            showSelected
            popoverClassName="w-fit"
            placeholder="Add assignees"
            options={users?.map((user) => ({
              value: user.id,
              label: user.firstName,
              icon: UserCircle,
            }))}
          />
        </FormField>
        <FormField name="priority" className="w-fit">
          <Select
            iconify="tabler:flag"
            placeholder="Priority"
            previewType="linear"
            popoverClassName="w-fit"
            options={taskPriorities.map((priority) => ({
              label: priority.label,
              value: priority.value,
              color: priority.hex,
              iconify: priority.icon,
              hotkey: priority.hotkey,
              iconClassName: priority.iconClassName,
            }))}
          />
        </FormField>
      </div>
    </>
  );
};

export const ProjectTaskDetailsModal = ({
  open,
  onClose: onCloseRaw,
  taskId,
  switchToTask,
}: {
  open: boolean;
  onClose: () => void;
  taskId: number;
  switchToTask: (taskId: number) => void;
}) => {
  const navigate = useNavigate();

  // const setInfoStore = useInfoStore((state) => state.setInfoItem);

  const [animateParent] = useAutoAnimate();

  const trpcUtils = trpc.useUtils();

  const [showEditTaskModal, setShowEditTaskModal] = useState<
    number | undefined
  >(undefined);
  const [addTimesheet, setAddTimesheet] = useState(false);
  const [editTimesheet, setEditTimesheet] = useState<number | undefined>(
    undefined
  );
  const [showAddSubTask, setShowAddSubTask] = useState(false);
  const [accordion, setAccordion] = useState<string[]>(["subtasks"]);
  const [showEditTaskProfileModal, setShowEditTaskProfileModal] = useState<
    number | undefined
  >(undefined);

  const { data: task } = trpc.projects.tasks.details.useQuery(taskId);
  const { data: users } = trpc.users.list.useQuery();
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT_TASK",
  });
  const { data: projectDetails } = trpc.projects.details.useQuery(
    task?.projectId || 0,
    {
      enabled: !!task?.projectId,
    }
  );

  const subTaskForm = useForm<z.infer<typeof Schemas.project.projectTask>>({
    resolver: zodResolver(Schemas.project.projectTask),
    defaultValues: {
      priority: "MEDIUM",
      projectId: task?.projectId || 0,
      parentTaskId: taskId,
    },
  });

  const subTaskUpdateMutation = trpc.projects.tasks.updateSubTask.useMutation();

  const addTaskMutation = trpc.projects.tasks.add.useMutation({
    onSuccess() {
      trpcUtils.projects.tasks.details.invalidate(taskId);
      subTaskForm.reset();
      setShowAddSubTask(false);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const realtimeTaskUpdateMutation = useUpdateTask({
    onSuccess() {
      return { invalidate: false };
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const taskUpdateMutation = useUpdateTask({
    onError(error) {
      toast.error(error.message);
    },
  });

  const taskDeleteMutation = trpc.projects.tasks.delete.useMutation({
    onSuccess() {
      toast.success("Succesfully deleted task.");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const debouncedTaskUpdateMutation = debounce(
    realtimeTaskUpdateMutation.mutate,
    500
  );

  const taskReminder = task?.reminders.length ? task.reminders[0] : undefined;

  const onClose = () => {
    onCloseRaw();
    trpcUtils.projects.tasks.details.invalidate(taskId);
    trpcUtils.projects.tasks.list.invalidate();
  };

  const taskDetails = task ? (
    <InfoItemsHZ
      className="!w-full mt-3"
      labelClassName="text-gray-500 font-normal mb-1"
      items={[
        {
          label: "Status",
          value: (
            <Select
              previewType="linear"
              value={task.status}
              popoverSide="left"
              popoverClassName="w-fit"
              onChange={(value) => {
                taskUpdateMutation.mutate({
                  id: taskId,
                  task: { status: value },
                });
              }}
              options={taskStatuses.map((status) => ({
                label: status.label,
                value: status.value,
                iconify: status.icon,
                hotkey: status.hotkey,
                iconClassName: status.iconClassName,
              }))}
            />
          ),
        },
        {
          hidden: task.status !== "COMPLETED",
          label: "Completed On",
          value:
            task.completedOn && task.status === "COMPLETED"
              ? heffl.format.dynamicDate(task.completedOn, true)
              : "",
        },
        {
          hidden: task.status !== "CANCELLED",
          label: "Cancel Reason",
          value: task.cancelReason || "No reason provided",
        },
        {
          label: "Assigned",
          value: (
            <MultiSelect
              icon={UserCircle}
              previewType="linear"
              showSelected
              popoverClassName="w-fit"
              popoverSide="left"
              placeholder="Add assignees"
              value={task.projectTaskAssignees.map((user) => user.userId)}
              options={users?.map((user) => ({
                value: user.id,
                label: user.firstName,
                icon: UserCircle,
              }))}
              onChange={(value) => {
                taskUpdateMutation.mutate({
                  id: taskId,
                  task: {
                    projectTaskAssignees: value,
                  },
                });
              }}
            />
          ),
        },
        {
          label: "Date",
          value: (
            <DatePicker
              previewType="linear"
              popoverSide="left"
              value={task.date || undefined}
              iconify={
                task.date
                  ? heffl.date.isDue(task.date) && task.status !== "COMPLETED"
                    ? "tabler:calendar-x"
                    : "tabler:calendar-event-filled"
                  : undefined
              }
              iconClassName={
                task.date && task.status !== "COMPLETED"
                  ? heffl.date.isDue(task.date)
                    ? "text-red-500"
                    : heffl.date.isThisWeek(task.date)
                    ? heffl.date.isToday(task.date)
                      ? "text-green-500"
                      : "text-orange-500"
                    : ""
                  : "text-gray-500"
              }
              onChange={(value) => {
                taskUpdateMutation.mutate({
                  id: taskId,
                  task: { date: value },
                });
              }}
            />
          ),
        },
        {
          label: "Priority",
          value: (
            <Select
              previewType="linear"
              value={task.priority}
              popoverSide="left"
              popoverClassName="w-fit"
              onChange={(value) => {
                taskUpdateMutation.mutate({
                  id: taskId,
                  task: { priority: value },
                });
              }}
              options={taskPriorities.map((priority) => ({
                label: priority.label,
                value: priority.value,
                color: priority.hex,
                iconify: priority.icon,
                hotkey: priority.hotkey,
                iconClassName: priority.iconClassName,
              }))}
            />
          ),
        },
        {
          label: "Tags",
          value: (
            <MultiSelect
              iconify="tabler:tag"
              previewType="linear"
              showSelected
              popoverClassName="w-fit"
              popoverSide="left"
              placeholder="Set tags"
              optionType="tag"
              value={task.projectTaskTags.map((t) => t.tagId)}
              options={tags?.map((t) => ({
                value: t.id,
                label: t.name,
                icon: Tag,
                color: t.color,
              }))}
              onChange={(value) => {
                taskUpdateMutation.mutate({
                  id: taskId,
                  task: {
                    projectTaskTags: value,
                  },
                });
              }}
            />
          ),
        },
        {
          label: "Reminders",
          value: (
            <SelectableDateicker
              previewType="linear"
              popoverSide="left"
              renderClassName="text-purple-500"
              placeholder="Set reminder"
              iconify="tabler:alarm"
              ogDate={task.date || null}
              previewLabel={
                taskReminder?.dateType === "CUSTOM_DATE"
                  ? heffl.format.dynamicDate(taskReminder?.date, true)
                  : undefined
              }
              value={taskReminder as never}
              options={reminderDurations}
              onChange={(value) => {
                console.log("value", value);
                value &&
                  task &&
                  taskUpdateMutation.mutate({
                    id: task.id,
                    task: {
                      reminders: {
                        date: value.date,
                        dateType: value.dateType,
                      },
                    },
                  });
              }}
            />
          ),
        },
        {
          hidden: !task.projectTaskProfiles,
          label: "Recurring",
          value: (
            <div className="flex gap-2 items-center">
              <Icon icon="tabler:repeat" className="w-4 h-4" />
              <p className="capitalize">
                {task.projectTaskProfiles?.recurringRule
                  ? rrulestr(task.projectTaskProfiles?.recurringRule).toText()
                  : ""}
              </p>
              <Button
                iconify="tabler:pencil"
                variant="outline"
                size="xs"
                onClick={() => {
                  task.projectTaskProfileId &&
                    setShowEditTaskProfileModal(task.projectTaskProfileId);
                }}
              >
                Edit
              </Button>
            </div>
          ),
        },
        {
          label: "Project",
          onClick: () => {
            navigate(`/projects/details/${projectDetails?.id}`);
          },
          value: (
            <Badge
              iconify="tabler:clipboard-list"
              variant="outline"
              small
              className="cursor-pointer"
            >
              {projectDetails?.number} - {projectDetails?.title}
            </Badge>
          ),
        },
        {
          label: "Created",
          value: (
            <span>
              {heffl.format.dynamicDate(task.createdAt, true)}
              <span className="text-gray-500"> by </span>
              {task.createdBy.firstName}
            </span>
          ),
        },
      ]}
    />
  ) : (
    <FullScreenSpinner />
  );

  console.log(subTaskForm.formState.errors);

  const addTaskForm = (
    <>
      {" "}
      {!showAddSubTask && (
        <Button
          className="!font-normal w-fit"
          icon={Plus}
          variant="ghost"
          size="sm"
          onClick={() => {
            setShowAddSubTask(true);
          }}
        >
          Add Sub-task
        </Button>
      )}
      {showAddSubTask && (
        <Form
          {...subTaskForm}
          className="flex flex-col gap-0 p-2 rounded-md border"
          onSubmit={(values) => {
            if (!task) return;

            addTaskMutation.mutate({
              ...values,
              projectId: task.projectId,
              parentTaskId: task.id,
            });
          }}
        >
          <TaskInputForm />
          <div className="flex gap-2 justify-end pt-2 border-t">
            <Button
              variant="secondary"
              onClick={() => {
                setShowAddSubTask(false);
                subTaskForm.reset();
              }}
              size="sm"
            >
              Cancel
            </Button>
            <Button
              loading={addTaskMutation.isLoading}
              type="submit"
              variant="primary"
              size="sm"
            >
              Add Sub-task
            </Button>
          </div>
        </Form>
      )}
    </>
  );

  return (
    <ModalDrawer
      type="sheet"
      closeOnOverlayClick
      actions={[
        {
          label: "Edit",
          icon: Pencil,
          onClick: () => setShowEditTaskModal(taskId),
        },
        {
          buttonVariant: "destructiveOutline",
          label: "Delete",
          icon: Trash,
          onClick: () => {
            heffl.modal.confirm({
              title: "Delete Task",
              description:
                "Are you sure you want to delete this task? This will delete all sub-tasks as well.",
              onConfirm: () => {
                taskDeleteMutation.mutate(taskId);
                onClose();
              },
            });
          },
        },
      ]}
      defaultActionsButtonSize="xs"
      title={
        <div className="flex gap-2 items-center text-sm font-normal text-gray-600">
          {/* <Button
            onClick={() => {
              setInfoStore("pinnedProjectTasks", [taskId]);
              onClose();
            }}
            iconify="tabler:minus"
            variant="ghost"
            size="sm"
          /> */}
          <Link
            to={`/projects/details/${task?.projectId}`}
            className="font-medium cursor-pointer hover:underline"
          >
            {makeEllipsis(task?.projects?.title || "", 20)}
          </Link>{" "}
          {task?.parentTaskId && (
            <span
              className="text-gray-600 cursor-pointer hover:underline"
              onClick={() =>
                task.parentTaskId && switchToTask(task.parentTaskId)
              }
            >
              / <span>{task.parentTask?.number}</span>
            </span>
          )}
          / <span>{task?.number}</span>
        </div>
      }
      open={open}
      onClose={onClose}
      sheetClassName="!p-0 !h-full w-full max-w-screen-lg"
    >
      <EditProjectTaskProfileModal
        open={showEditTaskProfileModal !== undefined}
        onClose={() => {
          setShowEditTaskProfileModal(undefined);
        }}
        id={showEditTaskProfileModal || 0}
      />
      <EditProjectTaskModal
        open={showEditTaskModal !== undefined}
        onClose={({ deleted }) => {
          setShowEditTaskModal(undefined);
          if (deleted) {
            onClose();
          }
        }}
        id={showEditTaskModal || 0}
      />
      <AddTimesheetDrawer
        open={addTimesheet}
        onClose={() => setAddTimesheet(false)}
        defaultValues={{ projectTaskId: taskId }}
        showTaskSelect={false}
      />
      {editTimesheet && (
        <TimesheetDetailsModal
          open={!!editTimesheet}
          onClose={() => setEditTimesheet(undefined)}
          id={editTimesheet}
        />
      )}

      {task ? (
        <div
          className="flex flex-col w-full sm:flex-row sm:h-[calc(100vh-50px)]"
          id="modal"
        >
          <ScrollArea className="overflow-auto pr-4 w-full h-full sm:p-3 sm:w-7/12">
            {task.parentTask && (
              <div
                onClick={() =>
                  task.parentTask && switchToTask(task.parentTask.id)
                }
                className="flex gap-2 items-center p-1 mb-2 text-xs rounded-md border cursor-pointer w-fit line-clamp-1"
              >
                <Icon icon="tabler:subtask" className="w-4 h-4 text-blue-500" />
                <p className="text-gray-500 line-clamp-1">
                  <span className="font-medium text-black">Sub-task of - </span>
                  {task.parentTask?.number} - {task.parentTask?.title}
                </p>
              </div>
            )}
            <div className="flex -top-0 z-10 justify-between items-center p-1 mb-4 bg-white rounded-md sm:sticky group hover:outline outline-gray-300 outline-1">
              <p
                className="text-xl font-semibold rounded-md outline-none"
                contentEditable
                suppressContentEditableWarning
                onInput={(e) => {
                  const newTitle = e.currentTarget.textContent;
                  if (newTitle && newTitle !== task.title) {
                    debouncedTaskUpdateMutation({
                      id: taskId,
                      task: {
                        title: newTitle,
                      },
                    });
                  }
                }}
              >
                {task.title}
              </p>
              <Icon
                icon="tabler:pencil"
                className="mr-2 w-5 h-5 text-gray-500 opacity-0 group-hover:opacity-100"
              />
            </div>
            <p className="mb-2 text-sm font-medium">Description</p>
            <MiniRichTextEditor
              inlineEdit
              className="!w-[530px]"
              placeholder="Describe the task, note down points etc...."
              value={task.description || ""}
              onChange={(value) => {
                debouncedTaskUpdateMutation({
                  id: taskId,
                  task: {
                    description: value,
                  },
                });
              }}
            />
            {!task.subTasks.length && !task.parentTaskId && (
              <div className="mt-4" ref={animateParent}>
                {addTaskForm}
              </div>
            )}

            <Accordion
              type="multiple"
              className="mt-8"
              value={accordion}
              onValueChange={(value) => {
                setAccordion(value);
                trpcUtils.projects.tasks.details.invalidate(taskId);
              }}
            >
              {task.subTasks.length > 0 && !task.parentTaskId && (
                <AccordionItem value="subtasks">
                  <AccordionTrigger className="data-[state=open]:border-b border-gray-200 ">
                    <div className="flex justify-between items-center w-full text-xs font-normal">
                      <div className="flex gap-2 items-center">
                        <span>Sub-tasks</span>
                        <ProgressLabel
                          noColor
                          className="!ring-0"
                          progress={
                            (calculateCompleted(
                              task.subTasks,
                              "status",
                              "COMPLETED"
                            ) /
                              task.subTasks.length) *
                            100
                          }
                          progressLabel={`${calculateCompleted(
                            task.subTasks,
                            "status",
                            "COMPLETED"
                          )}/${task.subTasks.length}`}
                        />
                      </div>
                      <Button
                        iconify="tabler:plus"
                        size="sm"
                        variant="ghost"
                        onClick={(e) => {
                          e.stopPropagation();
                          setAccordion(["subtasks"]);
                          setShowAddSubTask(true);
                        }}
                      />
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="mb-4">
                    <div
                      className="flex flex-col gap-3 pl-5"
                      ref={animateParent}
                    >
                      {task.subTasks.map((subTask) => (
                        <RightClickMenu
                          key={subTask.id}
                          className="flex flex-wrap gap-2 justify-between items-center"
                          items={[
                            {
                              label: "Open task",
                              iconify: "tabler:corner-up-right",
                              onClick: () => {
                                switchToTask(subTask.id);
                              },
                            },
                            {
                              label: "Edit",
                              iconify: "tabler:pencil",
                              onClick: () => {
                                setShowEditTaskModal(subTask.id);
                              },
                            },
                            {
                              label: "Delete",
                              iconify: "tabler:trash",
                              className: "text-red-500",
                              onClick: () => {
                                heffl.modal.confirm({
                                  title: "Delete Sub-task",
                                  description:
                                    "Are you sure you want to delete this sub-task?",
                                  onConfirm: () => {
                                    taskDeleteMutation.mutate(subTask.id);
                                  },
                                });
                              },
                            },
                          ]}
                        >
                          <div className="flex gap-2 items-center">
                            <CircleTick
                              checked={subTask.status === "COMPLETED"}
                              onClick={() => {
                                taskUpdateMutation.mutate({
                                  id: subTask.id,
                                  task: {
                                    status:
                                      subTask.status === "COMPLETED"
                                        ? "OPEN"
                                        : "COMPLETED",
                                  },
                                });
                              }}
                            />
                            <p
                              className="px-1 font-normal rounded outline-none hover:outline hover:outline-gray-300"
                              contentEditable
                              suppressContentEditableWarning
                              onInput={(e) => {
                                const newTitle = e.currentTarget.textContent;
                                if (newTitle && newTitle !== subTask.title) {
                                  debouncedTaskUpdateMutation({
                                    id: subTask.id,
                                    task: {
                                      title: newTitle,
                                    },
                                  });
                                }
                              }}
                            >
                              {subTask.title}
                            </p>
                          </div>
                          <div className="flex gap-2 items-center">
                            <DatePicker
                              previewType="linear"
                              className="w-fit"
                              placeholder="Date"
                              value={subTask.date || undefined}
                              iconify={
                                subTask.date
                                  ? heffl.date.isDue(subTask.date) &&
                                    subTask.status !== "COMPLETED"
                                    ? "tabler:calendar-x"
                                    : "tabler:calendar-event-filled"
                                  : undefined
                              }
                              iconClassName={
                                subTask.date && subTask.status !== "COMPLETED"
                                  ? heffl.date.isDue(subTask.date)
                                    ? "text-red-500"
                                    : heffl.date.isThisWeek(subTask.date)
                                    ? heffl.date.isToday(subTask.date)
                                      ? "text-green-500"
                                      : "text-orange-500"
                                    : ""
                                  : "text-gray-500"
                              }
                              onChange={(value) => {
                                taskUpdateMutation.mutate({
                                  id: subTask.id,
                                  task: { date: value },
                                });
                              }}
                            />
                            <MultiSelect
                              icon={UserCircle}
                              previewType="linear"
                              showSelected
                              popoverClassName="w-fit"
                              placeholder=""
                              value={subTask.projectTaskAssignees.map(
                                (user) => user.userId
                              )}
                              options={users?.map((user) => ({
                                value: user.id,
                                label: user.firstName,
                                icon: UserCircle,
                              }))}
                              onChange={(value) => {
                                taskUpdateMutation.mutate({
                                  id: subTask.id,
                                  task: {
                                    projectTaskAssignees: value,
                                  },
                                });
                              }}
                            />
                            <Select
                              iconOnly
                              previewType="linear"
                              value={subTask.priority}
                              popoverClassName="w-fit"
                              onChange={(value) => {
                                taskUpdateMutation.mutate({
                                  id: subTask.id,
                                  task: { priority: value },
                                });
                              }}
                              options={taskPriorities.map((priority) => ({
                                label: priority.label,
                                value: priority.value,
                                color: priority.hex,
                                iconify: priority.icon,
                                hotkey: priority.hotkey,
                                iconClassName: priority.iconClassName,
                              }))}
                            />
                            <DropMenu
                              items={[
                                {
                                  label: "Open task",
                                  iconify: "tabler:corner-up-right",
                                  onClick: () => {
                                    switchToTask(subTask.id);
                                  },
                                },
                                {
                                  label: "Edit",
                                  iconify: "tabler:pencil",
                                  onClick: () => {
                                    setShowEditTaskModal(subTask.id);
                                  },
                                },
                                {
                                  label: "Delete",
                                  iconify: "tabler:trash",
                                  className: "text-red-500",
                                  onClick: () => {
                                    heffl.modal.confirm({
                                      title: "Delete Sub-task",
                                      description:
                                        "Are you sure you want to delete this sub-task?",
                                      onConfirm: () => {
                                        taskDeleteMutation.mutate(subTask.id);
                                      },
                                    });
                                  },
                                },
                              ]}
                            >
                              <Button
                                variant="ghost"
                                iconify="tabler:dots-vertical"
                                size="sm"
                              />
                            </DropMenu>
                          </div>
                        </RightClickMenu>
                      ))}
                      {addTaskForm}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              )}

              {task.projectSubTasks.length > 0 && (
                <AccordionItem value="checklist">
                  <AccordionTrigger className="data-[state=open]:border-b border-gray-200 ">
                    <div className="flex justify-between items-center w-full text-xs font-normal">
                      <div className="flex gap-2 items-center">
                        <span>Checklist</span>
                        <ProgressLabel
                          noColor
                          className="!ring-0"
                          progress={
                            (calculateCompleted(
                              task.projectSubTasks,
                              "status",
                              "COMPLETED"
                            ) /
                              task.projectSubTasks.length) *
                            100
                          }
                          progressLabel={`${calculateCompleted(
                            task.projectSubTasks,
                            "status",
                            "COMPLETED"
                          )}/${task.projectSubTasks.length}`}
                        />
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="mb-4">
                    <div className="flex flex-col gap-3 pl-5">
                      {task.projectSubTasks.map((subTask) => (
                        <div
                          key={subTask.id}
                          className="flex gap-2 items-center"
                        >
                          <CircleTick
                            checked={subTask.status === "COMPLETED"}
                            onClick={() => {
                              subTaskUpdateMutation.mutate({
                                id: subTask.id,
                                subTask: {
                                  status:
                                    subTask.status === "OPEN"
                                      ? "COMPLETED"
                                      : "OPEN",
                                },
                              });
                            }}
                          />
                          <p className="font-normal">{subTask.title}</p>
                        </div>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              )}

              <AccordionItem value="timesheets">
                <AccordionTrigger className="data-[state=open]:border-b border-gray-200 ">
                  <div className="flex justify-between items-center w-full !text-xs font-normal">
                    <div className="flex gap-2 items-center">
                      <span>Timesheets</span>
                      <span className="text-xs font-medium text-gray-500">
                        {heffl.format.duration(
                          heffl.math.sum(
                            task.timesheets,
                            (t) => t.minutesWorked
                          ),
                          "xs"
                        )}
                      </span>
                    </div>
                    <Button
                      iconify="tabler:plus"
                      size="sm"
                      variant="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAddTimesheet(true);
                      }}
                    />
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="px-3">
                    <SimpleTable
                      borderless
                      size="small"
                      className="mt-3"
                      rows={task.timesheets}
                      idKey="id"
                      onRowClick={(timesheet) => {
                        setEditTimesheet(timesheet.id);
                      }}
                      columns={[
                        {
                          label: "Date",
                          render: (timesheet) => (
                            <p>
                              {heffl.format.dynamicDate(timesheet.startTime)}
                            </p>
                          ),
                        },
                        {
                          label: "User",
                          render: (timesheet) => (
                            <p>{timesheet.users.firstName}</p>
                          ),
                        },
                        {
                          label: "Hours",
                          render: (timesheet) => (
                            <p>
                              {Math.floor(timesheet.minutesWorked / 60)}h{" "}
                              {timesheet.minutesWorked % 60}m
                            </p>
                          ),
                        },
                        {
                          label: "Status",
                          render: (timesheet) =>
                            timesheet.approvalStatus === "APPROVED" ? (
                              <Badge variant="solidSuccess" icon={ThumbsUp}>
                                Approved
                              </Badge>
                            ) : (
                              <Badge variant="warning" icon={Clock} small>
                                Pending
                              </Badge>
                            ),
                        },
                        {
                          label: "Notes",
                          render: (timesheet) => (
                            <RenderHtml>{timesheet.notes}</RenderHtml>
                          ),
                        },
                      ]}
                    />
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <p className="mt-3 text-sm font-medium">Files</p>
            <InstantFileUploadCard
              className="py-2"
              entity="PROJECT_TASK"
              entityId={taskId}
              section="projects/tasks"
              multiple
              open={false}
              onClose={() => {}}
            />
            <div className="flex flex-col gap-2 mt-2" ref={animateParent}>
              {task.files?.files.map((file) => (
                <FileCardNew key={file.id} file={file} />
              ))}
            </div>
            {isMobile() && taskDetails}
            <div className="mt-8">
              <p className="text-sm font-medium">Activity</p>
              <ActivityTimelineNew
                className="mt-4"
                activities={task.acitivities || []}
                entity="PROJECT_TASK"
                entityId={taskId}
              />
            </div>
          </ScrollArea>
          {!isMobile() && (
            <div className="p-2 pl-4 border-l sm:w-5/12">{taskDetails}</div>
          )}
        </div>
      ) : (
        <FullScreenSpinner />
      )}
    </ModalDrawer>
  );
};
