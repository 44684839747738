import { cn } from "@heffl/ui/lib/utils";
import { Tab, Tabs } from "@nextui-org/tabs";
import { useKeenSlider } from "keen-slider/react";
import { ReactNode, useEffect, useState } from "react";
import "keen-slider/keen-slider.min.css";
import { KeenSliderOptions } from "keen-slider";
type Props<T extends string> = {
  tabs: {
    id: T;
    label: ReactNode;
    content: ReactNode;
  }[];
  value?: T;
  onChange?: (tab: T) => void;
  className?: string;
  fullWidth?: boolean;
  variant?: "underlined" | "light" | "solid" | "bordered";
  contentClassName?: string;
  autoScrollDisabled?: boolean;
};

export const ConditionalWrapper = ({
  children,
  condition,
  wrapper,
}: {
  children: ReactNode;
  condition: boolean;
  wrapper: (children: ReactNode) => ReactNode;
}) => {
  return condition ? wrapper(children) : children;
};

const NextTabs = <T extends string>({
  tabs,
  className,
  fullWidth = true,
  variant = "solid",
  value,
  onChange,
  contentClassName,
  autoScrollDisabled = false,
}: Props<T>) => {
  const [mobileTabSelected, setMobileTabSelected] = useState<T>(
    value ?? tabs[0].id
  );

  const [keenSliderOptions, setKeenSliderOptions] =
    useState<KeenSliderOptions>();

  useEffect(() => {
    if (value) {
      setMobileTabSelected(value);
      const index = tabs.findIndex((t) => t.id === value);
      !autoScrollDisabled && window.scrollTo(0, 0);
      if (instanceRef.current) instanceRef.current?.moveToIdx(index);
    }
  }, [value, tabs]);

  const [sliderRef, instanceRef] =
    useKeenSlider<HTMLDivElement>(keenSliderOptions);

  useEffect(() => {
    if (instanceRef.current) {
      instanceRef.current.slides;
    }
  }, [instanceRef]);

  useEffect(() => {
    if (instanceRef.current) {
      setKeenSliderOptions({
        mode: "snap",
        slides: {
          perView: 1,
        },
        slideChanged(v) {
          const index = Number(v?.track?.details?.abs || 0);
          setMobileTabSelected(tabs[index].id);
          onChange?.(tabs[index].id);
        },
      });
    }
  }, [instanceRef]);

  return (
    <>
      <Tabs
        fullWidth={fullWidth}
        variant={variant}
        items={tabs}
        className={cn(className, "px-4")}
        selectedKey={mobileTabSelected}
        onSelectionChange={(key) => {
          setMobileTabSelected(key as T);
          const index = tabs.findIndex((t) => t.id === key);
          if (instanceRef.current) instanceRef.current?.moveToIdx(index);
        }}
      >
        {(item) => <Tab key={item.id} title={item.label} />}
      </Tabs>
      <div ref={sliderRef} className="w-full keen-slider">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={cn("keen-slider__slide", tab.content ? "mt-2" : "")}
          >
            <div className={contentClassName}>{tab.content}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default NextTabs;
