import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { teamSettingsSchema } from "@heffl/server/src/schemas/teamSettings";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Switch } from "@heffl/ui/components/primitives/switch";
import TagsSelectInput from "@heffl/ui/components/TagsSelectInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
const ProjectSettings = () => {
  const form = useForm<z.infer<typeof teamSettingsSchema>>({
    resolver: zodResolver(teamSettingsSchema),
  });

  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();

  const updateTeamSettingsMutation = trpc.teams.updateTeamSettings.useMutation({
    onSuccess: () => {
      toast.success("Settings updated");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (data: z.infer<typeof teamSettingsSchema>) => {
    updateTeamSettingsMutation.mutate({
      projects: data.projects,
    });
  };

  useEffect(() => {
    if (teamDetails?.teamSettings) {
      form.reset({
        projects: {
          ...teamDetails.teamSettings.projects,
        },
      });
    }
  }, [teamDetails]);

  return (
    <Page title="Projects">
      <Form {...form} onSubmit={onSubmit}>
        <FormField
          name="projects.defaultTaskApproved"
          label="Default task approved"
        >
          <Switch />
        </FormField>
        <FormField
          name="projects.taskDurationMinutes"
          label="Task duration minutes"
        >
          <TagsSelectInput
            type="number"
            placeholder="Add a duration in minutes"
          />
        </FormField>

        <div className="flex justify-end">
          <Button
            loading={updateTeamSettingsMutation.isLoading}
            type="submit"
            icon={Save}
            variant="primary"
          >
            Update settings
          </Button>
        </div>
      </Form>
    </Page>
  );
};

export default ProjectSettings;
