import Document from "@tiptap/extension-document";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { EditorContent, useEditor } from "@tiptap/react";
import { cn } from "@heffl/ui/lib/utils";
import Placeholder from "@tiptap/extension-placeholder";
import { styled } from "goober";
import { Button } from "@heffl/ui/components/primitives/button";
// @ts-ignore
import suggestion from "./suggestion";

const StyledMention = styled("div")`
  width: 100%;
  .mention {
    background-color: #fdf4ff;
    border-radius: 0.25rem;
    padding: 0.125rem 0.25rem;
    font-weight: 500;
    color: #9333ea;
    &:hover {
      background-color: #cbd5e1;
    }
  }
`;

export type MentionItem = {
  label: string;
  value: string;
};

type Props = {
  className?: string;
  mentions?: MentionItem[];
  onSubmit: (content: string) => void;
};

const CommentInput = ({ className, mentions = [], onSubmit }: Props) => {
  const handleSubmit = () => {
    if (!editor) return;
    const content = editor.getHTML();
    console.log(content);
    editor.commands.clearContent();
    onSubmit(content);
  };

  // @ts-ignore
  const editor = useEditor({
    editorProps: {
      attributes: {
        class: cn(
          "bg-white border border-input rounded-xl px-3 py-2 outline-none min-h-14",
          className
        ),
      },
      placeholder: "Write a comment...",
      handleKeyDown: (view, event) => {
        // Check if there's an active mention suggestion
        const hasMentionSuggestion = document.querySelector(".tippy-box");

        // If Enter is pressed without modifiers and no mention suggestion
        if (
          event.key === "Enter" &&
          !event.shiftKey &&
          !event.altKey &&
          !event.metaKey &&
          !hasMentionSuggestion
        ) {
          event.preventDefault();
          handleSubmit();
          return true;
        }

        // Handle line break for Windows (Shift+Enter or Alt+Enter)
        if (event.key === "Enter" && (event.shiftKey || event.altKey)) {
          event.preventDefault();
          view.dispatch(
            view.state.tr.replaceSelectionWith(
              view.state.schema.nodes.paragraph.create()
            )
          );
          return true;
        }
        // Handle line break for Mac (Command+Enter)
        if (event.key === "Enter" && event.metaKey) {
          event.preventDefault();
          view.dispatch(
            view.state.tr.replaceSelectionWith(
              view.state.schema.nodes.paragraph.create()
            )
          );
          return true;
        }
        return false;
      },
    },
    extensions: [
      Placeholder.configure({
        placeholder: "Write a comment...",
      }),
      Document,
      Paragraph,
      Text,
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
          "data-id": "",
        },
        renderLabel: ({ node }) => {
          const mentionItems = mentions;
          const item = mentionItems.find(
            (item: MentionItem) => item.value === node.attrs.id
          );
          return item?.label || node.attrs.id;
        },
        suggestion: {
          ...suggestion,
          items: ({ query }) =>
            mentions.filter((item) =>
              item.label.toLowerCase().startsWith(query.toLowerCase())
            ),
        },
      }),
    ],
    content: "",
  });

  return (
    <StyledMention>
      <div className="relative">
        <EditorContent editor={editor} />
        <Button
          size="md"
          variant="ghost"
          className="absolute right-2 top-1/2 -translate-y-1/2"
          onClick={handleSubmit}
          iconify="tabler:send"
        />
      </div>
    </StyledMention>
  );
};

CommentInput.displayName = "CommentInput";

export default CommentInput;
