import DetailsPage from "@/components/details-page";
import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import RenderTimeline from "@/helpers/userActivity/render-timeline";
import appIcons from "@heffl/ui/components/appIcons";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import TemplateRender from "@heffl/ui/components/template-render";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { format } from "date-fns";
import dayjs from "dayjs";
import {
  Activity,
  ArrowDownToLine,
  Ban,
  Copy,
  FileSearch,
  MoreHorizontal,
  Pencil,
  PencilIcon,
  Plus,
  Printer,
  ReceiptText,
  Trash,
  DollarSign,
  Calendar,
  Hash,
  CreditCard,
} from "lucide-react";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { downloadPdf } from "../../sales/invoices/details";
import {
  AddPaymentMadeModal,
  EditPaymentMadeModal,
} from "../payments-made/list";
import { BillStatusBadge } from "./list";
import SimpleTable from "@heffl/ui/components/simple-table";
import calculateBill from "@heffl/server/src/helpers/lineItems/calculateBill";

export const generateBillName = (bill: {
  date: Date;
  billNumber: string;
  vendors: {
    name: string;
  };
}) => {
  if (!bill) return "";
  return `Bill ${bill.billNumber}-${bill.vendors.name}-${dayjs(
    bill.date
  ).format("DD-MM-YYYY")}`;
};

const BillDetails: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const billPrintRef = useRef<HTMLDivElement>(null);
  const confirm = useConfirm();

  const billId = Number(params.id);

  const [previewMode, setPreviewMode] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [showMakePaymentModal, setShowMakePaymentModal] = useState(false);
  const [showEditPaymentModal, setShowEditPaymentModal] = useState<
    number | null
  >(null);

  const { data: bill, isLoading } =
    trpc.purchases.bills.details.useQuery(billId);

  const printBill = useReactToPrint({
    content: () => billPrintRef.current,
    documentTitle: bill ? generateBillName(bill) : "",
  });

  const updateBillMutation = trpc.purchases.bills.update.useMutation({
    onSuccess() {
      toast.success("Bill updated successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const deleteBillMutation = trpc.purchases.bills.delete.useMutation({
    onSuccess() {
      toast.success("Bill deleted successfully");
      navigate("/purchases/bills");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  if (isLoading || !bill) return <FullScreenSpinner />;

  return (
    <>
      <AddPaymentMadeModal
        open={showMakePaymentModal}
        onClose={() => setShowMakePaymentModal(false)}
        billId={bill.id}
        defaultValues={{
          vendorId: bill.vendors.id,
        }}
      />
      {!!showEditPaymentModal && (
        <EditPaymentMadeModal
          open={true}
          onClose={() => setShowEditPaymentModal(null)}
          paymentId={showEditPaymentModal}
        />
      )}
      <DetailsPage
        activities={[]}
        title={`Bill #${bill.billNumber}`}
        widgets={[
          <Badge key="days-since" variant="neutral" small icon={Pencil}>
            Created {dayjs(bill.createdAt).fromNow()}
          </Badge>,
          <BillStatusBadge status={bill.status} small key="status" />,
        ]}
        description={`Created on ${format(
          bill.createdAt,
          "dd MMM yyyy hh:mm a"
        )}`}
        attributes={[
          {
            section: "Bill details",
            items: [
              {
                label: "Date",
                value: dayjs(bill.date).format("DD/MM/YYYY"),
              },
              {
                label: "Status",
                value: <BillStatusBadge status={bill.status} />,
              },
              {
                label: "To pay",
                value: heffl.format.currency(
                  calculateBill(bill).pendingTotal,
                  "AED"
                ),
              },
              {
                label: "Vendor",
                value: bill.vendors.name,
              },
              {
                label: "Due date",
                value: bill.dueDate
                  ? dayjs(bill.dueDate).format("DD/MM/YYYY")
                  : "Not specified",
              },
              {
                label: "Created by",
                value: heffl.format.name(bill.createdBy),
              },
            ],
          },
        ]}
        tabs={{
          items: [
            {
              key: "preview",
              label: "Preview",
              icon: FileSearch,
              children: (
                <div className="w-full rounded-lg border border-gray-200 sm:w-fit">
                  <div ref={billPrintRef}>
                    <TemplateRender
                      contentHtml={bill.contentInjected}
                      template={bill.documentTemplates}
                      previewMode={previewMode}
                    />
                  </div>
                </div>
              ),
            },
            {
              key: "payments",
              label: "Payments",
              icon: appIcons.purchases.payments.icon,
              count: bill.paymentMadeAllocations.length,
              children: (
                <SimpleTable
                  idKey="id"
                  fixedHeader={false}
                  className="!mt-3"
                  rows={bill.paymentMadeAllocations}
                  onRowClick={(row) =>
                    setShowEditPaymentModal(row.paymentsMade.id)
                  }
                  columns={[
                    {
                      label: "Date",
                      icon: Calendar,
                      render: (row) =>
                        format(row.paymentsMade.date, "dd MMM yyyy"),
                    },
                    {
                      label: "Payment",
                      icon: Hash,
                      render: (row) => (
                        <p className="cursor-pointer hover:underline hover:text-primary">
                          {row.paymentsMade.number}
                        </p>
                      ),
                    },
                    {
                      key: "amount",
                      label: "Amount",
                      icon: DollarSign,
                    },
                    {
                      label: "Paid Through",
                      icon: CreditCard,
                      render: (row) => row.paymentsMade.paidThroughAccount.name,
                    },
                  ]}
                />
              ),
              title: "Payments",
              actions: [
                {
                  hide:
                    bill.status !== "OPEN" && bill.status !== "PARTIALLY_PAID",
                  icon: Plus,
                  label: "Payment",
                  onClick: () => {
                    setShowMakePaymentModal(true);
                  },
                },
              ],
            },
            {
              key: "activity",
              label: "Activity",
              icon: Activity,
              children: <RenderTimeline userActivities={bill.userActivities} />,
            },
          ],
        }}
        actions={[
          {
            icon: Printer,
            buttonVariant: "outline",
            iconClassName: "text-purple-500",
            buttonClassName: "sm:rounded-r-none",
            label: "Print",
            onClick: () => {
              setPreviewMode(false);
              setTimeout(() => {
                printBill();
                setPreviewMode(true);
              }, 100);
            },
          },
          {
            buttonClassName: "sm:rounded-l-none sm:rounded-r-none sm:-ml-2",
            buttonVariant: "outline",
            icon: ArrowDownToLine,
            label: "Download",
            onClick: async () => {
              setDownloadLoading(true);
              await downloadPdf({
                name: generateBillName(bill),
                url: `print?bill=${bill.uuid}`,
              });
              setTimeout(() => {
                setDownloadLoading(false);
              }, 3000);
            },
            loading: downloadLoading,
            hideLabelOnDesktop: true,
          },
          {
            buttonClassName: "sm:rounded-l-none sm:-ml-2",
            buttonVariant: "outline",
            label: "Duplicate",
            icon: Copy,
            hideLabelOnDesktop: true,
            onClick: () => navigate(`/purchases/bills/add?billId=${bill.id}`),
          },
          {
            buttonVariant: "outline",
            icon: appIcons.purchases.expenses.icon,
            iconClassName: "text-green-500",
            label: "Make Payment",
            hide: bill.status !== "OPEN" && bill.status !== "PARTIALLY_PAID",
            onClick: () => setShowMakePaymentModal(true),
          },

          {
            buttonVariant: "outline",
            icon: ReceiptText,
            iconClassName: "text-green-500",
            loading: updateBillMutation.isLoading,
            label: "Mark as Open",
            hide: bill.status !== "DRAFT",
            onClick: () => {
              updateBillMutation.mutate({
                id: bill.id,
                bill: {
                  status: "OPEN",
                },
              });
            },
          },
          {
            icon: MoreHorizontal,
            label: "More",
            onClick: () => {},
            dropdown: (
              <>
                <Button
                  variant="ghost"
                  className="flex justify-start"
                  onClick={() => navigate(`/purchases/bills/edit/${bill.id}`)}
                >
                  <PencilIcon className="mr-2 w-5" />
                  Edit
                </Button>
                <Button
                  icon={Ban}
                  variant="ghost"
                  className="text-red-500"
                  onClick={() =>
                    heffl.modal.reason({
                      submitText: "Cancel Bill",
                      onSubmit: (reason) => {
                        updateBillMutation.mutate({
                          id: bill.id,
                          bill: {
                            status: "CANCELLED",
                            cancelReason: reason.description,
                          },
                        });
                      },
                    })
                  }
                >
                  Cancel Bill
                </Button>
                <Button
                  variant="ghost"
                  className="flex justify-start"
                  onClick={async () => {
                    const confirmed = await confirm(
                      "Are you sure to delete this bill?"
                    );
                    if (confirmed) {
                      deleteBillMutation.mutate(bill.id);
                    }
                  }}
                >
                  <Trash className="mr-2 w-5 text-red-500" />
                  Delete
                </Button>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default BillDetails;
