import heffl from "@/helpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useEffect, useState } from "react";

import DataGrid from "@/components/dataGrid/DataGrid";
import Page from "@/components/page";
import { Badge } from "@heffl/ui/components/primitives/badge";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import dayjs from "dayjs";
import { FolderKanban } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { rrulestr } from "rrule";
import { AddProjectProfileDrawer } from "./components/AddProjectProfileDrawer";
import useQueryParams from "@/helpers/useQuery";
import { SearchInput } from "@/components/FormComponents";
import FilterBar from "@/components/filter-bar";
import { useParamsState } from "@/lib/hooks/useParamsState";
import useTeam from "@/lib/hooks/useTeam";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";

const RecurringProjectsList = () => {
  const navigate = useNavigate();

  const queryParams = useQueryParams();
  const team = useTeam();

  const convertProjectId = queryParams.get("convertProjectId");
  const convertQuotationId = queryParams.get("convertQuotationId");

  const [filters, setFilters] = useParamsState<
    RouterInputs["projects"]["profiles"]["list"]
  >({
    search: "",
    startDate: undefined,
    endDate: undefined,
    pipelines: undefined,
    clients: undefined,
    createdAt: undefined,
    isActive: undefined,
    pageNo: 1,
    pageSize: 30,
  });

  const [addDrawerOpen, setAddDrawerOpen] = useState(false);
  const [clientsSearch, setClientsSearch] = useState("");

  const { data: projectProfiles, isLoading } =
    trpc.projects.profiles.list.useQuery(filters);

  const { data: pipelines } = trpc.projects.pipelines.list.useQuery();
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientsSearch,
    pageSize: 20,
  });

  useEffect(() => {
    if (convertProjectId && !addDrawerOpen) {
      setAddDrawerOpen(true);
    }
  }, [convertProjectId]);

  useEffect(() => {
    if (convertQuotationId && !addDrawerOpen) {
      setAddDrawerOpen(true);
    }
  }, [convertQuotationId]);

  if (!team) return <FullScreenSpinner />;

  if (team && !team.user.permissions.VIEW_RECURRING_PROJECTS.allowed) {
    return <NoPermissionScreen />;
  }

  return (
    <Page
      title="Recurring Projects"
      fullWidth
      className="sm:p-0"
      tags={[
        <Badge variant="warning" key="beta">
          Beta
        </Badge>,
      ]}
    >
      <div className="flex flex-col gap-3 justify-between w-full border-gray-200 sm:border-b sm:p-3 sm:flex-row sm:gap-0">
        <SearchInput
          value={filters.search || ""}
          onChange={(e: string) => setFilters({ ...filters, search: e })}
        />

        <ResponsiveActionButton
          onClick={() => setAddDrawerOpen(true)}
          text="Recurring Project"
          disabled={!team.user.permissions.CREATE_RECURRING_PROJECTS.allowed}
        />
      </div>

      <div className="sm:px-3">
        <FilterBar
          className="py-3"
          filters={[
            {
              key: "startDate",
              type: "date-range",
              label: "Start Date",
              value: filters.startDate,
              onChange: (value) => setFilters({ ...filters, startDate: value }),
            },
            {
              key: "endDate",
              type: "date-range",
              label: "End Date",
              value: filters.endDate,
              onChange: (value) => setFilters({ ...filters, endDate: value }),
            },
            {
              key: "pipelines",
              type: "checkbox",
              label: "Pipeline",
              value: filters.pipelines || [],
              onChange: (value) => setFilters({ ...filters, pipelines: value }),
              options:
                pipelines?.map((pipeline) => ({
                  label: pipeline.name,
                  value: pipeline.id,
                })) || [],
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Client",
              value: filters.clients || [],
              onSearch: (search) => setClientsSearch(search),
              onChange: (value) => setFilters({ ...filters, clients: value }),
              options:
                clients?.clients.map((client) => ({
                  label: client.name,
                  value: client.id,
                })) || [],
            },
            {
              key: "createdAt",
              type: "date-range",
              label: "Created At",
              value: filters.createdAt,
              onChange: (value) => setFilters({ ...filters, createdAt: value }),
            },
            {
              key: "isActive",
              multiple: false,
              type: "checkbox",
              label: "Status",
              value: filters.isActive || [],
              onChange: (value) => setFilters({ ...filters, isActive: value }),
              options: [
                { label: "Active", value: true },
                { label: "Inactive", value: false },
              ],
            },
          ]}
        />
      </div>

      <DataGrid
        name="projectProfilesListMain"
        className="h-[calc(100vh-166px)]"
        label="Recurring Projects"
        rows={projectProfiles?.list || []}
        columns={[
          {
            key: "number",
            name: "Number",
            width: 100,
            renderCell: ({ row }) => {
              return <p>{row.number}</p>;
            },
          },
          {
            key: "name",
            name: "Title",
            width: 300,
            renderCell: ({ row }) => {
              return (
                <p
                  className="text-sm font-medium cursor-pointer hover:underline hover:text-primary"
                  onClick={() => {
                    navigate(`/projects/recurring-projects/details/${row.id}`);
                  }}
                >
                  {row.projectTitle}
                </p>
              );
            },
          },
          {
            key: "isActive",
            name: "Status",
            width: 100,
            renderCell: ({ row }) => {
              return (
                <Badge variant={row.isActive ? "success" : "warning"}>
                  {row.isActive ? "Active" : "Inactive"}
                </Badge>
              );
            },
          },
          {
            key: "frequency",
            name: "Frequency",
            renderCell: ({ row }) => {
              return heffl.format.capitalize(
                rrulestr(row.recurringRule).toText()
              );
            },
          },
          {
            key: "clients",
            name: "Client",
            renderCell: ({ row }) => {
              const client = row.clients;
              if (!client) return "-";
              return client.name;
            },
          },
          {
            key: "recurringStartDate",
            name: "Start Date",
            renderCell: ({ row }) => {
              return heffl.format.date(dayjs(row.recurringStartDate).toDate());
            },
          },
          {
            key: "recurringEndDate",
            name: "End Date",
            renderCell: ({ row }) => {
              return row.recurringEndDate
                ? heffl.format.date(dayjs(row.recurringEndDate).toDate())
                : "-";
            },
          },
          {
            key: "createdAt",
            name: "Created At",
            renderCell: ({ row }) => {
              return heffl.format.dynamicDate(row.createdAt, true);
            },
          },
        ]}
        pagination={{
          pageNo: filters.pageNo,
          pageSize: filters.pageSize,
          count: projectProfiles?.meta?.count,
          setPageNo: (pageNo: number) => setFilters({ pageNo }),
          setPageSize: (pageSize: number) => setFilters({ pageSize }),
        }}
        loading={isLoading}
        rowKey="id"
        empty={{
          title: "No project recurring projects found",
          description: "Create a new project recurring project to get started",
          icon: FolderKanban,
        }}
      />

      {addDrawerOpen && (
        <AddProjectProfileDrawer
          open={addDrawerOpen}
          onClose={() => setAddDrawerOpen(false)}
        />
      )}
    </Page>
  );
};

export default RecurringProjectsList;
