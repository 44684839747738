import DataTable from "@/components/DataTable";
import DropMenu from "@/components/DropMenu";
import { SearchInput } from "@/components/FormComponents";
import Pagination from "@/components/Pagination";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import useTeam from "@/lib/hooks/useTeam";
import { CompanyLogo } from "@/pages/crm/deals/components/dealCard";
import enums from "@heffl/server/src/schemas/enums";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import { isMobile } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import {
  ArrowDownToLine,
  Building,
  Calendar,
  Copy,
  DollarSign,
  MoreHorizontal,
  Zap,
} from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { create } from "zustand";
import { downloadPdf, generateInvoiceName } from "../invoices/details";
import ProformaInvoiceCard from "./components/InvoiceCard";

// TODO: no status for proforma invoices, need to complete

type InvoiceStatus = z.infer<typeof enums.invoiceStatus>;
type InvoiceProducts =
  RouterOutputs["proformaInvoice"]["list"]["invoices"][number]["proformaInvoiceProducts"];

export const getProductName = (products: InvoiceProducts) => {
  if (products.length > 1) {
    return (
      <p>
        {products[0].name}..{" "}
        <span className="text-primary">+{products.length - 1} more</span>
      </p>
    );
  }
  if (products.length === 1) {
    return products[0].name;
  }
  return "No product";
};

type PageFilters = {
  key: string;
  setKey: (key: string) => void;
  filters: {
    status: InvoiceStatus | "All";
    search: string;
    pageNo: number;
  };
  setFilters: (filters: Partial<PageFilters["filters"]>) => void;
};
const usFilterStore = create<PageFilters>((set) => ({
  key: "",
  setKey: (key) => {
    set((state) => {
      if (state.key !== key) {
        // reset state if route key changes
        set(() => ({
          filters: { status: "All", search: "", pageNo: 1 },
        }));
      }
      return { key };
    });
  },
  filters: {
    status: "All",
    search: "",
    pageNo: 1,
  },
  setFilters: (filter) =>
    set((state) => ({ filters: { ...state.filters, ...filter } })),
  pagination: {
    pageNo: 1,
    totalPages: 0,
  },
  reset: () =>
    set(() => ({
      filters: { status: "All", search: "", pageNo: 1 },
    })),
}));

const ProformaInvoices = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const setKey = usFilterStore((state) => state.setKey);
  setKey(location.key);
  const filters = usFilterStore((state) => state.filters);
  const setFilters = usFilterStore((state) => state.setFilters);

  const team = useTeam();
  const { data: proformaInvoices, isLoading } =
    trpc.proformaInvoice.list.useQuery({
      ...filters,
      status: filters.status === "All" ? undefined : filters.status,
      pageNo: filters.pageNo,
      pageSize: 30,
    });

  if (!team) return <FullScreenSpinner />;

  if (!team.user.permissions.VIEW_PROFORMA_INVOICES.allowed) {
    return <NoPermissionScreen />;
  }

  return (
    <Page fullWidth title="Proforma Invoices">
      <div className="flex flex-col gap-2 justify-between sm:flex-row">
        <SearchInput
          placeholder="Search invoices..."
          value={filters.search}
          onChange={(e) => setFilters({ search: e })}
        />
        <div className="flex gap-3">
          <Tabs
            value={filters.status}
            onValueChange={(value) =>
              setFilters({ status: value as InvoiceStatus })
            }
          >
            <TabsList>
              <TabsTrigger value="All">All</TabsTrigger>
              <TabsTrigger value="DRAFT">Draft</TabsTrigger>
              <TabsTrigger value="SENT">Sent</TabsTrigger>
              <TabsTrigger value="PAID">Paid</TabsTrigger>
            </TabsList>
          </Tabs>
          {team.user.permissions.CREATE_PROFORMA_INVOICES.allowed && (
            <ResponsiveActionButton
              onClick={() => navigate("add")}
              text="Proforma Invoice"
            />
          )}
        </div>
      </div>
      {isLoading || !proformaInvoices ? (
        <FullScreenSpinner />
      ) : isMobile() ? (
        <div className="flex flex-col gap-2 mt-4">
          {proformaInvoices?.invoices.map((invoice) => (
            <ProformaInvoiceCard
              data={invoice}
              key={invoice.id}
              onClick={() =>
                navigate(`/sales/proforma-invoices/details/${invoice.id}`)
              }
            />
          ))}
          <Pagination
            pageNo={filters.pageNo}
            count={proformaInvoices?.meta.count}
            setPageNo={(pageNo) => setFilters({ pageNo })}
            className="justify-start"
          />
        </div>
      ) : (
        <DataTable
          rowKey="id"
          className="mt-4"
          onRowClick={(invoice) =>
            navigate(`/sales/proforma-invoices/details/${invoice.id}`)
          }
          loading={isLoading}
          columns={[
            {
              title: "#",
              render: (invoice) => `#${invoice.number}`,
              className: "!w-[60px]",
            },
            {
              icon: Building,
              title: "Client",
              className: "!w-[500px]",
              render: (invoice) => (
                <div className="flex gap-2 items-center py-1">
                  <CompanyLogo
                    company={invoice.clients}
                    className="w-10 h-10 rounded-full"
                  />
                  <div className="flex flex-col">
                    <p className="text-sm font-medium">
                      {invoice.clients?.name ?? "No company"}
                    </p>
                    <p className="text-xs font-medium text-gray-500">
                      {getProductName(invoice.proformaInvoiceProducts)}
                    </p>
                  </div>
                </div>
              ),
            },
            {
              icon: Calendar,
              title: "Date",
              render: (invoice) => format(invoice.date, "dd/MM/yyyy"),
              className: "!w-[160px]",
            },
            {
              icon: Zap,
              title: "Actions",
              textAlign: "right",
              className: "!w-[50px]",
              render: (invoice) => (
                <DropMenu
                  items={[
                    {
                      label: "Duplicate",
                      icon: Copy,
                      onClick: () =>
                        navigate(
                          `/sales/proforma-invoices/add?invoiceId=${invoice.id}`
                        ),
                    },
                    {
                      label: "Download",
                      icon: ArrowDownToLine,
                      onClick: async () => {
                        await downloadPdf({
                          name: generateInvoiceName(invoice),
                          url: `print?proforma-invoice=${invoice.uuid}`,
                        });
                      },
                    },
                  ]}
                >
                  <Button variant={"ghost"}>
                    <MoreHorizontal className="h-4 text-gray-700" />
                  </Button>
                </DropMenu>
              ),
            },
          ]}
          empty={{
            title: "No invoices found",
            icon: DollarSign,
            description: "No invoices available to display.",
            actionText: "Add invoice",
            actionHref: "/sales/proforma-invoices/add",
            buttonSize: "sm",
            hideAction: !team.user.permissions.CREATE_PROFORMA_INVOICES.allowed,
          }}
          pagination={{
            pageNo: filters.pageNo,
            count: proformaInvoices?.meta.count,
            setPageNo: (pageNo) => setFilters({ pageNo }),
          }}
          data={proformaInvoices?.invoices || []}
        />
      )}
    </Page>
  );
};

export default ProformaInvoices;
