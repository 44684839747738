import { z } from "zod";
import enums from "./enums";

export const chartOfAccounts = z.object({
  type: enums.accounting.chartOfAccountsType,
  name: z.string(),
  accountCode: z.string().nullish(),
  description: z.string().optional(),
  isActive: z.boolean().default(true),
  parentAccountId: z.number().nullish(),
  zohoAccountId: z.string().nullish(),
});
export const cashflowTransaction = z
  .object({
    date: z.date(),
    refNo: z.string().nullish(),
    description: z.string().nullish(),
    creditAccountId: z.number().int(),
    type: enums.accounting.cashflowTransactionTypes,
    amount: z.coerce
      .number()
      .positive()
      .min(0.01, "Amount must be greater than 0"),
    accountId: z.number().int(),
  })
  .refine((data) => data.creditAccountId !== data.accountId, {
    message: "Credit account and debit account cannot be the same",
    path: ["creditAccountId"],
  });
