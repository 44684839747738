/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Prisma } from "@prisma/client";
import { z } from "zod";
import enums from "../../schemas/enums";
import { Decimal } from "@prisma/client/runtime/library";

type LineItem = {
  quantity: number;
  price: number | Decimal;
  description?: string | null;
  tax?: number | Decimal;
  viewType: z.infer<typeof enums.lineItemViewType>;
  buyPrice?: number | Decimal;
};
type Props = {
  lineItems: LineItem[];
  discount: number;
};

// Add a helper function to standardize decimal precision
export const roundToTwo = (num: number): number => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const calculateLineItems = ({ lineItems = [], discount = 0 }: Props) => {
  const lineItemsOnly = lineItems.filter(
    (item) => item.viewType === "LINE_ITEM"
  );
  const subTotal = roundToTwo(
    lineItemsOnly.reduce(
      (total, item) => total + Number(item.price) * Number(item.quantity),
      0
    )
  );
  // evenly reduce the discount from each product, because discount is applied before tax, maybe each product will have seperate tax so evenly reducing
  const discountPerProduct = Number(discount) / lineItemsOnly.length;
  const newLineItems = lineItemsOnly.map((item) => {
    const price =
      Number(item.price) * Number(item.quantity) - discountPerProduct;
    const tax = (price / 100) * Number(item.tax || 0);
    return {
      ...item,
      price,
      tax,
    };
  });
  const lineItemsTotal = roundToTwo(
    newLineItems.reduce((total, item) => Number(total) + Number(item.price), 0)
  );
  const totalTax = roundToTwo(
    newLineItems.reduce((tax, item) => Number(tax) + Number(item.tax), 0)
  );
  const total = roundToTwo(Number(lineItemsTotal) + Number(totalTax));
  const totalBuyPrice = roundToTwo(
    newLineItems.reduce(
      (total, item) => Number(total) + Number(item.buyPrice || 0),
      0
    )
  );
  return {
    subTotal,
    totalTax,
    total,
    totalBuyPrice,
  };
};

export type CustomFieldLineItem = {
  name: string;
  isMultiplier: boolean;
  dataType: z.infer<typeof enums.customFieldTypes>;
  // what if isActive is turned of after some quotations, suggetion:dont allow deactivation hide the field and set the value to 1
  isActive: boolean;
};

type PossibleCustomFields =
  | {
      [key: string]: string | number;
    }
  | Prisma.JsonValue;

type MultiplierProps = {
  customFieldFields: CustomFieldLineItem[];
  customFieldValues: PossibleCustomFields;
};

export const calculateMultiplierTotal = ({
  customFieldFields,
  customFieldValues,
}: MultiplierProps) => {
  const multiplierFields = customFieldFields.filter(
    (field) => field.isMultiplier
  );
  const multiplierValues = multiplierFields.map((field) =>
    // @ts-ignore
    customFieldValues && customFieldValues[field.name]
      ? // @ts-ignore
        Number(customFieldValues[field.name])
      : 1
  );

  const multiplier = multiplierValues.reduce(
    (total, value) => total * value,
    1
  );

  return multiplier;
};

type QuotationLineItem = {
  quantity: number;
  price: number;
  description?: string | null;
  tax?: number;
  viewType: z.infer<typeof enums.lineItemViewType>;
  customFields: PossibleCustomFields | undefined;
};

type QuotationProps = {
  lineItems: QuotationLineItem[];
  discount: number;
  customFields: CustomFieldLineItem[];
};

export const calculateQuotationLineItems = ({
  lineItems = [],
  discount = 0,
  customFields,
}: QuotationProps) => {
  const lineItemsOnly = lineItems.filter(
    (item) => item.viewType === "LINE_ITEM"
  );

  const subTotal = roundToTwo(
    lineItemsOnly.reduce((total, item) => {
      const multiplier = calculateMultiplierTotal({
        customFieldFields: customFields,
        customFieldValues: item.customFields || {},
      });
      return total + Number(item.price) * Number(item.quantity) * multiplier;
    }, 0)
  );
  // evenly reduce the discount from each product, because discount is applied before tax, maybe each product will have seperate tax so evenly reducing
  const discountPerProduct = Number(discount) / lineItemsOnly.length;
  const newLineItems = lineItemsOnly.map((item) => {
    const multiplier = calculateMultiplierTotal({
      customFieldFields: customFields,
      customFieldValues: item.customFields || {},
    });

    const price =
      Number(item.price) * Number(item.quantity) * multiplier -
      discountPerProduct;
    const tax = (price / 100) * Number(item.tax || 0);
    return {
      ...item,
      price,
      tax,
    };
  });
  const lineItemsTotal = roundToTwo(
    newLineItems.reduce((total, item) => Number(total) + Number(item.price), 0)
  );
  const totalTax = roundToTwo(
    newLineItems.reduce((tax, item) => Number(tax) + Number(item.tax), 0)
  );
  const total = roundToTwo(Number(lineItemsTotal) + Number(totalTax));
  return {
    subTotal,
    totalTax,
    total,
  };
};
