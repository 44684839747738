import { useAutoAnimate } from "@formkit/auto-animate/react";
import CopyToClipboard from "@heffl/ui/components/primitives/copy-to-clipboard";
import { cn } from "@heffl/ui/lib/utils";
import { Link, LucideIcon } from "lucide-react";
import { ReactNode } from "react";

type Props = {
  items: {
    key?: string;
    label: string;
    value: string | ReactNode;
    hidden?: boolean;
    onClick?: () => void;
    icon?: LucideIcon;
    allowCopy?: boolean;
    copyText?: string;
    valueClassName?: string;
  }[];
  title?: string;
  className?: string;
  labelClassName?: React.ComponentProps<"dt">["className"];
};

export const InfoItemVTSingle = ({
  item,
  labelClassName,
}: {
  item: Props["items"][0];
  labelClassName?: string;
}) => {
  return (
    <div>
      <dt
        className={cn(
          "text-[12px] !font-medium !text-[#616261]",
          labelClassName
        )}
      >
        {item.label}
      </dt>
      <dd
        className={cn(
          "text-gray-700 sm:col-span-2 flex items-center gap-2",
          item.onClick && "hover:text-primary cursor-pointer"
        )}
        onClick={item.onClick}
      >
        {item.value}
        {item.allowCopy && (
          <CopyToClipboard
            copyText={
              item.copyText ||
              (typeof item.value === "string" ? item.value : "") ||
              ""
            }
          />
        )}
        {!!item.onClick && <Link className="h-3.5 text-primary" />}
      </dd>
    </div>
  );
};

const InfoItemsVT = ({ items, labelClassName, className, title }: Props) => {
  const [parent] = useAutoAnimate();

  return (
    <div className={cn("flow-root", className)}>
      {title && (
        <p className="text-[12px] font-medium text-[#616261]">{title}</p>
      )}
      <div
        ref={parent}
        className={cn("-my-3 text-sm space-y-2", title && "mt-1")}
      >
        {items
          .filter((item) => !item.hidden)
          .map((item) => (
            <InfoItemVTSingle
              key={item.key || item.label}
              item={item}
              labelClassName={labelClassName}
            />
          ))}
      </div>
    </div>
  );
};

export default InfoItemsVT;
