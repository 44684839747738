/* eslint-disable @typescript-eslint/no-explicit-any */
import { trpc } from "@/helpers/trpc";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { useEffect, useState } from "react";
import {
  dashboardChartItems,
  RenderDashboardChartItem,
} from "../charts-item-helpers";

type ChartDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  id: number;
};

export const ChartDetailsModal = ({
  open,
  onClose,
  id,
}: ChartDetailsModalProps) => {
  const [filterState, setFilterState] = useState<Record<string, any>>({});

  const { data } = trpc.dashboards.items.details.useQuery(id, {
    enabled: !!id,
  });

  const itemDetails = dashboardChartItems.find(
    (item) => item.name === data?.name
  );

  useEffect(() => {
    if (data) {
      setFilterState(data.filtersValues as Record<string, any>);
    }
  }, [data]);

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Chart Details"
      modalClassName="max-w-5xl h-[800px]"
    >
      {!data && <FullScreenSpinner />}
      {data && itemDetails && (
        <RenderDashboardChartItem
          name={data.name}
          filterValues={filterState}
          width={100}
        />
      )}
    </ModalDrawer>
  );
};
