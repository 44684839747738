import FilterBar from "@/components/filter-bar";
import heffl from "@/helpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { BarChart } from "@heffl/ui/components/charts/barchart";
import SimpleTable from "@heffl/ui/components/simple-table";
import { cn } from "@heffl/ui/lib/utils";
import { Link, useNavigate } from "react-router-dom";
import { match } from "ts-pattern";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import EmptyScreen from "@heffl/ui/components/primitives/empty-screen";
import { leadStageTypeMeta } from "@/lib/constants";

const LeadsByStatus = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<RouterInputs["leads"]["list"]>({
    stages: [],
    search: "",
    dates: [heffl.date.monthStart(), heffl.date.monthEnd()],
    assignedTo: [],
    owners: [],
    sources: [],
    customFields: {},
  });

  const { data: leads, isLoading } = trpc.leads.list.useQuery({
    dates: filters.dates,
    stages: filters.stages,
    assignedTo: filters.assignedTo,
    owners: filters.owners,
    sources: filters.sources,
    search: filters.search,
  });

  const { data: users } = trpc.users.list.useQuery({
    type: ["STAFF", "ADMIN", "OWNER", "SUPER_ADMIN"],
  });
  const { data: leadSources } = trpc.sources.list.useQuery();
  const { data: leadStages } = trpc.leads.stages.list.useQuery();

  return (
    <div>
      <FilterBar
        suffix={
          <div className="p-1 px-2 rounded-lg border">
            Count: {leads?.count}
          </div>
        }
        onChange={() => {}}
        className="pb-3"
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Created at",
            value: filters.dates,
            onChange: (value) =>
              setFilters({
                dates: value,
              }),
          },
          {
            key: "stages",
            type: "checkbox",
            label: "Stage",
            value: filters.stages,
            onChange: (value) =>
              setFilters({
                stages: value,
              }),
            options:
              leadStages?.map((stage) => ({
                label: stage.label,
                value: stage.id,
              })) || [],
          },
          {
            key: "assignedTo",
            type: "checkbox",
            label: "Assigned to",
            value: filters.assignedTo,
            onChange: (value) =>
              setFilters({
                assignedTo: value as number[],
              }),
            options: [
              { label: "Unassigned", value: 0 },
              ...(users?.map((user) => ({
                label: heffl.format.name(user),
                value: user.id,
              })) || []),
            ],
          },
          {
            key: "owners",
            type: "checkbox",
            label: "Owners",
            value: filters.owners,
            onChange: (value) =>
              setFilters({
                owners: value as number[],
              }),
            options:
              users?.map((user) => ({
                label: heffl.format.name(user),
                value: user.id,
              })) || [],
          },
          {
            key: "sources",
            type: "checkbox",
            label: "Sources",
            value: filters.sources,
            onChange: (value) =>
              setFilters({
                sources: value as number[],
              }),
            options:
              leadSources?.map((source) => ({
                label: source.name,
                value: source.id,
              })) || [],
          },
        ]}
      />

      {match({ isLoading, leads })
        .with({ isLoading: true }, () => <FullScreenSpinner />)
        .with({ isLoading: false, leads: { leads: [] } }, () => (
          <EmptyScreen title="No leads found" />
        ))
        .otherwise(() => (
          <>
            <div className="mb-4">
              <BarChart
                data={
                  leadStages?.map((stage) => ({
                    id: stage.id,
                    name: stage.label,
                    "Leads count":
                      leads?.leads?.filter((lead) => lead.stageId === stage.id)
                        .length || 0,
                    color: leadStageTypeMeta[stage.type].color,
                  })) || []
                }
                index="name"
                categories={["Leads count"]}
                showLegend={false}
                yAxisWidth={48}
                className="h-64"
                yAxisLabel="Leads count"
                xAxisLabel="Lead status"
                onValueChange={(value) => {
                  if (value) {
                    const params = heffl.url.objToParams({
                      ...filters,
                      stages: [value.id],
                      archived: [],
                    });

                    navigate(`/crm/leads/table?${params}`);
                  }
                }}
              />
            </div>

            <SimpleTable
              borderless
              groupBy={{
                rowKey: "stageId",
                groups:
                  leadStages?.map((stage) => ({
                    label: stage.label,
                    key: stage.id.toString(),
                  })) || [],
              }}
              size="small"
              columns={[
                {
                  label: "No",
                  key: "number",
                  width: 60,
                },
                {
                  label: "Name",
                  key: "name",
                  width: 200,
                  render: (row) => (
                    <Link
                      to={`/crm/leads/details/${row.id}`}
                      className="truncate rounded-md cursor-pointer hover:text-primary-600"
                    >
                      {row.name}
                    </Link>
                  ),
                },
                {
                  label: "Title",
                  key: "title",
                  width: 300,
                  render: (row) => (
                    <div className="truncate rounded-md cursor-pointer hover:text-primary-600">
                      {row.title}
                    </div>
                  ),
                },
                {
                  label: "Stage",
                  key: "stageId",
                  width: 100,
                  render: (row) => (
                    <div className="flex gap-1 items-center capitalize">
                      <div
                        className={cn(
                          "h-2 w-2 rounded-full",
                          leadStageTypeMeta[row.leadStages.type].color
                        )}
                      />
                      {row.leadStages.label}
                    </div>
                  ),
                },
                {
                  label: "Mobile",
                  key: "mobile",
                  width: 120,
                },
                {
                  label: "Source",
                  width: 120,
                  render: (row) => row.crmSources?.name || "No source",
                },
                {
                  label: "Owner",
                  width: 120,
                  render: (row) => heffl.format.name(row.ownedBy) || "No owner",
                },
                {
                  label: "Assignees",
                  width: 120,
                  render: (row) =>
                    row.leadAssignees
                      ?.map((a) => heffl.format.name(a.users))
                      .join(", "),
                },
                {
                  label: "Email",
                  key: "email",
                  width: 120,
                },
                {
                  label: "Mobile (secondary)",
                  key: "secondaryMobile",
                  width: 120,
                },
                {
                  label: "Lead Created",
                  key: "createdAt",
                  width: 170,
                  render: (row) => heffl.format.date(row.createdAt),
                },
              ]}
              rows={leads?.leads || []}
              idKey="id"
            />
          </>
        ))}
    </div>
  );
};

export default LeadsByStatus;
