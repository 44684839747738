import { ClientInput } from "@/components/FormComponents";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FormList from "@heffl/ui/components/primitives/form-list";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { cn, formatCurrency, formatName } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreditCard, Trash2, UserCircle } from "lucide-react";
import { capitalize, sum } from "radash";
import { useEffect } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import heffl from "@/helpers/heffl";
import InfoItems from "@heffl/ui/components/info-items";

const PaymentForm = ({
  form,
  edit = false,
  paymentAllocationsDetails,
}: {
  edit?: boolean;
  form: UseFormReturn<z.infer<typeof Schemas.sales.invoicePayment>>;
  paymentAllocationsDetails: {
    type: "INVOICE" | "OPENING_BALANCE";
    invoiceId?: number | null;
    amountTotal: number;
    amountDue: number;
    title: string;
  }[];
}) => {
  const clientId = form.watch("clientId");
  const amount = form.watch("amount");
  const paymentReceivedAllocations = form.watch("paymentReceivedAllocations");

  const totalAllocatedAmount = sum(
    paymentReceivedAllocations,
    (pa) => pa.amount
  );

  const { data: paymentMethods } =
    trpc.invoices.payments.methods.list.useQuery();
  const { data: users } = trpc.users.list.useQuery();

  const { data: depositToAccounts } = trpc.books.chartOfAccounts.list.useQuery({
    type: "PAID_THROUGH",
  });

  useEffect(() => {
    if (paymentMethods?.length && !edit) {
      const defaultPaymentMethod =
        paymentMethods.find((method) => method.isDefault) || paymentMethods[0];
      form.setValue("paymentMethodId", defaultPaymentMethod.id);
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (depositToAccounts?.chartOfAccounts?.length && !edit) {
      form.setValue(
        "depositToAccountId",
        depositToAccounts.chartOfAccounts.find(
          (account) => account.name === "Undeposited Funds"
        )?.id || depositToAccounts.chartOfAccounts[0].id
      );
    }
  }, [depositToAccounts]);

  return (
    <>
      <ClientInput name="clientId" label="Client" disabled={edit} />
      <div
        className={cn(
          "space-y-2",
          !clientId ? "opacity-50 pointer-events-none" : ""
        )}
      >
        <div className="grid grid-cols-3 gap-2">
          <FormField name="date" label="Date">
            <DatePicker />
          </FormField>
          <FormField name="paymentMethodId" label="Payment method">
            <Select
              icon={CreditCard}
              allowClear={false}
              placeholder="Select payment method"
              options={
                paymentMethods?.map((method) => ({
                  label: method.name,
                  value: method.id,
                })) || []
              }
            />
          </FormField>
          <FormField name="depositToAccountId" label="Deposit to account">
            <Select
              icon={heffl.icons.books.banking.icon}
              placeholder="Select deposit to account"
              options={
                depositToAccounts?.chartOfAccounts.map((account) => ({
                  label: account.name,
                  value: account.id,
                  type: account.type,
                })) || []
              }
              render={(option) => (
                <>
                  <span className="text-gray-500">
                    {capitalize(option.type)}
                  </span>
                  : {option.label}
                </>
              )}
            />
          </FormField>

          <FormField name="amount" label="Amount">
            <Input placeholder="Amount" prefix="AED" prefixFilled />
          </FormField>
          <FormField name="collectedByUserId" label="Collected by">
            <Select
              icon={UserCircle}
              placeholder="Select collected by"
              options={
                users?.map((user) => ({
                  label: formatName(user),
                  value: user.id,
                })) || []
              }
            />
          </FormField>
          <FormField
            name="refNo"
            label="Ref No"
            className="col-span-2 sm:col-span-1"
          >
            <Input placeholder="Ref No" />
          </FormField>
        </div>

        <FormList name="paymentReceivedAllocations">
          {({ _name }) => (
            <div className="p-2 space-y-2 rounded-lg border border-gray-200">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-2 font-semibold text-left">Invoice</th>
                    <th className="p-2 font-semibold text-right">Amount</th>
                    <th className="p-2 font-semibold text-right">Pending</th>
                    <th className="p-2 font-semibold text-right">Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentAllocationsDetails?.map((pa, index) => {
                    return (
                      <tr key={index} className="border-b hover:bg-gray-50">
                        <td className="p-2">{pa.title}</td>
                        <td className="p-2 text-right">
                          {formatCurrency(pa.amountTotal, "AED")}
                        </td>
                        <td className="p-2 text-right">
                          {formatCurrency(pa.amountDue, "AED")}
                        </td>
                        <td className="flex justify-end p-2 text-right">
                          <FormField
                            name={_name(index, "amount")}
                            className="w-20"
                          >
                            <Input placeholder="Amount" />
                          </FormField>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </FormList>
        <div className="flex justify-end">
          <div className="w-2/3">
            <InfoItems
              items={[
                {
                  label: "Amount Received",
                  children: heffl.format.currency(amount || 0, "AED"),
                },
                {
                  label: "Amount used for Payments",
                  children: heffl.format.currency(totalAllocatedAmount, "AED"),
                },
                {
                  label: (
                    <div className="flex gap-1 items-center">
                      <span>Amount in Excess</span>
                      {amount > totalAllocatedAmount && (
                        <span className="text-orange-500">⚠</span>
                      )}
                    </div>
                  ),
                  children: heffl.format.currency(
                    (amount || 0) - totalAllocatedAmount,
                    "AED"
                  ),
                },
              ]}
              bordered={true}
              size="small"
              column={1}
            />
          </div>
        </div>
        <FormField name="notes" label="Notes">
          <Textarea placeholder="Notes" />
        </FormField>
      </div>
    </>
  );
};

type paymentModalProps = {
  open: boolean;
  onClose: () => void;
  defaultValues?: {
    clientId?: number;
    invoiceId?: number;
  };
};

export const AddPaymentReceivedModal = ({
  open,
  onClose,
  defaultValues,
}: paymentModalProps) => {
  const form = useForm<z.infer<typeof Schemas.sales.invoicePayment>>({
    defaultValues: {
      date: new Date(),
      paymentReceivedAllocations: [],
    },
    resolver: zodResolver(Schemas.sales.invoicePayment),
  });

  const clientId = form.watch("clientId");

  const { data: pendingInvoices } = trpc.invoices.payments.pendingList.useQuery(
    {
      clientId: clientId!,
    },
    {
      enabled: !!clientId,
    }
  );

  useEffect(() => {
    if (defaultValues?.clientId) {
      form.setValue("clientId", defaultValues.clientId);
    }
  }, [defaultValues]);

  const paymentAddMutation = trpc.sales.paymentsRecieved.add.useMutation({
    onSuccess() {
      toast.success("Payment added successfully");
      onClose();
      form.reset();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.sales.invoicePayment>) => {
    const totalAllocatedAmount = Number(
      sum(values.paymentReceivedAllocations, (pa) => Number(pa.amount))
    );
    if (totalAllocatedAmount > values.amount) {
      return toast.error("Payment amount is less than the allocated amount");
    }
    paymentAddMutation.mutate(values);
    form.reset();
  };

  useEffect(() => {
    if (defaultValues?.invoiceId) {
      const invoice = pendingInvoices?.find(
        (pa) => pa.invoiceId === defaultValues.invoiceId
      );
      if (invoice) {
        form.setValue("amount", invoice.amountDue < 0 ? 0 : invoice.amountDue);
      }
    }
  }, [defaultValues, pendingInvoices]);

  useEffect(() => {
    if (pendingInvoices) {
      form.setValue(
        "paymentReceivedAllocations",
        pendingInvoices.map((pa) => ({
          type: pa.type,
          amount: defaultValues?.invoiceId === pa.invoiceId ? pa.amountDue : 0,
          invoiceId: pa.invoiceId,
        }))
      );
    }
  }, [pendingInvoices]);

  return (
    <ModalDrawer
      modalClassName="min-w-[600px]"
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      title="Add payment"
      footer={
        <Button
          className="w-full"
          variant="primary"
          type="submit"
          loading={paymentAddMutation.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
        >
          Add payment
        </Button>
      }
    >
      <Form
        {...form}
        onSubmit={async (values) => paymentAddMutation.mutate(values)}
      >
        <PaymentForm
          form={form}
          paymentAllocationsDetails={pendingInvoices || []}
        />
      </Form>
    </ModalDrawer>
  );
};

export const EditPaymentReceivedModal = ({
  open,
  onClose,
  id,
}: {
  id: number;
  open: boolean;
  onClose: () => void;
}) => {
  const form = useForm<z.infer<typeof Schemas.sales.invoicePayment>>();
  const confirm = useConfirm();

  const { data: paymentDetails } = trpc.invoices.payments.details.useQuery(id);

  const paymentUpdateMutation = trpc.sales.paymentsRecieved.update.useMutation({
    onSuccess() {
      toast.success("Payment updated successfully");
      onClose();
      form.reset();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const deletePaymentMutation = trpc.sales.paymentsRecieved.delete.useMutation({
    onSuccess() {
      toast.success("Payment deleted successfully");
      onClose();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    if (paymentDetails) {
      form.reset(paymentDetails);
    }
  }, [paymentDetails]);

  useEffect(() => {
    if (paymentDetails) {
      form.setValue(
        "paymentReceivedAllocations",
        paymentDetails.paymentReceivedAllocations.map((pa) => ({
          type: pa.type,
          amount: pa.amount,
          invoiceId: pa.invoiceId,
        }))
      );
    }
  }, [paymentDetails]);

  const onSubmit = (values: z.infer<typeof Schemas.sales.invoicePayment>) => {
    paymentUpdateMutation.mutate({
      id,
      payment: values,
    });
  };

  return (
    <ModalDrawer
      open={open}
      modalClassName="min-w-[600px]"
      onClose={() => {
        onClose();
        form.reset();
      }}
      title="Update payment"
      footer={
        <div className="flex gap-2 items-center w-full">
          <Button
            variant="destructiveOutline"
            icon={Trash2}
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this payment?",
                body: "This action cannot be undone.",
              });
              if (confirmed) {
                deletePaymentMutation.mutate(id);
              }
            }}
          />
          <Button
            className="!w-full"
            variant="primary"
            type="submit"
            loading={paymentUpdateMutation.isLoading}
            onClick={() => form.handleSubmit(onSubmit)()}
          >
            Update payment
          </Button>
        </div>
      }
    >
      {!paymentDetails && <FullScreenSpinner className="h-60" />}
      {paymentDetails && (
        <Form {...form} onSubmit={onSubmit}>
          <PaymentForm
            form={form}
            edit
            paymentAllocationsDetails={
              paymentDetails.paymentReceivedAllocations
            }
          />
        </Form>
      )}
    </ModalDrawer>
  );
};
