import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { formatCurrency, formatValue } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { CircleDollarSignIcon, Hammer } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { getJobId } from "../jobs/list";

type Filters = {
  expiryDateRange: [Date, Date] | undefined;
};

const ContractExpiringJobs = () => {
  const navigate = useNavigate();
  const permissions: ReturnType<typeof usePermissions> = usePermissions();

  const [filters, setFilters] = useParamsState<Filters>({
    expiryDateRange: [
      dayjs().startOf("month").toDate(),
      dayjs().endOf("month").toDate(),
    ],
  });

  const { data: contractExpiringJobs } =
    trpc.fieldService.reports.contractsExpiringJobs.useQuery({
      expiryDateRange: {
        startDate: filters.expiryDateRange?.[0] ?? new Date(),
        endDate: filters.expiryDateRange?.[1] ?? new Date(),
      },
    });

  console.log(contractExpiringJobs);

  if (!contractExpiringJobs) return <FullScreenSpinner />;

  return (
    <Page title="Contract Expiring Jobs" fullWidth className="sm:p-0">
      <div className="flex flex-col gap-2 mt-3 w-4/5 sm:mt-0 sm:items-center sm:p-3 sm:flex-row">
        <FilterBar
          onChange={() => {}}
          filters={[
            {
              key: "expiryDateRange",
              type: "date-range",
              label: "Expiry Date",
              value: filters.expiryDateRange,
              presets: [
                {
                  label: "This Month",
                  value: [
                    dayjs().startOf("month").toDate(),
                    dayjs().endOf("month").toDate(),
                  ],
                },
                {
                  label: "Next Month",
                  value: [
                    dayjs().add(1, "month").startOf("month").toDate(),
                    dayjs().add(1, "month").endOf("month").toDate(),
                  ],
                },
                {
                  label: "Next 3 Months",
                  value: [
                    dayjs().startOf("day").toDate(),
                    dayjs().add(3, "months").endOf("day").toDate(),
                  ],
                },
                {
                  label: "This Year",
                  value: [
                    dayjs().startOf("year").toDate(),
                    dayjs().endOf("year").toDate(),
                  ],
                },
              ],
              onChange: (e) => {
                setFilters({
                  expiryDateRange: e,
                });
              },
            },
          ]}
          suffix={
            <Card parentClassName="p-1">
              <div className="flex gap-2 justify-between">
                <div className="text-gray-500 flex items-center gap-0.5">
                  <CircleDollarSignIcon className="w-4 h-4" />
                  Total:{" "}
                </div>
                <div className="font-medium text-gray-700">
                  {" "}
                  {formatCurrency(contractExpiringJobs.meta.totalAmount, "AED")}
                </div>
              </div>
            </Card>
          }
        />
      </div>
      {contractExpiringJobs?.jobs ? (
        <DataGrid
          rowKey="id"
          className="mt-14 md:mt-0"
          name="contractExpiringJobs"
          allowExport={permissions?.EXPORT_JOBS.allowed}
          label="Jobs"
          rows={contractExpiringJobs.jobs}
          onCellClick={(row) => {
            navigate(`/field-service/jobs/details/${row.row.id}`);
          }}
          empty={{
            icon: Hammer,
            title: "No jobs",
            description: "No contract expiring jobs in this period.",
          }}
          columns={[
            {
              key: "id",
              name: "Id",
              width: 100,
              renderCell: ({ row }) => (
                <div className="font-medium text-gray-700">{getJobId(row)}</div>
              ),
            },
            {
              key: "client",
              name: "Client",
              width: 250,
              renderCell: ({ row }) => (
                <div className="font-medium text-gray-700">
                  {row.fsProperties.clients.name} -{" "}
                  <span className="text-gray-500">
                    {formatValue.propertyName(row.fsProperties)}
                  </span>
                </div>
              ),
            },
            {
              key: "service",
              name: "Service",
              width: 200,
              renderCell: ({ row }) => (
                <div className="!font-medium relative flex items-center group cursor-pointer gap-2">
                  {row.fsJobServices.map((service) => service.name).join(", ")}
                </div>
              ),
            },
            {
              key: "startDate",
              name: "Start Date",
              width: 120,
              renderCell: ({ row }) => (
                <div className="font-medium text-gray-700">
                  {dayjs(row.startDate).format("MMM DD, YYYY")}
                </div>
              ),
            },
            {
              key: "expiringIn",
              name: "Expiring in",
              width: 120,
              renderCell: ({ row }) => (
                <div className="font-medium text-gray-700">
                  {dayjs(row.endDate).diff(dayjs(), "days")} days
                </div>
              ),
            },
            {
              key: "endDate",
              name: "End Date",
              width: 120,
              renderCell: ({ row }) => (
                <div className="font-medium text-gray-700">
                  {dayjs(row.endDate).format("MMM DD, YYYY")}
                </div>
              ),
            },
          ]}
        />
      ) : (
        <FullScreenSpinner />
      )}
    </Page>
  );
};

export default ContractExpiringJobs;
