import ReportsHome from "@/components/reports-home";
import TodaysLeads from "./todays-leads";
import LeadsBySource from "./leads-by-source";
import LeadsByOwnership from "./leads-by-ownership";
import LeadsByStatus from "./leads-by-status";
import LeadsConversion from "./leads-conversion";
import LostReasonsReport from "./lost-reasons-report";

const LeadsReports = () => {
  return (
    <ReportsHome
      title="Leads Reports"
      reports={[
        {
          links: [
            {
              id: "todays-leads",
              label: "Today's Leads",
              children: <TodaysLeads />,
            },
            {
              id: "leads-by-source",
              label: "Leads by Source",
              children: <LeadsBySource />,
            },
            {
              id: "leads-by-ownership",
              label: "Leads by Ownership",
              children: <LeadsByOwnership />,
            },
            {
              id: "leads-by-status",
              label: "Leads by Status",
              children: <LeadsByStatus />,
            },
            {
              id: "leads-conversion",
              label: "Leads Conversion",
              children: <LeadsConversion />,
            },
            {
              id: "lost-reasons",
              label: "Lost Reasons",
              children: <LostReasonsReport />,
            },
          ],
        },
      ]}
    />
  );
};

export default LeadsReports;
