import { MultipleUserInput, UserInput } from "@/components/FormComponents";
import { trpc } from "@/helpers/trpc";
import { ObjectAttribute } from "@heffl/server/src/routes/customizations/objects/objectsSchema";
import Schemas from "@heffl/server/src/schemas";
import Select from "@heffl/ui/components/primitives/select";
import { FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import { Mail } from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import CustomFieldsInputForm from "../../../../helpers/customFields/custom-fields-input-form";
import { PhoneInput } from "@/components/ui/phone-input";

const LeadForm = ({
  form,
  edit = false,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.crm.lead>, unknown>;
  edit?: boolean;
}) => {
  const navigate = useNavigate();

  const [titleTouched, setTitleTouched] = useState(false);

  const { data: crmSources } = trpc.sources.list.useQuery();
  const { data: leadObject } =
    trpc.customizations.objects.details.useQuery("lead");

  const leadName = form.watch("name");
  const objectTemplateId = form.watch("objectTemplateId");

  const { data: users } = trpc.users.list.useQuery();

  useEffect(() => {
    if (!titleTouched && leadName && !edit) {
      form.setValue("title", `${leadName}'s Lead`);
    }
  }, [leadName]);

  useEffect(() => {
    if (leadObject?.templates.length && !edit) {
      form.setValue("objectTemplateId", leadObject.templates[0].id);
    }
  }, [leadObject]);

  if (!users || !leadObject) return <FullScreenSpinner />;

  const fieldsRender = {
    name: (
      <FormField name="name" label="Name">
        <Input placeholder="Person/Business name" autoFocus />
      </FormField>
    ),
    mobile: (
      <FormField name="mobile">
        <PhoneInput placeholder="Mobile" defaultCountry="AE" />
      </FormField>
    ),
    email: (
      <FormField name="email">
        <Input placeholder="Email" prefix={<Mail className="h-5" />} />
      </FormField>
    ),
    secondaryMobile: (
      <FormField name="secondaryMobile">
        <PhoneInput placeholder="Secondary Mobile" defaultCountry="AE" />
      </FormField>
    ),
    title: (
      <FormField name="title" label="Title">
        <Input placeholder="Title" onInput={() => setTitleTouched(true)} />
      </FormField>
    ),
    value: (
      <FormField name="value" label="Expected value">
        <Input prefix="AED" prefixFilled type="number" placeholder="00.00" />
      </FormField>
    ),
    crmSourceId: (
      <FormField name="crmSourceId" label="Source">
        <Select
          createButton={{
            label: "Add new",
            onClick: () => navigate("/settings/sources"),
          }}
          placeholder="Select source"
          options={
            crmSources?.map((source) => ({
              label: source.name,
              value: source.id,
            })) || []
          }
        />
      </FormField>
    ),
    leadAssignees: (
      <MultipleUserInput
        name="leadAssignees"
        label="Assigned to"
        className="w-full"
      />
    ),
    ownerUserId: (
      <UserInput
        className="sm:col-span-1"
        name="ownerUserId"
        label="Owner"
        defaultCurrentUser={!edit}
      />
    ),
  };

  const getTemplateAttributes = () => {
    if (!objectTemplateId) {
      return leadObject.attributes;
    }

    const templateAttributes =
      leadObject.templates.find((template) => template.id === objectTemplateId)
        ?.attributes || [];

    return templateAttributes.map((ta) => ({
      ...leadObject.attributes.find((la) => la.name === ta.name),
      width: ta.width,
      label: ta.label,
    })) as ObjectAttribute[];
  };

  return (
    <div>
      {!!leadObject.templates.length && (
        <StripeTabs
          value={objectTemplateId ? objectTemplateId.toString() : ""}
          onChange={(value) =>
            form.setValue("objectTemplateId", value ? Number(value) : null)
          }
          className="-mt-2 mb-3 w-full"
          items={[
            {
              label: "All",
              key: "",
            },
            ...leadObject.templates.map((template) => ({
              label: template.name,
              key: template.id.toString(),
            })),
          ]}
        />
      )}
      <div className="grid grid-cols-2 gap-2">
        {getTemplateAttributes().map((field) => (
          <div
            key={field.name}
            className={cn(
              field.width === 100 && "col-span-2",
              isMobile() && field.mobileWidth === 100 && "col-span-2"
            )}
          >
            {/* @ts-ignore */}
            {field.type === "system" && fieldsRender[field.name]}
            {field.type === "custom" && (
              <CustomFieldsInputForm showFields={[field.name]} section="LEAD" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeadForm;
