import FilterBar from "@/components/filter-bar";
import heffl from "@/helpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import StatsBar from "@heffl/ui/components/stats-cards";
import {
  ArrowUpCircle,
  CheckCircle,
  Handshake,
  Target,
  TrendingUp,
} from "lucide-react";
import {
  Funnel,
  FunnelChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { match } from "ts-pattern";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import EmptyScreen from "@heffl/ui/components/primitives/empty-screen";

const LeadsConversion = () => {
  const [filters, setFilters] = useParamsState<
    RouterInputs["crm"]["reports"]["leads"]["leadsConversion"]
  >({
    dates: [heffl.date.monthStart(), heffl.date.monthEnd()],
    owners: [],
    sources: [],
  });

  const { data: leads, isLoading } =
    trpc.crm.reports.leads.leadsConversion.useQuery({
      dates: filters.dates,
      owners: filters.owners,
      sources: filters.sources,
    });

  const { data: users } = trpc.users.list.useQuery({
    type: ["STAFF", "ADMIN", "OWNER", "SUPER_ADMIN"],
  });
  const { data: leadSources } = trpc.sources.list.useQuery();

  const funnelData = [
    {
      name: "Total Leads",
      value: leads?.stats.count || 0,
      fill: "#60a5fa",
    },
    {
      name: "Qualified Leads",
      value: leads?.stats.qualifiedLeads || 0,
      fill: "#34d399",
    },
    {
      name: "Converted Leads",
      value: leads?.stats.convertedDeals || 0,
      fill: "#f59e0b",
    },
  ];

  return (
    <div>
      <FilterBar
        defaultFilters={["owners", "sources"]}
        suffix={
          <div className="p-1 px-2 rounded-lg border">
            Count: {leads?.stats.count}
          </div>
        }
        onChange={() => {}}
        className="pb-3"
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Created at",
            value: filters.dates,
            onChange: (value) =>
              setFilters({
                dates: value,
              }),
          },
          {
            key: "owners",
            type: "checkbox",
            label: "Owners",
            value: filters.owners,
            onChange: (value) =>
              setFilters({
                owners: value as number[],
              }),
            options:
              users?.map((user) => ({
                label: heffl.format.name(user),
                value: user.id,
              })) || [],
          },
          {
            key: "sources",
            type: "checkbox",
            label: "Sources",
            value: filters.sources,
            onChange: (value) =>
              setFilters({
                sources: value as number[],
              }),
            options:
              leadSources?.map((source) => ({
                label: source.name,
                value: source.id,
              })) || [],
          },
        ]}
      />

      {match({ isLoading, leads })
        .with({ isLoading: true }, () => <FullScreenSpinner />)
        .with({ isLoading: false, leads: { stats: { count: 0 } } }, () => (
          <EmptyScreen title="No data found" />
        ))
        .otherwise(() => (
          <>
            <StatsBar
              items={[
                {
                  icon: Target,
                  title: "Total Leads",
                  value: leads?.stats.count.toString() || "0",
                },
                {
                  icon: CheckCircle,
                  title: "Qualified Leads",
                  value: `${leads?.stats.qualifiedLeads.toString()}`,
                },
                {
                  icon: TrendingUp,
                  title: "Qualified Rate",
                  value: leads?.stats.count
                    ? `${(
                        (leads.stats.qualifiedLeads / leads.stats.count) *
                        100
                      ).toFixed(1)}%`
                    : "0%",
                },
                {
                  icon: Handshake,
                  title: "Converted Leads",
                  value: leads?.stats.convertedDeals.toString() || "0",
                  info: "Deals won from leads",
                },
                {
                  icon: ArrowUpCircle,
                  title: "Qualified Lead Conversion",
                  info: "Deals won from qualified leads",
                  value: leads?.stats.qualifiedLeads
                    ? `${(
                        (leads.stats.convertedDeals /
                          leads.stats.qualifiedLeads) *
                        100
                      ).toFixed(1)}%`
                    : "0%",
                },
                {
                  icon: ArrowUpCircle,
                  title: "Lead Conversion",
                  info: "Deals won from all leads",
                  value: leads?.stats.count
                    ? `${(
                        (leads.stats.convertedDeals / leads.stats.count) *
                        100
                      ).toFixed(1)}%`
                    : "0%",
                },
              ]}
            />

            <div className="mt-8 h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <FunnelChart>
                  <Tooltip />
                  <Funnel
                    data={funnelData}
                    dataKey="value"
                    nameKey="name"
                    isAnimationActive
                  >
                    <LabelList
                      position="right"
                      fill="#000"
                      stroke="none"
                      dataKey="name"
                    />
                    <LabelList
                      position="center"
                      fill="#fff"
                      stroke="none"
                      dataKey="value"
                      style={{ fontSize: "12px", paddingLeft: "10px" }}
                    />
                  </Funnel>
                </FunnelChart>
              </ResponsiveContainer>
            </div>
          </>
        ))}
    </div>
  );
};

export default LeadsConversion;
