import dayjs from "dayjs";
import { useState, useEffect } from "react";

type Props = {
  value?: Date;
  onChange?: (value: Date) => void;
  className?: string;
  allowClear?: boolean;
  disabled?: boolean;
  triggerOnBlur?: boolean;
};

const TimePicker = ({
  onChange,
  value,
  disabled = false,
  triggerOnBlur = false,
}: Props) => {
  const [internalValue, setInternalValue] = useState<string>(
    value ? dayjs(value).format("HH:mm") : ""
  );

  useEffect(() => {
    if (value) {
      setInternalValue(dayjs(value).format("HH:mm"));
    }
  }, [value]);

  const handleBlur = () => {
    if (triggerOnBlur && onChange) {
      const newDate = new Date();
      newDate.setHours(parseInt(internalValue.split(":")[0]));
      newDate.setMinutes(parseInt(internalValue.split(":")[1]));
      const previousValue = value ? dayjs(value).format("HH:mm") : "";
      if (previousValue !== internalValue) {
        onChange(newDate);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(e.target.value);
    if (!triggerOnBlur && onChange) {
      const newDate = new Date();
      newDate.setHours(parseInt(e.target.value.split(":")[0]));
      newDate.setMinutes(parseInt(e.target.value.split(":")[1]));
      onChange(newDate);
    }
  };

  return (
    <input
      disabled={disabled}
      placeholder="HH:mm"
      className="px-2 w-full h-7 text-xs rounded-md border"
      value={disabled ? "" : internalValue}
      onChange={handleChange}
      onBlur={handleBlur}
      type="time"
    />
  );
};

TimePicker.displayName = "TimePicker";

export default TimePicker;
