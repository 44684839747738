import dayjs from "dayjs";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";

type UseQueryParamsStateReturnType<T> = [
  T,
  Dispatch<SetStateAction<Partial<T>>>
];

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";

const isParamDateArray = (paramValue: unknown) => {
  return (
    Array.isArray(paramValue) &&
    paramValue.length === 2 &&
    typeof paramValue[0] === "string" &&
    typeof paramValue[1] === "string" &&
    dayjs(paramValue[0]).isValid() &&
    dayjs(paramValue[1]).isValid()
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useParamsState = <T extends Record<string, any>>(
  initialState: T
): UseQueryParamsStateReturnType<T> => {
  const location = useLocation();

  const [value, setValue] = useState<T>(() => {
    if (typeof window === "undefined") return initialState;

    const { search } = window.location;
    const searchParams = new URLSearchParams(search);

    return Object.fromEntries(
      Object.entries(initialState).map(([key, defaultValue]) => {
        let paramValue: unknown = searchParams.get(key);
        if (paramValue) {
          paramValue = JSON.parse(paramValue as string);
        }
        if (isParamDateArray(paramValue)) {
          paramValue = [
            dayjs((paramValue as string[])[0]).toDate(),
            dayjs((paramValue as string[])[1]).toDate(),
          ];
        }

        return [key, paramValue !== null ? paramValue : defaultValue];
      })
    ) as T;
  });

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);

    Object.entries(value).forEach(([key, val]) => {
      if (val !== null && val !== "" && val !== undefined) {
        if (Array.isArray(val) && val.length > 0) {
          const processedVal = val.map((item) =>
            item instanceof Date ? dayjs(item).format(DATE_TIME_FORMAT) : item
          );
          currentSearchParams.set(key, JSON.stringify(processedVal));
        } else if (typeof val === "object" && Object.keys(val).length > 0) {
          const nonEmptyObject = Object.fromEntries(
            Object.entries(val)
              .filter(([, v]) => v !== null && v !== undefined && v !== "")
              .map(([k, v]) => [
                k,
                v instanceof Date ? dayjs(v).format(DATE_TIME_FORMAT) : v,
              ])
          );
          if (Object.keys(nonEmptyObject).length > 0) {
            currentSearchParams.set(key, JSON.stringify(nonEmptyObject));
          } else {
            currentSearchParams.delete(key);
          }
        } else if (
          typeof val === "string" ||
          typeof val === "number" ||
          typeof val === "boolean"
        ) {
          currentSearchParams.set(key, JSON.stringify(val));
        } else if (val instanceof Date) {
          currentSearchParams.set(
            key,
            JSON.stringify(dayjs(val).format(DATE_TIME_FORMAT))
          );
        } else {
          currentSearchParams.delete(key);
        }
      } else {
        currentSearchParams.delete(key);
      }
    });

    const newUrl = [window.location.pathname, currentSearchParams.toString()]
      .filter(Boolean)
      .join("?");

    window.history.replaceState(window.history.state, "", newUrl);
  }, [value, location.pathname]);

  const setValueWrapper: Dispatch<SetStateAction<Partial<T>>> = (
    newValueOrFunction
  ) => {
    setValue((prevValue) => {
      const newValue =
        typeof newValueOrFunction === "function"
          ? newValueOrFunction(prevValue)
          : newValueOrFunction;
      return { ...prevValue, ...newValue };
    });
  };

  return [value, setValueWrapper];
};
