import Calendar from "@event-calendar/core";
import DayGrid from "@event-calendar/day-grid";
import Interaction from "@event-calendar/interaction";
import { styled } from "goober";
import React, { useCallback, useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import { cn } from "../lib/utils";

type ScheduleEvent = {
  allDay: boolean;
  backgroundColor?: string;
  display: "auto";
  durationEditable?: boolean;
  editable?: boolean;
  extendedProps: Record<string, unknown>;
  id: string;
  resourceIds: string[];
  start: Date;
  end: Date;
  startEditable?: boolean;
  textColor?: string;
  title: string;
  titleHTML: string;
};

const CalendarWrapper = styled("div")<{ borderless?: boolean }>`
  .striped-background {
    background-image: repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1) 10px,
      transparent 10px,
      transparent 20px
    );
  }
  #ec-big-calendar > div > div.ec-header > div.ec-days > div.ec-day {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  background-color: white;
  .ec-event {
    border: 1px solid black;
    z-index: 1 !important;
  }
  .ec-toolbar {
    display: none;
  }
  .ec-body {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: ${(props) => (props.borderless ? "none" : "")};
  }
  .ec-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 40px;
    border-top: ${(props) => (props.borderless ? "none" : "")};
    border-left: ${(props) => (props.borderless ? "none" : "")};
    border-right: ${(props) => (props.borderless ? "none" : "")};
  }
  .ec-day-head {
    text-transform: uppercase;
  }
`;

type RenderEvent = {
  event: ScheduleEvent;
  timeText: string;
};

type BigCalendarProps = {
  borderless?: boolean;
  events: Array<{
    id: string;
    title: string;
    start: Date;
    end: Date;
    allDay?: boolean;
    backgroundColor?: string;
  }>;
  renderEvent?: (event: RenderEvent) => React.ReactNode;
  onEventClick?: (event: ScheduleEvent) => void;
  onDateSelect?: (info: { start: Date; end: Date; allDay: boolean }) => void;
  className?: string;
};

const BigCalendar: React.FC<BigCalendarProps> = ({
  events,
  onEventClick,
  onDateSelect,
  className,
  renderEvent,
  borderless = true,
}) => {
  const calendarRef = useRef<typeof Calendar | null>(null);

  const handleEventClick = useCallback(
    (arg: { event: ScheduleEvent }) => {
      if (onEventClick && arg && arg.event) {
        onEventClick(arg.event);
      }
    },
    [onEventClick]
  );

  const handleDateSelect = useCallback(
    (info: { start: Date; end: Date; allDay: boolean }) => {
      if (onDateSelect && info) {
        onDateSelect(info);
      }
    },
    [onDateSelect]
  );

  useEffect(() => {
    const targetElement = document.getElementById("ec-big-calendar");
    if (targetElement && !calendarRef.current) {
      calendarRef.current = new Calendar({
        target: targetElement,
        props: {
          plugins: [DayGrid, Interaction],
          options: {
            height: "100%",
            view: "dayGridMonth",
            events: events || [],
            eventClick: handleEventClick,
            selectable: true,
            select: handleDateSelect,
            dayHeaderFormat: { weekday: "short" },
            eventContent: renderEvent
              ? function (event: RenderEvent) {
                  return {
                    html: renderToString(renderEvent(event)),
                  };
                }
              : undefined,
          },
        },
      });
    } else if (calendarRef.current) {
      calendarRef.current.setOption("events", events || []);
    }
  }, [calendarRef, events, handleEventClick, handleDateSelect]);

  return (
    <CalendarWrapper className={className} borderless={borderless}>
      <div id="ec-big-calendar" className={cn("h-full")} />
    </CalendarWrapper>
  );
};

export default BigCalendar;
