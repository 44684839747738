import DropMenu from "@/components/DropMenu";
import { SearchInput } from "@/components/FormComponents";
import Pagination from "@/components/Pagination";
import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import calculateInvoice, {
  CalculateInvoice,
} from "@heffl/server/src/helpers/lineItems/calculateInvoice";
import enums from "@heffl/server/src/schemas/enums";
import TagsInput from "@heffl/ui/components/TagInput";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import TemplateRender from "@heffl/ui/components/template-render";
import { formatCurrency, formatName, isMobile } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import dayjs from "dayjs";
import {
  ArrowDownToLine,
  BadgeDollarSign,
  Building,
  Calendar,
  CalendarClock,
  CheckCircle,
  Copy,
  CreditCard,
  DollarSign,
  Eye,
  FolderDown,
  HandCoins,
  Mail,
  MoreHorizontal,
  Pencil,
  Tags,
  Timer,
  TrendingUp,
  User,
  X,
  Zap,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { z } from "zod";
import InvoiceCard from "./components/InvoiceCard";
import { downloadPdf, generateInvoiceName } from "./details";
import heffl from "@/helpers/heffl";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import SortBar from "@/components/sort-bar";

type InvoiceStatus = z.infer<typeof enums.invoiceStatus>;
type InvoiceProducts =
  RouterOutputs["invoices"]["list"]["invoices"][number]["invoiceProducts"];

export const getProductName = (products: InvoiceProducts) => {
  if (products.length > 1) {
    return (
      <p>
        {products[0].name}..{" "}
        <span className="text-primary">+{products.length - 1} more</span>
      </p>
    );
  }
  if (products.length === 1) {
    return products[0].name;
  }
  return "No product";
};

export const InvoiceStatusBadge = ({
  invoice,
}: {
  invoice: CalculateInvoice & {
    dueDate: Date;
    status: InvoiceStatus;
  };
}) => {
  if (invoice.status === "PAID") {
    return (
      <Badge variant="success" icon={CheckCircle}>
        Paid
      </Badge>
    );
  }
  if (invoice.status === "CANCELLED") {
    return (
      <Badge variant="error" icon={X}>
        Cancelled
      </Badge>
    );
  }

  if (invoice.status === "PARTIALLY_PAID") {
    return (
      <Badge variant="warning" icon={HandCoins}>
        Partially Paid
      </Badge>
    );
  }
  if (invoice.status === "SENT") {
    return (
      <Badge className="!bg-blue-300 text-white" icon={Mail}>
        Sent
      </Badge>
    );
  }
  return <Badge variant="neutral">Draft</Badge>;
};

type PageFilters = {
  search: string;
  pageNo: number;
  statuses: InvoiceStatus[];
  dates: [Date, Date] | undefined;
  createdAt: [Date, Date] | undefined;
  clients: number[];
  tags: number[];
  salesPersons: number[];
  pageSize: number;
  projectIds?: number[];
  productIds?: number[];
  sortBy: {
    date?: "asc" | "desc";
    createdAt?: "asc" | "desc";
    dueDate?: "asc" | "desc";
  };
};

type InvoicesListProps = {
  name: "invoicesListMain" | "invoicesListProject" | "invoicesListClient";
  filters: Partial<PageFilters>;
  pagination?: {
    setPageNo: (pageNo: number) => void;
    setPageSize: (pageSize: number) => void;
  };
  setInvoiceIds: (ids: number[]) => void;
  classes?: {
    dataGrid?: string;
  };
  setMeta?: ({ total }: { total: number }) => void;
};

export const InvoiceList = ({
  filters,
  pagination,
  classes,
  setInvoiceIds,
  name,
  setMeta,
}: InvoicesListProps) => {
  const navigate = useNavigate();
  const permissions = usePermissions();

  const { data: invoicesList, isLoading } = trpc.invoices.list.useQuery({
    ...filters,
    statuses: filters.statuses,
    pageNo: filters.pageNo,
    pageSize: filters.pageSize,
    clients: filters.clients,
    tags: filters.tags,
    startDate: filters.dates?.[0],
    endDate: filters.dates?.[1],
    salesPersons: filters.salesPersons,
    projectIds: filters.projectIds,
    productIds: filters.productIds,
  });
  const { data: invoiceTags } = trpc.tags.list.useQuery({
    type: "INVOICE",
  });

  const updateInvoiceMutation = trpc.invoices.update.useMutation({
    onError(error) {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    setInvoiceIds(invoicesList?.invoices.map((invoice) => invoice.id) || []);
  }, [invoicesList]);

  useEffect(() => {
    if (setMeta && invoicesList) {
      setMeta(invoicesList.meta);
    }
  }, [invoicesList]);

  return (
    <>
      {isLoading || !invoicesList ? (
        <FullScreenSpinner />
      ) : isMobile() ? (
        <div className="flex flex-col gap-2 mt-4">
          {invoicesList?.invoices.map((invoice) => (
            <InvoiceCard data={invoice} key={invoice.id} />
          ))}
          <Pagination
            pageNo={filters.pageNo || 1}
            count={invoicesList?.meta.count}
            setPageNo={(pageNo) => pagination?.setPageNo(pageNo)}
            className="justify-start"
          />
        </div>
      ) : (
        <DataGrid
          rowKey="id"
          className={classes?.dataGrid}
          onCellClick={(invoice) => {
            navigate(`/sales/invoices/details/${invoice.row.id}`);
          }}
          allowExport={permissions?.EXPORT_INVOICES.allowed}
          loading={isLoading}
          name={name}
          label="Invoices"
          empty={{
            icon: DollarSign,
            title: "No invoices found",
            description: "No invoices available to display.",
          }}
          pagination={{
            pageNo: filters.pageNo || 1,
            count: invoicesList?.meta.count,
            pageSize: filters.pageSize || 30,
            setPageNo: (pageNo) => pagination?.setPageNo(pageNo),
            setPageSize: (pageSize) => pagination?.setPageSize(pageSize),
          }}
          rows={invoicesList?.invoices || []}
          columns={[
            {
              key: "number",
              name: "#",
              width: 100,
              renderCell: ({ row }) => row.number,
            },
            {
              key: "client",
              name: "Client",
              width: 300,
              icon: Building,
              renderCell: ({ row }) => <p>{row.clients.name}</p>,
            },
            {
              key: "status",
              name: "Status",
              width: 130,
              icon: Eye,
              renderCell: ({ row }) => <InvoiceStatusBadge invoice={row} />,
            },
            {
              key: "date",
              name: "Date",
              width: 160,
              icon: Calendar,
              renderCell: ({ row }) => format(row.date, "dd/MM/yyyy"),
            },
            {
              key: "amount",
              name: "Amount",
              width: 160,
              icon: DollarSign,
              renderCell: ({ row }) =>
                formatCurrency(calculateInvoice(row).invoiceTotal, "AED", true),
            },
            {
              key: "tags",
              name: "Tags",
              width: 200,
              icon: Tags,
              renderCell: ({ row }) => (
                <TagsInput
                  value={row.invoiceTags.map((tag) => tag.tagId)}
                  onChange={(tags) => {
                    updateInvoiceMutation.mutate({
                      id: row.id,
                      invoice: {
                        invoiceTags: tags,
                      },
                    });
                  }}
                  tags={invoiceTags}
                />
              ),
            },
            {
              key: "dueDate",
              name: "Due Date",
              width: 160,
              icon: CalendarClock,
              renderCell: ({ row }) => format(row.dueDate, "dd/MM/yyyy"),
            },
            {
              key: "dueDays",
              name: "Due Days",
              width: 120,
              icon: Timer,
              renderCell: ({ row }) => {
                const diffDays = dayjs(dayjs()).diff(dayjs(row.dueDate), "day");
                return (
                  <span>
                    {diffDays > 0 &&
                    ["PARTIALLY_PAID", "SENT"].includes(row.status)
                      ? diffDays
                      : "-"}
                  </span>
                );
              },
            },
            {
              key: "pendingAmount",
              name: "Pending Amount",
              width: 160,
              icon: BadgeDollarSign,
              renderCell: ({ row }) =>
                formatCurrency(calculateInvoice(row).pendingTotal, "AED", true),
            },
            {
              key: "paidAmount",
              name: "Paid Amount",
              width: 160,
              icon: CreditCard,
              renderCell: ({ row }) =>
                formatCurrency(
                  calculateInvoice(row).paymentsTotal,
                  "AED",
                  true
                ),
            },
            {
              key: "profit",
              name: "Profit",
              width: 160,
              icon: TrendingUp,
              renderCell: ({ row }) => {
                const { invoiceTotal, totalBuyPrice } = calculateInvoice(row);
                return heffl.format.currency(
                  invoiceTotal - totalBuyPrice,
                  "AED",
                  true
                );
              },
            },
            {
              key: "salesPerson",
              name: "Sales Person",
              width: 160,
              icon: User,
              renderCell: ({ row }) => (
                <p>{row.salesPerson ? formatName(row.salesPerson) : "-"}</p>
              ),
            },
            {
              key: "actions",
              name: "Actions",
              width: 100,
              icon: Zap,
              renderCell: ({ row }) => (
                <DropMenu
                  items={[
                    {
                      label: "Edit",
                      icon: Pencil,
                      onClick: () => navigate(`edit/${row.id}`),
                    },
                    {
                      label: "Duplicate",
                      icon: Copy,
                      onClick: () => navigate(`add?invoiceId=${row.id}`),
                    },
                    {
                      label: "Download",
                      icon: ArrowDownToLine,
                      onClick: async () => {
                        await downloadPdf({
                          name: generateInvoiceName(row),
                          url: `print?invoice=${row.uuid}`,
                        });
                      },
                    },
                  ]}
                >
                  <Button
                    onClick={(e) => e.stopPropagation()}
                    variant={"ghost"}
                  >
                    <MoreHorizontal className="h-4 text-gray-700" />
                  </Button>
                </DropMenu>
              ),
            },
          ]}
        />
      )}
    </>
  );
};

const Invoices = () => {
  const navigate = useNavigate();
  const invoicesListRef = useRef(null);

  const printInvoice = useReactToPrint({
    content: () => invoicesListRef.current,
    documentTitle: "Heffl invoices export",
  });

  const [filters, setFilters] = useParamsState<PageFilters>({
    search: "",
    pageNo: 1,
    statuses: [],
    dates: undefined,
    clients: [],
    tags: [],
    salesPersons: [],
    pageSize: 30,
    productIds: [],
    createdAt: undefined,
    sortBy: {
      createdAt: "desc",
    },
  });
  const permissions = usePermissions();

  const [clientSearch, setClientSearch] = useState("");
  const [productSearch, setProductSearch] = useState("");
  const [invoiceIds, setInvoiceIds] = useState<number[]>([]);
  const [exportInvoiceList, setExportInvoiceList] = useState<boolean>(false);
  const [meta, setMeta] = useState<{
    total: number;
  }>({ total: 0 });

  const { data: invoices } = trpc.invoices.listDetails.useQuery(
    {
      ids: invoiceIds,
      sortBy: filters.sortBy,
    },
    {
      enabled: invoiceIds.length > 0 && exportInvoiceList,
    }
  );
  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });

  const { data: products } = trpc.products.list.useQuery({
    search: productSearch,
    pageSize: 10,
    include: filters.productIds || [],
  });

  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "INVOICE",
  });

  if (!permissions) return <FullScreenSpinner />;

  if (!permissions.VIEW_INVOICES.allowed) {
    return <NoPermissionScreen />;
  }
  return (
    <Page fullWidth title="Invoices" className="!p-0">
      <div className="flex flex-col gap-2 justify-between p-3 border-b sm:flex-row">
        <SearchInput
          placeholder="Search invoices..."
          value={filters.search}
          onChange={(e) => setFilters({ search: e })}
        />
        <div className="flex gap-2 items-center">
          <Button
            className="z-50"
            onClick={() => {
              setExportInvoiceList(true);
              setTimeout(() => {
                printInvoice();
                setExportInvoiceList(false);
              }, 2000);
            }}
            icon={FolderDown}
            loading={exportInvoiceList}
            disabled={!permissions.EXPORT_INVOICES.allowed}
          >
            Export PDF
          </Button>
          {permissions.CREATE_INVOICES.allowed && (
            <ResponsiveActionButton
              onClick={() => navigate("add")}
              text="Invoice"
            />
          )}
        </div>
      </div>
      {exportInvoiceList && (
        <div ref={invoicesListRef} className="invisible print:visible">
          {invoices?.map((invoice) => (
            <TemplateRender
              key={invoice.id}
              contentHtml={invoice.contentInjected}
              template={invoice.documentTemplates}
              previewMode={false}
            />
          ))}
        </div>
      )}

      <div className="flex flex-wrap gap-2 p-3 mt-0 mb-0">
        <SortBar
          options={[
            { label: "Date", value: "date", icon: Calendar },
            { label: "Due date", value: "dueDate", icon: Calendar },
            { label: "Created at", value: "createdAt", icon: Calendar },
          ]}
          value={filters.sortBy}
          onChange={(value) => setFilters({ sortBy: value })}
        />
        <FilterBar
          suffix={
            <div className="p-1 px-2 text-sm rounded-lg border shadow-sm">
              Total: {heffl.format.currency(meta.total || 0, "AED")}
            </div>
          }
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value }),
            },
            {
              key: "createdAt",
              type: "date-range",
              label: "Created at",
              value: filters.createdAt,
              onChange: (value) => setFilters({ createdAt: value }),
            },
            {
              key: "status",
              type: "checkbox",
              label: "Status",
              value: filters.statuses,
              onChange: (value) =>
                setFilters({ statuses: value as InvoiceStatus[] }),
              options: [
                { label: "Draft", value: "DRAFT" },
                { label: "Sent", value: "SENT" },
                { label: "Partially Paid", value: "PARTIALLY_PAID" },
                { label: "Paid", value: "PAID" },
                { label: "Cancelled", value: "CANCELLED" },
              ],
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Clients", // Added clients filter
              value: filters.clients,
              showSearch: true,
              onChange: (value) => setFilters({ clients: value as number[] }),
              onSearch: (value) => setClientSearch(value),
              options: clients?.clients.map((client) => ({
                label: client.name,
                value: client.id,
              })),
            },
            {
              key: "products",
              type: "checkbox",
              label: "Products",
              value: filters.productIds || [],
              onChange: (value) =>
                setFilters({ productIds: value as number[] }),
              showSearch: true,
              onSearch: (value) => setProductSearch(value),
              options: products?.products.map((product) => ({
                label: product.name,
                value: product.id,
              })),
            },
            {
              key: "tags",
              label: "Tags",
              type: "checkbox",
              value: filters.tags,
              options:
                tags?.map((tag) => ({
                  label: tag.name,
                  value: tag.id,
                })) ?? [],
              onChange: (e) => {
                setFilters({ tags: e as number[] });
              },
            },
            {
              key: "salesPersons",
              label: "Sales Persons",
              type: "checkbox",
              value: filters.salesPersons,
              options: users?.map((user) => ({
                label: formatName(user),
                value: user.id,
              })),
              onChange: (e) => {
                setFilters({ salesPersons: e as number[] });
              },
            },
          ]}
        />
      </div>
      <InvoiceList
        name="invoicesListMain"
        pagination={{
          setPageNo: (pageNo) => setFilters({ pageNo }),
          setPageSize: (pageSize) => setFilters({ pageSize }),
        }}
        setInvoiceIds={setInvoiceIds}
        filters={filters}
        classes={{
          dataGrid: "h-[calc(100vh-var(--header-height)-120px)]",
        }}
        setMeta={(m) => setMeta(m)}
      />
    </Page>
  );
};

export default Invoices;
