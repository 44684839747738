import heffl from "@/helpers/heffl";
import { trpc } from "@/helpers/trpc";
import InfoItems from "@heffl/ui/components/info-items";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { isMobile } from "@heffl/ui/lib/utils";
import { Pencil } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import EditCashflowModal from "./edit-cashflow-modal";

type CashflowTransactionDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  id: number;
};

const CashflowTransactionDetailsModal: React.FC<
  CashflowTransactionDetailsModalProps
> = ({ open, onClose, id }) => {
  const [showEdit, setShowEdit] = useState(false);

  const { data: transaction } =
    trpc.books.cashflowAccounts.transactions.details.useQuery(id);

  return (
    <>
      <ModalDrawer
        title="Transaction Details"
        open={open}
        onClose={onClose}
        modalClassName="max-w-2xl"
        actions={[
          {
            label: "Edit",
            icon: Pencil,
            onClick: () => setShowEdit(true),
          },
        ]}
        defaultActionsButtonSize="xs"
      >
        <EditCashflowModal
          open={showEdit}
          onClose={({ deleted }) => {
            setShowEdit(false);
            if (deleted) {
              onClose();
            }
          }}
          id={id}
        />
        {!transaction && <FullScreenSpinner />}
        {transaction && (
          <div className="flex flex-col gap-6">
            <div className="text-sm text-gray-500">Amount</div>
            <div className="-mt-6 text-2xl font-semibold">
              {heffl.format.currency(transaction.amount, "AED")}
            </div>

            <InfoItems
              column={isMobile() ? 1 : 2}
              size="small"
              items={[
                {
                  label: "Transaction Date",
                  children: heffl.format.date(transaction.date),
                },
                {
                  label: "Reference #",
                  children: transaction.refNo || "-",
                },
                {
                  label: "Transaction #",
                  children: `#${transaction.number}`,
                },
                {
                  label: "Type",
                  children: transaction.type
                    .split("_")
                    .map(heffl.format.capitalize)
                    .join(" "),
                },
                ...(transaction.type === "TRANSFER_TO_ACCOUNT"
                  ? [
                      {
                        label: "From Account",
                        children: (
                          <Link
                            to={`/books/cashflow-accounts/transactions/${transaction.accountId}`}
                            className="hover:underline text-primary-800"
                          >
                            {transaction.account.name}
                          </Link>
                        ),
                      },
                      {
                        label: "To Account",
                        children: (
                          <Link
                            to={`/books/cashflow-accounts/transactions/${transaction.creditAccountId}`}
                            className="hover:underline text-primary-800"
                          >
                            {transaction.creditAccount.name}
                          </Link>
                        ),
                      },
                    ]
                  : []),
                ...(transaction.type === "TRANSFER_FROM_ACCOUNT"
                  ? [
                      {
                        label: "From Account",
                        children: (
                          <Link
                            to={`/books/cashflow-accounts/transactions/${transaction.creditAccountId}`}
                            className="hover:underline text-primary-800"
                          >
                            {transaction.creditAccount.name}
                          </Link>
                        ),
                      },
                      {
                        label: "To Account",
                        children: (
                          <Link
                            to={`/books/cashflow-accounts/transactions/${transaction.accountId}`}
                            className="hover:underline text-primary-800"
                          >
                            {transaction.account.name}
                          </Link>
                        ),
                      },
                    ]
                  : []),
                {
                  label: "Payment Method",
                  children: transaction.paymentMethods?.name || "-",
                },
                {
                  label: "Created By",
                  children: heffl.format.name(transaction.createdBy),
                },
                {
                  label: "Created At",
                  children: heffl.format.dynamicDate(transaction.createdAt),
                },
                {
                  label: "Description",
                  children: transaction.description || "-",
                },
              ]}
            />
          </div>
        )}
      </ModalDrawer>
    </>
  );
};

export default CashflowTransactionDetailsModal;
