import { Button } from "./primitives/button";
import { X, LucideIcon } from "lucide-react";
import { cn } from "../lib/utils";
import { VariantProps } from "class-variance-authority";
import { buttonVariants } from "./primitives/button";

type ActionToolbarProps<T> = {
  selectedCount: number;
  actions?: {
    icon?: LucideIcon;
    key: T;
    label: string;
    onClick: () => void;
    variant?: VariantProps<typeof buttonVariants>["variant"];
    loading?: boolean;
    disabled?: boolean;
  }[];
  onClear: () => void;
  className?: string;
};

export const ActionsToolbar = <T extends string>({
  selectedCount,
  actions,
  onClear,
  className,
}: ActionToolbarProps<T>) => {
  if (!selectedCount) return null;
  return (
    <div
      className={cn(
        "fixed bottom-9 left-1/2 !z-50 p-3 px-5  bg-primary-700 text-white rounded-3xl transform flex items-center gap-2 animate-fadeInDown",
        className
      )}
    >
      <div className="flex gap-2 items-center pr-2">
        <div className="flex justify-center items-center px-2 h-5 text-sm text-gray-900 bg-white rounded-md">
          {selectedCount}
        </div>
        <p className="text-sm">selected</p>
      </div>

      {actions?.map((action) => (
        <Button
          className="text-gray-900 bg-white border-none !ring-0 hover:bg-gray-100"
          loading={action.loading}
          disabled={action.disabled}
          key={action.key.toString()}
          variant={action.variant}
          size="sm"
          icon={action.icon}
          onClick={action.onClick}
        >
          {action.label}
        </Button>
      ))}

      <Button
        onClick={onClear}
        size="sm"
        variant="ghost"
        className="ml-4 text-white hover:bg-gray-800 hover:bg-opacity-20 hover:text-white"
        icon={X}
      />
    </div>
  );
};
