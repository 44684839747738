import { trpc } from "@/helpers/trpc";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import SimpleTable from "@heffl/ui/components/simple-table";
import dayjs from "dayjs";
import InfoItems from "@heffl/ui/components/info-items";
import { Link } from "react-router-dom";
import heffl from "@/helpers/heffl";
import { Pencil, Receipt } from "lucide-react";

import { useState } from "react";
import { isMobile } from "@heffl/ui/lib/utils";
import { EditPaymentReceivedModal } from "../invoices/components/payment-modals";
import { PrintPreviewModal } from "./list";

type PaymentReceivedDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  id: number;
};

const PaymentReceivedDetailsModal = ({
  open,
  onClose,
  id,
}: PaymentReceivedDetailsModalProps) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showPaymentReceipt, setShowPaymentReceipt] = useState(false);

  const { data: paymentReceived } = trpc.invoices.payments.details.useQuery(id);

  const totalAllocatedAmount = heffl.math.sum(
    paymentReceived?.allocations.map((allocation) => allocation.amount) || []
  );

  return (
    <>
      {paymentReceived && (
        <PrintPreviewModal
          paymentUuid={paymentReceived.uuid}
          number={paymentReceived.number}
          open={showPaymentReceipt}
          onClose={() => setShowPaymentReceipt(false)}
        />
      )}
      <EditPaymentReceivedModal
        open={showEdit}
        onClose={() => setShowEdit(false)}
        id={id}
      />
      <ModalDrawer
        title="Payment Received Details"
        open={open}
        onClose={onClose}
        modalClassName="max-w-2xl"
        actions={[
          {
            label: "Receipt",
            icon: Receipt,
            onClick: () => setShowPaymentReceipt(true),
            iconClassName: "text-green-500",
          },
          {
            label: "Edit",
            icon: Pencil,
            onClick: () => setShowEdit(true),
          },
        ]}
        defaultActionsButtonSize="xs"
      >
        {!paymentReceived && <FullScreenSpinner />}
        {paymentReceived && (
          <div className="flex flex-col gap-6">
            <div className="text-sm text-gray-500">Amount</div>
            <div className="-mt-6 text-2xl font-semibold">
              {heffl.format.currency(paymentReceived.amount, "AED")}
            </div>

            <InfoItems
              column={isMobile() ? 1 : 2}
              size="small"
              items={[
                {
                  label: "Payment Date",
                  children: dayjs(paymentReceived.date).format("DD/MM/YYYY"),
                },
                {
                  label: "Reference #",
                  children: paymentReceived.refNo || "-",
                },
                {
                  label: "Payment #",
                  children: `#${paymentReceived.number}`,
                },
                {
                  label: "Client",
                  children: (
                    <Link
                      to={`/sales/clients/details/${paymentReceived.clients.id}`}
                      className="hover:underline text-primary-800"
                    >
                      {paymentReceived.clients.name}
                    </Link>
                  ),
                },
                {
                  label: "Deposit Account",
                  children: paymentReceived.depositToAccount.name,
                },
                {
                  label: "Created At",
                  children: dayjs(paymentReceived.createdAt).format(
                    "DD/MM/YYYY"
                  ),
                },
              ]}
            />

            <SimpleTable
              fixedHeader={false}
              columns={[
                {
                  label: "Date",
                  key: "date",
                  render: (row) => dayjs(row.date).format("DD/MM/YYYY"),
                },
                {
                  label: "Invoice Number",
                  key: "title",
                  render: (row) =>
                    row.invoiceId ? (
                      <Link
                        to={`/sales/invoices/details/${row.invoiceId}`}
                        className="hover:underline text-primary-800"
                      >
                        {row.title}
                      </Link>
                    ) : (
                      row.title
                    ),
                },
                {
                  label: "Invoice Amount",
                  key: "amountTotal",
                  render: (row) =>
                    heffl.format.currency(row.amountTotal, "AED", true),
                },
                {
                  label: "Due Amount",
                  key: "amountDue",
                  render: (row) =>
                    heffl.format.currency(row.amountDue, "AED", true),
                },
                {
                  label: "Payment Amount",
                  key: "amount",
                  render: (row) =>
                    heffl.format.currency(row.amount, "AED", true),
                },
              ]}
              rows={paymentReceived.allocations}
              idKey="id"
            />

            <div className="flex justify-end">
              <div className="w-2/3">
                <InfoItems
                  items={[
                    {
                      label: "Amount Received",
                      children: heffl.format.currency(
                        paymentReceived.amount,
                        "AED"
                      ),
                    },
                    {
                      label: "Amount used for Payments",
                      children: heffl.format.currency(
                        totalAllocatedAmount,
                        "AED"
                      ),
                    },
                    {
                      label: (
                        <div className="flex gap-1 items-center">
                          <span>Amount in Excess</span>
                          {paymentReceived.amount > totalAllocatedAmount && (
                            <span className="text-orange-500">⚠</span>
                          )}
                        </div>
                      ),
                      children: heffl.format.currency(
                        paymentReceived.amount - totalAllocatedAmount,
                        "AED"
                      ),
                    },
                  ]}
                  bordered={true}
                  size="small"
                  column={1}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 items-end">
              <div className="flex gap-8 pb-2 border-b">
                <div className="text-sm text-gray-500">Subtotal</div>
                <div className="text-sm text-gray-500">
                  {heffl.format.currency(paymentReceived.amount, "AED", true)}
                </div>
              </div>
              <div className="flex gap-8 pb-2 border-b">
                <div className="text-sm font-medium">Total</div>
                <div className="text-sm font-medium">
                  {heffl.format.currency(paymentReceived.amount, "AED", true)}
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalDrawer>
    </>
  );
};

export default PaymentReceivedDetailsModal;
