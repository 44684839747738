import Page from "@/components/page";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Sheet } from "@heffl/ui/components/primitives/sheet";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { isMobile } from "@heffl/ui/lib/utils";
import { CalendarDays, List, Plus } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import AddJob from "../jobs/add";
import { jobSchema } from "../jobs/components/job-form";
import SchedulesCalendar from "./calendar";
import SchedulesList from "./list";
import usePermissions from "@/lib/hooks/usePermissions";

const Schedules = () => {
  const { view } = useParams();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [syncedDates, setSyncedDates] = useState<[Date, Date] | undefined>(
    undefined
  );
  const [showAddJob, setShowAddJob] = useState<{
    open: boolean;
    defaultValues?: Partial<z.infer<typeof jobSchema>>;
  }>({ open: false });

  return (
    <Page title="Schedules" fullWidth className="!p-0">
      {showAddJob && isMobile() && (
        <ModalDrawer
          open={showAddJob.open}
          onClose={() => setShowAddJob({ open: false })}
        >
          <AddJob />
        </ModalDrawer>
      )}
      {showAddJob && !isMobile() && (
        <Sheet
          open={showAddJob.open}
          onClose={() => setShowAddJob({ open: false })}
          className="w-[90vw]"
        >
          <AddJob
            modal
            onSuccess={() => {
              setShowAddJob({ open: false });
            }}
            defaultValues={showAddJob.defaultValues}
          />
        </Sheet>
      )}
      {!isMobile() && (
        <StripeTabs
          contentClassName="pt-0"
          className="pt-2 w-full"
          tabParentClassName="pl-4"
          value={view}
          onChange={(tab) => navigate(`/field-service/schedules/${tab}`)}
          items={[
            { label: "Calendar", key: "calendar", icon: CalendarDays },
            { label: "List", key: "list", icon: List },
          ]}
          suffix={
            <>
              {permissions?.CREATE_JOBS.allowed && (
                <Button
                  variant={"primary"}
                  onClick={() =>
                    setShowAddJob({
                      open: true,
                    })
                  }
                  icon={Plus}
                >
                  Job
                </Button>
              )}
            </>
          }
        />
      )}
      {view === "calendar" && (
        <SchedulesCalendar
          syncedDates={syncedDates}
          setSyncedDates={setSyncedDates}
          onJobAdd={(defaultValues) =>
            setShowAddJob({ open: true, defaultValues })
          }
        />
      )}
      {view === "list" && (
        <SchedulesList
          setSyncedDates={setSyncedDates}
          syncedDates={syncedDates}
        />
      )}
    </Page>
  );
};

export default Schedules;
