import { Plus } from "lucide-react";
import { Button } from "@heffl/ui/components/primitives/button";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import { useEffect } from "react";

// Primary action button in listing page (eg: Add Invoice, Add quotation..)
// In desktop, button with Plus (+) icon and text
// In mobile, floating button with plus (+) icon

interface Props {
  onClick: () => void;
  text: string;
  className?: string;
  buttonSize?: "sm" | "md";
  hotkey?: string;
  disabled?: boolean;
}

const ResponsiveActionButton = ({
  onClick,
  text,
  className,
  buttonSize = "md",
  hotkey,
  disabled,
}: Props) => {
  useEffect(() => {
    if (isMobile()) {
      // document.body.style.paddingBottom = "80px";
      return () => {
        document.body.style.paddingBottom = "";
      };
    }
  }, []);

  return (
    <Button
      onClick={onClick}
      variant="primary"
      className={cn(
        isMobile() &&
          "fixed right-4 z-10 w-16 h-16 rounded-full sm:static bottom-[80px]",
        className
      )}
      size={buttonSize}
      hotkey={hotkey}
      disabled={disabled}
    >
      <Plus className="sm:mr-2 mr-0 sm:h-4 sm:w-4 h-10 !text-white " />
      {!isMobile() && text}
    </Button>
  );
};

export default ResponsiveActionButton;
