import { AlertProps as AntdAlertProps, Alert as AntdAlert } from "antd";

type AlertProps = {
  message: React.ReactNode | string;
  description?: React.ReactNode | string;
  type: AntdAlertProps["type"];
  className?: string;
  closable?: boolean;
};

export const Alert = ({
  message,
  description,
  type,
  className,
  closable = false,
}: AlertProps) => {
  return (
    <AntdAlert
      closable={closable}
      message={message}
      description={description}
      type={type}
      showIcon
      className={className}
    />
  );
};
