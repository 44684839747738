import { Info, LucideIcon } from "lucide-react";
import { cn } from "../lib/utils";
import { Tooltip } from "./primitives/tooltip";

type StatsBarProps = {
  items: {
    title: string;
    value: string;
    icon?: LucideIcon;
    info?: string;
  }[];
  className?: string;
};

const StatsBar = ({ items, className }: StatsBarProps) => {
  return (
    <div
      className={cn(
        "flex overflow-x-auto gap-4 px-4 w-full rounded-lg border scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent",
        className
      )}
    >
      {items.map((item, index) => (
        <div key={item.title} className="flex items-center">
          <div className="flex flex-col items-center p-2 whitespace-nowrap bg-white rounded-lg">
            <div className="flex gap-1 items-center">
              {item.icon && <item.icon className="flex-shrink-0 w-3 h-3" />}
              <p className="text-sm underline decoration-dashed decoration-gray-300 underline-offset-4">
                {item.title}
              </p>
              {item.info && (
                <Tooltip content={item.info}>
                  <Info className="w-3 h-3 text-gray-400" />
                </Tooltip>
              )}
            </div>
            <p className="mt-1 font-medium sm:text-base">{item.value}</p>
          </div>
          {index < items.length - 1 && (
            <div className="mx-4 w-px h-full bg-gray-200" />
          )}
        </div>
      ))}
    </div>
  );
};

export default StatsBar;
