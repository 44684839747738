import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Icon } from "@iconify/react";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import { Loader, LucideIcon } from "lucide-react";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "ring-1 ring-input bg-background hover:bg-accent hover:text-accent-foreground",
        primary: "bg-primary text-white hover:opacity-70",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "ring-1 ring-input bg-background hover:bg-accent hover:text-accent-foreground",
        primaryOutline: "ring-1 ring-primary text-primary",
        destructiveOutline:
          "text-destructive bg-red-50 hover:bg-red-100 ring-1 ring-red-300",
        secondary: "bg-gray-100 text-black hover:bg-secondary/80",
        warning: "bg-yellow-500 text-white hover:bg-yellow-600",
        warningOutline: "ring-1 ring-yellow-500 text-yellow-500",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        linkOutline: "ring-1 ring-input text-primary",
        print: "text-white bg-purple-600",
        success: "bg-success text-white hover:bg-success/90",
        download: "text-white bg-blue-600",
      },
      size: {
        default: "",
        xs: "h-5 rounded-md text-xs px-2",
        sm: "h-7 rounded-md text-sm px-2",
        md: "h-8 rounded-md text-sm py-6 sm:py-4 px-2",
        lg: "h-11 rounded-md text-sm px-2",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "md",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  suffix?: React.ReactNode;
  href?: string;
  icon?: LucideIcon;
  iconify?: string;
  iconClassName?: string;
  hotkey?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      children,
      disabled = false,
      type = "button",
      suffix,
      asChild = false,
      loading,
      iconClassName,
      hotkey,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    const iconSize =
      size === "xs"
        ? "h-3 w-3"
        : size === "sm"
        ? "h-4 w-4"
        : size === "md"
        ? "h-5 w-5"
        : size === "lg"
        ? "h-6 w-6"
        : "h-5 w-5";

    const innerRef = React.useRef<HTMLButtonElement>(null);
    React.useImperativeHandle(ref, () => innerRef.current as HTMLButtonElement);

    useHotkeys(
      hotkey ?? "",
      () => {
        if (innerRef.current) {
          innerRef.current.click();
        }
      },
      {
        enabled: hotkey !== undefined && !disabled && !loading && !isMobile(),
        preventDefault: true,
        enableOnFormTags: true,
        enableOnContentEditable: true,
      }
    );

    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          "font-medium whitespace-nowrap"
        )}
        ref={innerRef}
        disabled={loading || disabled}
        type={type}
        {...props}
      >
        {loading && <Loader className={iconSize + " animate-twSpin"} />}
        {props.icon && (
          <props.icon
            className={cn(
              "shrink-0",
              iconSize,
              children && "mr-1",
              iconClassName
            )}
          />
        )}

        {props.iconify && (
          <Icon
            icon={props.iconify}
            className={cn(
              "shrink-0",
              iconSize,
              children && "mr-1",
              iconClassName
            )}
          />
        )}
        {children}
        {suffix}
        {hotkey &&
          !isMobile() &&
          hotkey.split("+").map((key, index) => (
            <span className="flex gap-1 items-center" key={index}>
              <span className="flex gap-1 justify-center items-center px-2 ml-2 h-5 text-xs text-white uppercase rounded-md bg-white/30">
                {key}
              </span>
            </span>
          ))}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
