import DataTable from "@/components/DataTable";
import DropMenu from "@/components/DropMenu";
import Empty from "@/components/Empty";
import { ActivityDetailsModal } from "@/components/actionsModals/activities-modals";
import { TaskDetailsModal } from "@/components/actionsModals/tasks-modals";
import FilterBar from "@/components/filter-bar";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@heffl/ui/components/primitives/accordion";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import Select from "@heffl/ui/components/primitives/select";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { formatAmountToK, formatCurrency } from "@heffl/ui/lib/utils";
import { BarChart, BarList } from "@tremor/react";
import dayjs from "dayjs";
import { Filter, Image, LucideIcon } from "lucide-react";
import { useRef, useState } from "react";
import { exportComponentAsPNG } from "react-component-export-image";
import { AddClientDrawer } from "../clients/list";
import { AddContactDrawer } from "../contacts/list";
import AddDealDrawer from "../deals/components/add-deal-modal";
import { AddLeadDrawer } from "../leads/list";

// const DealsByAmountModal = ({
//   open,
//   onClose,
//   filters,
//   data,
// }: {
//   open: boolean;
//   onClose: () => void;
//   filters: DealFilters;
//   data: ChartData;
// }) => {
//   return (
//     <Modal open={open} onClose={onClose} className="max-w-4xl">
//       <div className="flex flex-col gap-2">
//         <p className="text-sm">Name: {data.name}</p>
//         <p className="text-sm">
//           Duration: {dayjs(filters?.dates?.[0]).format("DD MMM YYYY")} -{" "}
//           {dayjs(filters?.dates?.[1]).format("DD MMM YYYY")}
//         </p>
//       </div>
//       <div className="flex flex-row gap-4 mt-3">
//         <Card className="flex flex-col gap-2">
//           <span className="flex gap-1 items-center text-sm font-medium text-gray-500">
//             <CircleDollarSign className="w-4 h-4 text-green-500" />
//             Deals Won
//             <Tooltip content="Sum of accepted quotation amounts in won deals">
//               <HelpCircle className="w-3 h-3" />
//             </Tooltip>
//           </span>
//           <p className="text-xl font-medium">AED {data.won}</p>
//         </Card>
//         <Card className="flex flex-col gap-2">
//           <span className="flex gap-1 items-center text-sm font-medium text-gray-500">
//             <CircleDollarSign className="w-4 h-4 text-red-500" />
//             Deals Lost
//             <Tooltip content="Sum of rejected quotation amounts in lost deals">
//               <HelpCircle className="w-3 h-3" />
//             </Tooltip>
//           </span>
//           <p className="text-xl font-medium">AED {data.lost}</p>
//         </Card>
//       </div>
//       <div className="flex flex-col gap-1 p-2 mt-4 bg-gray-50 rounded-lg">
//         <span className="flex gap-1 items-center text-sm font-medium text-black">
//           <CircleDollarSign className="w-4 h-4 text-green-500" />
//           Deals Won
//         </span>
//         <DealsTable filters={filters} />
//       </div>
//       <Separator className="mt-4" />
//       <div className="flex flex-col gap-1 p-2 mt-4 bg-gray-50 rounded-lg">
//         <span className="flex gap-1 items-center text-sm font-medium text-black">
//           <CircleDollarSign className="w-4 h-4 text-red-500" />
//           Deals Lost
//         </span>
//         <DealsTable
//           filters={{
//             ...filters,
//             status: "LOST",
//           }}
//         />
//       </div>
//     </Modal>
//   );
// };

export type SingleQuickAction = {
  path: string;
  title: string;
  icon: LucideIcon;
  description: string;
  onClick: () => void;
};

export const QuickActioncard = ({ action }: { action: SingleQuickAction }) => {
  return (
    <div
      className="flex flex-row gap-3 items-center p-4 w-full rounded-lg border border-gray-200 cursor-pointer hover:bg-green-100 sm:items-start"
      onClick={action.onClick}
    >
      <div className="grid place-items-center w-8 h-8 bg-green-50 rounded-full">
        <action.icon className="w-4 text-primary" />
      </div>
      <div className="flex flex-col justify-center h-full">
        <p className="text-sm font-medium sm:tex-lg">{action.title}</p>
      </div>
    </div>
  );
};

type TQuickActions = "CONTACT" | "COMPANY" | "QUOTATION" | "DEAL" | "LEAD";

type Filters = {
  dates: [Date, Date] | undefined;
};

// type ChartData = {
//   name: string;
//   won: number;
//   lost: number;
//   userId: number;
// };

const CRMAdminDashboard = () => {
  const [filters, setFilters] = useParamsState<Filters>({
    dates: [dayjs().subtract(30, "day").toDate(), dayjs().toDate()],
  });
  const [selectedPipelineId, setSelectedPipelineId] = useState<string | number>(
    1
  );
  const [selectedQuickAction, setSelectedQuickAction] = useState<
    undefined | TQuickActions
  >(undefined);
  const [showTaskDetailsModal, setShowTaskDetailsModal] = useState<
    null | number
  >(null);
  const [showDetailsActivityModal, setShowDetailsActivityModal] = useState<
    null | number
  >(null);
  // const [showDealsModal, setShowDealsModal] = useState<undefined | ChartData>(
  //   undefined
  // );

  const allDashbaord = useRef<HTMLDivElement>(null);
  const leadsRef = useRef<HTMLDivElement>(null);
  const dealsNumberRef = useRef<HTMLDivElement>(null);
  const dealsAmountRef = useRef<HTMLDivElement>(null);

  const { data: pipelinesList } = trpc.deals.pipelines.list.useQuery();
  const { data: allData } = trpc.home.admin.allData.useQuery({
    startDate: filters.dates?.[0],
    endDate: filters.dates?.[1],
    pipelineId: Number(selectedPipelineId),
  });

  const onDrawerClose = () => setSelectedQuickAction(undefined);

  return (
    <div className="w-full">
      <AddLeadDrawer
        open={selectedQuickAction === "LEAD"}
        onClose={onDrawerClose}
      />
      <AddContactDrawer
        open={selectedQuickAction === "CONTACT"}
        onClose={onDrawerClose}
      />
      <AddClientDrawer
        open={selectedQuickAction === "COMPANY"}
        onClose={onDrawerClose}
      />
      <AddDealDrawer
        open={selectedQuickAction === "DEAL"}
        onClose={onDrawerClose}
      />
      {showDetailsActivityModal && (
        <ActivityDetailsModal
          open={!!showDetailsActivityModal}
          onClose={() => setShowDetailsActivityModal(null)}
          id={showDetailsActivityModal}
        />
      )}

      {showTaskDetailsModal && (
        <TaskDetailsModal
          open={!!showTaskDetailsModal}
          onClose={() => setShowTaskDetailsModal(null)}
          taskId={showTaskDetailsModal}
        />
      )}

      {/* {showDealsModal && (
        <DealsByAmountModal
          open={!!showDealsModal}
          onClose={() => setShowDealsModal(undefined)}
          filters={{
            owners: [Number(showDealsModal.userId)],
            status: "WON",
            dates: filters.dates,
          }}
          data={showDealsModal}
        />
      )} */}

      <div className="flex justify-between">
        <FilterBar
          onChange={() => {}}
          className=""
          filters={[
            {
              type: "date-range",
              key: "date",
              label: "Date",
              value: filters.dates,
              onChange: (value) =>
                setFilters({
                  dates: value,
                }),
            },
          ]}
        />
        <DropMenu
          items={[
            {
              label: "PNG",
              onClick: () => {
                exportComponentAsPNG(allDashbaord, {
                  fileName: `dashboard-${dayjs().format("DD-MM-YYYY")}`,
                  html2CanvasOptions: {
                    height: allDashbaord.current?.clientHeight || 10 + 30,
                    width: allDashbaord.current?.clientWidth,
                  },
                });
              },
              icon: Image,
            },
          ]}
        >
          <Button variant="outline" size="sm">
            Export
          </Button>
        </DropMenu>
      </div>
      {!pipelinesList || !allData ? (
        <FullScreenSpinner />
      ) : (
        <div className="flex flex-col gap-2 mb-10" ref={allDashbaord}>
          <div className="grid grid-cols-1 gap-4 mt-6 sm:grid-cols-2">
            <Card className="flex flex-col" ref={leadsRef}>
              <div className="flex justify-between items-center w-full">
                <p className="self-start mb-4 text-sm font-medium text-gray-700">
                  Lead by stage
                </p>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    exportComponentAsPNG(leadsRef, {
                      fileName: `lead-by-stage-${dayjs().format("DD-MM-YYYY")}`,
                      html2CanvasOptions: {
                        height: leadsRef.current?.clientHeight || 10 + 30,
                        width: leadsRef.current?.clientWidth,
                      },
                    });
                  }}
                >
                  Export
                </Button>
              </div>

              <h3 className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                Lead conversion
              </h3>
              <p className="text-lg font-semibold">
                {allData.conversionRate.conversionRate}%
              </p>
              <BarChart
                data={allData.leadStats}
                index="name"
                categories={["value"]}
                colors={["green"]}
                className="z-50 mt-3"
              />
              <Accordion type="single" collapsible className="mt-4 w-full">
                <AccordionItem value="item-1">
                  <AccordionTrigger>Show data in table</AccordionTrigger>
                  <AccordionContent>
                    <DataTable
                      className="mt-4"
                      rowKey="name"
                      data={allData.leadStats}
                      columns={[
                        {
                          title: "Stage",
                          render: (value) => (
                            <div className="flex flex-row gap-2">
                              {value.name}
                            </div>
                          ),
                        },
                        {
                          title: "Value",
                          render: (value) => (
                            <div className="font-medium text-gray-7000">
                              {value.value}
                            </div>
                          ),
                        },
                      ]}
                    />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </Card>

            <Card className="flex flex-col">
              <p className="self-start mb-4 text-sm font-medium text-gray-700">
                Lead by source
              </p>
              {allData.leadSourceStats.length ? (
                <BarList
                  data={allData.leadSourceStats}
                  color="pink"
                  className=""
                />
              ) : (
                <div className="flex flex-col justify-center items-center h-full">
                  <Empty
                    title="No lead sources added"
                    description="Please add a lead source in the settings"
                    icon={Filter}
                  />
                </div>
              )}
            </Card>
            <Card className="flex flex-col" ref={dealsNumberRef}>
              <div className="flex justify-between items-center">
                <p className="self-start mb-4 text-sm font-medium text-gray-700">
                  Number of deals by staff
                </p>
                <DropMenu
                  items={[
                    {
                      label: "PNG",
                      onClick: () => {
                        exportComponentAsPNG(dealsNumberRef, {
                          fileName: `deals-number-by-staff-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`,
                          html2CanvasOptions: {
                            height: dealsNumberRef.current
                              ? dealsNumberRef.current.clientHeight + 30
                              : 10,
                            width: dealsNumberRef.current
                              ? dealsNumberRef.current.clientWidth
                              : undefined,
                          },
                        });
                      },
                      icon: Image,
                    },
                  ]}
                >
                  <Button variant="outline" size="sm">
                    Export
                  </Button>
                </DropMenu>
              </div>
              <BarChart
                data={allData.dealsNumberByStaff}
                index="name"
                categories={["won", "lost"]}
                colors={["green", "red"]}
                className="w-full"
                stack={true}
                // onValueChange={(value) =>
                //   setShowDealsModal(
                //     value ? (value as unknown as ChartData) : undefined
                //   )
                // }
              />
              <Accordion type="single" collapsible className="mt-4 w-full">
                <AccordionItem value="item-1">
                  <AccordionTrigger>Show data in table</AccordionTrigger>
                  <AccordionContent>
                    <DataTable
                      className="mt-4"
                      rowKey="name"
                      data={allData.dealsNumberByStaff}
                      columns={[
                        {
                          title: "Staff",
                          render: (value) => value.name,
                        },
                        {
                          title: "Won",
                          render: (value) => value.won,
                        },
                        {
                          title: "Lost",
                          render: (value) => value.lost,
                        },
                      ]}
                    />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </Card>

            <Card className="flex flex-col" ref={dealsAmountRef}>
              <div className="flex justify-between items-center w-full">
                <p className="self-start mb-4 text-sm font-medium text-gray-700">
                  Amount of deals by staff
                </p>

                <DropMenu
                  items={[
                    {
                      label: "PNG",
                      onClick: () => {
                        exportComponentAsPNG(dealsAmountRef, {
                          fileName: `deals-amount-by-staff-${dayjs().format(
                            "DD-MM-YYYY"
                          )}`,
                          html2CanvasOptions: {
                            height: dealsAmountRef.current
                              ? dealsAmountRef.current.clientHeight + 30
                              : 10,
                            width: dealsAmountRef.current
                              ? dealsAmountRef.current.clientWidth
                              : undefined,
                          },
                        });
                      },
                      icon: Image,
                    },
                  ]}
                >
                  <Button variant="outline" size="sm">
                    Export
                  </Button>
                </DropMenu>
              </div>
              <BarChart
                data={allData.dealsAmountByStaff}
                index="name"
                categories={["won", "lost"]}
                colors={["green", "red"]}
                valueFormatter={(value) => formatAmountToK(value)}
                className="w-full"
                stack={true}
                // onValueChange={(value) =>
                //   setShowDealsModal(
                //     value ? (value as unknown as ChartData) : undefined
                //   )
                // }
              />
              <Accordion type="single" collapsible className="mt-4 w-full">
                <AccordionItem value="item-1">
                  <AccordionTrigger>Show data in table</AccordionTrigger>
                  <AccordionContent>
                    <DataTable
                      className="mt-4"
                      rowKey="name"
                      data={allData.dealsAmountByStaff}
                      columns={[
                        {
                          title: "Staff",
                          render: (value) => value.name,
                        },
                        {
                          title: "Won",
                          render: (value) => formatCurrency(value.won, "AED"),
                        },
                        {
                          title: "Lost",
                          render: (value) => formatCurrency(value.lost, "AED"),
                        },
                      ]}
                    />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </Card>
          </div>
          {/* Chart */}
          <Card className="mt-4">
            <h1 className="text-sm font-semibold">Amount by stage</h1>
            <div className="mt-2">
              <Select
                options={pipelinesList.map((pipeline) => ({
                  label: pipeline.name,
                  value: pipeline.id.toString(),
                }))}
                onChange={(value) => setSelectedPipelineId(Number(value))}
                value={selectedPipelineId.toString()}
                placeholder="Select pipeline"
                className="w-full sm:w-64"
              />
            </div>
            <div className="mt-6">
              {allData?.dealsAmountByStage && (
                <BarChart
                  data={allData.dealsAmountByStage.data || []}
                  index="name"
                  categories={["value"]}
                  colors={["green"]}
                  valueFormatter={(value) => formatAmountToK(value)}
                />
              )}
            </div>
            <Accordion type="single" collapsible className="mt-4 w-full">
              <AccordionItem value="item-1">
                <AccordionTrigger>Show data in table</AccordionTrigger>
                <AccordionContent>
                  <DataTable
                    className="mt-4"
                    rowKey="name"
                    data={allData.dealsAmountByStage.data || []}
                    columns={[
                      {
                        title: "Stage",
                        render: (value) => value.name,
                      },
                      {
                        title: "Value",
                        render: (value) => formatCurrency(value.value, "AED"),
                      },
                    ]}
                  />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </Card>
          {/* Card ends here */}
        </div>
      )}
    </div>
  );
};

export default CRMAdminDashboard;
