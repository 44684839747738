import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { getCustomFieldFilters } from "@/pages/crm/leads/list";
import enums, { enumsToOptions } from "@heffl/server/src/schemas/enums";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import StatsBar from "@heffl/ui/components/stats-cards";
import { formatCurrency, formatName } from "@heffl/ui/lib/utils";
import {
  ClockIcon,
  CreditCardIcon,
  DollarSignIcon,
  FileTextIcon,
  PercentCircle,
  TargetIcon,
  UserIcon,
} from "lucide-react";
import { sum } from "radash";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const ProjectFinancialReport: React.FC = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<
    RouterInputs["projects"]["reports"]["projectFinancialReport"]
  >({
    pipelines: [],
    clients: [],
    tags: [],
    assignees: [],
    statuses: ["ACTIVE"],
    createdAt: undefined,
    startDate: undefined,
    customFields: {},
  });
  const [clientSearch, setClientSearch] = useState("");

  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT",
  });
  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });

  const { data: projectCustomFields } =
    trpc.customizations.customFields.list.useQuery({
      section: "PROJECT",
      projectPipelineId: null,
    });

  const { data: pipelines } = trpc.projects.pipelines.list.useQuery();

  const projectsFilters = useMemo(
    () => ({
      pipelines: filters.pipelines,
      clients: filters.clients,
      tags: filters.tags,
      assignees: filters.assignees,
      statuses: filters.statuses,
      createdAt: filters.createdAt,
      startDate: filters.startDate,
      customFields: filters.customFields,
    }),
    [filters]
  );

  const { data: projects } =
    trpc.projects.reports.projectFinancialReport.useQuery(projectsFilters);

  if (!projects) return <FullScreenSpinner />;

  return (
    <Page title="Project Financial Report" className="!p-0" fullWidth showBack>
      <div className="flex flex-wrap items-center py-3 w-full">
        <FilterBar
          onChange={() => {}}
          className="px-3"
          filters={[
            {
              key: "pipelineId",
              type: "checkbox",
              multiple: false,
              label: "Pipeline",
              value: filters.pipelines as number[],
              onChange: (value) =>
                setFilters({
                  pipelines: value as number[],
                }),
              options: pipelines?.map((pipeline) => ({
                label: pipeline.name,
                value: pipeline.id,
              })),
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Clients",
              showSearch: true,
              value: filters.clients as number[],
              onChange: (value) => setFilters({ clients: value as number[] }),
              options: clients?.clients.map((client) => ({
                label: client.name,
                value: client.id,
              })),
              onSearch: (e) => setClientSearch(e),
            },
            {
              key: "assignees",
              type: "checkbox",
              label: "Assignees",
              value: filters.assignees as number[],
              onChange: (value) => setFilters({ assignees: value as number[] }),
              options: users?.map((user) => ({
                label: formatName(user),
                value: user.id,
              })),
            },
            {
              key: "statuses",
              type: "checkbox",
              label: "Status",
              value: filters.statuses as string[],
              onChange: (value) =>
                setFilters({
                  statuses: value as z.infer<typeof enums.projectStatsuses>[],
                }),
              options: enumsToOptions(enums.projectStatsuses) as unknown as {
                label: string;
                value: string;
              }[],
            },
            {
              key: "createdAt",
              type: "date-range",
              label: "Created Date",
              value: filters.createdAt as [Date, Date] | undefined,
              onChange: (value) =>
                setFilters({ createdAt: value as [Date, Date] }),
            },
            {
              key: "startDate",
              type: "date-range",
              label: "Start Date",
              value: filters.startDate as [Date, Date] | undefined,
              onChange: (value) =>
                setFilters({ startDate: value as [Date, Date] }),
            },
            {
              key: "tags",
              type: "checkbox",
              label: "Tags",
              value: filters.tags as number[],
              onChange: (value) => setFilters({ tags: value as number[] }),
              options: tags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
              })),
            },
            ...getCustomFieldFilters({
              customFields: projectCustomFields || [],
              setFilters,
              // @ts-ignore
              filters,
            }),
          ]}
        />
      </div>
      <StatsBar
        className="!mx-2 mb-12 w-fit"
        items={[
          {
            title: "Total value",
            icon: DollarSignIcon,
            value: heffl.format.currency(
              sum(projects, (p) => p.totalValue.total),
              "AED"
            ),
          },
          {
            title: "Invoiced amount",
            icon: DollarSignIcon,
            value: heffl.format.currency(
              sum(projects, (p) => p.invoicedAmount),
              "AED"
            ),
          },
          {
            title: "Paid amount",
            icon: DollarSignIcon,
            value: heffl.format.currency(
              sum(projects, (p) => p.paidAmount),
              "AED"
            ),
          },
          {
            title: "Pending amount",
            icon: DollarSignIcon,
            value: heffl.format.currency(
              sum(projects, (p) => p.pendingAmount),
              "AED"
            ),
          },
          {
            title: "Invoiced %",
            icon: PercentCircle,
            value: `${(
              (sum(projects, (p) => p.invoicedAmount) /
                sum(projects, (p) => p.totalValue.total)) *
              100
            ).toFixed(2)}%`,
          },
        ]}
      />
      <DataGrid
        rowKey="id"
        name="projectFinancialReport"
        className="h-[calc(100vh-170px-var(--header-height))]"
        label="Project Financial"
        loading={!projects}
        empty={{
          icon: TargetIcon,
          title: "No projects",
          description:
            "There are no projects to display in the financial report.",
        }}
        rows={projects}
        columns={[
          {
            key: "title",
            name: "Project Name",
            width: 200,
            icon: FileTextIcon,
            renderCell: ({ row }) => (
              <div
                className="truncate rounded-md cursor-pointer hover:text-primary-600 hover:underline"
                onClick={() => navigate(`/projects/details/${row.id}`)}
              >
                {row.title}
              </div>
            ),
          },
          {
            key: "clients",
            name: "Client",
            width: 150,
            icon: UserIcon,
            renderCell: ({ row }) =>
              row.clients ? (
                <div
                  className="truncate rounded-md cursor-pointer hover:text-primary-600 hover:underline"
                  onClick={() =>
                    row.clients?.id &&
                    navigate(`/sales/clients/details/${row.clients.id}`)
                  }
                >
                  {row.clients.name}
                </div>
              ) : (
                "N/A"
              ),
          },
          {
            key: "totalValue",
            name: "Total Value",
            width: 150,
            icon: DollarSignIcon,
            renderCell: ({ row }) =>
              `${formatCurrency(row.totalValue.total, "AED", true)}`,
          },
          {
            key: "invoicedAmount",
            name: "Invoiced Amount",
            width: 150,
            icon: DollarSignIcon,
            renderCell: ({ row }) =>
              `${formatCurrency(row.invoicedAmount, "AED", true)}`,
          },
          {
            key: "paidAmount",
            name: "Paid Amount",
            width: 150,
            icon: CreditCardIcon,
            renderCell: ({ row }) =>
              `${formatCurrency(row.paidAmount, "AED", true)}`,
          },
          {
            key: "pendingAmount",
            name: "Pending Amount",
            width: 150,
            icon: ClockIcon,
            renderCell: ({ row }) =>
              `${formatCurrency(row.pendingAmount, "AED", true)}`,
          },
          {
            key: "invoicedPercentage",
            name: "Invoiced %",
            width: 120,
            icon: PercentCircle,
            renderCell: ({ row }) => `${row.invoicedPercentage.toFixed(2)}%`,
          },
          {
            key: "pendingPercentage",
            name: "Pending %",
            width: 120,
            icon: PercentCircle,
            renderCell: ({ row }) => `${row.pendingPercentage.toFixed(2)}%`,
          },
        ]}
      />
    </Page>
  );
};

export default ProjectFinancialReport;
