import { trpc } from "@/helpers/trpc";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import SimpleTable from "@heffl/ui/components/simple-table";
import dayjs from "dayjs";
import InfoItems from "@heffl/ui/components/info-items";
import { Link } from "react-router-dom";
import heffl from "@/helpers/heffl";
import { Pencil } from "lucide-react";
import { EditPaymentMadeModal } from "./list";
import { useState } from "react";
import { isMobile } from "@heffl/ui/lib/utils";
type PaymentMadeDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  id: number;
};

export const PaymentMadeDetailsModal = ({
  open,
  onClose,
  id,
}: PaymentMadeDetailsModalProps) => {
  const [showEdit, setShowEdit] = useState(false);

  const { data: paymentMade } =
    trpc.purchases.paymentsMade.details.useQuery(id);

  return (
    <>
      <EditPaymentMadeModal
        open={showEdit}
        onClose={() => setShowEdit(false)}
        paymentId={id}
      />
      <ModalDrawer
        title="Payment Made Details"
        open={open}
        onClose={onClose}
        modalClassName="max-w-2xl"
        actions={[
          {
            label: "Edit",
            icon: Pencil,
            onClick: () => setShowEdit(true),
          },
        ]}
        defaultActionsButtonSize="xs"
      >
        {!paymentMade && <FullScreenSpinner />}
        {paymentMade && (
          <div className="flex flex-col gap-6">
            <div className="text-sm text-gray-500">Amount</div>
            <div className="-mt-6 text-2xl font-semibold">
              {heffl.format.currency(paymentMade.amount, "AED")}
            </div>

            <InfoItems
              column={isMobile() ? 1 : 2}
              size="small"
              items={[
                {
                  label: "Payment Date",
                  children: dayjs(paymentMade.date).format("DD/MM/YYYY"),
                },
                {
                  label: "Reference #",
                  children: paymentMade.refNo || "-",
                },
                {
                  label: "Payment #",
                  children: paymentMade.number || "-",
                },
                {
                  label: "Created At",
                  children: dayjs(paymentMade.createdAt).format("DD/MM/YYYY"),
                },
                {
                  label: "Vendor Name",
                  children: (
                    <Link
                      to={`/purchases/vendors/details/${paymentMade.vendors.id}`}
                      className="hover:underline text-primary-800"
                    >
                      {paymentMade.vendors.name}
                    </Link>
                  ),
                },
                {
                  label: "Payment Account",
                  children: paymentMade.paidThroughAccount.name,
                },
              ]}
            />

            <SimpleTable
              fixedHeader={false}
              columns={[
                {
                  label: "Date",
                  key: "date",
                  render: (row) => dayjs(row.date).format("DD/MM/YYYY"),
                },
                {
                  label: "Bill Number",
                  key: "title",
                  render: (row) =>
                    row.billId ? (
                      <Link
                        to={`/purchases/bills/details/${row.billId}`}
                        className="hover:underline text-primary-800"
                      >
                        {row.title}
                      </Link>
                    ) : (
                      row.title
                    ),
                },
                {
                  label: "Bill Amount",
                  key: "amountTotal",
                  render: (row) =>
                    heffl.format.currency(row.amountTotal, "AED", true),
                },
                {
                  label: "Due Amount",
                  key: "amountDue",
                  render: (row) =>
                    heffl.format.currency(row.amountDue, "AED", true),
                },
                {
                  label: "Payment Amount",
                  key: "amount",
                  render: (row) =>
                    heffl.format.currency(row.amount, "AED", true),
                },
              ]}
              rows={paymentMade.paymentMadeAllocations}
              idKey="id"
            />

            <div className="flex flex-col gap-2 items-end">
              <div className="flex gap-8 pb-2 border-b">
                <div className="text-sm text-gray-500">Subtotal</div>
                <div className="text-sm text-gray-500">
                  {heffl.format.currency(paymentMade.amount, "AED", true)}
                </div>
              </div>
              <div className="flex gap-8 pb-2 border-b">
                <div className="text-sm font-medium">Total</div>
                <div className="text-sm font-medium">
                  {heffl.format.currency(paymentMade.amount, "AED", true)}
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalDrawer>
    </>
  );
};

export default PaymentMadeDetailsModal;
