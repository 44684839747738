import { DEFAULT_DATE } from "@/lib/constants";
import formatReminderDate, {
  DateTypeString,
} from "@heffl/server/src/helpers/utils/formatReminderDate";
import { Calendar } from "@heffl/ui/components/primitives/calendar";
import { Input } from "@heffl/ui/components/primitives/input";
import {
  Popover,
  PopoverContent,
} from "@heffl/ui/components/primitives/popover";
import Select from "@heffl/ui/components/primitives/select";
import TimePicker from "@heffl/ui/components/primitives/time-picker";
import { PopoverAnchor } from "@radix-ui/react-popover";
import * as chrono from "chrono-node";
import dayjs from "dayjs";
import { LucideIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";

export type ReminderDateValue = {
  date: Date;
  dateType: DateTypeString;
};

type Props = {
  ogDate: Date | null;
  value?: ReminderDateValue;
  onChange?: (v: ReminderDateValue | undefined) => void;
  options?: { label: string; value: DateTypeString }[];
  icon?: LucideIcon;
  iconify?: string;
  className?: string;
  iconClassName?: string;
  placeholder?: string;
  renderClassName?: string;
  disabled?: boolean;
  previewType?: "linear";
  popoverSide?: "left" | "top" | "bottom" | "right";
  popoverClassName?: string;
  previewLabel?: string;
};

const SelectableDateicker = ({
  placeholder = "Select a value",
  ...dateProps
}: Props) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   if (
  //     dateProps.onChange &&
  //     dateProps.ogDate &&
  //     dateProps.value &&
  //     dateProps.value.dateType &&
  //     dateProps.value.date
  //   ) {
  //     dateProps.onChange(
  //       formatReminderDate({
  //         date: dateProps.value.date,
  //         dateType: dateProps.value.dateType,
  //         ogDate: dateProps.ogDate,
  //       })
  //     );
  //   }
  // }, [dateProps.ogDate]);

  useEffect(() => {
    if (showDatePicker) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
    setInputValue("");
  }, [showDatePicker]);

  return (
    <div className="">
      <Popover open={showDatePicker}>
        <PopoverAnchor>
          <Select
            className={dateProps.className}
            previewLabel={dateProps.previewLabel}
            disabled={dateProps.disabled}
            previewType={dateProps.previewType}
            icon={dateProps.icon}
            iconify={dateProps.iconify}
            value={dateProps.value?.dateType}
            onChange={(value) => {
              if (value === "CUSTOM_DATE") {
                setShowDatePicker(true);
              } else if (
                dateProps.onChange &&
                dateProps.ogDate &&
                value !== "CUSTOM_DATE"
              ) {
                dateProps.onChange(
                  formatReminderDate({
                    date: dateProps.value?.date || new Date(),
                    dateType: value,
                    ogDate: dateProps.ogDate,
                  })
                );
              }
            }}
            options={[...(dateProps.options || [])]}
            placeholder={placeholder}
            popoverClassName="w-fit"
            popoverSide={dateProps.popoverSide}
          />
        </PopoverAnchor>

        <PopoverContent
          className={`flex flex-col !p-1`}
          side={dateProps.popoverSide}
          sideOffset={4}
          align="start"
        >
          <div className="p-2">
            <Input
              ref={inputRef}
              className="w-full !h-8"
              placeholder="Next week, next month"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const parsed = chrono.parseDate(inputValue);
                  if (parsed) {
                    dateProps.onChange &&
                      dateProps.onChange({
                        date: parsed,
                        dateType: "CUSTOM_DATE",
                      });
                    setInputValue("");
                    setShowDatePicker(false);
                  }
                }
              }}
            />
          </div>
          <Calendar
            mode="single"
            selected={dateProps.value?.date}
            onSelect={(newValue) => {
              const time = dayjs(dateProps.value?.date || DEFAULT_DATE);
              dateProps.onChange &&
                newValue &&
                dateProps.onChange({
                  date: dayjs(newValue)
                    .set("hour", time.hour())
                    .set("minute", time.minute())
                    .toDate(),
                  dateType: "CUSTOM_DATE",
                });
              setShowDatePicker(false);
            }}
            initialFocus
          />
          <hr className="my-1" />
          <div className="p-1.5 space-y-1">
            <p className="text-sm font-medium">Time</p>
            <TimePicker
              value={dateProps.value?.date || DEFAULT_DATE}
              onChange={(newTimeValue) => {
                const time = dayjs(newTimeValue);
                if (
                  dateProps.onChange &&
                  newTimeValue &&
                  dateProps.value?.date
                ) {
                  dateProps.onChange({
                    date: dayjs(dateProps.value.date)
                      .set("hour", time.hour())
                      .set("minute", time.minute())
                      .toDate(),
                    dateType: "CUSTOM_DATE",
                  });
                }
              }}
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default SelectableDateicker;
