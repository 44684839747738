import * as AccordionPrimitive from "@radix-ui/react-accordion";
import * as React from "react";

import { Icon } from "@iconify/react";

import { cn } from "@heffl/ui/lib/utils";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn("", className)} {...props} />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    iconPosition?: "start" | "end";
    iconClassName?: string;
  }
>(
  (
    { className, children, iconPosition = "start", iconClassName, ...props },
    ref
  ) => (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          "flex flex-1 items-center justify-between my-1 font-medium transition-all  [&[data-state=closed]>svg]:-rotate-90",
          className,
          iconPosition === "start" && "justify-start"
        )}
        {...props}
      >
        {iconPosition === "start" && (
          <Icon
            icon="mynaui:chevron-down-solid"
            className={cn(
              "mr-1 w-4 h-4 text-gray-700 transition-transform duration-200 shrink-0",
              iconClassName
            )}
          />
        )}
        {children}

        {iconPosition === "end" && (
          <Icon
            icon="mynaui:chevron-down-solid"
            className={cn(
              "w-4 h-4 text-gray-700 transition-transform duration-200 shrink-0",
              iconClassName
            )}
          />
        )}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down py-3"
    {...props}
  >
    <div className={cn("pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
