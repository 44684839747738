import { Entity } from "./recordActivity";

type Label = Record<string, string>;

export type Labels = {
  [key in Entity]?: Label;
};
export const activityColumnLabels: Labels = {
  LEAD: {
    leadAssignees: "Assignees",
  },
  PROJECT: {
    projectTags: "Tags",
    projectAssignees: "Assignees",
    projectFiles: "Files",
    projectNotes: "Notes",
    projectQuotations: "Quotations",
  },
  PROJECT_TASK: {
    projectTaskTags: "Tags",
    projectTaskAssignees: "Assignees",
    projectTaskFiles: "Files",
    projectTaskNotes: "Notes",
    projectTaskQuotations: "Quotations",
  },
  DEAL: {
    dealAssignees: "Assignees",
    dealContacts: "Contacts",
    dealProducts: "Products",
  },
  SALES_ORDER: {
    salesOrderItems: "Items",
  },
  QUOTATION: {
    quotationProducts: "Products",
    quotationTags: "Tags",
    internalNotes: "Notes",
    dealId: "Linked deal",
    clientId: "Client",
  },
  INVOICE: {
    invoiceProducts: "Products",
    invoiceTags: "Tags",
    internalNotes: "Notes",
    paymentsRecieved: "Payments",
    paymentReceivedAllocations: "Payments",
    clientId: "Client",
  },
  PURCHASE_ORDER: {
    purchaseOrderItems: "Items",
    purchaseOrderTags: "Tags",
    internalNotes: "Notes",
    clientId: "Client",
  },
  PROFORMA_INVOICE: {
    proformaInvoiceProducts: "Products",
    proformaInvoiceTags: "Tags",
    internalNotes: "Notes",
    clientId: "Client",
  },
  BILL: {
    billProducts: "Items",
    billTags: "Tags",
    internalNotes: "Notes",
    clientId: "Client",
  },
  FS_JOB: {
    fsJobServices: "Services",
    fsJobTags: "Tags",
    internalNotes: "Notes",
    fs_job: "Job",
    clientId: "Client",
    fsPropertyId: "Property",
  },
};

export const getActivityColumnLabels = (
  entity: Entity,
  path: string | number
) => {
  const labels = activityColumnLabels[entity];
  if (labels) {
    return path in labels ? labels[path] : path.toString();
  }
  return path.toString();
};
