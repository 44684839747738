import { cn } from "@heffl/ui/lib/utils";
import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import ResponsiveActions, { ResponsiveAction } from "../responsive-actions";
import { Button } from "./button";
import { ScrollArea } from "./scroll-area";

const SheetTrigger = SheetPrimitive.Trigger;

const SheetPortal = ({ ...props }: SheetPrimitive.DialogPortalProps) => (
  <SheetPrimitive.Portal {...props} />
);
SheetPortal.displayName = SheetPrimitive.Portal.displayName;

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      "fixed inset-0 z-50 bg-background/20 bg-black opacity-5  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
    ref={ref}
  />
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva(
  "fixed z-50 bg-background shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500",
  {
    variants: {
      side: {
        top: "inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
        bottom:
          "inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
        left: "inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left ",
        right:
          "inset-y-0 right-0 h-full w-3/4  border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right",
      },
    },
    defaultVariants: {
      side: "right",
    },
  }
);

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(({ side = "right", className, children, ...props }, ref) => (
  <SheetPortal>
    <SheetOverlay />
    <SheetPrimitive.Content
      ref={ref}
      className={cn(sheetVariants({ side }), className, "flex flex-col")}
      {...props}
    >
      {children}
    </SheetPrimitive.Content>
  </SheetPortal>
));
SheetContent.displayName = SheetPrimitive.Content.displayName;

const SheetHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-2 text-center sm:text-left",
      className
    )}
    {...props}
  />
);
SheetHeader.displayName = "SheetHeader";

const SheetFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className
    )}
    {...props}
  />
);
SheetFooter.displayName = "SheetFooter";

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn("text-base font-medium", className)}
    {...props}
  />
));
SheetTitle.displayName = SheetPrimitive.Title.displayName;

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
SheetDescription.displayName = SheetPrimitive.Description.displayName;

const Sheet = ({
  children,
  title,
  description,
  open,
  onClose,
  side,
  className,
  footer,
  actions,
  closeOnOverlayClick = false,
}: {
  children: React.ReactNode;
  title?: string | React.ReactElement;
  description?: string;
  open?: boolean;
  onClose?: (v: boolean) => void;
  className?: string;
  side?: "bottom" | "left" | "right" | "top";
  footer?: React.ReactNode;
  actions?: ResponsiveAction[];
  closeOnOverlayClick?: boolean;
}) => {
  return (
    <SheetPrimitive.Root open={open} onOpenChange={onClose}>
      <SheetContent
        className={cn(
          "flex flex-col overflow-hidden px-0 pt-0",
          className,
          !footer && "pb-2"
        )}
        side={side}
        onInteractOutside={(e) => {
          !closeOnOverlayClick && e.preventDefault();
        }}
      >
        <SheetHeader className="pl-3 pr-2 py-1.5 space-y-0 border-b border-gray-200 shrink-0">
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <Button
                variant="ghost"
                size="md"
                onClick={() => onClose?.(false)}
                iconify="tabler:x"
              />
              <SheetTitle>{title}</SheetTitle>
            </div>
            <ResponsiveActions
              actions={actions || []}
              defaultButtonSize="xs"
              defaultButtonVariant="outline"
            />
          </div>
          <SheetDescription className="!-mt-0.5 text-xs">
            {description}
          </SheetDescription>
        </SheetHeader>
        <ScrollArea className="flex-1">
          <div className="px-3 py-1">{children}</div>
        </ScrollArea>
        {footer && (
          <SheetFooter className="p-2 border-t border-gray-200 shrink-0">
            {footer}
          </SheetFooter>
        )}
      </SheetContent>
    </SheetPrimitive.Root>
  );
};

export {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
};
