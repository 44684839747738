import { create } from "zustand";
import { persist } from "zustand/middleware";
import { z } from "zod";

// Define schemas for each state item
const stateSchemas = {
  topPhoneCountries: z.array(
    z.object({
      iso2: z.string(),
      count: z.number(),
    })
  ),
  pinnedProjectTasks: z.array(z.number()),
  ignoredUnlinkedInvoices: z.array(z.number()),
} as const;

const defaultValues: { [K in keyof StateTypes]: StateTypes[K] } = {
  topPhoneCountries: [],
  pinnedProjectTasks: [],
  ignoredUnlinkedInvoices: [],
};
const parseSet: {
  [K in keyof StateTypes]?: (
    value: StateTypes[K],
    currentValue: StateTypes[K],
    options?: object
  ) => StateTypes[K];
} = {
  topPhoneCountries: (value, currentValue) => {
    const merged = [...currentValue];
    value.forEach((newItem) => {
      const existingIndex = merged.findIndex(
        (item) => item.iso2 === newItem.iso2
      );
      if (existingIndex >= 0) {
        if (merged[existingIndex].count < 20)
          merged[existingIndex].count += newItem.count;
      } else {
        merged.push(newItem);
      }
    });
    return merged.sort((a, b) => b.count - a.count);
  },
  pinnedProjectTasks: (value, currentValue, options?: { delete?: boolean }) => {
    if (options?.delete) {
      // Remove the items in value from currentValue
      return currentValue.filter((item) => !value.includes(item));
    }
    // Convert to Set to remove duplicates and limit to max 4
    const combined = [...new Set([...value, ...currentValue])].map(Number);
    return combined.slice(-4); // Keep only the last 4 items
  },
};

// Infer types from schemas
type StateSchemas = typeof stateSchemas;
type StateTypes = {
  [K in keyof StateSchemas]: z.infer<StateSchemas[K]>;
};

// Separate interface for state to fix mapped type error
interface InfoState extends StateTypes {
  setInfoItem: <K extends keyof StateTypes>(
    key: K,
    value: StateTypes[K],
    options?: object
  ) => void;
}

// Default values matching schema types
const useInfoStore = create<InfoState>()(
  persist(
    (set) => ({
      ...defaultValues,
      setInfoItem: <K extends keyof StateTypes>(
        key: K,
        value: StateTypes[K],
        options?: object
      ) => {
        // Validate value against schema
        const schema = stateSchemas[key];
        const validated = schema.parse(value);

        set((state) => {
          // Use parse function if defined, otherwise use validated value
          const parsed = parseSet[key]
            ? // @ts-ignore
              parseSet[key](validated, state[key], options)
            : validated;
          return { [key]: parsed };
        });
      },
    }),
    {
      name: "heffl-info-storage",
      partialize: (state) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { setInfoItem, ...rest } = state;
        return rest;
      },
    }
  )
);

export default useInfoStore;
