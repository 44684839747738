import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { reportDatePresets } from "@/pages/books/reports/salesByItem";
import { formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { FileText } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const VendorBalancesReport: React.FC = () => {
  const [filters, setFilters] = useParamsState<
    RouterInputs["sales"]["reports"]["vendorBalancesReport"]
  >({
    dateType: "asOf",
    dates: [dayjs().endOf("month").toDate(), dayjs().endOf("month").toDate()],
  });

  const { data: vendorBalances } =
    trpc.sales.reports.vendorBalancesReport.useQuery(filters);

  const navigate = useNavigate();

  return (
    <Page title="Vendor Balances Report" fullWidth className="sm:!p-0" showBack>
      <FilterBar
        className="p-3"
        filters={[
          {
            key: "dates",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
            presets: reportDatePresets,
            endDateOnly: true,
          },
        ]}
      />
      <DataGrid
        rowKey="id"
        name="vendorBalancesReport"
        className="h-[calc(100vh-55px-var(--header-height))]"
        label="Vendor Balances"
        loading={!vendorBalances}
        empty={{
          icon: FileText,
          title: "No vendor balances",
          description:
            "There are no vendor balances to display in the summary report.",
        }}
        rows={vendorBalances?.vendors || []}
        columns={[
          {
            key: "name",
            name: "Vendor Name",
            width: 200,
            renderCell: ({ row }) => (
              <div
                className="truncate cursor-pointer hover:text-primary-600 hover:underline text-primary-800"
                onClick={() => navigate(`/purchases/vendors/details/${row.id}`)}
              >
                {row.name}
              </div>
            ),
          },
          {
            key: "billBalance",
            name: "Bill Balance",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.billBalance, "AED", true),
          },
          {
            key: "unUsedCredits",
            name: "Unused Credits",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.unUsedCredits, "AED", true),
          },
          {
            key: "balance",
            name: "Balance",
            width: 150,
            renderCell: ({ row }) =>
              formatCurrency(row.totalBalance, "AED", true),
          },
        ]}
      />
    </Page>
  );
};

export default VendorBalancesReport;
