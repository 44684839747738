import DropMenu from "@/components/DropMenu";
import Empty from "@/components/Empty";
import { SearchInput } from "@/components/FormComponents";
import Pagination from "@/components/Pagination";
import { AddActivityModal } from "@/components/actionsModals/activities-modals";
import { AddNotesModal } from "@/components/actionsModals/notes-modals";
import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar, {
  CheckboxFilterType,
  NumberRangeFilterType,
} from "@/components/filter-bar";
import KanbanBoard from "@/components/kanbanBoard/kanban";
import Page from "@/components/page";
import validateCustomFields from "@/helpers/customFields/validateCustomFields";
import heffl from "@/helpers/heffl";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import useQueryParams from "@/helpers/useQuery";
import { leadStageTypeMeta } from "@/lib/constants";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import { NoteCard } from "@/pages/projects/details/components/Notes";
import {
  formatCustomFields,
  renderCustomFieldValue,
} from "@heffl/server/src/helpers/customFields";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { cn, dynamicDateFormatting, isMobile } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { CSVImporter } from "csv-import-react";
import { isBefore, isToday } from "date-fns";
import dayjs from "dayjs";
import {
  AlertOctagon,
  Archive,
  ArrowRight,
  Calendar,
  CheckSquare,
  Download,
  KanbanSquare,
  MoreHorizontal,
  Pencil,
  StickyNote,
  Table,
  Target,
  Trash2,
} from "lucide-react";
import { construct } from "radash";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { match, P } from "ts-pattern";
import { z } from "zod";
import LeadForm from "./components/lead-form";
import LeadCard from "./components/leadCard";
import { EditLeadDrawer } from "./details";

export const AddLeadDrawer = ({
  open,
  onClose,
  defaultValues,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<z.infer<typeof Schemas.crm.lead>>;
}) => {
  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();

  const leadSettings = teamDetails?.teamSettings.leads;

  const { data: currentUser } = trpc.users.currentUser.useQuery();
  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section: "LEAD",
    }
  );
  const leadAddMutation = trpc.leads.add.useMutation({
    onSuccess() {
      toast.success("Succesfully added contact.");
      form.reset();
      onClose();
    },
    onError(err) {
      toast.error(err.message);
    },
  });

  const form = useForm<z.infer<typeof Schemas.crm.lead>>({
    resolver: zodResolver(Schemas.crm.lead.omit({ stageId: true })),
    defaultValues: {
      expectedCloseDate: dayjs().add(2, "weeks").toDate(),
    },
  });

  console.log(form.formState.errors);

  useEffect(() => {
    if (currentUser) {
      form.setValue("ownerUserId", currentUser.id);
      form.setValue("currencyId", currentUser.teams.currencyId);
    }
  }, [currentUser, form]);

  useEffect(() => {
    if (defaultValues) {
      form.reset({ ...form.getValues(), ...defaultValues });
    }
  }, [defaultValues]);

  const onAddLead = async (values: z.infer<typeof Schemas.crm.lead>) => {
    if (leadSettings) {
      if (leadSettings.requiredFields.mobile && !values.mobile) {
        form.setError("mobile", {
          message: "Mobile is required",
        });
        return;
      }
      if (leadSettings.requiredFields.crmSourceId && !values.crmSourceId) {
        form.setError("crmSourceId", {
          message: "Source is required",
        });
        return;
      }
    }

    // validate custom fields
    const { isValid } = validateCustomFields({
      customFields,
      customFieldsValues: values.customFields,
      form,
    });
    if (!isValid) return;
    leadAddMutation.mutate({ ...values });
  };

  return (
    <ModalDrawer
      className="w-full sm:max-w-sm"
      open={open}
      onClose={() => {
        form.reset();
        onClose();
      }}
      title="Add Lead"
      description="Add a new lead to your pipeline"
      footer={
        <Button
          loading={leadAddMutation.isLoading}
          className="w-full"
          type="submit"
          variant="primary"
          onClick={() => {
            form.handleSubmit(onAddLead)();
          }}
          size="md"
        >
          Add lead
        </Button>
      }
    >
      <Form {...form} onSubmit={onAddLead}>
        <LeadForm form={form} />
      </Form>
    </ModalDrawer>
  );
};

type Filters = {
  stages: number[];
  archived: boolean[];
  search?: string;
  dates: [Date, Date] | undefined;
  lostReasons: number[];
  assignedTo: number[];
  owners: number[];
  sources: number[];
  customFields: Record<
    string,
    z.infer<typeof Schemas.customFields.customFieldFilters>
  >;
  nextActivityDate: [Date, Date] | undefined;
  pageNo: number;
  pageSize: number;
};

type GetCustomFieldFilters = {
  customFields?: RouterOutputs["customizations"]["customFields"]["list"];
  setFilters: (value: object) => void;
  filters: {
    customFields: Record<
      string,
      z.infer<typeof Schemas.customFields.customFieldFilters>
    >;
  };
};

export const getCustomFieldFilters = ({
  customFields = [],
  setFilters,
  filters,
}: GetCustomFieldFilters): (
  | CheckboxFilterType<unknown>
  | NumberRangeFilterType
)[] => {
  // @ts-ignore
  return (
    customFields
      ?.filter(
        (field) =>
          field.dataType === "SINGLE_OPTION" || field.dataType === "NUMBER"
      )
      .map((field) => {
        if (field.dataType === "SINGLE_OPTION") {
          return {
            multiple: false,
            type: "checkbox",
            key: field.name,
            label: field.label,
            value: filters.customFields[field.name]?.value || [],
            // @ts-ignore
            onChange: (value) =>
              setFilters({
                customFields: {
                  ...filters.customFields,
                  [field.name]: {
                    type: "single-option",
                    value: value,
                  },
                },
              }),
            options: field.values.map((o) => ({
              label: o,
              value: o,
            })),
          };
        } else if (field.dataType === "NUMBER") {
          return {
            key: field.name,
            label: field.label,
            type: "number-range",
            value: filters.customFields[field.name]?.value,
            // @ts-ignore
            onChange: (value) =>
              setFilters({
                customFields: {
                  ...filters.customFields,
                  [field.name]: {
                    type: "number-range",
                    value: value,
                  },
                },
              }),
          };
        }
      }) || []
  );
};

const LeadsList = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const trpcUtils = trpc.useUtils();
  const queryParams = useQueryParams();

  const addLeadMobile = queryParams.get("addLeadMobile")
    ? "+" + queryParams.get("addLeadMobile")
    : undefined;

  const { view } = useParams<{ view: "board" | "table" }>();

  const [filters, setFilters] = useParamsState<Filters>({
    stages: [],
    archived: [false],
    search: "",
    dates: undefined,
    assignedTo: [],
    owners: [],
    sources: [],
    customFields: {},
    nextActivityDate: undefined,
    pageNo: 1,
    pageSize: 30,
    lostReasons: [],
  });

  const [selectedLead, setSelectedLead] = useState<
    | {
        action: "edit" | "addNote" | "addActivity" | "lostReason";
        leadId: number;
      }
    | undefined
  >(undefined);

  const [showImport, setShowImport] = useState(false);
  const [addLead, setAddLead] = useState(false);
  const [showLeadNotes, setShowLeadNotes] = useState<
    RouterOutputs["leads"]["list"]["leads"][number]["notes"]
  >([]);

  const leadParams = useMemo(() => {
    return {
      ...filters,
      startDate: filters.dates?.[0],
      endDate: filters.dates?.[1],
      pageNo: view === "board" ? undefined : filters.pageNo,
      pageSize: view === "board" ? undefined : filters.pageSize,
      archived: filters.archived.length > 0 ? filters.archived[0] : undefined,
      orderBy:
        view === "board" ? ("position" as const) : ("createdAt" as const),
    };
  }, [filters, view]);

  const { data: leads, isLoading } = trpc.leads.list.useQuery(leadParams);
  const leadImportMutation = trpc.leads.bulkAdd.useMutation({
    onSuccess() {
      toast.success("Leads added successfully");
    },
    onError(err) {
      toast.error(err.message);
    },
  });
  const leadBulkUpdateMutation = trpc.leads.bulkUpdate.useMutation();

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const { data: leadsCustomFields } =
    trpc.customizations.customFields.list.useQuery({
      section: "LEAD",
    });

  const { data: lostReasons } = trpc.lostReasons.list.useQuery();
  const { data: users } = trpc.users.list.useQuery({
    type: ["STAFF", "ADMIN", "OWNER", "SUPER_ADMIN"],
  });
  const { data: leadSources } = trpc.sources.list.useQuery();
  const { data: leadStages } = trpc.leads.stages.list.useQuery();

  const permissions = usePermissions();

  const leadUpdateMutation = trpc.leads.update.useMutation({
    onError(er) {
      toast.error(er.message);
    },
  });
  const leadDeleteMutation = trpc.leads.delete.useMutation({
    onSuccess() {
      toast.success("Succesfully deleted lead.");
    },
    onError(err) {
      toast.error(err.message);
    },
  });
  useEffect(() => {
    const addLead = queryParams.get("addLead");
    if (addLead) {
      setAddLead(true);
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete("addLead");
      window.history.replaceState({}, "", newUrl.toString());
    }
  }, [queryParams]);

  const customFieldFilters = getCustomFieldFilters({
    customFields: leadsCustomFields,
    setFilters,
    filters,
  });

  if (!leadsCustomFields || !currentUser || !leadStages)
    return <FullScreenSpinner />;

  const getNextLeadStage = (stageId: number) => {
    const currentStageIndex = leadStages.findIndex((s) => s.id === stageId);
    if (currentStageIndex === -1) return undefined;
    const nextStage = leadStages[currentStageIndex + 1];
    if (!nextStage) return undefined;
    return nextStage;
  };

  return (
    <Page title="Leads" fullWidth className="sm:p-0">
      <AddLeadDrawer
        open={addLead}
        onClose={() => setAddLead(false)}
        defaultValues={{
          mobile: addLeadMobile,
        }}
      />
      <div className="flex flex-col gap-3 justify-between w-full border-gray-200 sm:border-b sm:p-2 sm:flex-row sm:gap-0">
        <SearchInput
          value={filters.search || ""}
          onChange={(v) =>
            setFilters({
              search: v,
            })
          }
        />
        <CSVImporter
          modalIsOpen={showImport}
          modalOnCloseTriggered={() => setShowImport(false)}
          onComplete={async (data) => {
            setShowImport(false);
            const leadsToAdd = data.rows.map((lead: { values: object }) => {
              const constructedLead = construct(lead.values);
              if ("customFields" in constructedLead) {
                const {
                  isValid: isCustomFieldsValid,
                  errors: customFieldErrors,
                } = validateCustomFields({
                  customFields: leadsCustomFields,
                  customFieldsValues: constructedLead.customFields as Record<
                    string,
                    string
                  >,
                  form: null,
                });
                if (!isCustomFieldsValid) {
                  toast.error(
                    `"${
                      // @ts-ignore
                      constructedLead.name
                    }" lead import failed due to invalid custom fields - ${customFieldErrors
                      .map((error) => error.message)
                      .join(", ")}`
                  );
                  return;
                }
              }
              let customFields = {};

              // convert number custom fields to number types
              if (
                "customFields" in constructedLead &&
                typeof constructedLead.customFields === "object" &&
                constructedLead.customFields !== null
              ) {
                customFields = Object.entries(
                  constructedLead.customFields
                ).reduce<Record<string, string | number>>(
                  (acc, [key, value]) => {
                    const field = leadsCustomFields?.find(
                      (f) => f.name === key
                    );
                    if (field?.dataType === "NUMBER") {
                      acc[key] = Number(value);
                    } else {
                      acc[key] = value;
                    }
                    return acc;
                  },
                  {}
                );
              }
              return {
                ...constructedLead,
                ownerUserId: currentUser?.id,
                customFields,
              };
            });
            leadImportMutation.mutate(leadsToAdd);
            trpcUtils.leads.list.invalidate();
          }}
          template={{
            columns: [
              {
                name: "Name",
                key: "name",
                required: true,
                description: "The full name of the lead",
                suggested_mappings: ["Name", "Full Name"],
              },
              {
                name: "Mobile",
                key: "mobile",
                description: "The primary mobile number of the lead",
                suggested_mappings: ["Phone", "Mobile Number"],
              },
              {
                name: "Secondary Mobile",
                key: "secondaryMobile",
                description: "The secondary mobile number of the lead",
                suggested_mappings: ["Alternative Phone", "Other Mobile"],
              },
              {
                name: "Email",
                key: "email",
                description: "The email address of the lead",
                suggested_mappings: ["Email Address", "E-mail"],
              },
              {
                name: "Title",
                key: "title",
                description: "The title or position of the lead",
                suggested_mappings: ["Job Title", "Position"],
              },
              {
                name: "Value",
                key: "value",
                data_type: "number",
                description: "The potential value of the lead",
                suggested_mappings: ["Deal Value", "Potential Worth"],
              },
              {
                name: "Source",
                key: "create.crmSourceId",
                description: "The source of the lead",
                suggested_mappings: [
                  "Lead Source",
                  "Origin",
                  "Channel",
                  "Source",
                ],
              },
              ...leadsCustomFields.map((field) => ({
                name: field.label,
                key: `customFields.${field.name}`,
                description: "This is a custom field",
                required: field.required,
              })),
            ],
          }}
          primaryColor="#57CB99"
        />

        <div className="flex flex-row gap-3">
          <Button
            variant="outline"
            icon={Download}
            onClick={() => setShowImport(true)}
            className="hidden sm:flex"
          >
            Import
          </Button>
          {permissions?.CREATE_LEADS.allowed && (
            <ResponsiveActionButton
              onClick={() => setAddLead(true)}
              text="Lead"
            />
          )}
        </div>
      </div>
      {!isMobile() && (
        <StripeTabs
          contentClassName="pt-0"
          className="pt-2 w-full"
          tabParentClassName="pl-4"
          value={view}
          onChange={(tab) => navigate(`/crm/leads/${tab}`)}
          items={[
            { label: "Table", key: "table", icon: Table },
            { label: "Board", key: "board", icon: KanbanSquare },
          ]}
        />
      )}
      <div className="sm:px-3">
        <FilterBar
          defaultFilters={["stages", "nextActivityDate"]}
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          className="py-3"
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Created at",
              value: filters.dates,
              onChange: (value) =>
                setFilters({
                  dates: value,
                }),
            },
            {
              key: "stages",
              type: "checkbox",
              label: "Stage",
              value: filters.stages,
              onChange: (value) =>
                setFilters({
                  stages: value as number[],
                }),
              options:
                leadStages?.map((stage) => ({
                  label: stage.label,
                  value: stage.id,
                })) || [],
            },
            {
              key: "assignedTo",
              type: "checkbox",
              label: "Assigned to",
              value: filters.assignedTo,
              onChange: (value) =>
                setFilters({
                  assignedTo: value as number[],
                }),
              options: [
                { label: "Unassigned", value: 0 },
                ...(users?.map((user) => ({
                  label: user.firstName,
                  value: user.id,
                })) || []),
              ],
            },
            {
              key: "owners",
              type: "checkbox",
              label: "Owners",
              value: filters.owners,
              onChange: (value) =>
                setFilters({
                  owners: value as number[],
                }),
              options:
                users?.map((user) => ({
                  label: user.firstName,
                  value: user.id,
                })) || [],
            },
            {
              key: "archived",
              type: "checkbox",
              label: "Status",
              value: filters.archived,
              multiple: false,
              onChange: (value) =>
                setFilters({
                  archived: value as boolean[],
                }),
              options: [
                { label: "Active", value: false },
                { label: "Archived", value: true },
              ],
            },
            {
              key: "sources",
              type: "checkbox",
              label: "Sources",
              value: filters.sources,
              onChange: (value) =>
                setFilters({
                  sources: value as number[],
                }),
              options: [
                { label: "No Source", value: 0 },
                ...(leadSources?.map((source) => ({
                  label: source.name,
                  value: source.id,
                })) || []),
              ],
            },
            {
              key: "lostReasons",
              type: "checkbox",
              label: "Lost Reasons",
              value: filters.lostReasons,
              onChange: (value) =>
                setFilters({
                  lostReasons: value as number[],
                }),
              options: [
                { label: "No Reason", value: 0 },
                ...(lostReasons?.map((reason) => ({
                  label: reason.reason,
                  value: reason.id,
                })) || []),
              ],
            },
            {
              key: "nextActivityDate",
              type: "date-range",
              label: "Next Activity Date",
              presetType: "future",
              value: filters.nextActivityDate,
              onChange: (value) =>
                setFilters({
                  nextActivityDate: value,
                }),
            },
            ...customFieldFilters,
          ]}
        />
      </div>
      {selectedLead && selectedLead.action === "edit" && (
        <EditLeadDrawer
          open={true}
          leadId={selectedLead.leadId}
          onClose={() => {
            setSelectedLead(undefined);
          }}
        />
      )}
      {selectedLead && selectedLead.action === "addActivity" && (
        <AddActivityModal
          open={true}
          onClose={() => setSelectedLead(undefined)}
          leadId={selectedLead.leadId}
        />
      )}
      {selectedLead && selectedLead.action === "addNote" && (
        <AddNotesModal
          entity="LEAD"
          entityId={selectedLead.leadId}
          open={true}
          onClose={() => setSelectedLead(undefined)}
          leadId={selectedLead.leadId}
        />
      )}
      {/* {selectedLead && selectedLead.action === "lostReason" && (
        <SelectLostReasonModal
          submitText="Mark as unqualified"
          open={true}
          onClose={() => setSelectedLead(undefined)}
          type="LEAD"
          onChange={(params) => {
            leadUpdateMutation.mutate({
              id: selectedLead.leadId,
              lead: {
                lostReasonId: params.reasonId,
                lostReasonDescription: params.description,
                stage: "UNQUALIFIED",
              },
            });
          }}
        />
      )} */}
      <ModalDrawer
        open={!!showLeadNotes.length}
        onClose={() => setShowLeadNotes([])}
        title="Lead Notes"
      >
        <div className="flex flex-col gap-3">
          {showLeadNotes.map((note) => (
            <NoteCard key={note.id} note={note} onClick={() => {}} />
          ))}
        </div>
      </ModalDrawer>
      {match({ view, isMobile: isMobile(), leads })
        .with({ leads: undefined }, () => <FullScreenSpinner />)
        .with({ isMobile: true, leads: P.not(undefined) }, ({ leads }) => (
          <div className="flex overflow-y-scroll flex-col gap-3">
            {leads.leads.map((lead) => (
              <LeadCard
                data={lead}
                key={lead.id}
                onEdit={() =>
                  setSelectedLead({ leadId: lead.id, action: "edit" })
                }
              />
            ))}
            <Pagination
              pageNo={filters.pageNo}
              pageSize={filters.pageSize}
              setPageSize={(pageSize) => setFilters({ pageSize })}
              setPageNo={(pageNo) => setFilters({ pageNo })}
              className="pb-2 w-full"
              count={leads.count}
            />

            {leads.leads.length === 0 && (
              <Empty
                icon={Target}
                title="No leads"
                description="Please add some leads to get started."
                actionText="Add your first lead"
                onAction={() => setAddLead(true)}
                buttonSize="sm"
                hideAction={permissions && !permissions.CREATE_LEADS.allowed}
              />
            )}
          </div>
        ))
        .with(
          { view: "table", isMobile: false, leads: P.not(undefined) },
          ({ leads }) => (
            <DataGrid
              rowKey="id"
              name="leadsListMain"
              className="h-[calc(100vh-162px-var(--header-height))]"
              label="Leads"
              loading={isLoading}
              allowExport={permissions?.EXPORT_LEADS.allowed}
              allowSelect
              bulkActions={[
                {
                  label: "Archive",
                  icon: Archive,
                  loading: leadUpdateMutation.isLoading,
                  onClick: (rows, close) => {
                    Promise.all(
                      rows.map((row) =>
                        leadUpdateMutation.mutate({
                          id: row,
                          lead: { archived: true },
                        })
                      )
                    );
                    close();
                  },
                },
                {
                  label: "Delete",
                  icon: Trash2,
                  onClick: async (rows, close) => {
                    heffl.modal.confirm({
                      title: "Delete Leads",
                      description: `Are you sure you want to delete ${
                        rows.length
                      } lead${rows.length > 1 ? "s" : ""}?`,
                      confirmText: "Delete",
                      variant: "destructive",
                      onConfirm: () => {
                        Promise.all(
                          rows.map((row) => leadDeleteMutation.mutate(row))
                        );
                        close();
                      },
                    });
                  },
                },
              ]}
              rightClickMenuItems={(lead) => [
                {
                  type: "item",
                  icon: ArrowRight,
                  label: `Mark as ${getNextLeadStage(lead.stageId)?.label}`,
                  onClick: (row) => {
                    const nextStage = getNextLeadStage(row.stageId);
                    if (nextStage) {
                      if (nextStage.type === "UNQUALIFIED") {
                        heffl.modal.reason({
                          title: "Select lost reason",
                          type: "LEAD",
                          submitText: `Mark as ${nextStage.label}`,
                          onSubmit: (reason) => {
                            leadUpdateMutation.mutate({
                              id: row.id,
                              lead: {
                                stageId: nextStage.id,
                                lostReasonId: reason.reasonId,
                                lostReasonDescription: reason.description,
                              },
                            });
                          },
                        });
                      } else {
                        leadUpdateMutation.mutate({
                          id: row.id,
                          lead: { stageId: nextStage.id },
                        });
                      }
                    }
                  },
                  hidden: !getNextLeadStage(lead.stageId),
                },
                {
                  type: "item",
                  icon: Pencil,
                  label: "Edit Lead",
                  onClick: (row) =>
                    setSelectedLead({ leadId: row.id, action: "edit" }),
                },
                {
                  type: "item",
                  icon: Calendar,
                  label: "Add Activity",
                  onClick: (row) =>
                    setSelectedLead({ leadId: row.id, action: "addActivity" }),
                },
                {
                  type: "item",
                  icon: StickyNote,
                  label: "Add Note",
                  onClick: (row) =>
                    setSelectedLead({ leadId: row.id, action: "addNote" }),
                },
                {
                  type: "separator",
                },
                {
                  type: "item",
                  icon: Archive,
                  label: lead.archived ? "Unarchive" : "Archive",
                  onClick: async (row) => {
                    const confirmed = await confirm({
                      title: `Are you sure to ${
                        row.archived ? "unarchive" : "archive"
                      } this lead?`,
                      body: `This will ${
                        row.archived ? "unarchive" : "archive"
                      } the lead "${row.title}" and its history.`,
                      actionButton: `${row.archived ? "Unarchive" : "Archive"}`,
                    });
                    if (confirmed) {
                      leadUpdateMutation.mutate({
                        id: row.id,
                        lead: { archived: !row.archived },
                      });
                    }
                  },
                },
                {
                  className: "text-red-600",
                  type: "item",
                  icon: Trash2,
                  label: "Delete",
                  onClick: async (row) => {
                    const confirmed = await confirm({
                      title: "Are you sure to delete this lead?",
                      body: `This will delete the lead "${row.title}" and its history.`,
                      actionButton: "Delete",
                    });
                    if (confirmed) {
                      leadDeleteMutation.mutate(row.id);
                    }
                  },
                },
              ]}
              empty={{
                icon: Target,
                title: "No leads",
                description: "Please add some leads to get started.",
                actionText: "Add your first lead",
                onAction: () => setAddLead(true),
                buttonSize: "sm",
                hideAction: permissions && !permissions.CREATE_LEADS.allowed,
              }}
              rows={leads.leads}
              pagination={{
                pageNo: filters.pageNo,
                pageSize: filters.pageSize,
                count: leads.count,
                setPageNo: (pageNo) =>
                  setFilters({
                    pageNo,
                  }),
                setPageSize: (pageSize) =>
                  setFilters({
                    pageSize,
                  }),
              }}
              columns={[
                {
                  key: "number",
                  name: "No",
                  width: 100,
                  renderCell: ({ row }) => row.number,
                },
                {
                  key: "name",
                  name: "Name",
                  width: 200,
                  renderCell: ({ row }) => (
                    <div
                      onClick={() => navigate(`/crm/leads/details/${row.id}`)}
                      className="truncate rounded-md cursor-pointer hover:text-primary-600"
                    >
                      {row.name}
                    </div>
                  ),
                },
                {
                  key: "title",
                  name: "Title",
                  width: 300,
                  renderCell: ({ row }) => (
                    <div
                      onClick={() => navigate(`/crm/leads/details/${row.id}`)}
                      className="truncate rounded-md cursor-pointer hover:text-primary-600"
                    >
                      {row.title}
                    </div>
                  ),
                },
                {
                  key: "stage",
                  name: "Stage",
                  width: 120,
                  renderCell: ({ row }) => (
                    <div className="flex gap-1 items-center capitalize">
                      <div
                        className={cn(
                          "h-2 w-2 rounded-full",
                          leadStageTypeMeta[row.leadStages?.type].color
                        )}
                      />
                      {row.leadStages.label}
                    </div>
                  ),
                },
                {
                  key: "phone",
                  name: "Mobile",
                  width: 120,
                  renderCell: ({ row }) => row.mobile,
                },
                {
                  key: "email",
                  name: "Email",
                  width: 120,
                  renderCell: ({ row }) => row.email,
                },
                {
                  key: "secondaryMobile",
                  name: "Mobile (secondary)",
                  width: 120,
                  renderCell: ({ row }) => row.secondaryMobile,
                },
                {
                  key: "notes",
                  name: "Notes",
                  width: 200,
                  renderCell: ({ row }) =>
                    row.notes.length ? (
                      <Badge
                        variant="warning"
                        icon={StickyNote}
                        className="cursor-pointer w-fit"
                        onClick={() => setShowLeadNotes(row.notes)}
                      >
                        {row.notes.length}{" "}
                        {row.notes.length === 1 ? "Note" : "Notes"}
                      </Badge>
                    ) : (
                      "-"
                    ),
                },
                {
                  key: "nextActivity",
                  name: "Next Activity",
                  width: 200,
                  renderCell: ({ row }) => {
                    const todo = [
                      ...row.activities.map((a) => ({
                        date: a.startDate,
                        type: "activity",
                        title: a.title,
                      })),
                      ...row.tasks.map((t) => ({
                        date: t.date,
                        type: "task",
                        title: t.title,
                      })),
                    ].sort((a, b) => a.date.getTime() - b.date.getTime());
                    const Icon =
                      todo[0]?.type === "activity" ? Calendar : CheckSquare;
                    return todo[0] && Icon ? (
                      <div
                        className={cn(
                          "flex items-center",
                          isBefore(todo[0].date, new Date()) && "text-red-600",
                          isToday(todo[0].date) && "text-green-600"
                        )}
                      >
                        <Icon className="mr-1 h-4" />
                        {todo[0].title}{" "}
                        <span className="px-1 text-gray-300">•</span>
                        {dynamicDateFormatting(todo[0].date)}
                      </div>
                    ) : (
                      <div className="flex items-center text-yellow-600">
                        <AlertOctagon className="mr-1 h-4" /> No task
                      </div>
                    );
                  },
                },
                {
                  key: "source",
                  name: "Source",
                  width: 120,
                  renderCell: ({ row }) => row.crmSources?.name || "No source",
                },
                {
                  key: "assignees",
                  name: "Assignees",
                  width: 120,
                  renderCell: ({ row }) =>
                    row.leadAssignees?.map((a) => a.users.firstName).join(", "),
                },
                {
                  key: "createdAt",
                  name: "Lead Created",
                  width: 170,
                  renderCell: ({ row }) =>
                    dynamicDateFormatting(row.createdAt, false, ","),
                },
                {
                  key: "owner",
                  name: "Owner",
                  width: 120,
                  renderCell: ({ row }) => row.ownedBy?.firstName || "No owner",
                },
                {
                  key: "lostReason",
                  name: "Lost Reason",
                  width: 120,
                  renderCell: ({ row }) => (
                    <div className="truncate">{row.lostReasons?.reason}</div>
                  ),
                },
                {
                  key: "nextActivityDate",
                  name: "Next Activity Date",
                  width: 120,
                  renderCell: ({ row }) => {
                    const todo = [
                      ...row.activities.map((a) => ({
                        date: a.startDate,
                        type: "activity",
                        title: a.title,
                      })),
                      ...row.tasks.map((t) => ({
                        date: t.date,
                        type: "task",
                        title: t.title,
                      })),
                    ].sort((a, b) => a.date.getTime() - b.date.getTime());
                    return todo[0]?.date
                      ? dayjs(todo[0].date).format("DD MMM YYYY")
                      : "-";
                  },
                },
                {
                  key: "actions",
                  name: "Actions",
                  width: 90,
                  renderCell: ({ row }) => (
                    <DropMenu
                      items={[
                        {
                          label: row.archived ? "Unarchive" : "Archive",
                          onClick: () =>
                            leadUpdateMutation.mutate({
                              id: row.id,
                              lead: { archived: !row.archived },
                            }),
                        },
                        {
                          label: "Delete",
                          className: "text-red-600",
                          hidden: !permissions?.DELETE_LEADS.allowed,
                          onClick: async () => {
                            const confirmed = await confirm({
                              title: "Are you sure to delete this lead?",
                              body: `This will delete the lead "${row.title}" and its history.`,
                              actionButton: "Delete",
                            });
                            if (confirmed) {
                              leadDeleteMutation.mutate(row.id);
                            }
                          },
                        },
                      ]}
                    >
                      <Button variant="ghost" size="icon">
                        <MoreHorizontal className="w-5 h-5" />
                      </Button>
                    </DropMenu>
                  ),
                },
                ...(leadsCustomFields ?? []).map((field) => ({
                  key: field.name,
                  name: field.label,
                  // @ts-ignore
                  renderCell: ({ row }) =>
                    renderCustomFieldValue(
                      field,
                      formatCustomFields({
                        customFields: row.customFields,
                        customFieldFields: leadsCustomFields || [],
                      })[field.name]
                    ),
                })),
              ]}
            />
          )
        )
        .with(
          { view: "board", isMobile: false, leads: P.not(undefined) },
          ({ leads }) => (
            <KanbanBoard
              items={leads.leads.map((lead) => ({
                ...lead,
                stageId: lead.stageId.toString(),
              }))}
              statusKey="stageId"
              columns={
                leadStages?.map((stage) => ({
                  id: stage.id.toString(),
                  label: stage.label,
                  value: stage.id.toString(),
                  color: leadStageTypeMeta[stage.type].color,
                })) || []
              }
              renderItem={(lead) => (
                <LeadCard data={{ ...lead, stageId: Number(lead.stageId) }} />
              )}
              onDragging={(updated) => {
                trpcUtils.leads.list.setData(leadParams, () => {
                  return {
                    leads: updated.map((lead) => ({
                      ...lead,
                      stageId: Number(lead.stageId),
                    })),
                    count: leads.count,
                  };
                });
              }}
              onChange={(updated) => {
                leadBulkUpdateMutation.mutate(
                  updated.map((item) => ({
                    id: Number(item.id),
                    position: item.position,
                    stageId: Number(item.status),
                  }))
                );
              }}
              className="px-2 h-[calc(100vh-120px)] sm:h-[calc(100vh-208px)]"
            />
          )
        )
        .otherwise(() => (
          <FullScreenSpinner />
        ))}
    </Page>
  );
};

export default LeadsList;
