import { ContactInput, VendorInput } from "@/components/FormComponents";
import LineItemSelectorNew from "@/components/line-item-selector-new";
import { trpc } from "@/helpers/trpc";
import useQueryParams from "@/helpers/useQuery";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import Schemas from "@heffl/server/src/schemas";
import RichTextEditor from "@heffl/ui/components/primitives/RichTextEditor";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import { cn, formatCurrency } from "@heffl/ui/lib/utils";
import { Pencil, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";

const PurchaseOrderForm: React.FC<{
  form: UseFormReturn<z.infer<typeof Schemas.purchases.purchaseOrder>, unknown>;
  edit?: boolean;
}> = ({ form, edit = false }) => {
  const { data: templates } = trpc.templates.list.useQuery({
    type: "PURCHASE_ORDER",
  });

  const params = useQueryParams();
  const vendorIdParams = params.get("vendorId");

  const vendorId = form.watch("vendorId");
  const templateId = form.watch("templateId");
  const lineItems = form.watch("purchaseOrderItems");
  const discount = form.watch("discount");
  const lineItemsValues = calculateLineItems({ lineItems, discount });

  useEffect(() => {
    if (vendorIdParams) {
      form.setValue("vendorId", Number(vendorIdParams));
    }
  }, [vendorIdParams, form]);

  const [showEditContent, setShowEditContent] = useState(false);

  const { data: products } = trpc.products.list.useQuery({
    pageNo: 1,
    pageSize: 20,
  });

  const { data: templateDetails } = trpc.templates.details.useQuery(
    Number(templateId),
    {
      enabled: !!templateId && !edit,
    }
  );
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PURCHASE_ORDER",
  });

  const includeItemsVat = () => {
    if (!lineItems) return;
    const updatedLineItems = lineItems.map((item) => {
      if (typeof item.price === "number" && typeof item.tax === "number") {
        const vatDeductedAmount: number = (item.price * 100) / (100 + item.tax);
        return {
          ...item,
          price: Number(vatDeductedAmount.toFixed(2)),
        };
      }
      return item;
    });
    form.setValue("purchaseOrderItems", updatedLineItems || []);
  };

  useEffect(() => {
    if (templateDetails && !edit) {
      form.setValue("contentHtml", templateDetails.contentHtml || "");
    }
  }, [templateDetails, form, edit]);

  useEffect(() => {
    if (templates?.length && !edit && !templateId) {
      form.setValue("templateId", templates[0].id);
    }
  }, [templates]);

  if (!products || !templates || !tags) return <FullScreenSpinner />;

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-3">
        {!edit && (
          <FormField name="templateId" label="Template">
            <Select
              placeholder="Select template"
              options={templates.map((template) => ({
                label: template.name,
                value: template.id,
              }))}
            />
          </FormField>
        )}
        <FormField name="date" label="Date">
          <DatePicker />
        </FormField>
        <FormField name="purchaseOrderTags" label="Tags">
          <MultiSelect
            placeholder="Add tags"
            options={tags?.map((tag) => ({
              label: tag.name,
              value: tag.id,
              color: tag.color,
            }))}
          />
        </FormField>
        <FormField name="expectedDeliveryDate" label="Expected Delivery Date">
          <DatePicker />
        </FormField>
      </div>

      <div className="grid grid-cols-1 gap-2 sm:grid-cols-3">
        <VendorInput name="vendorId" label="Vendor" />
        <ContactInput
          name="contactId"
          label="Contact"
          fetchEnabled={!!vendorId}
          onAddModalClose={(id) => id && form.setValue("contactId", id)}
          disabled={!vendorId}
          setDefault={!edit && !!vendorId}
          allowClear
          vendorId={vendorId}
        />
      </div>

      <div className="mb-6">
        <div className="flex flex-col py-2">
          <FormField name="purchaseOrderItems" label="">
            <LineItemSelectorNew
              isEdit={edit}
              documentTemplateId={templateId}
              buyingPriceDefault={true}
              type="PURCHASE_ORDER"
            />
          </FormField>
        </div>
      </div>
      <div className="flex justify-end w-full">
        <table className="gap-8 text-base border-separate border-spacing-y-2">
          <tr>
            <td className="w-52">Subtotal</td>
            <td className="text-right">
              {formatCurrency(lineItemsValues.subTotal, "AED")}
            </td>
          </tr>
          <tr>
            <td className="w-52">Discount</td>
            <td className="text-right">
              <FormField name="discount">
                <Input suffix="AED" className="w-[70px]" />
              </FormField>
            </td>
          </tr>
          <tr>
            <td className="flex gap-2 items-center w-52">
              VAT
              <Button
                onClick={includeItemsVat}
                size="sm"
                variant="primaryOutline"
              >
                <Plus className="h-4" />
                Include
              </Button>
            </td>
            <td className="text-right">
              {formatCurrency(lineItemsValues.totalTax, "AED")}
            </td>
          </tr>
          <tr>
            <td className="w-52">Total</td>
            <td className="font-semibold text-right">
              {formatCurrency(lineItemsValues.total, "AED")}
            </td>
          </tr>
        </table>
      </div>
      {!showEditContent && (
        <Button
          variant="primaryOutline"
          onClick={() => setShowEditContent(true)}
        >
          <Pencil className="h-4" /> Edit content
        </Button>
      )}

      <FormField
        name="contentHtml"
        label="Content"
        className={cn(!showEditContent && "hidden")}
      >
        <RichTextEditor height="700px" />
      </FormField>
    </div>
  );
};

export default PurchaseOrderForm;
