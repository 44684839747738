import { z } from "zod";
import { lineItemProduct } from "./sales";
import enums from "./enums";

const projectProfile = z.object({
  name: z.string().min(1, "Please add a name"),
  projectTitle: z.string().min(1, "Please add a title"),
  description: z.string().nullish(),
  isActive: z.boolean().default(true),
  projectPipelineId: z.number(),
  projectTemplateId: z.number().nullish(),
  clientId: z.number().nullish(),
  projectDurationDays: z.coerce.number().positive(),
  recurringStartDate: z.date(),
  recurringEndDate: z.date().nullish(),
  recurringRule: z.string(),
  contactId: z.number().nullish(),
  salesPersonId: z.number().nullish(),
  projectCustomFields: z.any().nullish(),
  projectProfileItems: lineItemProduct
    .extend({
      id: z.number().nullish(),
      buyPrice: z.coerce.number().default(0),
      position: z.number(),
    })
    .array(),
  discount: z.coerce.number().default(0),
  projectProfileAssignees: z.coerce.number().array().nullish(),
  projectProfileQuotations: z.coerce.number().array().nullish(),
  convertProjectId: z.number().nullish(),
});

const projectProfileTask = z.object({
  date: z.date().nullish(),
  title: z.string().min(1, "Please add a title to task"),
  description: z.string().nullish(),
  dueDays: z.coerce.number().default(0),
  projectProfileId: z.number().nullish(),
  projectTaskProfileAssignees: z.coerce.number().array().nullish(),
  projectId: z.number().nullish(),
  recurringRule: z.string().min(1).nullish(),
  priority: enums.taskPriority.default("LOW"),
  parentTaskProfileId: z.number().nullish(),
  // for sub tasks recurring tasks linking
  parentProjectTaskId: z.number().nullish(),
});

export default {
  projectProfile,
  projectProfileTask,
};
