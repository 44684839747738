import { RouterOutputs, trpc } from "@/helpers/trpc";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { zodResolver } from "@hookform/resolvers/zod";
import duration from "dayjs/plugin/duration";
import {
  Calendar,
  Check,
  Clock,
  Pencil,
  RefreshCw,
  Timer,
  X,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

import DataGrid from "@/components/dataGrid/DataGrid";
import Empty from "@/components/Empty";
import FilterBar from "@/components/filter-bar";
import { SearchInput, UserInput } from "@/components/FormComponents";
import Page from "@/components/page";
import Pagination from "@/components/Pagination";
import heffl from "@/helpers/heffl";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import useTeam from "@/lib/hooks/useTeam";
import { DateTimePickerNew } from "@/pages/field-service/schedules/components/schduleDetailsModal";
import Schemas from "@heffl/server/src/schemas";
import enums from "@heffl/server/src/schemas/enums";
import appIcons from "@heffl/ui/components/appIcons";
import InfoItems from "@heffl/ui/components/info-items";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { Label } from "@heffl/ui/components/primitives/label";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import Select from "@heffl/ui/components/primitives/select";
import { Switch } from "@heffl/ui/components/primitives/switch";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import RenderHtml from "@heffl/ui/components/render-html";
import StatsBar from "@heffl/ui/components/stats-cards";
import {
  cn,
  dynamicDateFormatting,
  dynamicTwoDateFormatting,
  isMobile,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";

export const convertToHoursAndMinutes = (totalMinutes: number) => {
  // Calculate hours
  const hours = Math.floor(totalMinutes / 60);

  // Calculate remaining minutes
  const minutes = totalMinutes % 60;

  // Pad with leading zeros if needed
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};

dayjs.extend(duration);

const timeSheetStatuses = {
  OPEN: {
    label: "Pending",
    value: "OPEN",
    color: "text-yellow-500",
    variant: "warning",
    icon: Clock,
  },
  APPROVED: {
    label: "Approved",
    value: "APPROVED",
    color: "text-green-500",
    variant: "success",
    icon: Check,
  },
  REJECTED: {
    label: "Rejected",
    value: "REJECTED",
    color: "text-red-500",
    variant: "error",
    icon: X,
  },
} as const;

const TimesheetForm = ({
  form,
  showTaskSelect = true,
  clientId,
  projectId,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.project.timesheet>>;
  showTaskSelect?: boolean;
  clientId?: number | null;
  projectId?: number | null;
}) => {
  const startTime = form.watch("startTime");

  const durationType = form.watch("durationType");

  const [selectValues, setSelectValues] = useImmer<{
    clientSearch: string;
    clientId: number | undefined;
    projectId: number | undefined;
  }>({
    clientSearch: "",
    clientId: undefined,
    projectId: undefined,
  });

  useEffect(() => {
    setSelectValues((draft) => {
      draft.clientId = clientId || undefined;
      draft.projectId = projectId || undefined;
    });
  }, [clientId, projectId]);

  const { data: clients } = trpc.clients.list.useQuery({
    search: selectValues.clientSearch,
    pageSize: 10,
    pageNo: 1,
    include: selectValues.clientId ? [selectValues.clientId] : [],
  });
  const { data: projects } = trpc.projects.list.useQuery(
    {
      clients: selectValues.clientId ? [selectValues.clientId] : [],
      statuses: ["OPEN"],
    },
    {
      enabled: !!selectValues.clientId,
    }
  );

  const { data: projectTasks } = trpc.projects.tasks.list.useQuery(
    {
      projectId: selectValues.projectId,
      showAllTasks: true,
    },
    {
      enabled: !!selectValues.projectId,
    }
  );

  return (
    <div className="flex flex-col gap-3">
      {showTaskSelect && (
        <>
          <UserInput name="userId" label="User" defaultCurrentUser />
          <div className="flex gap-2">
            <span className="space-y-1 w-full">
              <Label>Client</Label>
              <Select
                placeholder="Select client"
                value={selectValues.clientId}
                onChange={(v) =>
                  setSelectValues((draft) => {
                    draft.clientId = v;
                    draft.clientSearch = "";
                    draft.projectId = undefined;
                    // @ts-ignore
                    form.setValue("projectTaskId", null);
                  })
                }
                options={
                  clients?.clients.map((c) => ({
                    label: c.name,
                    value: c.id,
                  })) || []
                }
                onSearch={(v) =>
                  setSelectValues((draft) => {
                    draft.clientSearch = v;
                  })
                }
              />
            </span>
            <span className="space-y-1 w-full">
              <Label>Project</Label>
              <Select
                placeholder="Select project"
                value={selectValues.projectId}
                onChange={(v) => {
                  setSelectValues((draft) => {
                    draft.projectId = v;
                  });
                  // @ts-ignore
                  form.setValue("projectTaskId", null);
                }}
                options={
                  projects?.projects.map((p) => ({
                    label: p.title,
                    value: p.id,
                  })) || []
                }
              />
            </span>
          </div>
          <FormField name="projectTaskId" label="Task">
            <Select
              className="w-full"
              options={
                projectTasks?.tasks.map((t) => ({
                  label: t.title,
                  value: t.id,
                })) || []
              }
              placeholder="Select project task"
              value={form.watch("projectTaskId")}
              onChange={(v) => form.setValue("projectTaskId", v)}
            />
          </FormField>
        </>
      )}
      <div className="grid grid-cols-2 gap-2">
        <FormField name="durationType" label="Type">
          <TabsInput
            options={[
              {
                value: "DATE",
                label: "Date",
                icon: Calendar,
              },
              {
                value: "MINUTES",
                label: "Duration",
                icon: Timer,
              },
            ]}
          />
        </FormField>
        <FormField name="billable" label="Billable">
          <Switch />
        </FormField>
      </div>
      {durationType === "DATE" && (
        <div className="flex flex-col gap-4 sm:flex-row">
          <FormField name="startTime" label="Start Time">
            <DateTimePickerNew toDate={dayjs().toDate()} />
          </FormField>
          <FormField name="endTime" label="End Time">
            <DateTimePickerNew toDate={dayjs().toDate()} fromDate={startTime} />
          </FormField>
        </div>
      )}
      {durationType === "MINUTES" && (
        <div className="grid grid-cols-2 gap-2 sm:grid-cols-4">
          <FormField name="startTime" label="Date">
            <DatePicker />
          </FormField>
          <FormField name="minutesWorked" label="Minutes Worked">
            <Input type="number" placeholder="120" />
          </FormField>
        </div>
      )}
      <FormField name="notes" label="Notes">
        <MiniRichTextEditor placeholder="Add notes" />
      </FormField>
    </div>
  );
};

export const EditTimesheetDrawer = ({
  open,
  onClose,
  timesheetId,
}: {
  open: boolean;
  onClose: () => void;
  timesheetId: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.project.timesheet>>({
    resolver: zodResolver(Schemas.project.timesheet),
    defaultValues: {},
  });
  const { data: timesheet } = trpc.timesheets.details.useQuery(timesheetId);

  const timesheetUpdateMutation = trpc.timesheets.update.useMutation({
    onSuccess: () => {
      toast.success("Timesheet updated successfully");
      onClose();
    },
  });

  useEffect(() => {
    if (timesheet) {
      form.reset({ ...timesheet });
    }
  }, [timesheet]);

  const onSubmit = (values: z.infer<typeof Schemas.project.timesheet>) => {
    timesheetUpdateMutation.mutate({
      id: timesheetId,
      timesheet: values,
    });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Edit timesheet"
      modalClassName="min-w-[600px]"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            onClick={() => form.handleSubmit(onSubmit)()}
            variant="primary"
            className="w-full"
            loading={timesheetUpdateMutation.isLoading}
          >
            Update timesheet
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <TimesheetForm
          form={form}
          clientId={timesheet?.projectTasks?.projects.clientId}
          projectId={timesheet?.projectTasks?.projects.id}
        />
      </Form>
    </ModalDrawer>
  );
};

export const AddTimesheetDrawer = ({
  open,
  onClose,
  defaultValues,
  showTaskSelect = true,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<
    z.infer<typeof Schemas.project.timesheet> & {
      clientId?: number;
      projectId?: number;
    }
  >;
  showTaskSelect?: boolean;
}) => {
  const form = useForm<z.infer<typeof Schemas.project.timesheet>>({
    resolver: zodResolver(Schemas.project.timesheet),
    defaultValues: {
      durationType: "DATE",
      startTime: new Date(),
      endTime: new Date(),
      ...defaultValues,
    },
  });

  const onModalClose = () => {
    form.reset();
    onClose();
  };

  const addTimesheetMutation = trpc.timesheets.add.useMutation({
    onSuccess: () => {
      toast.success("Timesheet added successfully");
      onModalClose();
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.project.timesheet>) => {
    addTimesheetMutation.mutate({
      ...values,
      endTime: values.durationType === "DATE" ? values.endTime : undefined,
      minutesWorked:
        values.durationType === "MINUTES" ? values.minutesWorked : 0,
    });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title="Add timesheet"
      modalClassName="min-w-[600px]"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            onClick={() => form.handleSubmit(onSubmit)()}
            type="submit"
            variant="primary"
            className="w-full"
            loading={addTimesheetMutation.isLoading}
          >
            Add timesheet
          </Button>
        </div>
      }
    >
      <Form
        {...form}
        onSubmit={(values) => {
          addTimesheetMutation.mutate({
            ...values,
          });
        }}
      >
        <TimesheetForm
          form={form}
          showTaskSelect={showTaskSelect}
          clientId={defaultValues?.clientId}
          projectId={defaultValues?.projectId}
        />
      </Form>
    </ModalDrawer>
  );
};

type Filters = {
  search: string;
  users: number[];
  approvalStatus: z.infer<typeof enums.timeSheetStatusTypes>[];
  dates?: [Date, Date];
  pageNo: number;
  pageSize: number;
  clients: number[];
  projectIds: number[];
  createdAt?: [Date, Date];
  billable?: boolean;
};

export const TimesheetDetailsModal = ({
  open,
  onClose,
  id,
}: {
  open: boolean;
  onClose: () => void;
  id: number | null;
}) => {
  const team = useTeam();
  const navigate = useNavigate();

  const [showEdit, setShowEdit] = useState(false);

  const { data: timesheet } = trpc.timesheets.details.useQuery(id!, {
    enabled: !!id,
  });

  const timesheetUpdateMutation = trpc.timesheets.update.useMutation({
    onSuccess: () => {
      toast.success("Timesheet updated successfully");
    },
    onError: () => {
      toast.error("Failed to update timesheet");
    },
  });

  const allowEditing =
    (team && team.user.permissions.UPDATE_TIMESHEETS_APPROVE.allowed) ||
    (team &&
      team.user.id === timesheet?.userId &&
      timesheet?.approvalStatus !== "APPROVED");

  const allowApprove =
    (team && team.user.permissions.UPDATE_TIMESHEETS_APPROVE.allowed) ||
    (team && ["ADMIN", "SUPER_ADMIN"].includes(team.user.type));

  return (
    <>
      {!!id && (
        <EditTimesheetDrawer
          open={showEdit}
          onClose={() => setShowEdit(false)}
          timesheetId={id}
        />
      )}
      <ModalDrawer
        title="Timesheet Details"
        open={open}
        onClose={onClose}
        modalClassName="max-w-2xl"
        actions={[
          {
            label: "Approve",
            icon: Check,
            onClick: () => {
              if (!timesheet) return;
              timesheetUpdateMutation.mutate({
                id: timesheet.id,
                timesheet: { approvalStatus: "APPROVED" },
              });
            },
            hide: !allowApprove || timesheet?.approvalStatus !== "OPEN",
            buttonVariant: "primaryOutline",
            loading:
              timesheetUpdateMutation.isLoading &&
              timesheetUpdateMutation.variables?.timesheet?.approvalStatus ===
                "APPROVED",
          },
          {
            label: "Reject",
            icon: X,
            onClick: () => {
              if (!timesheet) return;
              timesheetUpdateMutation.mutate({
                id: timesheet.id,
                timesheet: { approvalStatus: "REJECTED" },
              });
            },
            hide: !allowApprove || timesheet?.approvalStatus !== "OPEN",
            buttonVariant: "destructiveOutline",
            loading:
              timesheetUpdateMutation.isLoading &&
              timesheetUpdateMutation.variables?.timesheet?.approvalStatus ===
                "REJECTED",
          },
          {
            label: "Re-open",
            loading:
              timesheetUpdateMutation.isLoading &&
              timesheetUpdateMutation.variables?.timesheet?.approvalStatus ===
                "OPEN",
            icon: RefreshCw,
            onClick: () => {
              if (!timesheet) return;
              timesheetUpdateMutation.mutate({
                id: timesheet.id,
                timesheet: { approvalStatus: "OPEN" },
              });
            },
            hide: !allowApprove || timesheet?.approvalStatus === "OPEN",
          },
          {
            label: "Edit",
            icon: Pencil,
            onClick: () => setShowEdit(true),
            hide: !allowEditing,
          },
        ]}
        defaultActionsButtonSize="xs"
      >
        {!timesheet && <FullScreenSpinner />}
        {timesheet && (
          <div className="flex flex-col gap-2">
            <div className="text-sm text-gray-500">Duration</div>
            <div className="text-2xl font-semibold">
              {heffl.format.duration(timesheet.minutesWorked)}
            </div>
            <Badge variant="outline" className="w-fit" avatar>
              {heffl.format.name(timesheet.users)}
            </Badge>
            <InfoItems
              column={isMobile() ? 1 : 2}
              size="small"
              items={[
                {
                  label: "Date",
                  children: heffl.format.date(timesheet.startTime),
                  span: 1,
                },
                {
                  label: "Timesheet #",
                  children: (
                    <Badge variant="outline">#{timesheet.rawNumber}</Badge>
                  ),
                  span: 1,
                },
                {
                  label: "Created At",
                  children: dayjs(timesheet.createdAt).format("DD/MM/YYYY"),
                  span: 1,
                },
                {
                  label: "Status",
                  children: (
                    <Badge
                      variant={
                        timeSheetStatuses[timesheet.approvalStatus].variant
                      }
                      icon={timeSheetStatuses[timesheet.approvalStatus].icon}
                    >
                      {timeSheetStatuses[timesheet.approvalStatus].label}
                    </Badge>
                  ),
                  span: 1,
                },
                {
                  label: "Billable",
                  children: (
                    <Badge variant={timesheet.billable ? "success" : "warning"}>
                      {timesheet.billable ? "Yes" : "No"}
                    </Badge>
                  ),
                  span: 2,
                },
                {
                  label: "Project",
                  children: (
                    <Badge
                      variant="outline"
                      icon={appIcons.projects.project.icon}
                      onClick={() => {
                        navigate(
                          `/projects/details/${timesheet.projectTasks.projects.id}`
                        );
                      }}
                    >
                      {timesheet.projectTasks.projects.title}
                    </Badge>
                  ),
                  span: 2,
                },
                {
                  label: "Task",
                  children: (
                    <Badge variant="outline" icon={appIcons.projects.task.icon}>
                      {`${timesheet.projectTasks.number} : ${timesheet.projectTasks.title}`}
                    </Badge>
                  ),
                  span: 2,
                },

                {
                  label: "Notes",
                  children: <RenderHtml>{timesheet.notes || "-"}</RenderHtml>,
                  span: 4,
                },
              ]}
            />
          </div>
        )}
      </ModalDrawer>
    </>
  );
};

export const TimesheetsTable = ({
  name,
  filters,
  pagination,
  classes,
  showStats = false,
}: {
  name: "timesheetsListMain" | "timesheetsListProject";
  filters: Partial<Filters>;
  showStats?: boolean;
  pagination?: {
    setPageNo: (pageNo: number) => void;
    setPageSize: (pageSize: number) => void;
  };
  classes?: {
    dataGrid?: string;
    parent?: string;
    stats?: string;
  };
}) => {
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [editTimesheetDrawerOpen, setEditTimesheetDrawerOpen] = useState<
    number | null
  >(null);
  const [timesheetDetailsModalOpen, setTimesheetDetailsModalOpen] = useState<
    number | null
  >(null);

  const { data: timesheets } = trpc.timesheets.list.useQuery({
    users: filters.users,
    approvalStatuses: filters.approvalStatus,
    dates: filters.dates
      ? [
          dayjs(filters.dates[0]).startOf("day").toDate(),
          dayjs(filters.dates[1]).endOf("day").toDate(),
        ]
      : undefined,
    createdAt: filters.createdAt
      ? [
          dayjs(filters.createdAt[0]).startOf("day").toDate(),
          dayjs(filters.createdAt[1]).endOf("day").toDate(),
        ]
      : undefined,
    pageNo: filters.pageNo,
    pageSize: filters.pageSize,
    clients: filters.clients,
    projectIds: filters.projectIds,
    billable: filters.billable,
  });
  const { data: currentUser } = trpc.users.currentUser.useQuery();
  const timesheetUpdateMutation = trpc.timesheets.update.useMutation({
    onSuccess: () => {
      toast.success("Timesheet updated successfully");
    },
    onError: () => {
      toast.error("Failed to update timesheet");
    },
  });

  const bulkApproveMutation = trpc.timesheets.bulkApprove.useMutation({
    onSuccess: () => {
      toast.success("Timesheets approved successfully");
    },
    onError: () => {
      toast.error("Failed to approve timesheets");
    },
  });

  const allowTimesheetEditing = (
    timesheet: RouterOutputs["timesheets"]["list"]["list"][number]
  ) => {
    if (!currentUser || !permissions) return false;
    return (
      permissions.UPDATE_TIMESHEETS_APPROVE.allowed ||
      (currentUser.id === timesheet.userId &&
        timesheet.approvalStatus !== "APPROVED")
    );
  };

  const allowTimeSheetApproval = () => {
    if (!currentUser || !permissions) return false;
    return (
      permissions.UPDATE_TIMESHEETS_APPROVE.allowed ||
      ["ADMIN", "SUPER_ADMIN"].includes(currentUser.type)
    );
  };

  return (
    <div className={classes?.parent}>
      {!!editTimesheetDrawerOpen && (
        <EditTimesheetDrawer
          open={true}
          onClose={() => setEditTimesheetDrawerOpen(null)}
          timesheetId={editTimesheetDrawerOpen}
        />
      )}

      <TimesheetDetailsModal
        open={!!timesheetDetailsModalOpen}
        onClose={() => setTimesheetDetailsModalOpen(null)}
        id={timesheetDetailsModalOpen}
      />

      {showStats && (
        <StatsBar
          className={classes?.stats}
          items={[
            {
              title: "To approve",
              value: `${timesheets?.meta.stats.toApprove || 0}`,
            },
            {
              title: "Total billable",
              value: heffl.format.duration(
                timesheets?.meta.stats.billableMinutes || 0
              ),
            },
            {
              title: "Total non-billable",
              value: heffl.format.duration(
                timesheets?.meta.stats.nonBillableMinutes || 0
              ),
            },
            {
              title: "Total time",
              value: heffl.format.duration(
                timesheets?.meta.stats.totalMinutes || 0
              ),
            },
          ]}
        />
      )}

      <div className="sm:hidden">
        {timesheets && !timesheets?.list.length && (
          <Empty
            icon={Timer}
            title="No timesheets"
            description="Add timesheets to this task"
          />
        )}
        {!timesheets && <FullScreenSpinner />}
      </div>

      {!isMobile() && (
        <DataGrid
          rowKey="id"
          name={name}
          // className="h-[calc(100vh-117px-var(--header-height))]"
          className={classes?.dataGrid}
          label="Timesheets"
          rows={timesheets?.list || []}
          allowSelect
          bulkActions={[
            {
              loading: bulkApproveMutation.isLoading,
              label: "Approve",
              disabled: !allowTimeSheetApproval(),
              icon: Check,
              variant: "primaryOutline",
              onClick: (selectedRows, close) => {
                bulkApproveMutation.mutate(selectedRows.map((row) => row));
                close();
              },
            },
          ]}
          pagination={{
            pageNo: filters.pageNo || 1,
            count: timesheets?.meta.count,
            pageSize: filters.pageSize || 30,
            setPageNo: (pageNo) => pagination?.setPageNo(pageNo),
            setPageSize: (pageSize) => pagination?.setPageSize(pageSize),
          }}
          onCellClick={({ row }) => {
            setTimesheetDetailsModalOpen(row.id);
          }}
          columns={[
            {
              key: "number",
              name: "Number",
              width: 100,
              renderCell: ({ row }) => (
                <p className="cursor-pointer hover:underline hover:text-primary-600">
                  #{row.rawNumber}
                </p>
              ),
            },
            {
              key: "user",
              name: "#",
              width: 150,
              renderCell: ({ row }) => (
                <Badge variant="outline" icon={appIcons.common.user.icon}>
                  {heffl.format.name(row.users)}
                </Badge>
              ),
            },
            {
              key: "project",
              name: "Project",
              width: 200,
              renderCell: ({ row }) => (
                <p
                  onClick={() => {
                    navigate(
                      `/projects/details/${row.projectTasks?.projectId}`
                    );
                  }}
                  className="cursor-pointer hover:text-primary hover:underline"
                >
                  <Badge
                    variant="outline"
                    icon={appIcons.projects.project.icon}
                  >
                    {row.projectTasks?.projects.title}
                  </Badge>
                </p>
              ),
            },
            {
              key: "task",
              name: "Task",
              width: 120,
              renderCell: ({ row }) => (
                <Badge variant="outline" icon={appIcons.projects.task.icon}>
                  {row.projectTasks?.number} : {row.projectTasks?.title}
                </Badge>
              ),
            },
            {
              key: "client",
              name: "Client",
              width: 120,
              renderCell: ({ row }) =>
                row.projectTasks.projects.clients ? (
                  <Badge
                    avatar
                    variant="outline"
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(
                        `/sales/clients/details/${row.projectTasks?.projects?.clients?.id}`
                      );
                    }}
                  >
                    {row.projectTasks?.projects?.clients?.name}
                  </Badge>
                ) : (
                  <p>-</p>
                ),
            },
            {
              key: "date",
              name: "Date",
              width: 120,
              renderCell: ({ row }) => (
                <p>
                  {row.endTime
                    ? dynamicTwoDateFormatting({
                        startDate: row.startTime,
                        endDate: row.endTime,
                      })
                    : dynamicDateFormatting(row.startTime, true, ", ")}
                </p>
              ),
            },
            {
              key: "hours",
              name: "Hours",
              width: 100,
              renderCell: ({ row }) => (
                <p>{heffl.format.duration(row.minutesWorked)}</p>
              ),
            },
            {
              key: "status",
              name: "Status",
              width: 120,
              renderCell: ({ row }) => (
                <Badge
                  variant={timeSheetStatuses[row.approvalStatus].variant}
                  icon={timeSheetStatuses[row.approvalStatus].icon}
                >
                  {timeSheetStatuses[row.approvalStatus].label}
                </Badge>
              ),
            },
            {
              key: "notes",
              name: "Notes",
              width: 200,
              renderCell: ({ row }) => <RenderHtml>{row.notes}</RenderHtml>,
            },
            {
              key: "actions",
              name: "Actions",
              width: 120,
              renderCell: ({ row }) => (
                <div className="flex gap-2">
                  {allowTimesheetEditing(row) && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        const allowEditing =
                          currentUser?.type === "ADMIN" ||
                          currentUser?.type === "SUPER_ADMIN" ||
                          row.approvalStatus !== "APPROVED";
                        if (allowEditing) {
                          setEditTimesheetDrawerOpen(row.id);
                        }
                      }}
                      size="xs"
                      icon={Pencil}
                      variant="primaryOutline"
                    >
                      Edit
                    </Button>
                  )}
                  {row.approvalStatus === "OPEN" &&
                    allowTimeSheetApproval() && (
                      <div className="flex gap-2 items-center">
                        <Button
                          onClick={(e) => {
                            timesheetUpdateMutation.mutate({
                              id: row.id,
                              timesheet: { approvalStatus: "APPROVED" },
                            });
                            e.stopPropagation();
                          }}
                          size="xs"
                          icon={Check}
                          variant="primaryOutline"
                          loading={
                            timesheetUpdateMutation.isLoading &&
                            timesheetUpdateMutation.variables?.id === row.id
                          }
                        >
                          Approve
                        </Button>
                        <Button
                          onClick={(e) => {
                            timesheetUpdateMutation.mutate({
                              id: row.id,
                              timesheet: { approvalStatus: "REJECTED" },
                            });
                            e.stopPropagation();
                          }}
                          size="xs"
                          icon={X}
                          variant="destructiveOutline"
                          loading={
                            timesheetUpdateMutation.isLoading &&
                            timesheetUpdateMutation.variables?.id === row.id
                          }
                        >
                          Reject
                        </Button>
                      </div>
                    )}
                  {row.approvalStatus !== "OPEN" &&
                    allowTimeSheetApproval() && (
                      <Button
                        onClick={() => {
                          timesheetUpdateMutation.mutate({
                            id: row.id,
                            timesheet: { approvalStatus: "OPEN" },
                          });
                        }}
                        loading={
                          timesheetUpdateMutation.isLoading &&
                          timesheetUpdateMutation.variables?.id === row.id
                        }
                        size="xs"
                        icon={RefreshCw}
                        variant="outline"
                      >
                        Reset status
                      </Button>
                    )}
                </div>
              ),
            },
          ]}
          empty={{
            icon: Timer,
            title: "No timesheets",
            description: "Add timesheets to this task",
          }}
        />
      )}

      {isMobile() && (
        <>
          {timesheets?.list.map((timesheet) => (
            <div
              onClick={() => {
                if (allowTimesheetEditing(timesheet)) {
                  setEditTimesheetDrawerOpen(timesheet.id);
                }
              }}
              key={timesheet.id}
              className="p-4 border-b border-gray-200"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <div className="flex justify-center items-center mr-2 w-8 h-8 bg-gray-200 rounded-full">
                    {timesheet.users.firstName.charAt(0)}
                  </div>
                  <span className="font-medium">
                    {timesheet.users.firstName} {timesheet.users.lastName}
                  </span>
                </div>
                <span className="text-sm text-gray-500">
                  {dayjs(timesheet.startTime).format("MMM DD, YYYY")}
                </span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-600">
                  {timesheet.projectTasks?.projects.title}
                </span>
                <span className="text-sm font-medium">
                  {Math.floor(timesheet.minutesWorked / 60)}h{" "}
                  {timesheet.minutesWorked % 60}m
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-xs text-gray-500">
                  {timesheet.projectTasks?.title}
                </span>
                <span
                  className={cn(
                    "px-2 py-1 text-xs rounded-full",
                    timeSheetStatuses[timesheet.approvalStatus].color
                  )}
                >
                  {timeSheetStatuses[timesheet.approvalStatus].label}
                </span>
              </div>
              {timesheet.approvalStatus === "OPEN" &&
                allowTimeSheetApproval() && (
                  <div className="flex gap-2 items-center mt-2">
                    <Button
                      onClick={(e) => {
                        timesheetUpdateMutation.mutate({
                          id: timesheet.id,
                          timesheet: { approvalStatus: "APPROVED" },
                        });
                        e.stopPropagation();
                      }}
                      size="sm"
                      icon={Check}
                      variant="primaryOutline"
                      loading={timesheetUpdateMutation.isLoading}
                    >
                      Approve
                    </Button>
                    <Button
                      onClick={(e) => {
                        timesheetUpdateMutation.mutate({
                          id: timesheet.id,
                          timesheet: { approvalStatus: "REJECTED" },
                        });
                        e.stopPropagation();
                      }}
                      size="sm"
                      icon={X}
                      variant="destructiveOutline"
                      loading={timesheetUpdateMutation.isLoading}
                    >
                      Reject
                    </Button>
                  </div>
                )}
            </div>
          ))}
          <Pagination
            pageNo={filters.pageNo || 1}
            count={timesheets?.meta.count}
            setPageNo={(pageNo) => pagination?.setPageNo(pageNo)}
            className="justify-start"
          />
        </>
      )}
    </div>
  );
};

const TimesheetsList = () => {
  const [filters, setFilters] = useParamsState<Filters>({
    search: "",
    users: [],
    approvalStatus: [],
    dates: undefined,
    pageNo: 1,
    pageSize: 30,
    clients: [],
    projectIds: [],
    billable: undefined,
  });

  const [addTimesheetDrawerOpen, setAddTimesheetDrawerOpen] = useState(false);
  const [clientSearch, setClientSearch] = useState("");
  const [projectSearch, setProjectSearch] = useState("");

  const { data: clients } = trpc.clients.list.useQuery({
    pageNo: 1,
    pageSize: 10,
    search: clientSearch,
  });
  const { data: projects } = trpc.projects.list.useQuery({
    pageSize: 15,
    search: projectSearch,
  });
  const { data: users } = trpc.users.list.useQuery();

  return (
    <Page title="Timesheets" fullWidth className="!p-0">
      <AddTimesheetDrawer
        open={addTimesheetDrawerOpen}
        onClose={() => setAddTimesheetDrawerOpen(false)}
      />

      <div className="">
        <div className="flex justify-between p-3 w-full border-b border-gray-200">
          <SearchInput
            value={filters.search || ""}
            onChange={(v) =>
              setFilters({
                search: v,
                pageNo: 1,
              })
            }
          />
          <ResponsiveActionButton
            onClick={() => setAddTimesheetDrawerOpen(true)}
            text="Timesheet"
          />
        </div>
        <FilterBar
          className="p-3"
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          defaultFilters={["dates", "users"]}
          filters={[
            {
              key: "dates",
              type: "date-range",
              label: "Dates",
              value: filters.dates,
              onChange: (value) =>
                setFilters({
                  dates: value as [Date, Date],
                }),
            },
            {
              key: "createdAt",
              type: "date-range",
              label: "Created At",
              value: filters.createdAt,
              onChange: (value) =>
                setFilters({
                  createdAt: value as [Date, Date],
                }),
              presetType: "past",
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Clients",
              value: filters.clients,
              onSearch: (value) => setClientSearch(value),
              showSearch: true,
              onChange: (value) =>
                setFilters({
                  clients: value as number[],
                }),
              options:
                clients?.clients?.map((client) => ({
                  label: client.name,
                  value: client.id,
                })) || [],
            },
            {
              key: "projectIds",
              type: "checkbox",
              label: "Projects",
              value: filters.projectIds,
              onSearch: (value) => setProjectSearch(value),
              onChange: (value) =>
                setFilters({
                  projectIds: value as number[],
                }),
              options:
                projects?.projects?.map((project) => ({
                  label: project.title,
                  value: project.id,
                })) || [],
            },
            {
              key: "users",
              type: "checkbox",
              label: "Users",
              value: filters.users,
              onChange: (value) =>
                setFilters({
                  users: value as number[],
                }),
              options:
                users?.map((user) => ({
                  label: user.firstName,
                  value: user.id,
                })) || [],
            },
            {
              key: "billable",
              type: "checkbox",
              multiple: false,
              label: "Billable",
              value:
                filters.billable !== undefined
                  ? [filters.billable as unknown as string]
                  : [],
              options: [
                // @ts-ignore
                { label: "Billable", value: true },
                // @ts-ignore
                { label: "Non-billable", value: false },
              ],
              onChange: (value) =>
                value.length
                  ? setFilters({ billable: value[0] as unknown as boolean })
                  : setFilters({ billable: undefined }),
            },
            {
              key: "approvalStatus",
              type: "checkbox",
              label: "Approval Status",
              value: filters.approvalStatus,
              onChange: (value) =>
                setFilters({
                  approvalStatus: value as z.infer<
                    typeof enums.timeSheetStatusTypes
                  >[],
                }),

              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              options: Object.entries(timeSheetStatuses).map(([_, value]) => ({
                label: value.label,
                value: value.value,
              })),
            },
          ]}
        />
        <TimesheetsTable
          filters={filters}
          pagination={{
            setPageNo: (pageNo) => setFilters({ pageNo }),
            setPageSize: (pageSize) => setFilters({ pageSize }),
          }}
          name="timesheetsListMain"
          classes={{
            dataGrid: "h-[calc(100vh-185px-var(--header-height))]",
            stats: "border-none",
          }}
          showStats
        />
      </div>
    </Page>
  );
};

export default TimesheetsList;
