import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@heffl/ui/components/primitives/collapsible";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { useSidebar } from "@heffl/ui/components/primitives/sidebar";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";
import { cn } from "@heffl/ui/lib/utils";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { Link, LinkProps, useLocation, useNavigate } from "react-router-dom";
import { NavCollapsible, NavItem, NavLink, type NavGroup } from "./types";

export function NavGroup({ title, items }: NavGroup) {
  const location = useLocation();
  const href = location.pathname + location.search;
  const { state } = useSidebar();

  return (
    <div className="flex flex-col gap-2 py-2">
      {!!title && state !== "collapsed" && (
        <div className="px-3 text-xs font-medium text-gray-500">{title}</div>
      )}
      <div className="flex flex-col gap-1">
        {items.map((item) => {
          const key = `${item.title}-${item.url}`;

          if (!item.items)
            return <MenuLink key={key} item={item} href={href} />;

          return <MenuCollapsible key={key} item={item} href={href} />;
        })}
      </div>
    </div>
  );
}

type MenuItemRenderProps = {
  icon?: string;
  title: string;
  url?: LinkProps["to"];
  badge?: string;
  isActive?: boolean;
  isCollapsible?: boolean;
  isOpen?: boolean;
  onPlusUrl?: LinkProps["to"];
};

const MenuItemRender = ({
  item,
  isActive,
  inPopover = false,
}: {
  item: MenuItemRenderProps;
  isActive?: boolean;
  inPopover?: boolean;
}) => {
  const { state } = useSidebar();
  const isCollapsed = state === "collapsed";

  return (
    <div
      className={cn(
        "flex items-center justify-between px-2 py-1.5 hover:bg-gray-100 rounded-md w-full",
        isActive ? "bg-gray-100" : ""
      )}
    >
      <div className="flex gap-2 items-center">
        {item.icon && (
          <Icon
            icon={item.icon}
            className={cn(
              "!w-4 !h-4",
              isActive ? "!text-primary-800" : "!text-gray-500"
            )}
          />
        )}
        {(!isCollapsed || inPopover) && (
          <span
            className={cn(
              "!text-[13px] flex items-center gap-2 !text-gray-700",
              isActive ? "!text-primary-800" : ""
            )}
          >
            {item.title}
            {!isCollapsed && item.isCollapsible && (
              <Icon
                icon="mynaui:chevron-right-solid"
                className={cn(
                  "ml-auto h-3.5 w-3.5 transition-transform duration-200 !text-gray-600",
                  item.isOpen && "rotate-90"
                )}
              />
            )}
          </span>
        )}
      </div>
      {item.onPlusUrl && (
        <Link to={item.onPlusUrl}>
          <Icon
            icon="tabler:plus"
            className="!w-4 !h-4 hover:bg-white rounded"
          />
        </Link>
      )}
      {!isCollapsed && item.badge && (
        <div className="px-1 py-0.5 text-xs text-gray-500 bg-gray-100 rounded">
          {item.badge}
        </div>
      )}
    </div>
  );
};

const MenuLink = ({ item, href }: { item: NavLink; href: string }) => {
  const isActive = checkIsActive(href, item);
  const { state } = useSidebar();
  const isCollapsed = state === "collapsed";

  return isCollapsed ? (
    <Tooltip content={item.title} side="right">
      <Link to={item.url}>
        <MenuItemRender item={item} isActive={isActive} />
      </Link>
    </Tooltip>
  ) : (
    <Link to={item.url}>
      <MenuItemRender item={item} isActive={isActive} />
    </Link>
  );
};

const MenuCollapsible = ({
  item,
  href,
}: {
  item: NavCollapsible;
  href: string;
}) => {
  const navigate = useNavigate();

  const isActive = checkIsActive(href, item, true);
  const { state } = useSidebar();
  const isCollapsed = state === "collapsed";
  const [isOpen, setIsOpen] = useState(isActive);

  const [open, setOpen] = useState(false);

  if (isCollapsed) {
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger>
          <Tooltip content={item.title}>
            <div>
              <MenuItemRender item={item} isActive={isActive} />
            </div>
          </Tooltip>
        </PopoverTrigger>
        <PopoverContent side="right" className="p-1 w-48">
          <div className="flex flex-col gap-1">
            {item.items.map((subItem) => (
              <Link
                key={subItem.title}
                onClick={() => setOpen(false)}
                to={subItem.url}
              >
                <MenuItemRender
                  item={subItem}
                  isActive={checkIsActive(href, subItem)}
                  inPopover
                />
              </Link>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Collapsible defaultOpen={isActive} onOpenChange={setIsOpen}>
      <CollapsibleTrigger
        className="w-full"
        onClick={() => {
          navigate(item.items[0].url || "");
        }}
      >
        <MenuItemRender
          item={{ ...item, isCollapsible: true, isOpen }}
          isActive={isActive}
        />
      </CollapsibleTrigger>

      <CollapsibleContent>
        <div className="flex flex-col gap-1 pl-2 mt-1 ml-3 border-l border-gray-200">
          {item.items.map((subItem) => {
            const subItemActive = checkIsActive(href, subItem);
            return (
              <Link key={subItem.title} to={subItem.url}>
                <MenuItemRender item={subItem} isActive={subItemActive} />
              </Link>
            );
          })}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

function checkIsActive(href: string, item: NavItem, mainNav = false) {
  const baseUrl = item.activeUrl || item.url;
  const itemUrl = typeof baseUrl === "string" ? baseUrl : "";
  const hrefParts = href.split("/");
  const itemUrlParts = itemUrl.split("/");
  const hrefWithoutQuery = href.split("?")[0];

  if (item.items) {
    return item.items.some((subItem) => {
      const subItemUrl = subItem.url;
      return (
        href === subItemUrl ||
        hrefWithoutQuery === subItemUrl ||
        (typeof subItemUrl === "string" && href.startsWith(subItemUrl))
      );
    });
  }

  return (
    href === itemUrl ||
    hrefWithoutQuery === itemUrl ||
    (mainNav && hrefParts[1] !== "" && hrefParts[1] === itemUrlParts[1]) ||
    (itemUrlParts.length > 1 && href.startsWith(itemUrl))
  );
}
