import { cn } from "@heffl/ui/lib/utils";
import {
  EditorBubble,
  EditorCommand,
  EditorCommandEmpty,
  EditorCommandItem,
  EditorCommandList,
  EditorContent,
  EditorRoot,
} from "novel";

import { handleCommandNavigation, Placeholder } from "novel/extensions";
import { useState } from "react";
import { slashCommand, suggestionItems } from "./commands";
import { defaultExtensions } from "./extensions";
import "./prose-mirror-styles.css";
import { ColorSelector } from "./selectors/color-selector";
import { LinkSelector } from "./selectors/link-selector";
import { NodeSelector } from "./selectors/node-selector";
import { TextButtons } from "./selectors/text-buttons";

const extensions = [...defaultExtensions, slashCommand];

interface Props {
  onChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
}

const BlockEditor = ({
  onChange,
  value,
  disabled = false,
  placeholder,
  className,
}: Props) => {
  const [openNode, setOpenNode] = useState(false);
  const [openColor, setOpenColor] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [openAI] = useState(true);

  const placeholderExtension = Placeholder.configure({
    placeholder: placeholder || "Write something... or press / for commands",
  });

  if (value === undefined) {
    return null;
  }

  return (
    <EditorRoot>
      <EditorContent
        className={cn("!p-0 !w-fit", className)}
        // @ts-ignore
        extensions={[placeholderExtension, ...extensions]}
        // @ts-ignore
        editable={!disabled}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        initialContent={value as any}
        onUpdate={({ editor }) => {
          const html = editor.getHTML();
          if (onChange) {
            onChange(html);
          }
        }}
        editorProps={{
          handleDOMEvents: {
            keydown: (_view, event) => handleCommandNavigation(event),
          },
          attributes: {
            class: `prose prose-xs dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full border-none pl-2 prose-p:my-1 pt-1 pb-1 prose-h1:my-0 prose-h2:my-0 prose-h3:my-0 prose-h4:my-0 prose-h5:my-0 prose-h6:my-0`,
          },
        }}
      >
        <EditorCommand className="z-50 h-auto max-h-[330px]  w-72 overflow-y-auto rounded-md border border-muted bg-background px-1 py-2 shadow-md transition-all">
          <EditorCommandEmpty className="px-2 text-muted-foreground">
            No results
          </EditorCommandEmpty>
          <EditorCommandList>
            {suggestionItems.map((item) => (
              <EditorCommandItem
                value={item.title}
                onCommand={(val) => item.command?.(val)}
                className={`flex items-center px-2 py-1 space-x-2 w-full text-sm text-left rounded-md hover:bg-accent aria-selected:bg-accent`}
                key={item.title}
              >
                <div className="flex justify-center items-center w-10 h-10 rounded-md border border-muted bg-background">
                  {item.icon}
                </div>
                <div>
                  <p className="font-medium">{item.title}</p>
                  <p className="text-xs text-muted-foreground">
                    {item.description}
                  </p>
                </div>
              </EditorCommandItem>
            ))}
          </EditorCommandList>
        </EditorCommand>
        <EditorBubble
          tippyOptions={{
            placement: openAI ? "bottom-start" : "top",
          }}
          className="flex w-fit max-w-[90vw] overflow-hidden rounded border border-muted bg-background shadow-xl"
        >
          <NodeSelector open={openNode} onOpenChange={setOpenNode} />
          <LinkSelector open={openLink} onOpenChange={setOpenLink} />
          <TextButtons />
          <ColorSelector open={openColor} onOpenChange={setOpenColor} />
        </EditorBubble>
      </EditorContent>
    </EditorRoot>
  );
};
export default BlockEditor;
