"use client";

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@heffl/ui/components/primitives/drawer";
import { Check, ChevronDown, LucideIcon, PlusCircle } from "lucide-react";
import * as React from "react";
import { cn, isMobile } from "../../lib/utils";
import { Badge } from "./badge";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { ScrollArea, ScrollBar } from "./scroll-area";

interface Option<T> {
  label: string;
  value: T;
  color?: string;
  icon?: LucideIcon;
  iconClassName?: string;
}

interface MultiSelectorProps<T> {
  options?: Option<T>[];
  icon?: LucideIcon;
  iconify?: string;
  value?: T[];
  onChange?: (value: T[]) => void;
  showSelected?: boolean;
  placeholder?: string;
  className?: string;
  popoverSide?: "bottom" | "left" | "right" | "top";
  popoverClassName?: string;
  disabled?: boolean;
  onSearch?: (value: string) => void;
  createButton?: {
    onClick: () => void;
    label: string;
  };
  previewType?: "linear";
  optionType?: "tag";
}

export const MultiSelect = <T extends React.Key>({
  options = [],
  value = [],
  onChange,
  placeholder = "Select options...",
  className,
  disabled = false,
  icon: SelectIcon,
  iconify,
  createButton,
  onSearch,
  previewType,
  popoverSide = "bottom",
  popoverClassName,
  showSelected = false,
  optionType,
}: MultiSelectorProps<T>) => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const handleSelect = (optionValue: T) => {
    if (disabled) return;
    setSearch("");

    const newValue = value?.includes(optionValue)
      ? value.filter((v) => v !== optionValue)
      : [...value, optionValue];
    onChange?.(newValue);
  };

  const selectLinear = (
    <Button
      variant="ghost"
      className={cn("!text-xs -ml-2 w-full bg-white", className)}
      icon={SelectIcon}
      iconify={iconify}
      iconClassName={cn("mr-2")}
      size="sm"
    >
      {value?.length > 0 ? (
        optionType === "tag" ? (
          <div className="flex gap-1 items-center">
            {value.map((v) => {
              const option = options?.find((opt) => opt.value === v);
              return (
                <Badge
                  key={String(v)}
                  small
                  background={option?.color}
                  className="!rounded-md"
                >
                  {option?.label}
                </Badge>
              );
            })}
          </div>
        ) : (
          value
            .map((v) => {
              const option = options?.find((opt) => opt.value === v);
              return option?.label;
            })
            .join(", ")
        )
      ) : (
        <span className="text-gray-500 !text-xs !font-medium">
          {placeholder}
        </span>
      )}
    </Button>
  );

  const selectPreview = (
    <div
      className={cn(
        "flex gap-1 justify-between items-center px-2 py-3 sm:py-2 sm:min-h-9 text-sm rounded-md border cursor-pointer group border-input ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 bg-white",
        className,
        disabled && "cursor-not-allowed opacity-50"
      )}
    >
      <div className="flex flex-wrap gap-1.5 items-center">
        {SelectIcon && <SelectIcon className="mr-1 w-5 h-5" />}
        {value?.length > 0 ? (
          value.map((v) => {
            const option = options?.find((opt) => opt.value === v);
            return (
              <Badge
                small={false}
                key={String(v)}
                onRemove={() => {
                  if (!disabled) handleSelect(v);
                }}
                variant={"unique"}
                className="motion-preset-blur-right motion-duration-500"
              >
                {option?.label}
              </Badge>
            );
          })
        ) : (
          <span className="text-muted-foreground">{placeholder}</span>
        )}
      </div>
      <ChevronDown className="w-4 h-4 opacity-50 shrink-0" />
    </div>
  );

  const selectOptions = (
    <Command>
      <CommandList>
        <CommandInput
          disabled={disabled}
          placeholder="Search options..."
          value={search}
          onValueChange={(value) => {
            if (!disabled) {
              setSearch(value);
              onSearch?.(value);
            }
          }}
        />
        <CommandEmpty>No options found.</CommandEmpty>
        <CommandGroup>
          <ScrollArea
            className={cn("max-h-[300px] sm:max-h-[190px] !overflow-auto")}
          >
            {options
              ?.filter((option) =>
                !showSelected ? !value?.includes(option.value) : true
              )
              .map((option) => (
                <CommandItem
                  key={String(option.value)}
                  onSelect={() => handleSelect(option.value)}
                  disabled={disabled}
                >
                  <div
                    className={cn("flex justify-between items-center w-full")}
                  >
                    <div className="flex items-center">
                      {option.icon && (
                        <option.icon
                          className="mr-2 w-4 h-4"
                          style={{ color: option.color }}
                        />
                      )}
                      {option.label}
                    </div>
                    <Check
                      className={cn(
                        "h-4 font-medium",
                        value?.includes(option.value)
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                  </div>
                </CommandItem>
              ))}
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </CommandGroup>
      </CommandList>

      {createButton?.onClick && !disabled && (
        <div
          className="p-2.5 border-t border-gray-200 hover:text-white hover:bg-primary-300 flex items-center gap-1 cursor-pointer"
          key="create"
          onClick={() => {
            createButton?.onClick();
            setOpen(false);
          }}
        >
          <PlusCircle className="h-5 text-primary" />
          {createButton.label}
        </div>
      )}
    </Command>
  );

  if (!isMobile()) {
    return (
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger
          className={cn(previewType === "linear" ? "!w-fit" : "w-full")}
          asChild
          disabled={disabled}
          onClick={(e) => e.stopPropagation()}
        >
          {previewType === "linear" ? selectLinear : selectPreview}
        </PopoverTrigger>
        <PopoverContent
          className={cn(
            "p-0 w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height]",
            popoverClassName
          )}
          sideOffset={4}
          align="start"
          side={popoverSide || "bottom"}
          asChild
        >
          {selectOptions}
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Drawer
      open={open}
      onOpenChange={(newOpen) => !disabled && setOpen(newOpen)}
    >
      <DrawerTrigger
        className={cn(previewType === "linear" ? "!w-fit" : "w-full")}
        asChild
        disabled={disabled}
        onClick={(e) => e.stopPropagation()}
      >
        {previewType === "linear" ? selectLinear : selectPreview}
      </DrawerTrigger>
      <DrawerContent>
        {placeholder && (
          <DrawerHeader>
            <DrawerTitle>{placeholder}</DrawerTitle>
          </DrawerHeader>
        )}
        <div className="mt-4 border-t">{selectOptions}</div>
      </DrawerContent>
    </Drawer>
  );
};
