import dayjs from "dayjs";

type DateTypesUnits = "minutes" | "hours" | "days" | "weeks" | "months";
type DateDirection = "before" | "after";

export type DateTypeString =
  | "AT_TASK_DUE_TIME"
  | "CUSTOM_DATE"
  | `${number}_${DateTypesUnits}_${DateDirection}`;

export type DateStringOption = {
  label: string;
  value: DateTypeString;
  iconify?: string;
};

// ogDate is the date that the reminder is set for

type Props = {
  ogDate: Date;
  dateType: DateTypeString;
  date: Date;
};

const formatReminderDate = ({ ogDate, dateType, date }: Props) => {
  if (dateType === "CUSTOM_DATE")
    return {
      date,
      dateType,
    };

  if (dateType === "AT_TASK_DUE_TIME") {
    return {
      date: ogDate,
      dateType,
    };
  }
  const [count, dateTypeUnit, dateDirection] = dateType.split("_") as [
    string,
    DateTypesUnits,
    DateDirection
  ];
  const countFormatted =
    dateDirection === "before" ? -Number(count) : Number(count);
  const formattedDate = dayjs(ogDate).add(countFormatted, dateTypeUnit);
  return {
    date: formattedDate.toDate(),
    dateType: dateType,
  };
};

// used for converting date back to date string
export const deformatReminderDate = ({
  ogDate,
  date,
  availableUnits,
}: {
  ogDate: Date;
  date: Date;
  availableUnits: DateStringOption[];
}) => {
  const dateDirection = dayjs(date).isBefore(ogDate) ? "before" : "after";
  let dateTypeUnit: DateTypesUnits = "minutes";
  let count: number = dayjs(date).diff(ogDate, "minutes");

  if (dayjs(date).diff(ogDate, "minutes") === 0) {
    return {
      date,
      dateType: "AT_TASK_DUE_TIME",
    };
  } else if (dayjs(date).diff(ogDate, "months") !== 0) {
    dateTypeUnit = "months";
    count = dayjs(date).diff(ogDate, "months");
  } else if (dayjs(date).diff(ogDate, "weeks") !== 0) {
    dateTypeUnit = "weeks";
    count = dayjs(date).diff(ogDate, "weeks");
  } else if (dayjs(date).diff(ogDate, "days") !== 0) {
    dateTypeUnit = "days";
    count = dayjs(date).diff(ogDate, "days");
  } else if (dayjs(date).diff(ogDate, "hours") !== 0) {
    dateTypeUnit = "hours";
    count = dayjs(date).diff(ogDate, "hours");
  } else {
    dateTypeUnit = "minutes";
    count = dayjs(date).diff(ogDate, "minutes");
  }
  const dateTypeString = `${Math.abs(count)}_${dateTypeUnit}_${dateDirection}`;
  const isStringAvailable = availableUnits.find(
    (unit) => unit.value === dateTypeString
  );
  if (!isStringAvailable) {
    return {
      date,
      dateType: "CUSTOM_DATE",
    };
  }

  return {
    date,
    dateType: dateTypeString as DateTypeString,
  };
};

export default formatReminderDate;
