import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Popover, PopoverContent, PopoverTrigger } from "./primitives/popover";
import { useState } from "react";

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};

const EmojiPicker = ({ value, onChange }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="flex justify-center items-center p-1 w-8 h-8 text-xl bg-gray-100 rounded">
        {value || <p className="opacity-50 grayscale">😐</p>}
      </PopoverTrigger>
      <PopoverContent>
        <Picker
          data={data}
          onEmojiSelect={(emoji: { native: string }) => {
            setOpen(false);
            onChange?.(emoji.native);
          }}
        />
      </PopoverContent>
    </Popover>
  );
};

export default EmojiPicker;
