import {
  createTRPCReact,
  type inferReactQueryProcedureOptions,
} from "@trpc/react-query";
import type { AppRouter } from "@heffl/server/src/routes";
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server";

export const trpc = createTRPCReact<AppRouter>({
  overrides: {
    useMutation: {
      async onSuccess(opts) {
        const response = await opts.originalFn();
        // for skipping the invalidation of the queries
        const invalidate =
          // @ts-ignore
          response && "invalidate" in response ? response.invalidate : true;
        // Invalidate all queries in the react-query cache:

        !invalidate && console.log("invalidate skipped");
        if (invalidate) {
          await opts.queryClient.invalidateQueries();
        }
      },
    },
  },
});
export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterProcedureOptions = inferReactQueryProcedureOptions<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;
