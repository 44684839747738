import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { Card } from "@heffl/ui/components/primitives/card";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Label } from "@heffl/ui/components/primitives/label";
import Select from "@heffl/ui/components/primitives/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { Banknote, Building, FileText } from "lucide-react";
import { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { AddPropertyModal } from "../properties/property-modal";
import { AddPropertyUnitModal } from "../properties/unit-modals";
import { ClientInput } from "@/components/FormComponents";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import dayjs from "dayjs";
import LineItemSelectorNew from "@/components/line-item-selector-new";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import heffl from "@/helpers/heffl";

const LeaseForm = ({
  form,
  edit = false,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.propertyManagement.pmLease>>;
  edit?: boolean;
}) => {
  const startDate = form.watch("startDate");

  const [propertyId, setPropertyId] = useState<number | null>(null);
  const [addProperty, setAddProperty] = useState(false);
  const [addUnit, setAddUnit] = useState(false);

  const { data: properties } = trpc.propertyManagement.properties.list.useQuery(
    {
      search: "",
    }
  );

  const selectedProperty = properties?.find(
    (property) => property.id === propertyId
  );

  return (
    <>
      <AddPropertyModal
        open={addProperty}
        onClose={() => setAddProperty(false)}
      />
      {propertyId && (
        <AddPropertyUnitModal
          open={addUnit}
          onClose={() => setAddUnit(false)}
          propertyId={propertyId}
        />
      )}
      <Card title="Unit Details" icon={<Building className="text-green-500" />}>
        <div className="grid grid-cols-4 gap-3">
          <div className="flex flex-col gap-2">
            <Label>Property</Label>
            <Select
              iconify="tabler:building"
              options={properties?.map((property) => ({
                label: property.name,
                value: property.id,
              }))}
              onChange={(value) => setPropertyId(value)}
              value={propertyId}
              createButton={{
                label: "Add new property",
                onClick: () => setAddProperty(true),
              }}
            />
          </div>

          {selectedProperty && (
            <FormField name="pmPropertyUnitId" label="Unit">
              <Select
                iconify="tabler:key"
                options={selectedProperty.pmPropertyUnits.map((unit) => ({
                  label: unit.name,
                  value: unit.id,
                }))}
                createButton={{
                  label: "Add new unit",
                  onClick: () => setAddUnit(true),
                }}
              />
            </FormField>
          )}
        </div>
      </Card>
      <div className="grid grid-cols-3 gap-3">
        <div className="col-span-1">
          <Card
            title="Lease Details"
            icon={<FileText className="text-orange-500" />}
          >
            <div className="grid grid-cols-2 gap-3">
              <ClientInput
                name="clientId"
                label="Client"
                className="col-span-2"
              />
              <FormField name="startDate" label="Start date">
                <DatePicker className="flex" />
              </FormField>
              <FormField name="endDate" label="End date">
                <DatePicker
                  className="flex"
                  disabled={!startDate}
                  fromDate={startDate}
                  presets={[
                    {
                      label: "1 year",
                      value: dayjs(startDate).add(364, "day").toDate(),
                    },
                    {
                      label: "6 months",
                      value: dayjs(startDate).add(6, "months").toDate(),
                    },
                    {
                      label: "3 months",
                      value: dayjs(startDate).add(3, "months").toDate(),
                    },
                  ]}
                />
              </FormField>
              <FormField
                name="firstRentDate"
                label="First rent date"
                className="col-span-2"
              >
                <DatePicker
                  className="flex"
                  disabled={!startDate}
                  fromDate={startDate}
                />
              </FormField>
            </div>
          </Card>
        </div>
        <div className="col-span-2">
          <Card title="Lease Items" icon={<Banknote />}>
            <FormField name="pmLeaseItems" label="" className="w-full">
              <LineItemSelectorNew type="JOB" isEdit={edit} required={true} />
            </FormField>
          </Card>
        </div>
      </div>
    </>
  );
};

const AddLease = () => {
  const form = useForm<z.infer<typeof Schemas.propertyManagement.pmLease>>({
    resolver: zodResolver(Schemas.propertyManagement.pmLease),
    defaultValues: {
      pmLeaseItems: [],
    },
  });

  const leaseAddMutation = trpc.propertyManagement.leases.add.useMutation({
    onSuccess: () => {
      heffl.toast.success("Lease added successfully");
    },
    onError: (error) => {
      heffl.toast.error(error.message);
    },
  });

  const onSubmit = (
    data: z.infer<typeof Schemas.propertyManagement.pmLease>
  ) => {
    leaseAddMutation.mutate(data);
  };

  console.log(form.formState.errors);

  return (
    <Page
      showBack={false}
      showHome={false}
      showAccount={false}
      suffix={
        <div className="flex gap-2">
          <ResponsivePrimaryButton
            onClick={form.handleSubmit(onSubmit)}
            variant="primary"
            loading={leaseAddMutation.isLoading}
          >
            Add Lease
          </ResponsivePrimaryButton>
        </div>
      }
      title="Add Lease"
      className="h-screen bg-gray-100"
    >
      <Form {...form} onSubmit={onSubmit}>
        <LeaseForm form={form} />
      </Form>
    </Page>
  );
};

export default AddLease;
