import { trpc } from "@/helpers/trpc";
import { RouterOutputs } from "@/helpers/trpc";
import { integrationSchemas } from "@heffl/server/src/helpers/integrations";
import enums from "@heffl/server/src/schemas/enums";
import { z } from "zod";

type serverOutput = RouterOutputs["teams"]["details"];

type TeamDetails = {
  id: number;
  name: string;
  isTaxRegistered: boolean;
  subdomain: string | null;
  integrations: {
    get: <T extends keyof typeof integrationSchemas>(
      provider: T
    ) =>
      | {
          integrated: false;
          data: undefined;
        }
      | {
          integrated: true;
          data: z.infer<(typeof integrationSchemas)[T]>;
        };
  };
  apps: {
    installed: (app: z.infer<typeof enums.appKeys>) => boolean;
  };
  user: {
    id: number;
    permissions: serverOutput["userPermissions"];
    type: z.infer<typeof enums.userTypes>;
  };
  settings: serverOutput["teamSettings"];
};

const useTeam = (): TeamDetails | undefined => {
  const { data } = trpc.teams.details.useQuery();

  if (!data) return undefined;

  return {
    id: data.id,
    name: data.name,
    isTaxRegistered: data.isTaxRegistered,
    subdomain: data.subdomain,
    integrations: {
      get: <T extends keyof typeof integrationSchemas>(provider: T) => {
        const integration = data?.integrations.find(
          (i) => i.provider === provider
        );

        if (
          !integration ||
          !integration.connected ||
          (integration.type === "USER" && !integration.userId)
        )
          return {
            integrated: false as const,
            data: undefined,
          };

        return {
          integrated: true as const,
          data: integration.data as z.infer<(typeof integrationSchemas)[T]>,
        };
      },
    },
    apps: {
      installed: (app) =>
        data?.installedApps?.some(
          (installedApp) => installedApp.appName === app
        ),
    },
    user: {
      id: data.user.id,
      permissions: data.userPermissions,
      type: data.user.type,
    },
    settings: data.teamSettings,
  };
};

export default useTeam;
