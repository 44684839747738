import { RouterInputs, RouterOutputs, trpc } from "@/helpers/trpc";
import { DashboardChartItem } from "../charts-item-helpers";
import heffl from "@/helpers/heffl";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Link } from "react-router-dom";
import { objectToParamsJSON } from "@heffl/ui/lib/utils";

const ProjectCount: React.FC<{
  filterValues: RouterInputs["projects"]["reports"]["projectCount"];
}> = ({ filterValues }) => {
  const { data: projectCount } = trpc.projects.reports.projectCount.useQuery({
    ...filterValues,
  });

  if (projectCount === undefined) return <FullScreenSpinner />;

  return (
    <div className="text-8xl text-center">
      <Link
        to={`/projects/table?${objectToParamsJSON({
          ...filterValues,
        })}`}
      >
        {projectCount?.total}
      </Link>
    </div>
  );
};

export const projectCountChart: DashboardChartItem = {
  name: "project-count",
  title: "Project Count",
  description: "Count of projects",
  entity: "PROJECT",
  defaultWidth: 25,
  mainChartType: "number",
  components: {
    stat: ProjectCount,
    chart: null,
    table: null,
  },
  filters: [
    {
      key: "createdAt",
      type: "date-range",
      label: "Created at",
    },
    {
      key: "startDate",
      type: "date-range",
      label: "Start date",
    },
    {
      key: "endDate",
      type: "date-range",
      label: "End date",
    },
    {
      key: "pipelineId",
      type: "checkbox",
      multiple: false,
      label: "Pipeline",
      optionsMeta: {
        hasAsyncSearch: false,
        query: "projects.pipelines.list",
        queryInput: {},
        parseResult: (data: RouterOutputs["projects"]["pipelines"]["list"]) =>
          data.map((pipeline) => ({
            label: pipeline.name,
            value: pipeline.id,
          })),
      },
    },
    {
      key: "clients",
      type: "checkbox",
      label: "Clients",
      optionsMeta: {
        hasAsyncSearch: true,
        query: "clients.list",
        queryInput: {
          pageSize: 20,
        } as RouterInputs["clients"]["list"],
        parseResult: (data: RouterOutputs["clients"]["list"]) =>
          data.clients.map((client) => ({
            label: client.name,
            value: client.id,
          })),
      },
    },
    {
      key: "assignees",
      type: "checkbox",
      label: "Assignees",
      optionsMeta: {
        hasAsyncSearch: false,
        query: "users.list",
        queryInput: {} as RouterInputs["users"]["list"],
        parseResult: (data: RouterOutputs["users"]["list"]) =>
          data?.map((user) => ({
            label: heffl.format.name(user),
            value: user.id,
          })),
      },
    },
    {
      key: "statuses",
      type: "checkbox",
      label: "Status",
      options: ["OPEN", "CLOSED"].map((status) => ({
        label: status,
        value: status,
      })),
    },
    {
      key: "invoiced",
      label: "Invoiced",
      type: "checkbox",
      multiple: false,
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
      ],
    },
    {
      key: "archived",
      label: "Archived",
      type: "checkbox",
      multiple: false,
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
      ],
    },
    {
      key: "tags",
      type: "checkbox",
      label: "Tags",
      optionsMeta: {
        hasAsyncSearch: false,
        query: "tags.list",
        queryInput: {},
        parseResult: (data: RouterOutputs["tags"]["list"]) =>
          data.map((tag) => ({
            label: tag.name,
            value: tag.id,
          })),
      },
    },
  ],
};
