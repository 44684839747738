import { THistory } from "@heffl/server/src/routes/crm/deals";
import { Button } from "@heffl/ui/components/primitives/button";
import StripeTabs, {
  TabsProps,
} from "@heffl/ui/components/primitives/stripe-tabs";
import ResponsiveActions, {
  ResponsiveAction,
} from "@heffl/ui/components/responsive-actions";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import { ChevronLeft, LucideIcon, Text } from "lucide-react";
import { useNavigate } from "react-router-dom";
import InfoItemsHZ, { InfoItemHZProps } from "./InfoItemHZ";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@heffl/ui/components/primitives/accordion";
import { useQueryState } from "nuqs";
import { StagesInput } from "@heffl/ui/components/stage-input";

type DetailsPageProps<T extends string, S = string | number> = {
  icon?: LucideIcon;
  title: string;
  widgets?: (React.ReactNode | null)[];
  stage?: {
    stages: {
      id: S;
      text: string;
    }[];
    value: S;
    onChange: (value: S) => void;
    loading: boolean;
  };
  description?: string;
  tabs?: TabsProps<T>;
  attributes?: {
    section: string;
    items?: InfoItemHZProps["items"];
    children?: React.ReactNode;
    icon?: LucideIcon;
    onClick?: () => void;
  }[];
  activities?: THistory[];
  actions?: ResponsiveAction[];
};

const DetailsPage = <T extends string, S = string | number>(
  props: DetailsPageProps<T, S>
) => {
  const navigate = useNavigate();

  const detailsTab = {
    key: "details",
    label: "Details",
    icon: Text,
    children: (
      <Accordion
        type="multiple"
        defaultValue={props.attributes?.map((attr) => attr.section)}
        className="divide-y divide-gray-200"
      >
        {props.attributes?.map(
          ({ section, items, icon: Icon, onClick, children }) => (
            <AccordionItem key={section} value={section}>
              <AccordionTrigger
                iconClassName="text-gray-500 w-4 h-4 mr-2 ml-2"
                className="text-[#232529] flex justify-between items-center w-full "
              >
                <div className="flex justify-between items-center pr-2 w-full font-semibold">
                  <span>{section}</span>
                  {Icon && (
                    <Button
                      variant="ghost"
                      size="xs"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick?.();
                      }}
                      icon={Icon}
                      iconClassName="w-4 h-4 text-gray-500"
                    />
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent className="p-2 pb-2">
                {items && <InfoItemsHZ items={items} className="pl-1" />}
                {children}
              </AccordionContent>
            </AccordionItem>
          )
        )}
      </Accordion>
    ),
    actions: [],
    title: "",
  };

  const getTabs = () => {
    if (!props.tabs?.items) return [];

    if (isMobile()) {
      return [detailsTab, ...props.tabs.items].map((item) => ({
        ...item,
      }));
    }
    return props.tabs.items.map((item) => ({
      ...item,
    }));
  };

  const [selectedTab, setSelectedTab] = useQueryState("tab", {
    defaultValue: getTabs()[0].key as T,
  });

  const Icon = props.icon;

  return (
    <div className="flex overflow-hidden w-full h-screen bg-gray-50">
      <div className="flex flex-col w-full bg-white">
        <div className="flex gap-2 items-center p-1.5 w-full border-b border-gray-200">
          <Button
            className=" !w-7 !h-7 rounded-full"
            size="icon"
            variant="outline"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeft className="h-4" />
          </Button>
          {!!Icon && (
            <div className="grid place-items-center p-2 bg-gray-100 rounded-full border border-gray-300 shadow-md">
              <Icon className="text-gray-500" size={18} />
            </div>
          )}
          <span className="flex justify-between items-center pr-4 w-full">
            <div>
              <div className="flex gap-1 items-center">
                <p className="text-sm font-semibold text-gray-800 line-clamp-1">
                  {props.title}
                </p>
              </div>
              {!!props.description?.length && (
                <p className="font-normal text-gray-500 text-[11px] -mt-1 line-clamp-1">
                  {props.description}
                </p>
              )}
            </div>
            <ResponsiveActions actions={props.actions || []} />
          </span>
        </div>
        {!!props.widgets?.length && (
          <div className="flex  sm:items-center items-start justify-between sm:py-5 sm:px-5 p-2 gap-2 sm:gap-0 sm:h-8 bg-[#FBFBFB] border-b border-gray-200 sm:flex-row flex-col">
            <div className="flex gap-2 items-center">
              {props.widgets.filter(Boolean).map((widget) => widget)}
            </div>
            {props.stage && (
              <StagesInput
                loading={props.stage.loading}
                size="s"
                stages={
                  props.stage.stages as { id: string | number; text: string }[]
                }
                value={props.stage.value as string | number}
                onChange={(value: string | number) =>
                  props.stage?.onChange(value as S)
                }
              />
            )}
          </div>
        )}
        <div className="flex flex-1 w-full h-full min-h-0">
          <div
            className={cn(
              "flex flex-col w-9/12 h-full",
              isMobile() && "w-full"
            )}
          >
            <StripeTabs
              {...props.tabs}
              onChange={(tab) => setSelectedTab(tab as T)}
              className={cn(props.tabs?.className)}
              items={getTabs().map((item) => ({
                ...item,
                children: undefined,
              }))}
              value={selectedTab}
              tabParentClassName={cn(props.tabs?.tabParentClassName, "pl-4")}
            />
            <div
              className={cn(
                "overflow-auto flex-1 p-3",
                props.tabs?.contentClassName,
                props.tabs?.items.find((item) => item.key === selectedTab)
                  ?.contentClassName
              )}
            >
              {!!(
                getTabs().find((item) => item.key === selectedTab)?.title ||
                getTabs().find((item) => item.key === selectedTab)?.actions
                  ?.length
              ) && (
                <div className="flex justify-between w-full">
                  <h3 className="text-lg font-medium">
                    {getTabs().find((item) => item.key === selectedTab)?.title}
                  </h3>
                  <ResponsiveActions
                    actions={
                      getTabs().find((item) => item.key === selectedTab)
                        ?.actions || []
                    }
                  />
                </div>
              )}
              {getTabs().find((item) => item.key === selectedTab)?.children}
            </div>
          </div>
          {!isMobile() && (
            <div className="flex flex-col w-3/12 h-full border-l border-gray-200">
              <StripeTabs
                tabParentClassName="pl-4"
                contentClassName="!overflow-auto h-full min-h-0 p-0 pt-1 pb-12"
                items={[detailsTab]}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsPage;
