import ReportsHome from "@/components/reports-home";
import TaskAssigneePie from "./task-assignee-pie";
import TaskStatusByProject from "./task-status-by-projects";
import TaskStatusByAssignee from "./task-status-by-assignee";
import TaskStatusPie from "./task-status-pie";
import EmployeePerformance from "./employee-performance";
import TaskOverdue from "./task-overdue";

const TasksReport = () => {
  return (
    <ReportsHome
      title="Tasks Report"
      reports={[
        {
          title: "Basic reports",
          links: [
            {
              id: "task-status-pie",
              label: "Status Distribution",
              description:
                "Overview of tasks distribution across different statuses",
              children: <TaskStatusPie />,
            },
            {
              id: "task-assignee-pie",
              label: "Assignee Distribution",
              description:
                "Visualizes how tasks are distributed across team members",
              children: <TaskAssigneePie />,
            },
            {
              id: "task-overdue",
              label: "Overdue Tasks",
              description: "List of overdue tasks",
              children: <TaskOverdue />,
            },
          ],
        },
        {
          title: "Advanced reports",
          links: [
            {
              id: "task-status-by-assignee",
              label: "Task Status by Assignee",
              description:
                "This report shows the status of tasks by assignee. It includes the number of tasks in each status for each assignee.",
              children: <TaskStatusByAssignee />,
            },
            {
              id: "task-status-by-project",
              label: "Task Status by Project",
              description:
                "This report shows the status of tasks by project. It includes the number of tasks in each status for each project.",
              children: <TaskStatusByProject />,
            },
            {
              id: "employee-performance",
              label: "Employee Performance",
              description:
                "Analyze employee task performance metrics including completion rates, delays, and trends.",
              children: <EmployeePerformance />,
            },
          ],
        },
      ]}
      defaultReport="task-status-pie"
    />
  );
};

export default TasksReport;
