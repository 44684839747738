import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import heffl from "@/helpers/heffl";
import { getS3URL } from "@/helpers/s3Helpers";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import TemplateRender from "@heffl/ui/components/template-render";
import {
  getPrintPageMargins,
  PrintWrapper,
} from "@/pages/field-service/schedules/schedules-export";
import { Button } from "@heffl/ui/components/primitives/button";
import RenderHtml from "@heffl/ui/components/render-html";
import { formatAddress } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { convert } from "html-to-text";
import { DollarSign, Printer } from "lucide-react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useImmer } from "use-immer";
import useTeam from "@/lib/hooks/useTeam";

interface StatementOfAccountsProps {
  clientId: number;
}

const StatementOfAccounts: React.FC<StatementOfAccountsProps> = ({
  clientId,
}) => {
  const navigate = useNavigate();
  const team = useTeam();
  const printRef = useRef<HTMLDivElement>(null);

  const [filters, setFilters] = useImmer({
    dates: {
      startDate: dayjs().startOf("month").toDate(),
      endDate: dayjs().endOf("month").toDate(),
    },
  });

  const { data: teamData } = trpc.teams.currentTeam.useQuery();
  const { data, isLoading } = trpc.clients.statement.useQuery({
    clientId,
    startDate: filters.dates.startDate,
    endDate: filters.dates.endDate,
  });

  const printStatement = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `SOA - ${data?.client?.name} - ${heffl.format.date(
      filters.dates.startDate
    )} to ${heffl.format.date(filters.dates.endDate)}`,
  });

  if (isLoading) {
    return <p className="text-gray-600">Loading...</p>;
  }

  if (!data) {
    return (
      <div className="flex flex-col justify-center items-center p-4 bg-gray-100 rounded-lg">
        <DollarSign className="mb-2 w-12 h-12 text-gray-400" />
        <h3 className="mb-1 text-lg font-semibold text-gray-700">
          No statement data
        </h3>
        <p className="text-sm text-gray-500">
          No statement data available for the selected period.
        </p>
      </div>
    );
  }

  const handleRowClick = (
    item: RouterOutputs["clients"]["statement"]["statement"][number]
  ) => {
    if (item.type === "payment") {
      navigate(`/sales/payments/?editPaymentId=${item.data.id}`);
    } else if (item.type === "invoice") {
      navigate(`/sales/invoices/details/${item.data.id}`);
    }
  };

  const printComponent =
    data.template && data.template.templateProperties ? (
      <TemplateRender
        contentHtml={data.statementHTML}
        // @ts-ignore
        template={data.template}
        previewMode={true}
      />
    ) : (
      <>
        {" "}
        <style>{getPrintPageMargins()}</style>
        <div className="flex justify-between items-start pb-6 border-b border-gray-200">
          <div className="flex gap-4 items-center">
            {teamData?.files?.fields?.team_logo && (
              <img
                src={getS3URL(teamData?.files?.fields?.team_logo[0])}
                alt="logo"
                className="object-contain w-36"
              />
            )}
          </div>
          <div className="text-sm text-right text-gray-600 w-[350px] break-words">
            <p className="text-lg font-bold text-black">{teamData?.name}</p>
            <RenderHtml>{teamData?.address || ""}</RenderHtml>
          </div>
        </div>
        <div className="flex flex-col mt-6 mb-6 space-y-4">
          <div className="flex justify-between items-start">
            <div className="flex flex-col w-4/5">
              <p className="w-full font-medium">To</p>
              <p className="text-base font-medium">{data.client?.name}</p>
              <p className="w-44 text-xs">
                {!!data.client.clientAddresses?.length &&
                  formatAddress(data.client?.clientAddresses[0])}
              </p>
            </div>
            <div className="flex flex-col">
              <h1 className="text-2xl font-bold">Statement of Accounts</h1>
              <p className="text-sm font-medium text-gray-800">
                {heffl.format.date(filters.dates.startDate)} To{" "}
                {heffl.format.date(filters.dates.endDate)}
              </p>
              <table className="mt-4 w-80">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-2 text-left border border-gray-300">
                      Account Summary
                    </th>
                    <th className="p-2 text-right border border-gray-300">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-2 border border-gray-300">
                      Opening Balance
                    </td>
                    <td className="p-2 text-right border border-gray-300">
                      {heffl.format.currency(data.openingBalance || 0, "AED")}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border border-gray-300">
                      Invoiced Amount
                    </td>
                    <td className="p-2 text-right border border-gray-300">
                      {heffl.format.currency(data.invoicedAmount, "AED")}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border border-gray-300">
                      Amount Received
                    </td>
                    <td className="p-2 text-right border border-gray-300">
                      {heffl.format.currency(data.amountReceived, "AED")}
                    </td>
                  </tr>
                  <tr className="font-bold">
                    <td className="p-2 border border-gray-300">Balance Due</td>
                    <td className="p-2 text-right border border-gray-300">
                      {heffl.format.currency(data.balanceDue, "AED")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <RenderHtml>{data.statementHTML}</RenderHtml>
        </div>
      </>
    );

  return (
    <>
      <div className="flex justify-between px-4 mb-12">
        <FilterBar
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              presets: [
                {
                  label: "Today",
                  value: [
                    dayjs().startOf("day").toDate(),
                    dayjs().endOf("day").toDate(),
                  ],
                },
                {
                  label: "This week",
                  value: [
                    dayjs().startOf("week").toDate(),
                    dayjs().endOf("week").toDate(),
                  ],
                },
                {
                  label: "This month",
                  value: [
                    dayjs().startOf("month").toDate(),
                    dayjs().endOf("month").toDate(),
                  ],
                },
                {
                  label: "This year",
                  value: [
                    dayjs().startOf("year").toDate(),
                    dayjs().endOf("year").toDate(),
                  ],
                },
              ],
              value: [filters.dates.startDate, filters.dates.endDate],
              onChange: (value) =>
                value &&
                setFilters({
                  dates: {
                    startDate: value[0],
                    endDate: value[1],
                  },
                }),
            },
          ]}
        />
        <Button
          size="sm"
          onClick={printStatement}
          icon={Printer}
          variant="primaryOutline"
        >
          Print statement
        </Button>
      </div>
      <div ref={printRef} className="hidden print:block">
        <PrintWrapper>{printComponent}</PrintWrapper>
      </div>
      {/* compare with zoho doublecheck and only release  */}
      <DataGrid
        name="clientChartOfAccounts"
        label="Statement of Accounts"
        rowKey="id"
        rows={data.statement}
        columns={[
          {
            key: "date",
            name: "Date",
            width: 120,
            renderCell: ({ row }) => heffl.format.date(row.date),
          },
          {
            key: "description",
            name: "Description",
            width: 300,
            renderCell: ({ row }) => (
              <div
                className="truncate cursor-pointer hover:underline"
                onClick={() => handleRowClick(row)}
              >
                {convert(row.description)}
              </div>
            ),
          },
          {
            key: "property",
            name: "Property",
            width: 120,
            hidden: !team?.apps.installed("FIELD_SERVICE"),
            renderCell: ({ row }) =>
              "jobProperty" in row && row.jobProperty
                ? row.jobProperty.name
                  ? `${row.jobProperty.name} - ${row.jobProperty.city}`
                  : row.jobProperty?.city
                : "-",
          },
          {
            key: "debit",
            name: "Debit",
            width: 120,
            cellClass: "text-right",
            renderCell: ({ row }) =>
              (row.type === "invoice" ||
                row.type === "opening_balance" ||
                row.type === "client_opening_balance") &&
              heffl.format.currency(row.debit, "AED", true),
          },
          {
            key: "credit",
            name: "Credit",
            width: 120,
            cellClass: "text-right",
            renderCell: ({ row }) =>
              row.type === "payment" && row.credit
                ? heffl.format.currency(row.credit, "AED", true)
                : "",
          },
          {
            key: "balance",
            name: "Balance",
            width: 120,
            cellClass: "text-right",
            renderCell: ({ row }) =>
              heffl.format.currency(row.balance, "AED", true),
          },
        ]}
      />
      <div className="flex gap-12 justify-end mt-3">
        <p className="font-semibold">Balance due</p>
        <p className="">
          {heffl.format.currency(data.balanceDue, "AED", true)}
        </p>
      </div>
    </>
  );
};

export default StatementOfAccounts;
