import { styled } from "goober";

const RenderHtml = ({
  children,
  className,
  cssStyles = "",
}: {
  children: string | undefined | null;
  className?: string;
  cssStyles?: string;
}) => {
  const Wrapper = styled("div")`
    ${cssStyles}
    ol li {
      list-style-position: outside !important;
      margin-left: 14px !important;
    }
    ul li {
      list-style-position: outside !important;
      margin-left: 14px !important;
    }
    // disable checkbox
    input[type="checkbox"] {
      cursor: not-allowed !important;
      pointer-events: none !important;
      opacity: 0.5 !important;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;
    }
    input[type="checkbox"]:checked {
      opacity: 0.5 !important;
    }
    label {
      pointer-events: none !important;
    }
  `;
  return (
    <Wrapper
      dangerouslySetInnerHTML={{ __html: children || "" }}
      className={`${className}`}
    />
  );
};

export default RenderHtml;
