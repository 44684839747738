import { Descriptions } from "antd";
import { DescriptionsItemType } from "antd/es/descriptions";

const InfoItems = ({
  items,
  bordered = true,
  column = undefined,
  size = "middle",
  className,
  layout = "horizontal",
}: {
  items: DescriptionsItemType[];
  bordered?: boolean;
  column?: number;
  size?: "small" | "middle";
  className?: string;
  layout?: "horizontal" | "vertical";
}) => {
  return (
    <Descriptions
      className={className}
      column={column}
      items={items}
      bordered={bordered}
      size={size}
      layout={layout}
    />
  );
};

export default InfoItems;
