/* eslint-disable @typescript-eslint/no-explicit-any */
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const UnitForm = () => {
  return (
    <>
      <div className="flex gap-2">
        <FormField name="name" label="Unit name">
          <Input placeholder="Unit name" autoFocus />
        </FormField>
      </div>
      <div className="flex gap-2">
        <FormField name="sizeSqft" label="Size (sqft)">
          <Input type="number" placeholder="Size in square feet" />
        </FormField>
        <FormField name="marketRent" label="Market Rent">
          <Input type="number" placeholder="Market rent amount" />
        </FormField>
      </div>
    </>
  );
};

export const AddPropertyUnitModal = ({
  open,
  onClose,
  propertyId,
}: {
  open: boolean;
  onClose: () => void;
  propertyId: number;
}) => {
  const form = useForm<
    z.infer<typeof Schemas.propertyManagement.pmPropertyUnit>
  >({
    resolver: zodResolver(Schemas.propertyManagement.pmPropertyUnit),
    defaultValues: {
      name: "",
      pmPropertyId: propertyId,
    },
  });

  const onModalClose = () => {
    onClose();
    form.reset();
  };

  const addPmPropertyUnitMutation =
    trpc.propertyManagement.properties.units.add.useMutation({
      onSuccess() {
        toast.success("Successfully added unit.");
        onModalClose();
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  const onSubmit = (
    values: z.infer<typeof Schemas.propertyManagement.pmPropertyUnit>
  ) => {
    addPmPropertyUnitMutation.mutate(values);
  };

  return (
    <ModalDrawer
      title="Add unit"
      open={open}
      onClose={() => onModalClose()}
      footer={
        <Button
          type="submit"
          variant="primary"
          loading={addPmPropertyUnitMutation.isLoading}
          className="!w-full"
          onClick={form.handleSubmit(onSubmit)}
        >
          Add unit
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <UnitForm />
      </Form>
    </ModalDrawer>
  );
};
