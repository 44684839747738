import Page from "@/components/page";
import SimpleTable from "@heffl/ui/components/simple-table";
import { BarChart2, FileText, User } from "lucide-react";
import { useNavigate } from "react-router-dom";

const reports = [
  {
    title: "Lease Reports",
    description: "Overview of active leases, renewals and occupancy rates.",
    slug: "lease-reports",
  },
  {
    title: "Property Reports",
    description: "Property portfolio analysis and performance metrics.",
    slug: "property-reports",
  },
  {
    title: "Financial Reports",
    description: "Rental income, expenses and financial performance tracking.",
    slug: "financial-reports",
  },
  {
    title: "Maintenance Reports",
    description: "Maintenance requests, costs and property upkeep tracking.",
    slug: "maintenance-reports",
  },
];

const PropertyManagementReports = () => {
  const navigate = useNavigate();

  return (
    <Page title="Property Management Reports">
      <SimpleTable
        size="small"
        fixedHeader={false}
        columns={[
          {
            label: "Report Title",
            icon: BarChart2,
            render: (report) => (
              <span className="font-medium text-blue-400 cursor-pointer">
                {report.title}
              </span>
            ),
          },
          {
            label: "Description",
            icon: FileText,
            render: (report) => (
              <span className="text-sm text-gray-500">
                {report.description}
              </span>
            ),
          },
          {
            label: "Created By",
            icon: User,
            render: () => (
              <span className="text-sm text-gray-500">System generated</span>
            ),
          },
        ]}
        rows={reports}
        idKey="slug"
        onRowClick={(report) =>
          navigate(`/property-management/reports/${report.slug}`)
        }
      />
    </Page>
  );
};

export default PropertyManagementReports;
