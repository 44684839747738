import Schemas from "@heffl/server/src/schemas";
import { Button } from "@heffl/ui/components/primitives/button";
import { cn, convertFileSize } from "@heffl/ui/lib/utils";
import { Icon } from "@iconify/react";
import { produce } from "immer";
import { Trash2 } from "lucide-react";
import { useRef } from "react";
import { useDropzone } from "react-dropzone";
import { z } from "zod";
import FileIcon from "./file-icon";

type NewFile = {
  name: string;
  size: number;
  format: string;
  file: File;
};

type ExistingFile = z.infer<typeof Schemas.files.fileItem>;
type DeletedFile = { id: number; link: string };

export type FileTypes = {
  existing: ExistingFile[];
  new: NewFile[];
  deleted: DeletedFile[];
};

const fileSizes = [
  {
    name: "5MB" as const,
    value: 5 * 1024 * 1024,
  },
  {
    name: "10MB" as const,
    value: 10 * 1024 * 1024,
  },
];

type Props = {
  allowMultiple?: boolean;
  value?: FileTypes;
  onChange?: (file: FileTypes) => void;
  accept?: string;
  // TODO setup maxSize functionality
  maxSize?: (typeof fileSizes)[number]["name"];
  label?: string;
  className?: string;
  showPreview?: boolean;
  buttonVariant?: "outline" | "ghost";
  previewClassName?: string;
};

const fileToObject = (file: File) => ({
  name: file.name,
  size: file.size,
  format: file.type,
  file,
});

const FilePicker = ({
  value = { new: [], deleted: [], existing: [] },
  onChange,
  allowMultiple = false,
  accept,
  className,
  showPreview = true,
  previewClassName,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (files: File[]) => {
    if (!onChange || !value) return;
    let updatedFiles = value;
    if (files.length) {
      const filesObject = files.map(fileToObject);
      if (allowMultiple) {
        updatedFiles = produce(updatedFiles, (draft) => {
          draft.new.push(...filesObject);
        });
      } else {
        updatedFiles = produce(updatedFiles, (draft) => {
          if (draft.existing.length > 0 || draft.new.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            draft.deleted.push(...draft.existing);
            draft.existing = [];
            draft.new = [];
          }
          draft.new.push(filesObject[0]);
        });
      }
      onChange(updatedFiles);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileSelect,
    accept: accept ? { [accept]: [] } : undefined,
    multiple: allowMultiple,
  });

  return (
    <>
      <div
        {...getRootProps()}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={cn(
          className,
          "flex gap-2 justify-center items-center p-3 w-full rounded-md border border-dashed"
        )}
      >
        <input
          {...getInputProps()}
          type="file"
          className="!hidden"
          accept={accept as string | undefined}
          multiple={allowMultiple}
          ref={fileInputRef}
        />
        {!isDragActive && (
          <>
            <p className="text-xs text-center text-gray-600">
              Drop your files here to
            </p>
            <Button
              size="xs"
              variant="primary"
              onClick={() => fileInputRef.current?.click()}
            >
              Upload
            </Button>
          </>
        )}
        {isDragActive && (
          <>
            <Icon
              icon="tabler:square-rounded-arrow-down"
              className="w-5 h-5 motion-preset-oscillate"
            />
            <p className="text-xs text-center text-gray-600">Drop files here</p>
          </>
        )}
      </div>

      {showPreview && (
        <div
          className={cn("grid grid-cols-2 gap-2 pt-2 w-full", previewClassName)}
        >
          {[...value.existing, ...value.new].map((file) => (
            <div
              className="flex gap-4 justify-between p-2 rounded-md border"
              key={file.name}
            >
              <div className="flex gap-4 items-center">
                <FileIcon
                  ext={file.name.split(".").pop() || ""}
                  className="h-11 border shadow-sm"
                />
                <div className="flex flex-col justify-between h-11 text-xs">
                  <p>{file.name}</p>
                  <p className="text-xs text-gray-500">
                    {convertFileSize(file.size)}
                  </p>
                </div>
              </div>
              <Trash2
                onClick={() => {
                  if (!onChange || !value) return;
                  const updatedFiles = produce(value, (draft) => {
                    if ("id" in file && typeof file.id === "number") {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      draft.deleted.push(file as FileTypes["existing"][number]);
                      draft.existing = draft.existing.filter(
                        (f) => f.id !== file.id
                      );
                    } else {
                      draft.new = draft.new.filter((f) => f.name !== file.name);
                    }
                  });
                  onChange(updatedFiles);
                }}
                className="h-5 text-red-500 cursor-pointer"
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default FilePicker;
