import { getActivityColumnLabels } from "@heffl/server/src/helpers/userActivities/columnLabels";
import {
  Entity,
  UserActivity,
} from "@heffl/server/src/helpers/userActivities/recordActivity";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@heffl/ui/components/primitives/accordion";
import { Timeline } from "@heffl/ui/components/primitives/Timeline";
import dayjs from "dayjs";
import { convert } from "html-to-text";
import { capitalize } from "lodash";
import { isNumber } from "radash";
import { ReactNode } from "react";
import heffl from "../heffl";

const actionLabels = {
  ADD: "created",
  UPDATE: "updated",
  DELETE: "deleted",
};

const updateTypeLabels = {
  CREATE: "added",
  REMOVE: "removed",
  CHANGE: "updated",
};

export const renderUpdateTitle = (userActivity: UserActivity) => {
  const update = userActivity.updates[0];
  if (!update)
    return (
      <p className="!text-xs">
        <span className="font-medium">{userActivity.users.firstName}</span>{" "}
        <span className="font-normal text-gray-500">
          {actionLabels[userActivity.action]}
        </span>
        <span className="font-medium">
          {" "}
          {capitalize(
            getActivityColumnLabels(
              userActivity.entity,
              userActivity.entity.toLowerCase().replace("_", " ")
            )
          )}
        </span>
        <span className="text-gray-500">
          {" · "} {dayjs(userActivity.createdAt).fromNow()}
        </span>
      </p>
    );
  return (
    <p className="!text-xs">
      <span className="font-medium">{userActivity.users.firstName}</span>{" "}
      <span className="font-normal text-gray-500">
        {updateTypeLabels[update.type]}{" "}
      </span>
      <span className="font-medium">
        {`${capitalize(
          update.path
            .filter((path) => !isNumber(path))
            .map((path) => getActivityColumnLabels(userActivity.entity, path))
            .join(" > ")
        )}`}
      </span>
      <span className="font-medium">
        {"value" in update &&
          !(hideValueChanged[userActivity.entity] || []).includes(
            update.path[0].toString()
          ) &&
          update.type === "CHANGE" && (
            <span className="ml-1">
              <span className="text-gray-500">to </span>
              <span className="font-medium">
                {formatValue(update.path[0], update.value)}
              </span>
            </span>
          )}
      </span>
      <span className="text-gray-500">
        {" · "} {dayjs(userActivity.createdAt).fromNow()}
      </span>
    </p>
  );
};

// list of items that shows updated but dosent show value
const hideValueChanged: { [key in Entity]?: string[] } = {
  QUOTATION: ["dealId"],
  INVOICE: ["paymentsRecieved"],
  PURCHASE_ORDER: [],
  BILL: ["billProducts"],
  FS_JOB: [],
  PROFORMA_INVOICE: [],
  SALES_ORDER: [],
  LEAD: [],
  DEAL: [],
  PROJECT: [],
  PROJECT_TASK: [],
};
const isValidDate = (value: string): boolean => {
  if (typeof value !== "string") return false;

  // Check if string matches exact ISO format
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  return isoDateRegex.test(value);
};

const formatValue = (_key: unknown, value: unknown) => {
  if (isValidDate(value as string)) {
    return heffl.format.date(dayjs(value as string).toDate());
  }

  return ["string", "number"].includes(typeof value)
    ? typeof value === "number"
      ? value
      : convert(String(value))
    : "";
};

export const renderUpdateDescription = (userActivity: UserActivity) => {
  // skips first one it is already shown in title
  const description = userActivity.updates.slice(1).map((update, index) => (
    <li
      key={index}
      className="py-1 pl-1 text-xs leading-relaxed list-disc text-gray-700 transition-colors marker:text-yellow-500 hover:text-gray-900"
    >
      <span className="font-medium text-gray-900">
        {`${capitalize(
          update.path
            .filter((path) => !isNumber(path))
            .map((path) => getActivityColumnLabels(userActivity.entity, path))
            .join(" > ")
        )}`}
      </span>
      <span className="text-gray-600">
        {` ${updateTypeLabels[update.type]}`}
      </span>
      {"value" in update &&
        !(hideValueChanged[userActivity.entity] || []).includes(
          update.path[0].toString()
        ) &&
        update.type === "CHANGE" && (
          <span className="ml-1">
            to{" "}
            <span className="font-semibold text-blue-600">
              {formatValue(update.path[0], update.value)}
            </span>
          </span>
        )}
    </li>
  ));

  if (userActivity.action === "ADD") {
    return (
      <div>
        <span className="text-xs">
          {dayjs(userActivity.createdAt).fromNow()}
        </span>
      </div>
    );
  }
  if (userActivity.action === "UPDATE") {
    return (
      <div>
        <ul>
          {userActivity.updates.length > 2 ||
          userActivity.updates.some(
            (update) => "value" in update && String(update.value).length > 100
          ) ? (
            <Accordion type="single" collapsible>
              <AccordionItem value="updates">
                <AccordionTrigger
                  className="text-xs font-medium"
                  iconPosition="end"
                >
                  <div className="flex gap-3 items-center">
                    {userActivity.updates.length} updates done
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <ul>{description}</ul>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ) : (
            <ul>{description}</ul>
          )}
        </ul>
      </div>
    );
  }
  return "";
};

const RenderTimeline = ({
  userActivities,
}: {
  userActivities: UserActivity[];
}) => {
  const items: {
    title: ReactNode;
    description: ReactNode;
  }[] = [];

  userActivities.forEach((activity) => {
    items.push({
      title: renderUpdateTitle(activity),
      description: renderUpdateDescription(activity),
    });
  });
  return <Timeline activeItem={0} items={items} />;
};

export default RenderTimeline;
