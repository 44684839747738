import { SearchInput } from "@/components/FormComponents";
import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import heffl from "@/helpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import { Badge } from "@heffl/ui/components/primitives/badge";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import dayjs from "dayjs";
import { Building2, FileText } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ListLeases = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useParamsState<
    RouterInputs["propertyManagement"]["leases"]["list"]
  >({
    search: "",
    clientId: [],
    propertyId: [],
  });

  const [clientSearch, setClientSearch] = useState("");

  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
  });
  const { data: properties } = trpc.propertyManagement.properties.list.useQuery(
    {
      search: "",
    }
  );

  const { data, isLoading } = trpc.propertyManagement.leases.list.useQuery(
    filters,
    {
      keepPreviousData: true,
    }
  );

  if (!data) return <FullScreenSpinner />;

  return (
    <Page title="Leases" fullWidth className="sm:!p-0">
      <div className="flex flex-row justify-between border-gray-200 sm:p-3 sm:border-b">
        <SearchInput
          value={filters.search || ""}
          onChange={(v) =>
            setFilters({
              search: v,
            })
          }
          placeholder="Search leases..."
        />
        <ResponsiveActionButton
          onClick={() => navigate("/property-management/leases/add")}
          text="Lease"
        />
      </div>

      <FilterBar
        className="py-3 sm:p-3"
        filters={[
          {
            key: "clientId",
            label: "Client",
            type: "checkbox",
            value: filters.clientId || [],
            onSearch: setClientSearch,
            options:
              clients?.clients?.map((client) => ({
                label: client.name,
                value: client.id,
              })) || [],
            onChange: (value) => setFilters({ clientId: value as number[] }),
          },
          {
            key: "propertyId",
            label: "Property",
            type: "checkbox",
            value: filters.propertyId || [],
            options:
              properties?.map((property) => ({
                label: property.name || "",
                value: property.id,
              })) || [],
            onChange: (value) => setFilters({ propertyId: value as number[] }),
          },
        ]}
      />

      <DataGrid
        name="propertyManagementLeases"
        label="Leases"
        loading={isLoading}
        className="h-[calc(100vh-195px)]"
        rows={data.leases || []}
        rowKey="id"
        onCellClick={({ row }) =>
          navigate(`/property-management/leases/details/${row.id}`)
        }
        columns={[
          {
            key: "number",
            name: "Number",
            width: 100,
            renderCell: ({ row }) => <div>{row.number}</div>,
          },
          {
            key: "client",
            name: "Client",
            width: 300,
            renderCell: ({ row }) => (
              <div className="flex gap-2 items-center">
                <Building2 className="w-4 h-4" />
                <span>{row.clients.name}</span>
              </div>
            ),
          },
          {
            key: "contact",
            name: "Contact",
            width: 150,
            renderCell: () => <div></div>,
          },
          {
            key: "property",
            name: "Property",
            width: 150,
            renderCell: ({ row }) => (
              <Badge variant="default" iconify="tabler:building">
                {row.pmPropertyUnits.pmProperties.name}
              </Badge>
            ),
          },
          {
            key: "unit",
            name: "Unit",
            width: 150,
            renderCell: ({ row }) => (
              <Badge variant="outline" iconify="tabler:key">
                {row.pmPropertyUnits.name}
              </Badge>
            ),
          },
          {
            key: "status",
            name: "Status",
            width: 120,
            renderCell: () => <Badge variant="success">Active</Badge>,
          },
          {
            key: "startDate",
            name: "Start Date",
            width: 150,
            renderCell: ({ row }) => (
              <div>{heffl.format.date(dayjs(row.startDate).toDate())}</div>
            ),
          },
          {
            key: "endDate",
            name: "End Date",
            width: 150,
            renderCell: ({ row }) => (
              <div>{heffl.format.date(dayjs(row.endDate).toDate())}</div>
            ),
          },
          {
            key: "firstRentDate",
            name: "First Rent Date",
            width: 150,
            renderCell: ({ row }) => (
              <div>{heffl.format.date(dayjs(row.firstRentDate).toDate())}</div>
            ),
          },
          {
            key: "totalAmount",
            name: "Total Amount",
            width: 150,
            renderCell: ({ row }) => (
              <div>
                {heffl.format.currency(
                  calculateLineItems({
                    lineItems: row.pmLeaseItems,
                    discount: 0,
                  }).total,
                  "AED"
                )}
              </div>
            ),
          },

          {
            key: "createdAt",
            name: "Created At",
            width: 150,
            renderCell: ({ row }) => (
              <div>{heffl.format.date(dayjs(row.createdAt).toDate())}</div>
            ),
          },
        ]}
        empty={{
          title: "No leases found",
          icon: FileText,
          description: "Add your first lease to get started",
          actionText: "Add lease",
          onAction: () => navigate("/property-management/leases/add"),
        }}
      />
    </Page>
  );
};

export default ListLeases;
