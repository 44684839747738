import { cn } from "../../lib/utils";
import { Search } from "lucide-react";

type EmptyScreenProps = {
  title?: string;
  description?: string;
  icon?: React.ReactNode;
  className?: string;
};

const EmptyScreen = ({
  title = "No data found",
  description = "There are no items to display.",
  icon = <Search className="w-8 h-8 text-gray-400" />,
  className,
}: EmptyScreenProps) => {
  return (
    <div
      className={cn(
        "flex flex-col justify-center items-center p-8 w-full",
        className
      )}
    >
      <div className="p-4 mb-4 bg-gray-50 rounded-full">{icon}</div>
      <h3 className="mb-1 font-medium text-gray-900">{title}</h3>
      <p className="text-sm text-center text-gray-500">{description}</p>
    </div>
  );
};

export default EmptyScreen;
