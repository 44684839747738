import Page from "@/components/page";
import { cn } from "@heffl/ui/lib/utils";
// import { useQueryState } from "nuqs";
import { Menu } from "lucide-react";
import { useQueryState } from "nuqs";
import { useState } from "react";

interface SidebarItem {
  title?: string;
  links: {
    id: string;
    label: string;
    description?: string;
    count?: number;
    children?: React.ReactNode;
  }[];
}

interface ReportsHomeProps {
  title: string;
  reports: SidebarItem[];
  defaultReport?: string;
}

const ReportsHome = ({
  title,
  reports: sidebarData,
  defaultReport,
}: ReportsHomeProps) => {
  const [activeLink, setActiveLink] = useQueryState("report", {
    defaultValue: defaultReport || sidebarData[0]?.links[0]?.id || "",
    parse: (value: string) => value,
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <Page title={title} className="flex !p-0" showBack fullWidth>
      {/* Mobile Menu Button */}
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="fixed top-[var(--header-height)] left-2 z-50 p-2 bg-white rounded-md shadow-md md:hidden"
      >
        <Menu className="w-6 h-6" />
      </button>

      {/* Sidebar */}
      <div
        className={cn(
          "overflow-y-auto fixed z-40 flex-none w-52 bg-white border-r border-gray-200 transition-transform duration-300 h-[calc(100vh-var(--header-height))]",
          "md:translate-x-0",
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        )}
      >
        <div className="p-2">
          {sidebarData.map((section) => (
            <div key={section.title} className="mb-6">
              {section.title && (
                <h3 className="mb-3 text-xs font-medium tracking-wider text-gray-500 uppercase">
                  {section.title}
                </h3>
              )}
              <div className="space-y-1">
                {section.links.map((link) => (
                  <button
                    key={link.id}
                    onClick={() => {
                      // Clear URL params and set only the report param
                      const url = new URL(window.location.href);
                      url.search = "";
                      url.searchParams.set("report", link.id);
                      window.history.replaceState({}, "", url.toString());
                      setActiveLink(link.id);
                      setIsSidebarOpen(false);
                    }}
                    className={cn(
                      "flex justify-between items-center w-full px-3 py-1.5 text-sm rounded-md text-left",
                      activeLink === link.id
                        ? "bg-primary-50 text-primary-600"
                        : "text-gray-600 hover:bg-gray-50"
                    )}
                  >
                    <span>{link.label}</span>
                    {link.count !== undefined && (
                      <span
                        className={cn(
                          "px-2 py-1 text-xs rounded-full",
                          activeLink === link.id
                            ? "bg-primary-100 text-primary-600"
                            : "bg-gray-100 text-gray-600"
                        )}
                      >
                        {link.count}
                      </span>
                    )}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 w-0 min-w-0 md:ml-52">
        <div className="p-1.5 border-b border-gray-200">
          <p className="text-sm font-medium text-gray-900">
            {sidebarData
              .flatMap((s) => s.links)
              .find((l) => l.id === activeLink)?.label || title}
          </p>
          <p className="text-xs text-gray-500">
            {sidebarData
              .flatMap((s) => s.links)
              .find((l) => l.id === activeLink)?.description || ""}
          </p>
        </div>
        <div className="p-3">
          {sidebarData.flatMap((s) => s.links).find((l) => l.id === activeLink)
            ?.children || <div>No content</div>}
        </div>
      </div>

      {/* Overlay for mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-30 bg-black/20 md:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}
    </Page>
  );
};

export default ReportsHome;
