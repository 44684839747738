import Schemas from "@heffl/server/src/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { MultipleUserInput } from "@/components/FormComponents";
import EmojiPicker from "@heffl/ui/components/emoji-picker";
import { Button } from "@heffl/ui/components/primitives/button";
import { trpc } from "@/helpers/trpc";
import { useNavigate } from "react-router-dom";

type Props = {
  open: boolean;
  onClose: () => void;
};

const AddCustomDashboardModal = ({ open, onClose: onCloseRaw }: Props) => {
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof Schemas.dashboards.dashboard>>({
    resolver: zodResolver(Schemas.dashboards.dashboard),
    defaultValues: {
      icon: "📈",
      type: "PROJECT",
    },
  });

  const onClose = () => {
    form.reset();
    onCloseRaw();
  };

  const addDashboardMutation = trpc.dashboards.add.useMutation({
    onSuccess: (data) => {
      onClose();
      navigate(`/projects/dashboard/${data.id}`);
    },
  });

  const onSubmit = (data: z.infer<typeof Schemas.dashboards.dashboard>) => {
    addDashboardMutation.mutate(data);
  };

  return (
    <ModalDrawer
      title="Add Custom Dashboard"
      open={open}
      onClose={onClose}
      footer={
        <Button
          onClick={form.handleSubmit(onSubmit)}
          className="w-full"
          variant="primary"
          loading={addDashboardMutation.isLoading}
        >
          Add Dashboard
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <FormField name="icon" label="Icon">
          <EmojiPicker />
        </FormField>
        <FormField name="title" label="Title">
          <Input placeholder="Enter title" autoFocus />
        </FormField>

        <FormField name="description" label="Description">
          <Textarea rows={2} placeholder="Enter description" />
        </FormField>
        <MultipleUserInput
          name="viewers"
          label="Viewers"
          placeholder="People who can view this dashboard"
          defaultCurrentUser
        />
      </Form>
    </ModalDrawer>
  );
};

export default AddCustomDashboardModal;
