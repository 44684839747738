import {
  SidebarProvider,
  SidebarTrigger,
} from "@heffl/ui/components/primitives/sidebar";
import { cn, isMobile, makeEllipsis } from "@heffl/ui/lib/utils";
import { LucideIcon, MoreHorizontal } from "lucide-react";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import useNav from "@/stores/useNav";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { NewSidebar } from "../sidebar/new-sidebar";
import { Button } from "@heffl/ui/components/primitives/button";
import { ProjectTaskDetailsModal } from "@/pages/projects/tasks/components/project-task-modals";
import useInfoStore from "@/stores/useInfoStore";
import { trpc } from "@/helpers/trpc";
type TProps = {
  links: {
    icon: LucideIcon;
    path: string;
    label: string;
    subMenuLinks?: {
      icon: LucideIcon;
      path: string;
      label: string;
    }[];
  }[];
};

type TMoreDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  links: { icon: LucideIcon; path: string; label: string }[];
};

const MoreDrawer = ({ isOpen, onClose, links }: TMoreDrawerProps) => {
  const { selectedPage, setSelectedPage } = useNav();
  const navigate = useNavigate();

  return (
    <ModalDrawer open={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-2 h-full">
        {links.map((link) => (
          <div
            key={link.path}
            onClick={() => {
              setSelectedPage(link.path);
              navigate(link.path);
              onClose();
            }}
            className={`flex gap-1 rounded-lg cursor-pointer items-center p-2 hover:bg-gray-100 ${
              selectedPage === link.path && "bg-green-200"
            }`}
          >
            <link.icon className={`h-5 text-primary`} />

            <p className={`text-sm font-medium`}>{link.label}</p>
          </div>
        ))}
      </div>
      <SidebarProvider>
        <main>
          <SidebarTrigger />
        </main>
      </SidebarProvider>
    </ModalDrawer>
  );
};

const showBottomBarPages = [
  "crm/dashboard",
  "crm/leads/:view",
  "crm/deals/:view",
  "crm/deals",
  "crm/contacts",
  "crm/clients",
  "crm/goals",
  "crm/tasks/:view",

  "sales/dashboard",
  "sales/invoices",
  "sales/quotations",
  "sales/proforma-invoices",
  "sales/products",
  "sales/clients",
  "sales/goals",
  "sales/sales-orders",

  "projects/dashboard",
  "projects/tasks/:view",
  "projects/templates",
  "projects/archive",
  "projects/pipelines/list/:id",
  "projects/:view",
  "projects/timesheets",

  "field-service/dashboard",
  "field-service/schedules/:view",
  "field-service/jobs",
  "field-service/reports",

  "settings",
  "settings/tags",

  "files/list",
  "files/trash",
];

const SideBarWrapper = ({ links = [] }: TProps) => {
  const defaultOpen = true;

  const location = useLocation();
  const pinnedTasks = useInfoStore((state) => state.pinnedProjectTasks);
  const setInfoStore = useInfoStore((state) => state.setInfoItem);
  const { selectedPage, setSelectedPage } = useNav();
  const navigate = useNavigate();

  const [isMoreDrawerOpen, setIsMoreDrawerOpen] = useState(false);
  const [showPinnedTask, setShowPinnedTask] = useState<undefined | number>(
    undefined
  );

  const { data: pinnedTasksData } = trpc.projects.tasks.list.useQuery(
    {
      ids: pinnedTasks,
    },
    {
      enabled: pinnedTasks.length > 0,
      keepPreviousData: pinnedTasks.length > 1,
    }
  );

  useEffect(() => {
    if (!selectedPage) {
      setSelectedPage(location.pathname.split("/")[2]);
    }
  }, [location.pathname]);

  const slicedLinks = links.length > 5 ? links.slice(0, 4) : links;

  const showBottomBar = () => {
    return showBottomBarPages.some((pageRaw) => {
      const pages = pageRaw.split("/");
      const currentPages = location.pathname
        .split("/")
        .filter((page) => page !== "");
      if (pages.length !== currentPages.length) {
        return false;
      }
      const shouldShow = pages.every((page, index) => {
        if (page.startsWith(":")) {
          return true;
        }
        return page === currentPages[index];
      });
      return shouldShow;
    });
  };

  const mobileBottomBar = (
    <div className="flex bottom-0 left-0 w-full h-[65px] bg-white border-b border-t border-gray-200 px-0 justify-between items-center fixed z-20">
      {slicedLinks.map((link) => (
        <div
          key={link.path}
          onClick={() => {
            setSelectedPage(link.path);
            navigate(link.path);
          }}
          className={cn(
            "w-full h-full px-4 py-2 text-[11px] font-medium text-gray-600 flex flex-col items-center gap-1",
            selectedPage === link.path && "text-primary"
          )}
        >
          <link.icon className={`h-5`} />
          {link.label}
        </div>
      ))}
      {/* Next line showing MoreHorizontal icon */}
      {links.length > 5 && (
        <div
          className={cn(
            "flex flex-col gap-1 items-center px-4 py-2 w-full h-full font-medium text-gray-600 text-[11px]"
          )}
          onClick={() => setIsMoreDrawerOpen(true)}
        >
          <MoreHorizontal className={`h-5`} />
        </div>
      )}
      <MoreDrawer
        isOpen={isMoreDrawerOpen}
        onClose={() => setIsMoreDrawerOpen(false)}
        links={links}
      />
    </div>
  );

  return (
    <div className="w-full">
      {showPinnedTask && (
        <ProjectTaskDetailsModal
          open={!!showPinnedTask}
          onClose={() => setShowPinnedTask(undefined)}
          taskId={showPinnedTask}
          switchToTask={(taskId) => {
            setShowPinnedTask(taskId);
          }}
        />
      )}
      {isMobile() && showBottomBar() && mobileBottomBar}
      <SidebarProvider defaultOpen={defaultOpen}>
        <NewSidebar />
        <div
          id="content"
          className={cn(
            "max-w-full w-full ml-auto relative",
            "peer-data-[state=collapsed]:w-[calc(100%-var(--sidebar-width-icon)-1rem)]",
            "peer-data-[state=expanded]:w-[calc(100%-var(--sidebar-width))]",
            "h-svh flex flex-col",
            "group-data-[scroll-locked=1]/body:h-full",
            "group-data-[scroll-locked=1]/body:has-[main.fixed-main]:h-svh"
          )}
        >
          <Outlet />
          {pinnedTasksData?.tasks && pinnedTasksData.tasks.length > 0 && (
            <div className="flex fixed right-0 bottom-0 gap-2 items-center">
              {pinnedTasksData.tasks.map((task) => (
                <div
                  key={task.id}
                  className="flex gap-2 items-center px-2 p-1 bg-white rounded-t-lg !shadow-2xl border border-gray-200 cursor-pointer"
                  onClick={() => {
                    setShowPinnedTask(task.id);
                  }}
                >
                  <p className="text-sm font-medium">
                    {task.number} - {makeEllipsis(task.title, 15)}
                  </p>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setInfoStore("pinnedProjectTasks", [task.id], {
                        delete: true,
                      });
                    }}
                    iconify="tabler:x"
                    variant="ghost"
                    size="sm"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </SidebarProvider>
    </div>
  );
};

export default SideBarWrapper;
