import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@heffl/ui/components/primitives/dialog";
import { cn } from "@heffl/ui/lib/utils";
import ResponsiveActions, {
  ResponsiveAction,
} from "@heffl/ui/components/responsive-actions";
import { Button } from "./button";
import { X } from "lucide-react";

const Modal = ({
  children,
  title,
  description,
  open,
  onClose,
  className,
  footer,
  closeOnOverlayClick = false,
  actions,
}: {
  closeOnOverlayClick?: boolean;
  children: React.ReactNode;
  title?: string | React.ReactElement;
  description?: string;
  open: boolean;
  onClose: (v: boolean) => void;
  className?: string;
  footer?: React.ReactNode;
  actions?: ResponsiveAction[];
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        className={cn(
          "max-h-[90%]  overflow-hidden px-0 pt-0",
          className,
          !footer && "pb-2"
        )}
        onInteractOutside={(e) => {
          !closeOnOverlayClick && e.preventDefault();
        }}
      >
        <DialogHeader className="pl-3 pr-2 py-1.5 space-y-0 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <DialogTitle className="text-base font-medium">{title}</DialogTitle>
            <div className="flex gap-4 items-center">
              <ResponsiveActions
                actions={actions || []}
                defaultButtonSize="xs"
                defaultButtonVariant="outline"
              />
              <Button
                variant="ghost"
                size="icon"
                className="!p-0.5 w-6 h-6"
                onClick={() => onClose(false)}
              >
                <X className="w-5 h-5" />
                <span className="sr-only">Close</span>
              </Button>
            </div>
          </div>
          <DialogDescription className="!-mt-0.5 text-xs">
            {description}
          </DialogDescription>
        </DialogHeader>
        <div className="overflow-y-auto flex-1 px-3 py-1">{children}</div>
        {footer && (
          <DialogFooter className="p-2 border-t border-gray-200">
            {footer}
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
