import { AddActivityModal } from "@/components/actionsModals/activities-modals";
import { AddNotesModal } from "@/components/actionsModals/notes-modals";
import { AddTaskModal } from "@/components/actionsModals/tasks-modals";
import Page, { PageHeight } from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { DATE_WORD_FORMAT } from "@/lib/constants";
import Schemas from "@heffl/server/src/schemas";
import CameraPicker from "@heffl/ui/components/camera-picker";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import CopyToClipboard from "@heffl/ui/components/primitives/copy-to-clipboard";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import Select from "@heffl/ui/components/primitives/select";
import NextTabs from "@heffl/ui/components/tabs";
import {
  cn,
  convertFileSize,
  formatCurrency,
  formatName,
  isMobile,
} from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { format } from "date-fns";

import FileIcon from "@/components/file-icon";
import validateCustomFields from "@/helpers/customFields/validateCustomFields";
import { renderCustomFieldValue } from "@heffl/server/src/helpers/customFields";
import FilePicker from "@heffl/ui/components/file-picker";
import { AutosizeTextarea } from "@heffl/ui/components/primitives/autosize-textarea";

import DropMenu from "@/components/DropMenu";
import { AddLogModal } from "@/components/actionsModals/log-modals";
import { messageOnWhatsApp } from "@/pages/field-service/staff-app/schedules/details";
import { AddLostReasonDrawer } from "@/pages/settings/lostReasons";
import enums from "@heffl/server/src/schemas/enums";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import {
  Calendar,
  CalendarCheck2,
  Camera,
  Check,
  CheckCircle,
  Mail,
  MessageCircle,
  MessageSquare,
  Mic,
  Paperclip,
  Pencil,
  PenSquare,
  Phone,
  Save,
  SendHorizonal,
  StickyNote,
  Trash2,
  User,
  UserCircle,
  X,
} from "lucide-react";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { z } from "zod";
import { EditContactDrawer } from "../contacts/list";
import AddDealDrawer from "../deals/components/add-deal-modal";
import { InfoItem, MobileRoundedButton, Stage } from "../deals/details";
import Leadfocus from "./components/focus";
import LeadHistory from "./components/history";
import LeadForm from "./components/lead-form";
import heffl from "@/helpers/heffl";

export const CommentInput = ({
  leadId,
  onClose,
  dealId,
  fsScheduleId,
  className,
  activityId,
  quotationId,
  entity,
  entityId,
}: {
  leadId?: number;
  dealId?: number;
  fsScheduleId?: number;
  onClose?: () => void;
  className?: string;
  activityId?: number;
  quotationId?: number;
  entity: z.infer<typeof enums.entities>;
  entityId: number;
}) => {
  const { startRecording, stopRecording, recordingTime, recordingBlob } =
    useAudioRecorder();

  const [comment, setComment] = useImmer<{
    comment: string;
    recordingBlob: Blob | null | undefined;
    file?: {
      type: "VOICE" | "IMAGE" | "FILE";
      file: File;
    };
  }>({
    comment: "",
    recordingBlob: null,
    file: undefined,
  });

  const [voiceRecording, setVoiceRecording] = useState(false);
  const [loading, setLoading] = useState(false);

  const onModalClose = () => {
    setComment({
      comment: "",
      recordingBlob: null,
    });
    setVoiceRecording(false);
    stopRecording();
    onClose && onClose();
  };

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation({
      onSuccess: (data) => {
        console.log(data);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const addCommentMutation = trpc.comments.add.useMutation({
    onSuccess: () => {
      toast.success("Comment added");
      onModalClose();
    },
  });

  useEffect(() => {
    setComment((draft) => {
      draft.recordingBlob = recordingBlob;
    });
  }, [recordingBlob]);

  const onSubmit = async () => {
    setLoading(true);
    let presignedUrls;
    if (comment.recordingBlob) {
      const voiceRecordName = `voice-recording-${nanoid()}`;
      const recordingFile = new File([comment.recordingBlob], voiceRecordName, {
        type: comment.recordingBlob.type,
      });
      presignedUrls = await getPresignedUrls({
        files: [
          {
            name: voiceRecordName,
            format: recordingFile.type,
            size: comment.recordingBlob.size,
          },
        ],
        section: "leads/comments",
      });
      await axios.put(presignedUrls[0].presignedUrl, recordingFile, {
        headers: {
          "Content-Type": recordingFile.type,
        },
      });
    }
    if (comment.file) {
      const fileName = `${comment.file.type.toLowerCase()}-${nanoid()}`;
      let fileUpdated = new File([comment.file.file], fileName, {
        type: comment.file.file.type,
      });

      if (comment.file.type === "IMAGE") {
        fileUpdated = await imageCompression(fileUpdated, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        });
      }

      presignedUrls = await getPresignedUrls({
        files: [
          {
            name: fileName,
            format: fileUpdated.type,
            size: comment.file.file.size,
          },
        ],
        section: "leads/comments",
      });
      await axios.put(presignedUrls[0].presignedUrl, fileUpdated, {
        headers: {
          "Content-Type": fileUpdated.type,
        },
      });
    }
    addCommentMutation.mutate({
      leadId: leadId,
      dealId: dealId,
      fsScheduleId: fsScheduleId,
      activityId: activityId,
      quotationId: quotationId,
      comment: comment.comment,
      type: comment.file?.type || "TEXT",
      files: { new: presignedUrls || [], deleted: [] },
      entity,
      entityId,
    });
    setLoading(false);
  };

  return (
    <div className={className}>
      {!isMobile() && (
        <div className="flex flex-col items-end">
          <FilePicker
            label="Attach file"
            className="!w-full text-primary-800 border-primary-800"
            allowMultiple={false}
            value={{
              new: comment.file
                ? [
                    {
                      file: comment.file.file,
                      name: comment.file.file.name,
                      format: comment.file.file.type,
                      size: comment.file.file.size,
                    },
                  ]
                : [],
              existing: [],
              deleted: [],
            }}
            onChange={(files) => {
              if (files.new.length > 0 && files.new[0].file)
                setComment((draft) => {
                  draft.file = {
                    type: files.new[0].format.startsWith("image/")
                      ? "IMAGE"
                      : "FILE",
                    file: files.new[0].file,
                  };
                });
              else {
                setComment((draft) => {
                  draft.file = undefined;
                });
              }
            }}
          />
          <MiniRichTextEditor
            value={comment.comment}
            onChange={(value) => {
              setComment((draft) => {
                draft.comment = value;
              });
            }}
            height={100}
            placeholder="Add a comment"
            className="mt-2 w-full"
          />
          <Button
            className="mt-2 w-full"
            onClick={onSubmit}
            loading={loading}
            variant="primary"
            disabled={
              !comment.comment && !comment.recordingBlob && !comment.file
            }
          >
            Add comment
          </Button>
        </div>
      )}
      {isMobile() && (
        <div className="flex flex-col gap-3 items-center">
          {comment.recordingBlob && (
            <audio controls>
              <source
                src={URL.createObjectURL(comment.recordingBlob)}
                type="audio/wav"
              />
              Your browser does not support the audio element.
            </audio>
          )}
          {comment.file && comment.file.type === "IMAGE" && (
            <div className="flex relative justify-center w-full">
              <Button
                onClick={() => {
                  setComment((draft) => {
                    draft.file = undefined;
                  });
                }}
                variant="ghost"
                size="icon"
                className="absolute top-2 right-2 w-6 h-6 text-white bg-red-400 rounded-full"
              >
                <X className="w-4 h-4" />
              </Button>
              <img
                src={URL.createObjectURL(comment.file.file)}
                className="h-52 rounded-lg"
              />
            </div>
          )}
          {comment.file && comment.file.type === "FILE" && (
            <div
              className="flex gap-4 justify-between p-2 w-full bg-gray-50 rounded-md border"
              key={comment.file.file.name}
            >
              <div className="flex gap-4 items-center">
                <FileIcon
                  ext={comment.file.file.name.split(".").pop() || ""}
                  className="border shadow-sm"
                />
                <div className="flex flex-col justify-between h-11">
                  <p>{comment.file.file.name}</p>
                  <p className="text-xs text-gray-500">
                    {convertFileSize(comment.file.file.size)}
                  </p>
                </div>
              </div>
              <Trash2
                onClick={() => {
                  setComment((draft) => {
                    draft.file = undefined;
                  });
                }}
                className="h-5 text-red-500 cursor-pointer"
              />
            </div>
          )}

          <div className="flex gap-2 items-center w-full">
            <p
              className={cn(
                "font-medium text-red-500 hidden",
                recordingTime && "block animate-pulse"
              )}
            >
              {(recordingTime / 60).toFixed(2)}
            </p>
            <div className="relative w-full">
              <AutosizeTextarea
                className="pr-20"
                maxHeight={100}
                placeholder="Message"
                value={comment.comment}
                onChange={(e) => {
                  setComment((draft) => {
                    draft.comment = e.target.value;
                  });
                }}
              />
              {!comment.file && (
                <div className="flex absolute right-1 top-1/2 transform -translate-y-1/2">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => {
                      const fileInput = document.createElement("input");
                      fileInput.type = "file";
                      fileInput.onchange = (e) => {
                        const target = e.target as HTMLInputElement;
                        if (target.files && target.files.length > 0) {
                          setComment((draft) => {
                            draft.file =
                              target.files && target.files.length > 0
                                ? {
                                    type: "FILE",
                                    file: target.files[0] as File,
                                  }
                                : undefined;
                          });
                        }
                      };
                      fileInput.click();
                    }}
                  >
                    <Paperclip className="w-5 h-5" />
                  </Button>
                  <CameraPicker
                    onChange={(file) => {
                      setComment((draft) => {
                        draft.file = {
                          type: "IMAGE",
                          file: file,
                        };
                      });
                    }}
                  >
                    <Button variant="ghost" size="icon">
                      <Camera strokeWidth={2.2} className="w-5 h-5" />
                    </Button>
                  </CameraPicker>
                </div>
              )}
            </div>

            {comment.comment || comment.recordingBlob || comment.file ? (
              <Button
                loading={loading}
                onClick={onSubmit}
                variant="primary"
                className="px-3"
              >
                <SendHorizonal />
              </Button>
            ) : null}
            {!comment.comment && !comment.recordingBlob && !comment.file && (
              <Button
                variant="primary"
                className={cn("px-3", voiceRecording && "bg-green-500")}
                onClick={() => {
                  voiceRecording ? stopRecording() : startRecording();
                  setVoiceRecording(!voiceRecording);
                }}
              >
                {voiceRecording ? <Check /> : <Mic />}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const AddCommentModal = ({
  open,
  onClose,
  leadId,
  dealId,
}: {
  open: boolean;
  onClose: () => void;
  leadId?: number;
  dealId?: number;
}) => {
  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title={isMobile() ? undefined : "Add comment"}
    >
      {dealId && (
        <CommentInput
          leadId={leadId}
          dealId={dealId}
          onClose={onClose}
          entity="DEAL"
          entityId={dealId}
        />
      )}
    </ModalDrawer>
  );
};

export const EditLeadDrawer = ({
  leadId,
  open,
  onClose,
}: {
  leadId: number;
  open: boolean;
  onClose: () => void;
}) => {
  const confirm = useConfirm();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof Schemas.crm.lead>>({
    resolver: zodResolver(Schemas.crm.lead),
  });

  const { data: lead } = trpc.leads.details.useQuery(leadId, {
    refetchOnWindowFocus: false,
  });

  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section: "LEAD",
    }
  );

  const deleteLeadMutation = trpc.leads.delete.useMutation({
    onSuccess: () => {
      toast.success("Lead deleted successfully");
      navigate("/crm/leads/table");
    },
  });

  const onCloseModal = () => {
    onClose();
    // form.reset();
  };

  const leadUpdateMutation = trpc.leads.update.useMutation({
    onSuccess: () => {
      toast.success("Lead updated successfully");
      onCloseModal();
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const onLeadUpdate = async (values: z.infer<typeof Schemas.crm.lead>) => {
    const { isValid } = validateCustomFields({
      customFields,
      customFieldsValues: values.customFields,
      form,
    });
    if (!isValid) return;
    leadUpdateMutation.mutate({
      id: leadId,
      lead: values,
    });
  };

  useEffect(() => {
    if (lead) {
      form.reset({
        ...lead,
        leadAssignees: lead.leadAssignees.map((assignee) => assignee.userId),
      });
    }
  }, [lead, form]);

  return (
    <ModalDrawer
      className="w-full sm:max-w-sm"
      open={open}
      onClose={onClose}
      title="Edit lead"
      footer={
        <div className="flex flex-row gap-2 w-full">
          <Button
            variant="destructiveOutline"
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this lead?",
                body: "This action cannot be undone.",
              });
              if (confirmed) {
                deleteLeadMutation.mutate(leadId);
              }
            }}
            icon={Trash2}
            loading={deleteLeadMutation.isLoading}
          />
          <Button
            icon={Save}
            loading={leadUpdateMutation.isLoading}
            variant="primary"
            className="w-full"
            onClick={() => {
              form.handleSubmit(onLeadUpdate)();
            }}
          >
            Update
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onLeadUpdate}>
        <LeadForm form={form} edit />
      </Form>
    </ModalDrawer>
  );
};

export type LostReasonModalProps = {
  open: boolean;
  onClose: () => void;
  onChange: (params: {
    reasonId?: number | null;
    description?: string | null;
  }) => void;
  submitText?: string;
  type?: "QUOTATION" | "DEAL" | "LEAD";
  defaultValues?: {
    reasonId: number | null;
    description: string | null;
  };
};

const reasonSchema = z.object({
  reasonId: z.number().nullish(),
  description: z.string().nullish(),
});

export const SelectLostReasonModal: React.FC<LostReasonModalProps> = ({
  open,
  onClose,
  onChange,
  type,
  submitText = "Continue",
  defaultValues,
}) => {
  const [showAddLostReason, setShowAddLostReason] = useState<boolean>(false);

  const { data: lostReasons } = trpc.lostReasons.list.useQuery(
    {
      type: type!,
    },
    {
      enabled: !!type,
    }
  );

  const form = useForm<z.infer<typeof reasonSchema>, unknown>({
    defaultValues: {
      reasonId: null as number | null,
      description: "",
    },
  });

  const selectedReason = form.watch("reasonId");

  const onModalClose = () => {
    onClose();
    form.reset({ reasonId: null, description: "" });
  };

  useEffect(() => {
    if (lostReasons && lostReasons.length && !selectedReason) {
      form.setValue("reasonId", lostReasons[0].id);
    }
  }, [lostReasons, form, selectedReason]);

  useEffect(() => {
    if (defaultValues && !selectedReason) {
      form.reset({ ...defaultValues });
    }
  }, [defaultValues, form]);

  const onSubmit = (values: z.infer<typeof reasonSchema>) => {
    onChange(values);
    onModalClose();
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title="Select Lost Reason"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            variant="destructiveOutline"
            onClick={() => {
              onChange({});
              onModalClose();
            }}
          >
            Skip
          </Button>
          <Button
            className="w-full"
            variant="primary"
            onClick={() => form.handleSubmit(onSubmit)()}
          >
            {submitText}
          </Button>
        </div>
      }
    >
      {showAddLostReason && (
        <AddLostReasonDrawer
          open={showAddLostReason}
          onClose={(newLostReasonId) => {
            if (newLostReasonId) {
              form.setValue("reasonId", newLostReasonId);
            }
            setShowAddLostReason(false);
          }}
          type={type}
        />
      )}
      <Form {...form} onSubmit={onSubmit}>
        {type && (
          <FormField name="reasonId">
            <Select
              createButton={{
                label: "Add new",
                onClick: () => setShowAddLostReason(true),
              }}
              options={
                lostReasons?.map((reason) => ({
                  label: reason.reason,
                  value: reason.id,
                })) || []
              }
            />
          </FormField>
        )}
        <FormField name="description">
          <Textarea placeholder="Explain the reason for the lost deal..." />
        </FormField>
      </Form>
    </ModalDrawer>
  );
};

const LeadDetails = () => {
  const params = useParams();
  const leadId = Number(params.leadId);

  const navigate = useNavigate();

  const [showAdd, setShowAdd] = useImmer<{
    activity: boolean;
    note: boolean;
    task: boolean;
    comment: boolean;
    log: z.infer<typeof enums.activityTypes> | undefined;
  }>({
    activity: false,
    note: false,
    task: false,
    comment: false,
    log: undefined,
  });

  const [editLead, setEditLead] = useState(false);
  const [addDeal, setAddDeal] = useState(false);
  const [editContact, setEditContact] = useState(false);

  const { data: leadStages } = trpc.leads.stages.list.useQuery();

  const { data: lead } = trpc.leads.details.useQuery(leadId);

  useHotkeys(
    "w",
    () => {
      messageOnWhatsApp(lead?.mobile || "", "");
    },
    {
      enabled: !!lead?.mobile,
    }
  );

  const leadUpdateMutation = trpc.leads.update.useMutation({
    onSuccess: () => {
      toast.success("Lead updated successfully");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  if (!lead || !leadStages) return <FullScreenSpinner />;

  const leadDetails = (
    <ScrollArea
      className={`p-3 w-full h-full sm:w-1/4 sm:block`}
      style={{ height: PageHeight }}
    >
      {editLead && (
        <EditLeadDrawer
          leadId={leadId}
          open={true}
          onClose={() => setEditLead(false)}
        />
      )}
      <div className="flex flex-col gap-3 mt-6">
        <div className="flex flex-col gap-1 items-center w-full">
          <h1 className="text-xl font-semibold text-gray-800">{lead.title}</h1>
          <Button
            variant="outline"
            size="sm"
            className="py-0 text-xs"
            onClick={() => {
              setEditLead(true);
            }}
          >
            <Pencil className="h-3.5" />
            Edit lead
          </Button>
          <div className="flex flex-row gap-2">
            <p className="text-sm text-gray-400">{lead.name}</p>
          </div>
        </div>
        <Button
          onClick={() => setAddDeal(true)}
          className="w-full text-xs"
          variant="primary"
          disabled={lead.leadStages.type !== "CONVERTED"}
        >
          Convert to deal
        </Button>

        <div className="flex flex-row gap-1 justify-center items-center w-full">
          <div className="w-1 h-1 bg-green-500 rounded-full" />
          <p className="text-xs text-gray-400">
            Last activity: {lead.updatedAt?.toDateString() || "Not updated"}
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-3 mt-5">
        {lead.leadStages.type === "UNQUALIFIED" && (
          <div className="">
            <InfoItem
              label="Lost reason"
              labelClassName="text-red-500"
              value={lead?.lostReasons?.reason || "No lost reason added"}
            />
            <p className="text-xs text-gray-500">
              {lead.lostReasonDescription}
            </p>
          </div>
        )}
        <InfoItem label="Name" value={lead.name} />
        <InfoItem
          label="Mobile"
          value={
            lead.mobile ? (
              <div className="flex gap-1 items-center">
                <p className="text-sm font-medium text-gray-800">
                  {lead.mobile}
                </p>
                <CopyToClipboard copyText={lead.mobile} />
                <Button
                  onClick={() => {
                    messageOnWhatsApp(lead.mobile || "", "");
                  }}
                  iconify="logos:whatsapp-icon"
                  variant="ghost"
                  size="md"
                />
              </div>
            ) : (
              "No mobile added"
            )
          }
        />
        {!!lead.secondaryMobile && (
          <InfoItem
            label="Secondary mobile"
            value={
              lead.secondaryMobile ? (
                <div className="flex gap-1 items-center">
                  <p className="text-sm font-medium text-gray-800">
                    {lead.secondaryMobile}
                  </p>
                  <CopyToClipboard copyText={lead.secondaryMobile} />
                </div>
              ) : (
                "No mobile added"
              )
            }
          />
        )}
        <InfoItem
          label="Email"
          value={
            lead.email ? (
              <div className="flex gap-1 items-center">
                <p className="text-sm font-medium text-gray-800">
                  {lead.email}
                </p>
                <CopyToClipboard copyText={lead.email} />
              </div>
            ) : (
              "No email added"
            )
          }
        />
        <InfoItem label="Source" value={lead.crmSources?.name || "N/A"} />
        <InfoItem
          label="Expected value"
          value={
            lead.value
              ? formatCurrency(lead.value, lead.currencies.symbol)
              : "Not added"
          }
        />
        <InfoItem
          label="Expected close date"
          value={
            lead.expectedCloseDate
              ? format(lead.expectedCloseDate, DATE_WORD_FORMAT)
              : "Not set"
          }
        />
        <InfoItem
          label="Lead assignees"
          value={
            <div className="flex flex-row gap-2 items-center px-1">
              {lead.leadAssignees.map((assignee) => (
                <Badge key={assignee.userId} variant="unique" icon={User}>
                  {assignee.users.firstName}
                </Badge>
              ))}
              {lead.leadAssignees.length === 0 && (
                <Badge variant="error" icon={UserCircle}>
                  No assignees
                </Badge>
              )}
            </div>
          }
        />
        <InfoItem label="Lead owner" value={formatName(lead.ownedBy)} />

        {lead.customFieldFields &&
          lead.customFieldFields.map((field) => (
            <InfoItem
              key={field.id}
              label={field.label}
              value={renderCustomFieldValue(
                field,
                lead.customFields[field.name]
              )}
            />
          ))}
      </div>
    </ScrollArea>
  );

  const leadActivity = (
    <ScrollArea
      className="p-3 bg-white border-none sm:w-3/4 sm:block sm:bg-gray-100 sm:border-l bg sm:border-gray-200"
      style={{ height: PageHeight }}
    >
      <Card className="">
        {isMobile() ? (
          <Select
            options={leadStages.map((stage) => ({
              label: stage.label,
              value: stage.id,
              type: stage.type,
            }))}
            value={lead.stageId}
            className=""
            render={({ label, type }) => (
              <Badge
                variant={
                  type === "UNQUALIFIED"
                    ? "error"
                    : type === "CONVERTED"
                    ? "success"
                    : "default"
                }
                small={false}
              >
                {label}
              </Badge>
            )}
            onChange={(value) => {
              const stage = leadStages.find((s) => s.id === value);
              if (!stage) return;
              if (
                lead.leadStages.type !== "UNQUALIFIED" &&
                stage.type === "UNQUALIFIED"
              ) {
                heffl.modal.reason({
                  title: "Select lost reason",
                  type: "LEAD",
                  submitText: `Mark as ${stage.label}`,
                  onSubmit: (reason) => {
                    leadUpdateMutation.mutate({
                      id: leadId,
                      lead: {
                        stageId: stage.id,
                        lostReasonId: reason.reasonId,
                        lostReasonDescription: reason.description,
                      },
                    });
                  },
                });
              } else {
                leadUpdateMutation.mutate({
                  id: leadId,
                  lead: {
                    stageId: stage.id,
                  },
                });
              }
              if (stage.type === "CONVERTED") {
                setAddDeal(true);
              }
            }}
          />
        ) : (
          <div className="flex flex-row flex-grow mt-0 sm:mt-3">
            {leadStages.map((stage, index) => (
              <Stage
                key={stage.id}
                loading={false}
                onClick={() => {
                  if (
                    lead.leadStages.type !== "UNQUALIFIED" &&
                    stage.type === "UNQUALIFIED"
                  ) {
                    heffl.modal.reason({
                      title: "Select lost reason",
                      type: "LEAD",
                      submitText: `Mark as ${stage.label}`,
                      onSubmit: (reason) => {
                        leadUpdateMutation.mutate({
                          id: leadId,
                          lead: {
                            stageId: stage.id,
                            lostReasonId: reason.reasonId,
                            lostReasonDescription: reason.description,
                          },
                        });
                      },
                    });
                  } else {
                    leadUpdateMutation.mutate({
                      id: leadId,
                      lead: {
                        stageId: stage.id,
                      },
                    });
                  }
                  if (stage.type === "CONVERTED") {
                    setAddDeal(true);
                  }
                }}
                index={index}
                stageCount={leadStages.length}
                text={stage.label}
                selected={lead.stageId === stage.id}
                selectedIndex={
                  leadStages.findIndex((s) => s.id === lead.stageId) || 0
                }
                className={cn(
                  lead.leadStages.type === "UNQUALIFIED" && "bg-red-400"
                )}
              />
            ))}
          </div>
        )}
      </Card>

      <div className="flex gap-5 justify-center items-center mt-5 w-full sm:justify-end sm:gap-2">
        <DropMenu
          items={[
            {
              label: "Email",
              onClick: () =>
                setShowAdd((draft) => {
                  draft.log = "email";
                }),
              icon: Mail,
            },
            {
              label: "Call",
              onClick: () =>
                setShowAdd((draft) => {
                  draft.log = "call";
                }),
              icon: Phone,
            },
            {
              label: "Whatsapp message",
              onClick: () =>
                setShowAdd((draft) => {
                  draft.log = "whatsapp_message";
                }),
              icon: MessageCircle,
            },
            {
              label: "Meeting",
              onClick: () =>
                setShowAdd((draft) => {
                  draft.log = "meeting";
                }),
              icon: Calendar,
            },
            {
              label: "SMS",
              onClick: () =>
                setShowAdd((draft) => {
                  draft.log = "sms";
                }),
              icon: MessageSquare,
            },
          ]}
        >
          <MobileRoundedButton label="Log" icon={PenSquare} />
        </DropMenu>
        <MobileRoundedButton
          label="Note"
          icon={StickyNote}
          onClick={() =>
            setShowAdd((draft) => {
              draft.note = true;
            })
          }
        />
        <MobileRoundedButton
          label="Task"
          icon={CheckCircle}
          onClick={() =>
            setShowAdd((draft) => {
              draft.task = true;
            })
          }
        />
        <MobileRoundedButton
          label="Activity"
          icon={CalendarCheck2}
          onClick={() =>
            setShowAdd((draft) => {
              draft.activity = true;
            })
          }
        />
        <MobileRoundedButton
          label="Message"
          icon={MessageCircle}
          onClick={() =>
            setShowAdd((draft) => {
              draft.comment = true;
            })
          }
        />
      </div>
      <Leadfocus leadId={leadId} />
      <LeadHistory leadId={leadId} />
    </ScrollArea>
  );

  return (
    <Page
      className="!p-0"
      title="Lead details"
      fullWidth
      showBack
      breadcrumbs={[
        {
          label: "Leads",
          path: "/crm/leads/table",
        },
        {
          label: "Lead details",
          path: `/crm/leads/details/${lead.id}`,
        },
      ]}
    >
      {editContact && (
        <EditContactDrawer id={leadId} onClose={() => setEditContact(false)} />
      )}
      {/* <SelectLostReasonModal
        submitText="Mark as unqualified"
        open={showLostReason}
        onClose={() => setShowLostReason(false)}
        type="LEAD"
        defaultValues={{
          reasonId: lead.lostReasonId,
          description: lead.lostReasonDescription,
        }}
        onChange={(params) => {
          const firstUnqualifiedStage = leadStages.find(
            (stage) => stage.type === "UNQUALIFIED"
          );
          if (!firstUnqualifiedStage) return;

          leadUpdateMutation.mutate({
            id: leadId,
            lead: {
              lostReasonId: params.reasonId,
              lostReasonDescription: params.description,
              stageId: firstUnqualifiedStage.id,
            },
          });
        }}
      /> */}

      <AddNotesModal
        leadId={leadId}
        entity="LEAD"
        entityId={leadId}
        open={showAdd.note}
        onClose={() =>
          setShowAdd((draft) => {
            draft.note = false;
          })
        }
      />

      <AddCommentModal
        open={showAdd.comment}
        onClose={() => {
          setShowAdd((draft) => {
            draft.comment = false;
          });
        }}
        leadId={leadId}
      />
      <AddActivityModal
        leadId={leadId}
        open={showAdd.activity}
        onClose={() =>
          setShowAdd((draft) => {
            draft.activity = false;
          })
        }
      />
      <AddTaskModal
        open={showAdd.task}
        onClose={() =>
          setShowAdd((draft) => {
            draft.task = false;
          })
        }
        leadId={leadId}
      />
      {showAdd.log && (
        <AddLogModal
          open={!!showAdd.log}
          onClose={() =>
            setShowAdd((draft) => {
              draft.log = undefined;
            })
          }
          leadId={leadId}
          type={showAdd.log}
        />
      )}
      <AddDealDrawer
        open={addDeal}
        onClose={() => setAddDeal(false)}
        defaultValues={{
          crmSourceId: lead.crmSourceId,
          leadId: lead.id,
          ownerUserId: lead.ownerUserId,
          price: lead.value,
          title: `${lead.name}'s deal`,
          client: {
            name: lead.name,
            primaryContact: {
              mobile: lead.mobile,
              email: lead.email,
            },
            crmSourceId: lead.crmSourceId,
          },
        }}
        onAddDeal={async (id) => {
          const firstConvertedStage = leadStages.find(
            (stage) => stage.type === "CONVERTED"
          );

          if (!firstConvertedStage) return;
          await leadUpdateMutation.mutateAsync({
            id: leadId,
            lead: {
              stageId: firstConvertedStage?.id,
              archived: true,
            },
          });
          navigate(`/crm/deals/details/${id}`);
        }}
      />
      <div className="flex relative flex-col">
        {isMobile() ? (
          <NextTabs
            className="mt-3"
            tabs={[
              {
                id: "leadActivity",
                label: "Activity",
                content: leadActivity,
              },
              {
                id: "leadDetails",
                label: "Details",
                content: leadDetails,
              },
            ]}
          />
        ) : (
          <div className="flex flex-row w-full" style={{ height: PageHeight }}>
            {leadDetails}
            {leadActivity}
          </div>
        )}
      </div>
    </Page>
  );
};

export default LeadDetails;
