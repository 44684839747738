import * as React from "react";
import { Tooltip as AntTooltip } from "antd";
import { cn } from "@heffl/ui/lib/utils";

const Tooltip = ({
  children,
  content,
  delayDuration = 100,
  side = "top",
  showArrow = true,
  className,
}: {
  children: React.ReactNode;
  content: React.ReactNode | string;
  className?: string;
  htmlType?: "button";
  delayDuration?: number;
  side?: "top" | "bottom" | "left" | "right";
  showArrow?: boolean;
}) => {
  return (
    <AntTooltip
      title={content}
      mouseEnterDelay={delayDuration / 1000}
      placement={side}
      arrow={showArrow}
      overlayClassName={cn("!z-50 text-xs", !content && "hidden", className)}
    >
      {children}
    </AntTooltip>
  );
};

export { Tooltip };
