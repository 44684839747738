import { FC } from "react";
import { cn } from "../../lib/utils";

interface ProgressLabelProps {
  progress: number;
  suffix?: string;
  progressLabel?: string;
  className?: string;
  noColor?: boolean;
}
export const ProgressLabel: FC<ProgressLabelProps> = ({
  progress,
  progressLabel,
  suffix = "",
  noColor = false,
  className,
}) => {
  const getColor = () => {
    if (progress >= 90) return "text-green-600";
    if (progress >= 80) return "text-green-500";
    if (progress >= 70) return "text-lime-500";
    if (progress >= 60) return "text-yellow-500";
    if (progress >= 50) return "text-yellow-600";
    if (progress >= 40) return "text-orange-500";
    if (progress >= 30) return "text-orange-600";
    if (progress >= 20) return "text-red-400";
    if (progress >= 10) return "text-red-500";
    return "text-red-600";
  };

  return (
    <div
      className={cn(
        "inline-flex gap-1 items-center pl-1 pr-1.5 py-0.5 rounded-full ring-gray-500/20 ring-1 bg-white",
        className
      )}
    >
      <div className={cn("relative w-3.5 h-3.5", !noColor && getColor())}>
        <svg className="w-3.5 h-3.5 -rotate-90" viewBox="0 0 36 36">
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="opacity-20 stroke-current"
            strokeWidth="4"
          />
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="stroke-current"
            strokeWidth="4"
            strokeDasharray={100}
            strokeDashoffset={100 - progress}
            strokeLinecap="round"
            style={{
              transition: "stroke-dashoffset 0.4s ease-in-out",
            }}
          />
        </svg>
      </div>
      <span className={cn("text-xs font-medium", !noColor && getColor())}>
        {progressLabel || `${progress}%`}
        <span className="pl-1 font-normal text-gray-700">{suffix}</span>
      </span>
    </div>
  );
};
